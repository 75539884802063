<app-header-pages
  title="{{ 'routers.services.title' | translate: { id: service.id } }}"
  subTitle="{{ 'routers.services.subTitle' | translate }}"
  class="margin-top0"
></app-header-pages>

<div class="row margin-top0">
  <div class="col-2">
    <app-service-menu [service]="service"></app-service-menu>
  </div>
  <div class="col-10">
    <app-card [hidHeader]="true" headerClass="full-width">
      <div class="d-flex justify-content-between mb-2">
        <button class="btn btn-action" (click)="addRouteToService()">
          <mat-icon>add</mat-icon>
          {{ "routers.services.addRoute" | translate }}
        </button>
      </div>
      <app-dynamic-table
        [data]="dataSource"
        [columnsSetup]="columnsSetup"
        [spinner]="spinner"
        pageSize="10"
      >
        <ng-container matColumnDef="actions">
          <mat-header-cell
            *matHeaderCellDef
            scope="col"
            class="show-props"
          ></mat-header-cell>
          <mat-cell *matCellDef="let row" class="show-props">
            <button class="btn btn-icon" (click)="showOptions(row)">
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef scope="col">{{
            "routers.table.name" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a>{{ row | route }} </a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="methods">
          <mat-header-cell *matHeaderCellDef scope="col">{{
            "routers.table.methods" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div style="width: 100%">
              <app-methods [methods]="row.methods"></app-methods>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="service">
          <mat-header-cell
            *matHeaderCellDef
            scope="col"
            class="column-flex-10"
            >{{ "routers.table.service" | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="column-flex-10">
            {{ row.service.id }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="hosts">
          <mat-header-cell
            *matHeaderCellDef
            scope="col"
            class="column-flex-6"
            >{{ "routers.table.hosts" | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="column-flex-6">
            <ng-container *ngFor="let host of row.hosts">
              {{ host | nulleable }}
            </ng-container>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delete">
          <mat-header-cell
            *matHeaderCellDef
            scope="col"
            class="column-flex-6"
          ></mat-header-cell>
          <mat-cell *matCellDef="let row" class="column-flex-6">
            <button class="btn btn-delete" (click)="editRoute(row)">
              <mat-icon class="edit">edit</mat-icon>
              {{ "routers.table.edit" | translate }}
            </button>
            <button class="btn btn-delete" (click)="deleteRouter(row.id)">
              <mat-icon class="delete">delete</mat-icon>
              {{ "routers.table.delete" | translate }}
            </button>
          </mat-cell>
        </ng-container>
      </app-dynamic-table>
    </app-card>
  </div>
</div>
