import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Audit } from '../../../core/model/audit.model';
import { AuditService } from '../../../services/apis-old/audit.service';
import { ColumnSetup } from 'src/app/components/dynamic-table/dynamic-table.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';

// import 'moment/locale/pt-br';

export interface RowItem {
  principal: string;
  timestamp: string;
  type: string;
}

@Component({
  selector: 'app-auditorias',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class AuditComponent implements OnInit {
  public pageEvent: PageEvent;
  public total = 0;
  public pages = 0;
  public itemsPerPage = 10;
  public spinner = false;
  public dataSource;
  public predicate: string;
  public ascending: boolean;
  public columnsToDisplay = [];

  public range = new FormGroup({
    start: new FormControl(
      moment().add(-1, 'M').format('YYYY-MM-DD'),
      Validators.required
    ),
    end: new FormControl(moment().format('YYYY-MM-DD'), Validators.required),
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private auditoriasService: AuditService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadData(0);
  }

  closeDate(): void {
    if (this.range.valid) {
      this.loadData(0);
    }
  }

  changePages(e: PageEvent) {
    this.itemsPerPage = e.pageSize;
    this.loadData(e.pageIndex);
  }

  private sort(): string[] {
    const result = [this.predicate + ',' + (this.ascending ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  private loadData(pageIndex: number): void {
    this.dataSource = [];
    this.spinner = true;
    this.auditoriasService
      .query({
        page: pageIndex,
        size: this.itemsPerPage,
        sort: this.sort(),
        fromDate: moment(this.range.value.start).format('YYYY-MM-DD'),
        toDate: moment(this.range.value.end).format('YYYY-MM-DD'),
      })
      .subscribe(
        (res: HttpResponse<Audit[]>) => {
          this.dataSource = res.body;
          this.total = parseInt(res.headers.get('X-Total-Count'));
          this.pages = Math.ceil(this.total / this.itemsPerPage);
          this.columnsToDisplay = Object.keys(res.body[0]);
        },
        (err) => {
          this.spinner = false;
        }
      );
  }
}
