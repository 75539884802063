import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'nulleable'
})
export class NulleablePipe implements PipeTransform {

    transform(value: any, ...args: unknown[]): any {
        if (value) {
            return value;
        }
        return '-';
    }

}
