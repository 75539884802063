<app-header-pages title="{{'upstreams.title' | translate }}" subTitle="{{'upstreams.subTitle' | translate }}"></app-header-pages>


<div class="d-flex justify-content-between">
    <button class="btn btn-action" (click)="createUpstream()">
        <mat-icon>add</mat-icon>
        {{'upstreams.table.new'|translate}}
    </button>
    <!--    <app-filter-table #filter></app-filter-table>-->
</div>

<app-dynamic-table [data]="dataSource" [columnsSetup]="columnsSetup" [spinner]="spinner" pageSize="10" noData="{{'upstreams.table.noData'|translate}}">
    <ng-container matColumnDef="testUrl">
        <mat-header-cell *matHeaderCellDef scope="col" class="column-flex-3"></mat-header-cell>
        <mat-cell *matCellDef="let row;" class="column-flex-3">
            <button class="btn" (click)="getUpstreamOptions(row)">
                <mat-icon>visibility</mat-icon>
            </button>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef scope="col">{{ 'upstreams.table.name' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <a (click)="editUpstream(row)">{{ row.name }} </a>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tags">
        <mat-header-cell *matHeaderCellDef scope="col">{{ 'upstreams.table.tags' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <app-tags [tags]="row.tags"></app-tags>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <button class="btn btn-delete" (click)="showDetails(row)">
                <mat-icon class="edit">description</mat-icon>
                {{ 'upstreams.table.description' | translate }}</button>
            <button class="btn btn-delete" (click)="editUpstream(row)">
                <mat-icon class="edit">edit</mat-icon>
                {{ 'upstreams.table.edit' | translate }}</button>
            <button class="btn btn-delete" (click)="deleteUpstream(row.id)">
                <mat-icon class="delete">delete</mat-icon>
                {{ 'upstreams.table.delete' | translate }}</button>
        </mat-cell>
    </ng-container>
</app-dynamic-table>
