import {Directive, HostListener} from '@angular/core';

@Directive({
    selector: 'input[numberLetter]'
})
export class FormatNumbersLettersDirective {

    constructor() {
    }

    @HostListener('window:keydown', ['$event']) onKeyDownWindow(event: any) {
        const eventKey = event as KeyboardEvent;
        const pattern = /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ]+/;

        if (pattern.test(eventKey.key)) {
            return true;
        } else {
            eventKey.preventDefault();
            return false;
        }
    }
}
