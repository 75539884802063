import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LocalstorageService } from 'src/app/services/localstorage.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material/dialog';
import { SpecService } from 'src/app/services/gateway/spec.service';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'bread-spec-content',
  templateUrl: './bread-spec.component.html',
  styleUrls: ['./bread-spec.component.scss'],
})
export class BreadSpecComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}
}
