import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ColumnSetup } from '../../../components/dynamic-table/dynamic-table.component';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SweetAlertComponent } from '../../../components/sweet-alert/sweet-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalServiceComponent } from './modal-service/modal-service.component';
import { ServicesService } from '../../../services/gateway/services.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ModalOptionsComponent } from './modal-options/modal-options.component';
import { Router } from '@angular/router';
import { ConnectionsService } from '../../../services/apis/connections.service';
import { TranslateService } from '@ngx-translate/core';

export interface RowItem {
  id: string;
  name: string;
  host: string;
  tags: string;
  url: string;
  created_at: string;
  ca_certificates: string;
  client_certificate: string;
  path: string;
  port: number;
  protocol: string;
  read_timeout: number;
  retries: number;
  tls_verify: string;
  tls_verify_depth: string;
  updated_at: number;
  write_timeout: number;
  connect_timeout: number;
}

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit, AfterViewInit {
  indexTab = 0;
  isEdit = false;
  notOpenTab = true;
  public spinner = false;
  public dataSource: RowItem[] = [];
  public columnsSetup: ColumnSetup[] = [
    { columnDef: 'view', title: '', custom: true },
    { columnDef: 'name', title: '', custom: true },
    {
      columnDef: 'host',
      title: 'services.table.host',
      cell: (row: RowItem) => `${row.host}`,
    },
    { columnDef: 'tags', title: '', custom: true },
    {
      columnDef: 'createdDate',
      title: 'services.table.created',
      cell: (row: RowItem) =>
        `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`,
    },
    { columnDef: 'delete', title: '', custom: true },
  ];
  tabs: string[] = [];

  constructor(
    private translate: TranslateService,
    private dateFormat: DatePipe,
    private servicesService: ServicesService,
    private toastr: ToastrService,
    private sweet: SweetAlertComponent,
    private router: Router,
    private connectionService: ConnectionsService,
    public dialog: MatDialog
  ) {}

  ngAfterViewInit(): void {
    this.getServices();
    this.connectionService.getConnectionEmitter().subscribe((value) => {
      if (value) {
        this.getServices();
      }
    });
  }

  ngOnInit(): void {}
  openService(): void {}

  showOptions(row): void {
    const dialogRef = this.dialog.open(ModalOptionsComponent, {
      height: 'auto',
      width: '750px',
      disableClose: false,
      data: row,
    });
    dialogRef.componentInstance.title = 'services.modal.title_show';
    dialogRef.componentInstance.buttonCancel = 'services.modal.button_cancel';
  }

  editService(row: RowItem): void {
    const dialogRef = this.dialog.open(ModalServiceComponent, {
      height: 'auto',
      width: '750px',
      disableClose: false,
      data: row,
    });
    dialogRef.componentInstance.title = 'services.modal.title_edit';
    dialogRef.componentInstance.buttonCancel = 'services.modal.button_cancel';
    dialogRef.componentInstance.buttonEdit = 'services.modal.button_edit';
    dialogRef.componentInstance.modalOption = 'edit';
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getServices();
      }
    });
  }

  statusService(id): void {}

  deleteService(id): void {
    this.translate.get('services.message').subscribe((translate) => {
      this.sweet.confirmBox({
        title: translate.confirm.title,
        text: translate.confirm.text,
        confirmButtonText: translate.confirm.button,
        showCancelButton: true,
        alertAction: (result) => {
          if (result.isConfirmed) {
            this.servicesService.deleteService(id).subscribe(
              () => {
                this.toastr.success(translate.deleted);
                this.getServices();
              },
              (err) => {
                this.sweet.error({
                  title: translate.error,
                });
              }
            );
          }
        },
      });
    });
  }

  private getServices(): void {
    this.dataSource = [];
    this.spinner = true;
    this.servicesService.getServices().subscribe(
      (response: RowItem[]) => {
        this.spinner = false;
        this.dataSource = response;
      },
      (err) => {
        console.log(err);
        this.spinner = false;
      }
    );
  }

  addService(): void {
    const dialogRef = this.dialog.open(ModalServiceComponent, {
      height: 'auto',
      width: '750px',
      disableClose: false,
    });
    dialogRef.componentInstance.title = 'services.modal.title_open';
    dialogRef.componentInstance.buttonCancel = 'services.modal.button_cancel';
    dialogRef.componentInstance.buttonCreate = 'services.modal.button_create';
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getServices();
      }
    });
  }

  selectionChange(event: MatTabChangeEvent): void {
    this.indexTab = event.index;
  }

  removeTab(index: number): void {
    this.tabs.splice(index, 1);
    this.isEdit = false;
    this.notOpenTab = true;
  }
}
