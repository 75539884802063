import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-methods',
    templateUrl: './method.component.html',
    styleUrls: ['./method.component.scss']
})
export class MethodComponent implements OnInit {
    @Input() methods: string[] = [];

    constructor() {
    }

    ngOnInit(): void {
    }

}
