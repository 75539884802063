import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {filter, map} from 'rxjs/operators';
import {RoutesModel} from '../../../core/model/routes.model';
import {RouterService} from '../../../services/gateway/router.service';

@Injectable({providedIn: 'root'})
export class RouterResolve implements Resolve<RoutesModel> {
    constructor(private service: RouterService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RoutesModel> {
        const id = route.params.id;
        if (id) {
            return this.service.getRoutersById(id).pipe(
                filter((response: HttpResponse<RoutesModel>) => response.ok),
                map((response: HttpResponse<RoutesModel>) => response.body)
            );
        }
        return of(null);
    }
}
