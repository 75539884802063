import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  IAlbum,
  IEvent,
  Lightbox,
  LIGHTBOX_EVENT,
  LightboxConfig,
  LightboxEvent,
} from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/model/login.model';

import { LocalstorageService } from 'src/app/services/localstorage.service';
import { UsersService } from '../../../../services/apis-old/users.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IOption } from 'ng-select';
import { SelectOptionService } from 'src/app/theme/shared/components/select/select-option.service';
import 'rxjs/add/operator/map';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ServicesService } from 'src/app/services/apis-old/services.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public activeTab: string;

  public editProfile: boolean;
  public editProfileIcon: string;

  public editContact: boolean;
  public editContactIcon: string;

  public editOtherInfo: boolean;
  public editOtherInfoIcon: string;

  public albums: Array<IAlbum>;
  private subscription: Subscription;

  public user: User;

  public formDatosPersonales: FormGroup;
  public formDatosSistema: FormGroup;

  simpleOption: Array<IOption>;

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;

  constructor(
    private lightbox: Lightbox,
    private lightboxEvent: LightboxEvent,
    private lighboxConfig: LightboxConfig,
    private sanitizer: DomSanitizer,
    private usersService: UsersService,
    private fb: FormBuilder,
    public selectOptionService: SelectOptionService,
    public toastr: ToastrService,
    private localstorageService: LocalstorageService,
    private servicesServices: ServicesService
  ) {
    this.activeTab = 'profile';

    this.editProfile = false;
    this.editProfileIcon = 'icon-edit';

    this.editContact = false;
    this.editContactIcon = 'icon-edit';

    this.editOtherInfo = false;
    this.editOtherInfoIcon = 'icon-edit';

    this.albums = [];
    for (let i = 1; i <= 6; i++) {
      const album = {
        src: 'assets/images/light-box/l' + i + '.jpg',
        caption: 'Image ' + i + ' caption here',
        thumb: 'assets/images/light-box/sl' + i + '.jpg',
      };

      this.albums.push(album as IAlbum);
    }
    lighboxConfig.fadeDuration = 1;
  }

  ngOnInit(): void {
    this.getAuthorities();
    this.user = this.localstorageService.getItemObject<User>('user');

    this.loadForm();
  }

  private loadForm(): void {
    this.formDatosPersonales = this.fb.group({
      nombre: [this.user?.firstName],
      apellido: [this.user?.lastName],
      mail: [this.user?.email],
      user: [this.user?.login],
      perfilesUser: [this.user?.authorities],
      estado: [this.user?.activated],
      idioma: [this.user?.langKey],
      imageUrl: [this.user?.imageUrl],
    });

    this.formDatosSistema = this.fb.group({});
  }

  public getSantizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  open(index: number): void {
    this.subscription = this.lightboxEvent.lightboxEvent$.subscribe(
      (event: IEvent) => this._onReceivedEvent(event)
    );
    this.lightbox.open(this.albums, index, {
      wrapAround: true,
      showImageNumberLabel: true,
    });
  }

  private _onReceivedEvent(event: IEvent): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this.subscription.unsubscribe();
    }
  }

  getAuthorities(): void {
    this.servicesServices.getUsersAuthorities().subscribe(
      (resp) => {
        this.simpleOption = resp.map((role) => ({ value: role, label: role }));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onSubmit(): void {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor...',
    });
    Swal.showLoading();
    // TODO: Use EventEmitter with form value
    const requestuser: any = {
      id: this.user.id,
      login: this.formDatosPersonales.controls.user.value,
      firstName: this.formDatosPersonales.controls.nombre.value,
      lastName: this.formDatosPersonales.controls.apellido.value,
      email: this.formDatosPersonales.controls.mail.value,
      authorities: this.formDatosPersonales.controls.perfilesUser.value,
      langKey: this.formDatosPersonales.controls.idioma.value,
      activated: this.formDatosPersonales.controls.estado.value,
      imageUrl: this.formDatosPersonales.controls.imageUrl.value,
      permissions: [],
    };

    this.usersService.actualizarUsuario(requestuser).subscribe(
      (resp) => {
        Swal.close();
        this.editProfile = !this.editProfile;
        this.editProfileIcon = this.editProfile ? 'icon-x' : 'icon-edit';
        this.localstorageService.removeItem('user');
        this.localstorageService.setItem<User>('user', resp);
        this.user = resp;
        this.toastr.success('Datos actualizados con exito');
      },
      (err) => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Error al actualizar',
          text: '',
        });
      }
    );
  }
}
