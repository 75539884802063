import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ICatalog, ICategory} from '../../pages/developer-portal/catalog/catalog.model';

@Component({
    selector: 'app-catalogs',
    templateUrl: './catalogs.component.html',
    styleUrls: ['./catalogs.component.scss']
})
export class CatalogsComponent implements OnInit {
    @Input() catalogs: ICatalog[] = [];

    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
    }

    getMoreCategories(): string {
        return this.translate.instant('catalog.categories.more', {count: this.catalogs.length - 1});
    }
}
