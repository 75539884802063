import {Component, Input, OnInit} from '@angular/core';
import {Service} from '../../../../core/model/service.model';

@Component({
    selector: 'app-service-menu',
    templateUrl: './service-menu.component.html',
    styleUrls: ['./service-menu.component.scss']
})
export class ServiceMenuComponent implements OnInit {

    @Input()
    service: Service;

    constructor() {
    }

    ngOnInit(): void {
    }

}
