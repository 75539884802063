import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { endpoint } from '../../core/constants/services.constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SchemasService {
  private URL_BASE: string = environment.url_services;
  private ENDPOINT_SCHEMAS_SERVICES = `${this.URL_BASE}${endpoint.ENDPOINT_SCHEMAS_SERVICES}`;
  private ENDPOINT_SCHEMAS_ROUTES = `${this.URL_BASE}${endpoint.ENDPOINT_SCHEMAS_ROUTES}`;
  private ENDPOINT_SCHEMAS_CONSUMERS = `${this.URL_BASE}${endpoint.ENDPOINT_SCHEMAS_CONSUMERS}`;
  private ENDPOINT_SCHEMAS_CERTIFICATES = `${this.URL_BASE}${endpoint.ENDPOINT_SCHEMAS_CERTIFICATES}`;
  private ENDPOINT_SCHEMAS_CA_CERTIFICATES = `${this.URL_BASE}${endpoint.ENDPOINT_SCHEMAS_CA_CERTIFICATES}`;

  private ENDPOINT_SCHEMAS_UPSTREAMS = `${this.URL_BASE}${endpoint.ENDPOINT_SCHEMAS_UPSTREAMS}`;

  constructor(private http: HttpClient) {}

  getServices(): Observable<any> {
    return this.http.get(this.ENDPOINT_SCHEMAS_SERVICES).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getRouters(): Observable<any> {
    return this.http.get(this.ENDPOINT_SCHEMAS_ROUTES).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getConsumers(): Observable<any> {
    return this.http.get(this.ENDPOINT_SCHEMAS_CONSUMERS).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCertificates(): Observable<any> {
    return this.http.get(this.ENDPOINT_SCHEMAS_CERTIFICATES).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCaCertificates(): Observable<any> {
    return this.http.get(this.ENDPOINT_SCHEMAS_CA_CERTIFICATES).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getUpstreams(): Observable<any> {
    return this.http.get(this.ENDPOINT_SCHEMAS_UPSTREAMS).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
