import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LocalstorageService} from '../../services/localstorage.service';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

    constructor(private localStorageService: LocalstorageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                },
            });
        }
        if (request.body) {
            const body = request.body;
            if (!this.isString(body)) {
                const claves = Object.keys(body);
                let requestBody = {};
                let containsValues = false;
                claves.forEach(key => {
                    if (body[key] !== null && body[key] !== undefined) {
                        containsValues = true;
                        const newObject = {[key]: body[key]};
                        requestBody = Object.assign(requestBody, newObject);
                    }
                });
                if (containsValues) {
                    request = request.clone({
                        body: requestBody
                    });
                }
            }
        }
        const env = this.localStorageService.getItem('environment');
        if (env) {
            request = request.clone({
                setHeaders: {
                    Environment: env,
                },
            });
        }
        return next.handle(request).pipe(
            catchError(this.mensajeError)
        );
    }

    mensajeError(error: HttpErrorResponse): Observable<never> {
        // return throwError('error personalizado');
        return throwError(error);
    }

    isString(value): boolean {
        return typeof value === 'string' || value instanceof String;
    }
}


// export class AuthInterceptor implements HttpInterceptor {
//   constructor(private localStorage: LocalStorageService, private sessionStorage: SessionStorageService) {}

//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     if (!request || !request.url || (request.url.startsWith('http') && !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL)))) {
//       return next.handle(request);
//     }

//     const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
//     if (token) {
//       request = request.clone({
//         setHeaders: {
//           Authorization: 'Bearer ' + token,
//         },
//       });
//     }
//     return next.handle(request);
//   }
// }
