import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTab, MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {ConnectionsService} from 'src/app/services/apis/connections.service';
import {CheckUrlService} from 'src/app/services/apis/check-url.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {MessageError} from 'src/app/core/interfaces/message-error.interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-modal-info',
    templateUrl: './modal-info.component.html',
    styleUrls: ['./modal-info.component.scss']
})
export class ModalInfoComponent implements OnInit, AfterViewInit {

    public tabIndex = 0;
    public formConnection: FormGroup;
    title = '';
    selectedTab = 0;
    buttonCreate = '';
    buttonEdit = '';
    buttonCancel = '';
    modalOption = 'create';
    MESSAGE_REQUIRED = 'info.message.required';
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    @ViewChild(MatTabGroup) tabGroup;

    constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        private connectionService: ConnectionsService,
        private checkUrl: CheckUrlService,
        private toastr: ToastrService,
        public dialog: MatDialogRef<ModalInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const tabs: MatTab[] = this.tabGroup._tabs._results;
            this.selectedTab = tabs.findIndex((element: MatTab) => element.ariaLabel === (this.data && this.data.authType));
        });
    }

    ngOnInit(): void {
        this.formConnection = this.buildForm();
        this.setValidatorsForm('');
        if (this.data !== undefined) {
            this.updateForm(this.data);

        }
    }

    setValidatorsForm(type: string): void {
        this.cleanValidators();
        this.formConnection.controls.algorithm.setValue('NONE');
        switch (type) {
            case 'JWT_AUTH':
                this.formConnection.controls.nameConnection.setValidators([Validators.required, Validators.maxLength(15)]);
                this.formConnection.controls.url.setValidators([Validators.required]);
                this.formConnection.controls.secret.setValidators([Validators.required]);
                this.formConnection.controls.algorithm.setValidators([Validators.required]);
                this.formConnection.controls.algorithm.setValue('HS256');
                this.formConnection.controls.keyJwt.setValidators([Validators.required]);
                this.formConnection.controls.environment.setValidators([Validators.required]);
                break;
            case 'KEY_AUTH':
                this.formConnection.controls.nameConnection.setValidators([Validators.required, Validators.maxLength(15)]);
                this.formConnection.controls.url.setValidators([Validators.required]);
                this.formConnection.controls.apiKey.setValidators([Validators.required]);
                this.formConnection.controls.environment.setValidators([Validators.required]);
                this.formConnection.controls.keyName.setValidators([Validators.required]);
                break;
            case 'BASIC_AUTH':
                this.formConnection.controls.nameConnection.setValidators([Validators.required]);
                this.formConnection.controls.url.setValidators([Validators.required]);
                this.formConnection.controls.usernameBasic.setValidators([Validators.required]);
                this.formConnection.controls.passwordBasic.setValidators([Validators.required]);
                this.formConnection.controls.environment.setValidators([Validators.required]);
                break;
            default:
                this.formConnection.controls.nameConnection.setValidators([Validators.required, Validators.maxLength(15)]);
                this.formConnection.controls.url.setValidators([Validators.required]);
                this.formConnection.controls.environment.setValidators([Validators.required]);
                break;
        }
    }

    cleanValidators(): void {
        const keys = Object.keys(this.formConnection.value);
        keys.forEach(key => {
            this.formConnection.get(key).clearValidators();
        });
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.tabIndex = tabChangeEvent.index;
        const tabChange = tabChangeEvent.tab.ariaLabel;
        this.setValidatorsForm(tabChange);
        this.formConnection.patchValue({
            authType: tabChangeEvent.tab.ariaLabel
        });
    }

    isValid(type: string, name: string): boolean {
        return this.formConnection.get(name).hasError(type);
    }

    buildForm(): FormGroup {
        return this.fb.group({
            id: [null],
            nameConnection: [''],
            url: [null],
            apiKey: [''],
            keyJwt: [''],
            secret: [''],
            algorithm: [''],
            usernameBasic: [''],
            passwordBasic: [''],
            environment: [''],
            keyName: [''],
            authType: ['NONE']
        });
    }

    submit(form: AbstractControl): void {
        if (form.invalid) {
            this.translate.get('info.message').subscribe(translate => {
                this.toastr.error(translate.invalidForm);
                return;
            });
        }

        const formValues = form.value;
        if (this.modalOption === 'create') {
            const requestServer = {
                id: formValues.id,
                nombre: formValues.nameConnection,
                environment: formValues.environment.trim(),
                url: formValues.url,
                authType: formValues.authType,
                algoritmo: formValues.algorithm,
                key: formValues.keyJwt,
                secret: formValues.secret,
                userName: formValues.usernameBasic,
                password: formValues.passwordBasic,
                apiKey: formValues.apiKey,
                apiKeyHeaderName: formValues.keyName,
                activo: false
            };

            this.translate.get('info.message').subscribe(translate => {
                this.connectionService.createServer(requestServer)
                    .subscribe(() => {
                        this.toastr.success(translate.created);
                        this.dialog.close();
                    }, (err) => {
                        this.toastr.error(translate.error);
                        console.error(err);
                    });
            });
        } else {
            const jwtValue = formValues.keyJwt;
            const secretValue = formValues.secret;
            const requestServer = {
                id: formValues.id,
                nombre: formValues.nameConnection,
                environment: formValues.environment.trim(),
                url: formValues.url,
                authType: formValues.authType,
                algoritmo: formValues.algorithm,
                key: jwtValue,
                secret: secretValue,
                userName: formValues.usernameBasic,
                password: formValues.passwordBasic,
                apiKey: formValues.apiKey,
                apiKeyHeaderName: formValues.keyName
            };
            this.translate.get('info.message').subscribe(translate => {
                this.connectionService.updateServer(requestServer)
                    .subscribe(() => {
                        this.toastr.success(translate.updated);
                        this.dialog.close();
                    }, (err) => {
                        this.toastr.error(translate.error);
                        console.error(err);
                    });
            });
        }
    }

    updateForm(row: any): void {
        this.formConnection.patchValue({
            id: row.id,
            nameConnection: row.nombre,
            environment: row.environment,
            url: row.url,
            authType: row.authType,
            algorithm: row.algoritmo,
            keyJwt: row.key,
            secret: row.secret,
            usernameBasic: row.userName,
            passwordBasic: row.password,
            apiKey: row.apiKey,
            keyName: row.apiKeyHeaderName,
            activo: row.activo
        });
    }
}
