import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IUser, User} from 'src/app/core/model/login.model';

import {LocalstorageService} from 'src/app/services/localstorage.service';
import {ServicesService} from 'src/app/services/apis-old/services.service';
import {UsersService} from 'src/app/services/apis-old/users.service';
import {ToastrService} from 'ngx-toastr';

import {ColumnSetup} from '../../../../components/dynamic-table/dynamic-table.component';
import {MessageError} from 'src/app/core/interfaces/message-error.interface';
import {ConnectionsService} from '../../../../services/apis/connections.service';
import {EnvironmentPermission, IConnection, IEnvironmentPermission, ISelectedPermission, Permission} from '../../connections/connection.model';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertComponent} from '../../../../components/sweet-alert/sweet-alert.component';
import {Router} from '@angular/router';
import {ENVIRONMENT_PERMISSION, ENVIRONMENT_SCOPE, GLOBAL_SCOPE, ROLE_AUDIT} from '../../../../core/constants/app.constants';
import {MatSelectChange} from '@angular/material/select';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

export interface RowItem {
  id: string;
  name: string;
  menu: string;
  label: string;
  permission: string;
  scope: string;
}

export const valuesAuth = {
  admin: 'ROLE_ADMIN',
  user: 'ROLE_USER'
};

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  public optionsAuth = [
    {value: valuesAuth.admin, view: 'userManagement.detalle.admin'},
    {value: valuesAuth.user, view: 'userManagement.detalle.user'}
  ];
  public dataSource: RowItem[] = [];
  public dataSourceGlobal: RowItem[] = [];
  public columnsSetup: ColumnSetup[] = [
    {columnDef: 'permisos', title: 'userManagement.permission.title', cell: (row: RowItem) => `${row.label}`},
    {columnDef: 'deny', title: 'Deny', custom: true},
    {columnDef: 'read', title: 'Read', custom: true},
    {columnDef: 'write', title: 'Write', custom: true},
  ];
  public user: User;
  public formDatos: FormGroup;
  public ambientes: FormArray;
  public permisos: FormArray;
  environments: EnvironmentPermission[] = [];
  environmentsToSelect: EnvironmentPermission[] = [];
  selectedEnvironments: EnvironmentPermission[] = [];
  environmentsToShow: Set<EnvironmentPermission> = new Set<EnvironmentPermission>();

  MESSAGE_REQUIRED = 'connections.message.required';

  position = 'bottom-right';
  title: string;
  theme = 'bootstrap';
  type = 'default';

  environmentMaps = new Map<string, IEnvironmentPermission>();
  selectedPermissions: Set<Permission> = new Set<Permission>();
  globalPermissions: Permission[] = [];
  auditPermission: string[] = [];
  userHasAuditRole = false;

  public messageError: MessageError = {
    required: [
      {type: 'required', message: this.MESSAGE_REQUIRED}
    ]
  };
  step = 0;
  environmentsControl: FormControl;

  constructor(
    private sanitizer: DomSanitizer,
    private servicesServices: ServicesService,
    private usersService: UsersService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private connectionService: ConnectionsService,
    private localstorageService: LocalstorageService,
    private translate: TranslateService,
    private sweet: SweetAlertComponent,
    private route: Router,
  ) {
    this.ambientes = new FormArray([]);
    this.permisos = new FormArray([]);
    this.environmentsControl = new FormControl([]);
  }

  ngOnInit(): void {
    this.getPermission();
    this.getGlobalPermission();
    this.user = this.localstorageService.getItemObject<User>('user');
    this.environments = this.localstorageService.getItemObject<EnvironmentPermission[]>(ENVIRONMENT_PERMISSION);
    this.environments.forEach(value => this.environmentsToSelect.push(value));
    this.loadForm();
  }

  private loadForm(): void {
    this.formDatos = new FormGroup({
      login: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      authorities: new FormControl(valuesAuth.admin, [Validators.required]),
      langKey: new FormControl('es'),
      imageUrl: new FormControl(''),
      activated: new FormControl(true),
      environments: new FormControl([], [Validators.required]),
      environmentPermissions: this.ambientes,
    });
  }

  getSantizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  isValid(type: string, name: string): boolean {
    return this.formDatos.get(name).hasError(type);
  }

  getPermission(): void {
    this.usersService.getPermissionByScope(ENVIRONMENT_SCOPE).subscribe((response: RowItem[]) => {
      const permMap = response.map(item => {
        return [item.menu, {
          name: item.name.replace('READ', 'DENY').replace('WRITE', 'DENY'),
          menu: item.menu,
          label: item.label,
          permission: 'DENY',
          scope: item.scope,
          id: item.id,
        }];
      }).sort() as any;

      const permMapArr = new Map(permMap);
      const unicos = [...permMapArr.values()] as RowItem[];
      const keys = [...permMapArr.keys()] as string[];
      this.dataSource = this.getData(keys, response, unicos) as RowItem[];
    });
  }

  getGlobalPermission(): void {
    this.usersService.getPermissionByScope(GLOBAL_SCOPE).subscribe((response: RowItem[]) => {
      this.globalPermissions = response;
      const permMap = response.map(item => {
        return [item.menu, {
          name: item.name.replace('READ', 'DENY').replace('WRITE', 'DENY'),
          menu: item.menu,
          label: item.label,
          permission: 'DENY',
          scope: item.scope,
          id: item.id,
        }];
      }).sort() as any;

      const permMapArr = new Map(permMap);
      const unicos = [...permMapArr.values()] as RowItem[];
      const keys = [...permMapArr.keys()] as string[];
      this.dataSourceGlobal = this.getData(keys, response, unicos) as RowItem[];
    });
  }

  getData(arrKeys: string[], arrOriginal: RowItem[], arrUnique: RowItem[]): RowItem[] {
    return arrKeys.reduce((acc, value) => {
      const objectValue = arrUnique.find(f => f.menu === value);
      const permissions = arrOriginal
        .filter(f => f.menu === value)
        .map(m => ({[m.permission.toLowerCase()]: m.permission}))
        .reduce((accPer, valuePer) => {
          return {...accPer, ...valuePer};
        }, {});
      return [...acc, {...objectValue, permissions}];
    }, []) as RowItem[];
  }

  crearNuevoUsuario(): void {
    const translates = [
      'userManagement.detalle.dialogText',
      'userManagement.detalle.confirmText',
      'userManagement.detalle.errorText'
    ];
    this.translate.get(translates).subscribe(translation => {
      this.sweet.basic({
        allowOutsideClick: false,
        icon: 'info',
        text: translation['userManagement.detalle.dialogText'],
      });
      this.sweet.loading();
      const createdUser = this.createFromForm();
      const environment = this.localstorageService.getItem('environment');
      this.usersService.crearUsuario(createdUser, environment)
        .subscribe(resp => {
          this.user = resp;

          this.sweet.close();
          this.sweet.confirmBox({
            title: translation['userManagement.detalle.confirmText'],
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            alertAction: () => {
              this.route.navigateByUrl('/admin/management/users');
            }
          });
        }, () => {
          this.sweet.error({
            icon: 'error',
            title: translation['userManagement.detalle.errorText'],
            text: 'err'
          });
        });
    });

  }

  protected createFromForm(): IUser {
    const environmentPermissions = [];
    this.environmentMaps.forEach(value => environmentPermissions.push(value));
    const globalPermissions = [];
    this.selectedPermissions.forEach(value => globalPermissions.push(value));
    return {
      ...new User(),
      id: this.formDatos.get(['id'])?.value,
      login: this.formDatos.get(['login'])?.value,
      firstName: this.formDatos.get(['firstName'])?.value,
      lastName: this.formDatos.get(['lastName'])?.value,
      email: this.formDatos.get(['email'])?.value,
      authorities: [this.formDatos.get(['authorities'])?.value, ...this.auditPermission],
      langKey: this.formDatos.get(['langKey'])?.value,
      imageUrl: this.formDatos.get(['imageUrl'])?.value,
      activated: this.formDatos.get(['activated'])?.value,
      environmentPermissions,
      globalPermissions,
    };
  }

  selectPermission(event: ISelectedPermission): void {
    const {name, menu, label, permission, scope, id} = event.permission;
    const perm: Permission = {name, menu, label, permission, scope, id};
    if (this.environmentMaps.has(event.environment)) {
      const environmentPerms = this.environmentMaps.get(event.environment);
      const element = environmentPerms.permissions.findIndex(value => value.menu === menu);
      if (element >= 0) {
        environmentPerms.permissions.splice(element, 1);
      }
      environmentPerms.permissions.push(perm);
    } else {
      const organization = this.localstorageService.getItem('organization');
      const permissionEnv = new EnvironmentPermission(event.environment, organization);
      permissionEnv.permissions.push(perm);
      this.environmentMaps.set(event.environment, permissionEnv);
    }
  }

  changeSelect(menu: string, permission: string): void {
    const elementsIndex = this.dataSourceGlobal.findIndex(element => element.menu === menu);
    const newArray = [...this.dataSourceGlobal];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      name: newArray[elementsIndex].name.replace('DENY', permission).replace('READ', permission).replace('WRITE', permission),
      permission
    };
    this.dataSourceGlobal = newArray;
    const globalPermission = this.globalPermissions.find(element => element.menu === menu);
    if (globalPermission && this.notHasPermission(globalPermission)) {
      this.selectedPermissions.add(globalPermission);
    }
  }

  selectEnvironment(event: MatSelectChange): void {
    const element = event.value as EnvironmentPermission;
    const organization = this.localstorageService.getItem('organization');
    const permission = new EnvironmentPermission(element.environment, organization);
    const has = this.environmentsToShow.has(permission);
    if (!has) {
      this.environmentsToShow.add(element);
    }
    this.selectedEnvironments = [];
    this.environmentsToShow.forEach(value => this.selectedEnvironments.push(value));
    this.environmentMaps.set(permission.environment, permission);
  }

  isEmptyEnvironment(): boolean {
    return this.environmentsToShow.size === 0;
  }

  notHasPermission(global: Permission): boolean {
    const {name, menu, permission, scope, label, id} = global;
    return !this.selectedPermissions.has({name, menu, permission, scope, label, id});
  }

  removeEnvironment(env: EnvironmentPermission): void {
    const environments = this.selectedEnvironments;
    this.removeFirst(environments, env);
    this.environmentsToShow.delete(env);
    this.selectedEnvironments = [];
    this.environmentsToShow.forEach(value => this.selectedEnvironments.push(value));
  }

  removeFirst(array: EnvironmentPermission[], toRemove: EnvironmentPermission): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  compareEnvironments(inicio: EnvironmentPermission, fin: EnvironmentPermission): boolean {
    return inicio === fin || inicio.environment === fin.environment;
  }

  selectAuditRole(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.auditPermission.push(ROLE_AUDIT);
    } else {
      this.auditPermission = [];
    }
    this.userHasAuditRole = event.checked;
  }

  nextStep(): void {
    this.step++;
  }

  backStep(): void {
    this.step--;
  }

  finish(): void {
    this.step++;
  }
}
