import {EnvironmentPermission, Permission} from '../../pages/management/connections/connection.model';

export interface Login {
    username: string;
    password: string;
    rememberMe: boolean;
}

export interface IUser {
    id?: string;
    login: string;
    firstName: string;
    lastName: string;
    email: string;
    imageUrl?: any;
    activated: boolean;
    langKey: string;
    createdBy?: string;
    createdDate?: Date;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    authorities: string[];
    environmentPermissions?: EnvironmentPermission[];
    globalPermissions?: Permission[];
}

export class User {

    constructor(
        public id?: string,
        public login?: string,
        public firstName?: string,
        public lastName?: string,
        public email?: string,
        public imageUrl?: string,
        public activated?: boolean,
        public langKey?: string,
        public createdBy?: string,
        public createdDate?: Date,
        public lastModifiedBy?: string,
        public lastModifiedDate?: Date,
        public authorities?: string[],
        public environmentPermissions?: EnvironmentPermission[],
        public globalPermissions?: Permission[],
    ) {
    }
}
