import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RoutesModel} from '../../../../core/model/routes.model';

@Component({
    selector: 'app-router-options',
    templateUrl: './router-options.component.html',
    styleUrls: ['./router-options.component.scss']
})
export class RouterOptionsComponent implements OnInit {
    routesModel: RoutesModel;
    title = '';
    buttonCancel = '';

    constructor(public dialog: MatDialogRef<RouterOptionsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.routesModel = data;
    }

    ngOnInit(): void {
    }

}
