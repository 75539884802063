import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SelectModule } from 'ng-select';
import { SelectOptionService } from 'src/app/theme/shared/components/select/select-option.service';
import { ThemeModule } from 'src/app/theme/theme.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeRoutingModule } from './home-routing.module';
import { ComponentsModule } from '../../components/components.module';
import { SpecComponent } from './spec/spec.component';
import { SpecContentComponent } from './spec/spec-content/spec-content.component';
import { ModalSpecComponent } from './spec/modal-spec/modal-spec.component';
import { BreadSpecComponent } from './spec/bread-spec/bread-spec.component';
import { MaterialModule } from '../../material.module';
import { HomeComponent } from './home.component';

@NgModule({
  declarations: [
    DashboardComponent,
    SpecComponent,
    HomeComponent,
    SpecContentComponent,
    ModalSpecComponent,
    BreadSpecComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ThemeModule,
    TranslateModule,
    SelectModule,
    MaterialModule,
    ComponentsModule,
  ],
  exports: [BreadSpecComponent],
  providers: [SelectOptionService],
})
export class HomeModule {}
