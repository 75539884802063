import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { endpoint } from '../../core/constants/services.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CaCertificateService {
  private URL_BASE: string = environment.url_services;
  private ENDPOINT_CA_CERTIFICATE = `${this.URL_BASE}${endpoint.ENDPOINT_CA_CERTIFICATE}`;
  private ENDPOINT_KONG_CA_CERTIFICATE = `${this.URL_BASE}${endpoint.KONG_CA_CERTIFICATE}`;

  constructor(private http: HttpClient) {}

  getCertificates(): Observable<any> {
    return this.http.get(`${this.ENDPOINT_KONG_CA_CERTIFICATE}`).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  getCertificate(id: string): Observable<any> {
    return this.http.get(`${this.ENDPOINT_CA_CERTIFICATE}/${id}`);
  }

  createCertificate(body: any): Observable<any> {
    return this.http.post(this.ENDPOINT_KONG_CA_CERTIFICATE, body);
  }

  updateCertificate(body: any): Observable<any> {
    return this.http.put(this.ENDPOINT_CA_CERTIFICATE, body);
  }

  deleteCertificate(id: string): Observable<any> {
    return this.http.delete(`${this.ENDPOINT_CA_CERTIFICATE}/${id}`);
  }

  statusCertificate(id: string, status): Observable<any> {
    const params = new HttpParams().append('status', status);
    return this.http.post(
      `${this.ENDPOINT_CA_CERTIFICATE}/${id}/activate`,
      params
    );
  }
}
