<form [formGroup]="formJwt" (ngSubmit)="submit()">
    <div class="container">
        <h1 mat-dialog-title>{{'consumers.jwt.modal.title' | translate }}</h1>
        <hr>
        <div mat-dialog-content>
            <div class="row">
                <div class="col-12">
                    <label>{{ 'consumers.jwt.modal.key.placeholder' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="key"/>
                        <mat-hint>{{ 'consumers.jwt.modal.key.hint' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'key')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'consumers.jwt.modal.algorithm.placeholder' | translate }}</label>

                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="algorithm">
                            <mat-option *ngFor="let opt of algoritmos" [value]="opt">{{ opt }}</mat-option>
                        </mat-select>
                        <mat-hint>{{ 'consumers.jwt.modal.algorithm.hint' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'algorithm')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'consumers.jwt.modal.rsaPublicKey.placeholder' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="rsaPublicKey"/>
                        <mat-hint>{{ 'consumers.jwt.modal.rsaPublicKey.hint' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'rsaPublicKey')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'consumers.jwt.modal.secret.placeholder' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="secret"/>
                        <mat-hint>{{ 'consumers.jwt.modal.secret.hint' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'secret')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div mat-dialog-actions align="end" class="mt-2 pl-3">
            <button class="btn btn-close" [mat-dialog-close]="undefined">{{ 'consumers.modal.buttonCancel' | translate }} </button>
            <button class="btn btn-action" [disabled]="formJwt.invalid">{{ 'consumers.jwt.save' | translate }}</button>
        </div>
    </div>
</form>
