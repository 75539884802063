<div class="user-profile user-card mb-4">
    <div class="card-header border-0 p-0 pb-0">
        <div class="cover-img-block">
            <div class="overlay"></div>
            <div class="change-cover">
                <div class="dropdown" ngbDropdown placement="bottom-left">
                    <a class="drp-icon dropdown-toggle" ngbDropdownToggle data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="icon feather icon-camera"></i></a>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:"><i class="feather icon-upload-cloud mr-2"></i>upload
                            new</a>
                        <a class="dropdown-item" href="javascript:"><i class="feather icon-image mr-2"></i>from
                            photos</a>
                        <a class="dropdown-item" href="javascript:"><i class="feather icon-film mr-2"></i> upload
                            video</a>
                        <a class="dropdown-item" href="javascript:"><i class="feather icon-trash-2 mr-2"></i>remove</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body py-0">
        <div class="user-about-block m-0">
            <div class="row">
                <div class="col-md-4 text-center mt-n5">
                    <div class="change-profile text-center">
                        <div class="dropdown w-auto d-inline-block" ngbDropdown>
                            <a class="dropdown-toggle" data-toggle="dropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                                <div class="profile-dp">
                                    <div class="position-relative d-inline-block">
                                        <img class="img-radius img-fluid wid-100" [src]="user?.imageUrl" alt="User Image"/>
                                        <!-- onError="this.src='assets/images/user/img-default.jpg'"/>-->
                                    </div>
                                    <div class="overlay">
                                        <span>change</span>
                                    </div>
                                </div>
                                <div class="certificated-badge">
                                    <i class="fas fa-certificate text-c-blue bg-icon"></i>
                                    <i class="fas fa-check front-icon text-white"></i>
                                </div>
                            </a>
                            <div class="dropdown-menu" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript:"><i
                                        class="feather icon-upload-cloud mr-2"></i>upload new</a>
                                <a class="dropdown-item" href="javascript:"><i class="feather icon-image mr-2"></i>from
                                    photos</a>
                                <a class="dropdown-item" href="javascript:"><i
                                        class="feather icon-shield mr-2"></i>Protact</a>
                                <a class="dropdown-item" href="javascript:"><i
                                        class="feather icon-trash-2 mr-2"></i>remove</a>
                            </div>
                        </div>
                    </div>
                    <h5 class="mb-1">{{ user?.login }}</h5>
                    <p class="mb-2 text-muted">{{ user?.firstName }} {{ user?.lastName }}</p>
                </div>
                <div class="col-md-8 mt-md-4">
                    <div class="row">
                        <div class="col-md-6">
                            <a href="javascript:" class="mb-1 text-muted d-flex align-items-end text-h-primary"><i
                                    class="feather icon-globe mr-2 f-18"></i>{{ user?.langKey == 'es' ? 'Español' : 'Ingles'}}</a>
                            <div class="clearfix"></div>
                            <a href="mailto:demo@domain.com" class="mb-1 text-muted d-flex align-items-end text-h-primary"><i
                                    class="feather icon-mail mr-2 f-18"></i>{{user?.email}}</a>
                            <div class="clearfix"></div>
                            <a href="javascript:" class="mb-1 text-muted d-flex align-items-end text-h-primary"><i
                                    class="feather icon-phone mr-2 f-18"></i>+1 9999-999-999</a>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="media">
                                <i class="feather icon-map-pin mr-2 mt-1 f-18"></i>
                                <div class="media-body">
                                    <p class="mb-0 text-muted">4289 Calvin Street</p>
                                    <p class="mb-0 text-muted">Baltimore, near MD Tower Maryland,</p>
                                    <p class="mb-0 text-muted">Maryland (21201)</p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!--<ul class="nav nav-tabs profile-tabs nav-fill" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'profile'}"
                                (click)="this.activeTab = 'profile'" id="profile-tab" data-toggle="tab"
                                href="javascript:" role="tab" aria-controls="profile" aria-selected="false">
                                <i class="feather icon-user mr-2"></i>Profile
                            </a>
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- profile body start -->
<div class="row">
    <div class="offset-md-2 col-md-8 order-md-2">
        <!--<div class="tab-content" id="myTabContent">-->

        <!--<div class="tab-pane fade" [ngClass]="{'show active': this.activeTab === 'profile'}" id="profile"
                role="tabpanel" aria-labelledby="profile-tab">-->
        <div class="merge-card">

            <app-card [hidHeader]="true" cardClass="mb-0" blockClass="d-flex align-items-center justify-content-between">
                <h5 class="mb-0">{{ 'profile.title' | translate }}</h5>
                <button type="button" (click)="this.editProfile = !this.editProfile; this.editProfileIcon = this.editProfile ? 'icon-x': 'icon-edit';" class="btn btn-primary btn-sm rounded m-0 float-right" data-toggle="collapse" data-target=".pro-det-edit" aria-expanded="false"
                        aria-controls="pro-det-edit-1 pro-det-edit-2">
                    <i class="feather" [ngClass]="editProfileIcon"></i>
                </button>
            </app-card>

            <app-card [hidHeader]="true" blockClass="border-top pro-det-edit collapse show" *ngIf="!editProfile">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label font-weight-bolder">ID</label>
                    <div class="col-sm-9">
                        {{ user?.id }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.login' | translate }}</label>
                    <div class="col-sm-9">
                        {{ user?.login }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.firstName' | translate }}</label>
                    <div class="col-sm-9">
                        {{ user?.firstName }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.lastName' | translate }}</label>
                    <div class="col-sm-9">
                        {{ user?.lastName }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.email' | translate }}</label>
                    <div class="col-sm-9">
                        {{ user?.email }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.langKey' | translate }}</label>
                    <div class="col-sm-9">
                        {{ user?.langKey == 'es' ? ('profile.detalle.es' | translate) : ('profile.detalle.en' | translate)}}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.state' | translate }}</label>
                    <div class="col-sm-9">
                        {{ user?.activated ? ('profile.detalle.enabled' | translate) : ('profile.detalle.disabled' | translate)}}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.profiles' | translate }}</label>
                    <div class="col-sm-9">
                        <h5><span class="badge m-r-5 badge-primary" *ngFor="let item of user?.authorities">{{ item }}</span></h5>
                    </div>
                </div>
                <!-- <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Fecha de Nacimiento</label>
                            <div class="col-sm-9">
                                16-12-1994
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Location</label>
                            <div class="col-sm-9">
                                <p class="mb-0 text-muted">4289 Calvin Street</p>
                                <p class="mb-0 text-muted">Baltimore, near MD Tower Maryland,</p>
                                <p class="mb-0 text-muted">Maryland (21201)</p>
                            </div>
                        </div> -->
            </app-card>
            <app-card [hidHeader]="true" blockClass="border-top pro-det-edit collapse show" *ngIf="editProfile">
                <form [formGroup]="formDatosPersonales" (ngSubmit)="onSubmit()">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">ID</label>
                        <div class="col-sm-9">
                            {{ user?.id }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.login' | translate }}</label>
                        <div class="col-sm-9">
                            <input formControlName="user" type="text" class="form-control" placeholder="Login">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.firstName' | translate }}</label>
                        <div class="col-sm-9">
                            <input formControlName="nombre" type="text" class="form-control" placeholder="{{ 'profile.detalle.firstName' | translate }}">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.lastName' | translate }}</label>
                        <div class="col-sm-9">
                            <input formControlName="apellido" type="text" class="form-control" placeholder="{{ 'profile.detalle.lastName' | translate }}">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.email' | translate }}</label>
                        <div class="col-sm-9">
                            <input formControlName="mail" type="mail" class="form-control" placeholder="example@mail.com">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.langKey' | translate }}</label>
                        <div class="col-sm-9">
                            <select class="form-control" formControlName="idioma">
                                <option value="es">{{ 'profile.detalle.es' | translate }}</option>
                                <option value="en">{{ 'profile.detalle.en' | translate }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.state' | translate }}</div>
                        <div class="col-sm-9">
                            <div class="switch switch-primary d-inline m-r-10">
                                <input type="checkbox" formControlName="estado" id="switch-p-1" checked>
                                <label for="switch-p-1" class="cr"></label>
                            </div>
                            <label>{{ user?.activated ? ('profile.detalle.enabled' | translate) : ('profile.detalle.disabled' | translate) }}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.profile' | translate }}</label>
                        <div class="col-sm-9">
                            <ng-select [ngClass]="'ng-select'" [options]="simpleOption" [multiple]="true" formControlName="perfilesUser">
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'profile.detalle.imageUrl' | translate }}</label>
                        <div class="col-sm-9">
                            <input formControlName="imageUrl" type="text" class="form-control" placeholder="{{ 'profile.detalle.imageUrl' | translate }}">
                        </div>
                    </div>
                    <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label font-weight-bolder">Gender</label>
                                <div class="col-sm-9">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadioInline1" name="customRadioInline1"
                                            class="custom-control-input" checked>
                                        <label class="custom-control-label" for="customRadioInline1">Male</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadioInline2" name="customRadioInline1"
                                            class="custom-control-input">
                                        <label class="custom-control-label" for="customRadioInline2">Female</label>
                                    </div>
                                </div>
                            </div> -->
                    <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label font-weight-bolder">Fecha de Nacimiento</label>
                                <div class="col-sm-9">
                                    <input type="date" class="form-control" value="1994-12-16">
                                </div>
                            </div> -->
                    <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label font-weight-bolder">Martail Status</label>
                                <div class="col-sm-9">
                                    <select class="form-control" id="exampleFormControlSelect1">
                                        <option>Select Marital Status</option>
                                        <option>Married</option>
                                        <option selected>Unmarried</option>
                                    </select>
                                </div>
                            </div> -->
                    <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label font-weight-bolder">Location</label>
                                <div class="col-sm-9">
                                    <textarea
                                        class="form-control">4289 Calvin Street,  Baltimore, near MD Tower Maryland, Maryland (21201)</textarea>
                                </div>
                            </div> -->
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-6">
                            <button class="btn btn-success btn-block" type="submit" [disabled]="!formDatosPersonales.valid">Guardar</button>
                        </div>
                    </div>
                </form>
            </app-card>
        </div>
        <!--</div> -->


        <!--</div>-->
    </div>

</div>
