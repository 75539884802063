<form [formGroup]="formBasic" (ngSubmit)="submit()">
    <div class="container">
        <h1 mat-dialog-title>{{'consumers.basic.modal.title' | translate }}</h1>
        <hr>
        <div mat-dialog-content>
            <div class="row">
                <div class="col-12">
                    <label>{{ 'consumers.basic.modal.username.placeholder' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="username"/>
                        <mat-hint>{{ 'consumers.basic.modal.username.hint' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'username')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'consumers.basic.modal.password.placeholder' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="password"/>
                        <mat-hint>{{ 'consumers.basic.modal.password.hint' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'password')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div mat-dialog-actions align="end" class="mt-2 pl-3">
            <button class="btn btn-close" [mat-dialog-close]="undefined">{{ 'consumers.modal.buttonCancel' | translate }} </button>
            <button class="btn btn-action" [disabled]="formBasic.invalid">{{ 'consumers.basic.save' | translate }}</button>
        </div>
    </div>
</form>
