<app-header-pages
  title="{{ 'apis.title' | translate }}"
  subTitle="{{ 'apis.subTitle' | translate }}"
></app-header-pages>
<div class="justify-content-between">
  <div class="d-flex justify-content-between mb-2">
    <a [routerLink]="['/admin/gateway/apis/create']">
      <button class="btn btn-action">
        <mat-icon>add</mat-icon>
        {{ "apis.table.newApi" | translate }}
      </button>
    </a>
  </div>
  <app-dynamic-table
    [data]="dataSource"
    [columnsSetup]="columnsSetup"
    [spinner]="spinner"
    pageSize="10"
  >
    <ng-container matColumnDef="delete">
      <mat-header-cell
        *matHeaderCellDef
        scope="col"
        class="column-flex-6"
      ></mat-header-cell>
      <mat-cell *matCellDef="let row" class="column-flex-6">
        <button class="btn btn-delete" (click)="editApi(row)">
          <mat-icon class="edit">edit</mat-icon>
          {{ "apis.table.edit" | translate }}
        </button>
        <button class="btn btn-delete" (click)="deleteApi(row.id)">
          <mat-icon class="delete">delete</mat-icon>
          {{ "apis.table.delete" | translate }}
        </button>
      </mat-cell>
    </ng-container>
  </app-dynamic-table>
</div>
