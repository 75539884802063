import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepper-button',
  templateUrl: './stepper-button.component.html',
  styleUrls: ['./stepper-button.component.scss'],
})
export class StepperButtonComponent implements OnInit {
  @Input() stepperNext: boolean;
  @Input() stepperBack: boolean;
  @Input() upload: boolean;
  @Input() spinner: boolean;
  @Input() condition: boolean;
  @Input() callback: Function;
  @Input() uploadCallback: Function;

  @Input() text: string;

  ngOnInit(): void {
    if (!this.text) {
      this.text = 'Next';
    }
    if (this.stepperBack !== false) {
      this.stepperBack = true;
    }
    if (!this.stepperNext) {
      this.stepperNext = true;
    }

    if (!this.stepperNext) {
      this.stepperNext = true;
    }
  }

  handleCallback() {
    if (this.callback) {
      this.callback();
    }
  }

  handleCallbackUpload() {
    if (this.uploadCallback) {
      this.uploadCallback();
    }
  }
}
