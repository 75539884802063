import {Component, Input, OnInit} from '@angular/core';
import {ConsumerJwtCredential, ConsumerModel} from '../../../../../core/model/consumer.model';
import {ColumnSetup} from '../../../../../components/dynamic-table/dynamic-table.component';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {AddJwtComponent} from './add-jwt/add-jwt.component';
import {ToastrService} from 'ngx-toastr';
import {SweetAlertComponent} from '../../../../../components/sweet-alert/sweet-alert.component';
import {ConsumerService} from '../../../../../services/gateway/consumer.service';
import {TranslateService} from '@ngx-translate/core';

export interface RowItem {
    id: string;
    name: string;
    client_secret: string;
    client_id: string;
    redirect_uris: string;
    tags: string;
    created_at: string;
}


@Component({
    selector: 'app-jwt-credential',
    templateUrl: './jwt-credential.component.html',
    styleUrls: ['./jwt-credential.component.scss']
})
export class JwtCredentialComponent implements OnInit {
    @Input() jwtCredentials: ConsumerJwtCredential[] = [];
    @Input() consumer: ConsumerModel;
    public spinner = false;
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'json', title: '', custom: true},
        {
            columnDef: 'createdDate',
            title: 'consumers.table.createdDate',
            cell: (row: RowItem) => `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`
        },
        {columnDef: 'actions', title: '', custom: true},
    ];

    constructor(private dateFormat: DatePipe,
                public dialog: MatDialog,
                private toastr: ToastrService,
                private sweet: SweetAlertComponent,
                private translate: TranslateService,
                private consumerService: ConsumerService) {
    }

    ngOnInit(): void {
    }

    deleteJwt(id): void {
        this.translate.get('consumers.message').subscribe(translate => {
            this.sweet.confirmBox({
                title: translate.confirm.title,
                text: translate.confirm.text,
                confirmButtonText: translate.confirm.button,
                showCancelButton: true,
                alertAction: (result) => {
                    if (result.isConfirmed) {
                        this.spinner = true;
                        this.consumerService.deleteJwtCredential(id, this.consumer.id).subscribe(() => {
                                this.toastr.success(translate.jwt.deleted);
                                this.getJwtCredential();
                            },
                            (err) => {
                                console.log(err);
                                this.sweet.error({
                                    title: translate.error
                                });
                            }
                        );
                    }
                }
            });
        });
    }

    getJwtCredential(): void {
        this.consumerService.getJwtAuthCredential(this.consumer.id).subscribe(res => {
            this.spinner = false;
            this.jwtCredentials = res;
        });
    }

    addJwt(): void {
        const dialogRef = this.dialog.open(AddJwtComponent, {
            height: 'auto',
            width: '750px',
            disableClose: false,
            data: this.consumer
        });
        dialogRef.afterClosed().subscribe((jwt) => {
            if (jwt) {
                this.getJwtCredential();
            }
        });
    }
}
