<app-card [hidHeader]="true" headerClass="full-width">
    <div class="navbar-content">
        <div class="collapse" id="nav-user-link" [style.display]="'block'">
            <ul class="list-unstyled">
                <hr style="margin-bottom: 0rem; margin-top: 1rem;"/>
                <li class="list-group-item">
                    <a (click)="selectCredential(0)" class="hand-cursor">
                        <i class="feather icon-user m-r-5"></i>
                        {{ 'consumers.basic.menuTitle' | translate }}
                    </a>
                </li>
                <li class="list-group-item">
                    <a (click)="selectCredential(1)" class="hand-cursor">
                        <i class="feather icon-log-out m-r-5"></i>
                        {{ 'consumers.key.menuTitle' | translate }}
                    </a>
                </li>
                <li class="list-group-item">
                    <a (click)="selectCredential(2)" class="hand-cursor">
                        <i class="feather icon-log-out m-r-5"></i>
                        {{ 'consumers.hmac.menuTitle' | translate }}
                    </a>
                </li>
                <li class="list-group-item">
                    <a (click)="selectCredential(3)" class="hand-cursor">
                        <i class="feather icon-log-out m-r-5"></i>
                        {{ 'consumers.oauth.menuTitle' | translate }}
                    </a>
                </li>
                <li class="list-group-item">
                    <a (click)="selectCredential(4)" class="hand-cursor">
                        <i class="feather icon-log-out m-r-5"></i>
                        {{ 'consumers.jwt.menuTitle' | translate }}
                    </a>
                </li>
                <hr style="margin-top: 0rem; margin-bottom: 0rem;"/>
            </ul>
        </div>
    </div>
</app-card>
