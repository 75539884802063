export const endpoint = {
  ENDPOINT_AUTH: '/api/authenticate',
  ENDPOINT_ACCOUNT: '/api/account',
  ENDPOINT_REGISTER: '/api/register',
  ENDPOINT_CHANGE_PASSWORD: '/api/account/change-password',
  ENDPOINT_RESET_INIT: '/api/account/reset-password/init',
  ENDPOINT_RESET_FINISH: '/api/account/reset-password/finish',
  ENDPOINT_ACTIVATE: '/api/activate',
  ENDPOINT_CONNECTIONS: '/api/servers',
  ENDPOINT_SERVICES: '/api/services',
  KONG_SERVICES: '/kong/services',
  ENDPOINT_API: '/api/apis',
  ENDPOINT_API_SPECS: '/api/apis/specs',
  KONG_API: '/kong/api',
  ENDPOINT_CERTIFICATE: '/api/certificates',
  ENDPOINT_CA_CERTIFICATE: '/api/ca_certificates',
  KONG_CERTIFICATE: '/kong/certificates',
  KONG_CA_CERTIFICATE: '/kong/ca_certificates',
  ENDPOINT_CONSUMER: '/api/consumers',
  KONG_CONSUMER: '/kong/consumers',
  ENDPOINT_PLUGINS: '/api/plugins',
  KONG_PLUGINS: '/kong/plugins',
  ENDPOINT_ROUTER: '/api/routes',
  KONG_ROUTER: '/kong/routes',
  ENDPOINT_UPSTREAM: '/api/upstreams',
  KONG_UPSTREAM: '/kong/upstreams',
  ENDPOINT_INFORMATION: '/api/upstreams',
  KONG_INFORMATION: '/kong/upstreams',
  KONG_STATUS: '/kong/status',
  KONG_NODE: '/kong',
  ENDPOINT_PORTALS: '/api/portals',
  ENDPOINT_MENU: '/api/portals/menus',
  ENDPOINT_CATALOG: '/api/portals/catalog',
  ENDPOINT_SPECS: '/api/specs',
  ENDPOINT_SPECS_CONTENTS: '/api/specs/contents',
  ENDPOINT_CONTENT: '/api/specs/find-content',
  ENDPOINT_SPEC_UPLOAD: '/api/specs/contents/openapi',
  ENDPOINT_CATEGORIES: '/api/categories',
  ENDPOINT_CATEGORY: '/api/portals/category',
  ENDPOINT_CATALOG_DOWNLOAD: '/api/download-spec',
  ENDPOINT_PAGES: '/api/portals/pages',
  ENDPOINT_CSS: '/api/portals/font-styles',
  ENDPOINT_SETTINGS: '/api/portals/settings',
  ENDPOINT_SCHEMAS_SERVICES: '/kong/schemas/services',
  ENDPOINT_SCHEMAS_ROUTES: '/kong/schemas/routes',
  ENDPOINT_SCHEMAS_CONSUMERS: '/kong/schemas/consumers',
  ENDPOINT_SCHEMAS_CERTIFICATES: '/kong/schemas/certificates',
  ENDPOINT_SCHEMAS_CA_CERTIFICATES: '/kong/schemas/ca_certificates',
  ENDPOINT_SCHEMAS_UPSTREAMS: '/kong/schemas/upstreams',
};
