<div class="container">
  <div mat-dialog-content>
    <div class="label-connection mt-2">
      <div class="header-container">
        <span>Home</span>
        <button
          class="btn btn-action"
          (click)="goBackToSpec()"
          [disabled]="specVisited.length == 0"
        >
          <mat-icon style="margin-right: 5px">arrow_back</mat-icon>
          Regresar
        </button>
      </div>
    </div>
    <ng-container *ngIf="specs.length > 0; else noSpecs">
      <div class="example-list">
        <div class="example-list-header">
          <p style="float: left">Name</p>
        </div>
        <ng-container *ngIf="specs.length > 0">
          <mat-selection-list
            class="example-list"
            [multiple]="false"
            (selectionChange)="selectItem($event)"
          >
            <mat-list-option
              class="example-box"
              *ngFor="let spec of specs"
              [value]="spec"
            >
              <div class="mt-1" matLine>
                <p style="float: left">
                  <ng-container *ngIf="spec.kind == 'Folder'; else documento">
                    <mat-icon
                      style="position: relative; top: 7px; margin-right: 8px"
                      >folder</mat-icon
                    >
                  </ng-container>
                  <ng-template #documento>
                    <mat-icon
                      style="position: relative; top: 7px; margin-right: 8px"
                      >insert_drive_file</mat-icon
                    >
                  </ng-template>
                  <span>{{ spec.name }}</span>
                </p>
              </div>
            </mat-list-option>
          </mat-selection-list>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <ng-template #noSpecs>
    <div class="alert alert-warning" role="alert">
      No specs found in this folder
    </div>
  </ng-template>
</div>
