<app-header-pages title="{{'apis.title' | translate }}" subTitle="{{'apis.subTitle' | translate }}"></app-header-pages>
<form [formGroup]="formulario" (submit)="updateRevision()">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-3">
                    <h6>{{'apis.revision'|translate}}</h6>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="server">
                            <mat-option *ngFor="let revision of revisions" [value]="revision.id">
                            <span style="margin-right: 25px">
                                Revision {{revision.revision}}
                            </span>
                                <span class="badge badge-primary separation" style="font-size: 13px;">
                                {{revision.environment}}
                            </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-6">
                    <h6>{{'apis.table.deployment'|translate}}</h6>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="deployment">
                            <mat-option *ngFor="let deployment of deployments" [value]="deployment.environment" style="justify-content: space-between">
                            <span style="margin-right: 25px">
                                {{deployment.environment}}
                            </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-6 pt-4">
                    <button class="btn btn-action" type="button" (click)="updateRevision()">
                        <mat-icon>add</mat-icon>
                        {{'apis.table.changeRevision'|translate}}
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h5>{{'apis.table.deployment'|translate}}</h5>
                </div>
                <div class="col-12">
                    <app-dynamic-table [data]="dataSource" [columnsSetup]="columnsSetup" [spinner]="spinner" [showPage]="false"></app-dynamic-table>
                </div>
            </div>
            <div class="row" style="margin-top: 20px;">
                <div class="col-12">
                    <h5>{{'apis.table.proxyEndpoint'|translate}}</h5>
                </div>
                <div class="col-12">
                    <app-dynamic-table [data]="dataEndPoint" [columnsSetup]="columnsEndpoint" [spinner]="spinner" [showPage]="false"></app-dynamic-table>
                </div>
            </div>
        </div>
    </div>
</form>
