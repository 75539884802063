<app-header-pages
  title="{{ 'spec.title' | translate }}"
  subTitle="{{ 'spec.subTitle' | translate }}"
></app-header-pages>

<div class="d-flex justify-content-between">
  <button [matMenuTriggerFor]="menu" class="btn btn-action">
    <mat-icon>add</mat-icon>
    {{ "spec.menu.title" | translate }}
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="createFolder()">
      {{ "spec.menu.options.folder" | translate }}
    </button>
    <a [routerLink]="['/admin/home/specs/content']">
      <button mat-menu-item>{{ "spec.menu.options.spec" | translate }}</button>
    </a>
    <button mat-menu-item (click)="createSpecFromFile()">
      {{ "spec.menu.options.import" | translate }}
    </button>
  </mat-menu>
</div>

<div class="row mt-1">
  <div class="col-12 col-lg-12">
    <app-dynamic-table
      [data]="dataSource"
      [columnsSetup]="columnsSetup"
      [spinner]="spinner"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef scope="col" class="show-props">{{
          "spec.table.name" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="show-props">
          <div *ngIf="row.kind === 'Folder'">
            <a
              [routerLink]="['/admin/home/specs/folder/', row.id]"
              (click)="addNewPath(row)"
            >
              <button class="btn btn-icon">
                <mat-icon>{{ selectIcon(row.kind) }}</mat-icon>
              </button>
              {{ row.name }}
            </a>
          </div>
          <div *ngIf="row.kind != 'Folder'">
            <a [routerLink]="['/admin/home/specs/content/', row.id]">
              <button class="btn btn-icon">
                <mat-icon svgIcon="open-api"></mat-icon>
              </button>
              {{ row.name }}
            </a>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef scope="col" class="show-props">{{
          "spec.table.actions" | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-1">
                <a
                  [routerLink]="['/admin/gateway/apis/create', row.name]"
                  *ngIf="row.kind != 'Folder'"
                >
                  <div class="btn btn-delete">
                    <mat-icon class="edit">cloud</mat-icon>
                    {{ "spec.table.api" | translate }}
                  </div>
                </a>
              </div>
              <div class="col-1">
                <a
                  [routerLink]="['/admin/home/specs/content/', row.id]"
                  *ngIf="row.kind != 'Folder'"
                >
                  <div class="btn btn-delete">
                    <mat-icon class="edit">edit</mat-icon>
                    {{ "spec.table.edit" | translate }}
                  </div>
                </a>
                <button
                  class="btn btn-delete"
                  (click)="editFolder(row)"
                  *ngIf="row.kind === 'Folder'"
                >
                  <mat-icon class="edit">edit</mat-icon>
                  {{ "spec.table.edit" | translate }}
                </button>
              </div>
              <div class="col-1">
                <button class="btn btn-delete" (click)="delete(row)">
                  <mat-icon class="delete">delete</mat-icon>
                  {{ "spec.table.delete" | translate }}
                </button>
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>
    </app-dynamic-table>
  </div>
</div>
