import {NgModule} from '@angular/core';
import {FormatNumbersLettersDirective} from './directives/format-numbers-letters.directive';

@NgModule({
    imports: [],
    declarations: [
        FormatNumbersLettersDirective
    ],
    exports: [
        FormatNumbersLettersDirective
    ]
})
export class CoreModule {
}
