import {IPortal} from '../portal/portal.model';
import {Moment} from 'moment';

export interface ICatalog {
    id?: string;
    title?: string;
    displayTitle?: string;
    displayDescription?: string;
    displayImage?: string;
    documentationType?: DocumentationType;
    categories?: ICategory[];
    status?: boolean;
    visibility?: boolean;
    published?: boolean | null;
    createDate?: Moment | null;
    lastModifiedDate?: Moment | null;
    portal?: IPortal | null;
    content?: IContent | null;
}

export class Catalog implements ICatalog {
    constructor(
        public id?: string,
        public title?: string,
        public displayTitle?: string,
        public displayDescription?: string,
        public displayImage?: string,
        public documentationType?: DocumentationType,
        public categories?: ICategory[],
        public status?: boolean,
        public visibility?: boolean,
        public published?: boolean,
        public createDate?: Moment | null,
        public lastModifiedDate?: Moment | null,
        public portal?: IPortal | null,
        public content?: IContent | null,
    ) {
        this.status = this.status ?? false;
        this.visibility = this.visibility ?? false;
        this.published = this.published ?? false;
        this.categories = this.categories || [];
    }
}

export enum DocumentationType {
    NO_DOCUMENTATION = 'NO_DOCUMENTATION',
    DOCUMENTATION = 'DOCUMENTATION',
}

export function getMenuIdentifier(catalog: ICatalog): string | undefined {
    return catalog.id;
}

export interface ICategory {
    id?: string;
    name?: string;
    apis?: string[];
}

export class Category implements ICategory {
    constructor(
        public id?: string,
        public name?: string,
        public apis?: string[],
    ) {
    }
}

export interface ISpec {
    id?: string;
    parentId?: string;
    content?: string;
    kind?: string;
    name?: string;
}

export interface IContent {
    id?: string;
    name?: string;
    content?: string;
}
