import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ColumnSetup } from '../../../components/dynamic-table/dynamic-table.component';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ModalCertificatesComponent } from './modal-certificates/modal-certificates.component';
import { CertificateService } from '../../../services/gateway/certificate.service';
import { TranslateService } from '@ngx-translate/core';
import { SchemasService } from 'src/app/services/gateway/schemas.service';

export interface RowItem {
  id: string;
  cert: string;
  key: string;
  tags: string;
  snis: string[];
  created_at: string;
}

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss'],
})
export class CertificatesComponent implements OnInit, AfterViewInit {
  public spinner = false;
  public dataSource: RowItem[] = [];
  public columnsSetup: ColumnSetup[] = [
    {
      columnDef: 'cert',
      title: 'certificates.table.cert',
      cell: (row: RowItem) => `${row.cert}`,
    },
    {
      columnDef: 'key',
      title: 'certificates.table.key',
      cell: (row: RowItem) => `${row.key}`,
    },
    { columnDef: 'tags', title: 'certificates.table.tags', custom: true },
    { columnDef: 'snis', title: 'certificates.table.snis', custom: true },
    {
      columnDef: 'createdDate',
      title: 'certificates.table.createdDate',
      cell: (row: RowItem) =>
        `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`,
    },
    { columnDef: 'delete', title: '', custom: true },
  ];

  constructor(
    private translate: TranslateService,
    private dateFormat: DatePipe,
    private certificateService: CertificateService,
    private schemasService: SchemasService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getCertificates();
  }

  createCertificate(): void {
    this.schemasService.getCertificates().subscribe(
      (response) => {
        const schema = response.fields;
        const dialogRef = this.dialog.open(ModalCertificatesComponent, {
          height: 'auto',
          width: '750px',
          disableClose: true,
          data: { schema },
        });
        dialogRef.componentInstance.title = 'certificates.modal.titleOpen';
        dialogRef.componentInstance.buttonCancel =
          'certificates.modal.buttonCancel';
        dialogRef.componentInstance.buttonCreate =
          'certificates.modal.buttonCreate';
        dialogRef.afterClosed().subscribe(() => {
          this.getCertificates();
        });
      },
      (err) => {}
    );
  }

  editCertificate(row): void {
    this.schemasService.getCertificates().subscribe(
      (response) => {
        const schema = response.fields;
        const dialogRef = this.dialog.open(ModalCertificatesComponent, {
          height: 'auto',
          width: '750px',
          disableClose: true,
          data: { schema, row },
        });
        dialogRef.componentInstance.title = 'certificates.modal.titleOpen';
        dialogRef.componentInstance.buttonCancel =
          'certificates.modal.buttonCancel';
        dialogRef.componentInstance.buttonCreate =
          'certificates.modal.buttonEdit';
        dialogRef.afterClosed().subscribe(() => {
          this.getCertificates();
        });
      },
      (err) => {}
    );
  }

  statusConnection(id): void {}

  deleteConnection(id): void {}

  private getCertificates(): void {
    this.dataSource = [];
    this.spinner = true;
    this.certificateService.getCertificates().subscribe(
      (response: RowItem[]) => {
        this.spinner = false;
        this.dataSource = response.map((route) => ({
          ...route,
          createdDate: new Date(),
        }));
      },
      (err) => {
        console.log(err);
        this.spinner = false;
      }
    );
  }
}
