import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home/home.component';
import {PagesPortalRoutingModule} from './pages-portal-routing.module';

@NgModule({
    declarations: [HomeComponent],
    imports: [
        CommonModule,
        PagesPortalRoutingModule
    ]
})
export class PagesPortalModule {
}
