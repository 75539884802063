<form [formGroup]="formTarget" (ngSubmit)="submit()">
    <div class="container">
        <h1 mat-dialog-title>{{title | translate }}</h1>
        <hr>
        <div mat-dialog-content>
            <div class="row">
                <div class="col-12">
                    <label>{{ 'upstreams.target.target' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="target"/>
                        <mat-hint>{{ 'upstreams.target.hint.target' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'target')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.target.weight' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="number" formControlName="weight"/>
                        <mat-hint>{{ 'upstreams.target.hint.weight' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'weight')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.target.tags' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipList aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of tags" [selectable]="true" [removable]="true" (removed)="removeTag(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'upstreams.modal.tags' | translate }}"
                                    formControlName="tags"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTag($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'upstreams.target.hint.tags' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'tags')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div mat-dialog-actions align="end" class="mt-2 pl-3">
            <button class="btn btn-close" [mat-dialog-close]="undefined">{{ 'upstreams.target.buttonCancel' | translate }} </button>
            <button class="btn btn-action">{{ 'upstreams.target.buttonCreate' | translate }}</button>
        </div>
    </div>
</form>
