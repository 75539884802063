import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {endpoint} from '../../core/constants/services.constants';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Service} from '../../core/model/service.model';
import {PluginsModel} from '../../core/model/plugins.model';
import {RoutesModel} from '../../core/model/routes.model';

@Injectable({
    providedIn: 'root'
})
export class ServicesService {

    private URL_BASE: string = environment.url_services;
    private ENDPOINT_SERVICES = `${this.URL_BASE}${endpoint.ENDPOINT_SERVICES}`;
    private ENDPOINT_KONG_SERVICES = `${this.URL_BASE}${endpoint.KONG_SERVICES}`;

    constructor(private http: HttpClient) {
    }

    getServices(): Observable<any> {
        return this.http.get(this.ENDPOINT_KONG_SERVICES).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    getService(id: string): Observable<HttpResponse<Service>> {
        return this.http.get<Service>(`${this.ENDPOINT_KONG_SERVICES}/${id}`, {observe: 'response'});
    }

    getServiceRoutes(id: string): Observable<RoutesModel[]> {
        return this.http.get<RoutesModel[]>(`${this.ENDPOINT_KONG_SERVICES}/${id}/routes`, {observe: 'response'}).pipe(
            map((response: any) => {
                return response.body.data || [];
            })
        );
    }

    getServicePlugins(id: string): Observable<PluginsModel[]> {
        return this.http.get<PluginsModel[]>(`${this.ENDPOINT_KONG_SERVICES}/${id}/plugins`, {observe: 'response'}).pipe(
            map((response: any) => {
                return response.body.data || [];
            })
        );
    }

    createRouterToService(body: any, serviceId: string): Observable<any> {
        return this.http.post(`${this.ENDPOINT_KONG_SERVICES}/${serviceId}/routes`, body);
    }

    updateRouterService(body: any, serviceId: string): Observable<any> {
        return this.http.put(`${this.ENDPOINT_KONG_SERVICES}/${serviceId}/routes/${body.id}`, body);
    }

    createService(body: Service): Observable<any> {
        return this.http.post(this.ENDPOINT_KONG_SERVICES, body);
    }

    updateService(service: Service): Observable<any> {
        return this.http.put(`${this.ENDPOINT_KONG_SERVICES}/${service.id}`, service);
    }

    deleteService(id: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_SERVICES}/${id}`);
    }

    statusService(id: string, status): Observable<any> {
        const params = new HttpParams().append('status', status);
        return this.http.post(`${this.ENDPOINT_SERVICES}/${id}/activate`, params);
    }
}
