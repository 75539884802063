import {Component, Inject, OnInit} from '@angular/core';
import {RoutesModel} from '../../../../core/model/routes.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-upstreams-options',
    templateUrl: './upstreams-options.component.html',
    styleUrls: ['./upstreams-options.component.scss']
})
export class UpstreamsOptionsComponent implements OnInit {
    upstream: RoutesModel;
    title = '';
    buttonCancel = '';

    constructor(public dialog: MatDialogRef<UpstreamsOptionsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.upstream = data;
    }

    ngOnInit(): void {
    }
}
