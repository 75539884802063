import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApisComponent } from './apis/apis.component';
import { CreateApiComponent } from './apis/create-api/create-api.component';
import { ServicesComponent } from './services/services.component';
import { RoutersComponent } from './routers/routers.component';
import { ConsumersComponent } from './consumers/consumers.component';
import { PluginsComponent } from './plugins/plugins.component';
import { UpstreamsComponent } from './upstreams/upstreams.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { GatewayRoutingModule } from './gateway-routing.module';
import { InfoComponent } from './info/info.component';
import { ComponentsModule } from '../../components/components.module';
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../theme/shared/shared.module';
import { CoreModule } from '../../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { ModalApiComponent } from './apis/modal-api/modal-api.component';
import { ModalCertificatesComponent } from './certificates/modal-certificates/modal-certificates.component';
import { ModalServiceComponent } from './services/modal-service/modal-service.component';
import { ModalConsumerComponent } from './consumers/modal-consumer/modal-consumer.component';
import { ModalInfoComponent } from './info/modal-info/modal-info.component';
import { SelectPluginComponent } from './plugins/modal-plugin/select-plugin.component';
import { ModalRouterComponent } from './routers/modal-router/modal-router.component';
import { ModalUpstreamsComponent } from './upstreams/modal-upstreams/modal-upstreams.component';
import { DetailComponent } from './consumers/detail/detail.component';
import { ModalOptionsComponent } from './services/modal-options/modal-options.component';
import { PipesModule } from '../../pipes/pipes.module';
import { ServiceDetailComponent } from './services/service-detail/service-detail.component';
import { MatStepperModule } from '@angular/material/stepper';
import { PluginDetailsComponent } from './plugins/plugin-details/plugin-details.component';
import { ServiceMenuComponent } from './services/service-menu/service-menu.component';
import { ServicePluginsComponent } from './services/service-plugins/service-plugins.component';
import { ServiceRoutesComponent } from './services/service-routes/service-routes.component';
import { RouterOptionsComponent } from './routers/router-options/router-options.component';
import { UpstreamsOptionsComponent } from './upstreams/upstreams-options/upstreams-options.component';
import { ConsumerOptionsComponent } from './consumers/consumer-options/consumer-options.component';
import { ApiWizardComponent } from './apis/api-wizard/api-wizard.component';
import { RouterServiceOptionsComponent } from './services/service-routes/router-options/router-service-options.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { ModalAddPluginComponent } from './plugins/modal-add-plugin/modal-add-plugin.component';
import { AddRouteServiceComponent } from './services/service-routes/add-route-service/add-route-service.component';
import { EditConsumerComponent } from './consumers/edit-consumer/edit-consumer.component';
import { AddGroupComponent } from './consumers/add-group/add-group.component';
import { CredentialsComponent } from './consumers/credentials/credentials.component';
import { AccesibleRoutesComponent } from './consumers/accesible-routes/accesible-routes.component';
import { CredentialMenuComponent } from './consumers/credentials/credential-menu/credential-menu.component';
import { BasicCredentialComponent } from './consumers/credentials/basic-credential/basic-credential.component';
import { KeyCredentialComponent } from './consumers/credentials/key-credential/key-credential.component';
import { HmacCredentialComponent } from './consumers/credentials/hmac-credential/hmac-credential.component';
import { OauthCredentialComponent } from './consumers/credentials/oauth-credential/oauth-credential.component';
import { JwtCredentialComponent } from './consumers/credentials/jwt-credential/jwt-credential.component';
import { AddBasicComponent } from './consumers/credentials/basic-credential/add-basic/add-basic.component';
import { AddHmacComponent } from './consumers/credentials/hmac-credential/add-hmac/add-hmac.component';
import { AddJwtComponent } from './consumers/credentials/jwt-credential/add-jwt/add-jwt.component';
import { AddKeyComponent } from './consumers/credentials/key-credential/add-key/add-key.component';
import { AddOauthComponent } from './consumers/credentials/oauth-credential/add-oauth/add-oauth.component';
import { RouterDetailsComponent } from './routers/router-details/router-details.component';
import { EditApiComponent } from './apis/edit-api/edit-api.component';
import { UpstreamDetailComponent } from './upstreams/upstream-detail/upstream-detail.component';
import { UpstreamMenuComponent } from './upstreams/upstream-menu/upstream-menu.component';
import { UpstreamTargetComponent } from './upstreams/upstream-target/upstream-target.component';
import { AddUpstreamTargetComponent } from './upstreams/add-upstream-target/add-upstream-target.component';
import { AddDatadogPluginComponent } from './plugins/add-datadog-plugin/add-datadog-plugin.component';
import { CaCertificatesComponent } from './ca-certificates/ca-certificates.component';
import { ModalCaCertificatesComponent } from './ca-certificates/modal-ca-certificates/modal-ca-certificates.component';
import { MonacoEditorModule } from 'ngx-monaco-editor';

@NgModule({
  declarations: [
    ApisComponent,
    CreateApiComponent,
    ServicesComponent,
    RoutersComponent,
    ConsumersComponent,
    PluginsComponent,
    UpstreamsComponent,
    CertificatesComponent,
    CaCertificatesComponent,
    InfoComponent,
    ModalApiComponent,
    ModalCertificatesComponent,
    ModalCaCertificatesComponent,
    ModalServiceComponent,
    ModalConsumerComponent,
    ModalInfoComponent,
    SelectPluginComponent,
    ModalRouterComponent,
    ModalUpstreamsComponent,
    DetailComponent,
    ModalOptionsComponent,
    ServiceDetailComponent,
    PluginDetailsComponent,
    ServiceMenuComponent,
    ServicePluginsComponent,
    ServiceRoutesComponent,
    UpstreamsOptionsComponent,
    ConsumerOptionsComponent,
    ApiWizardComponent,
    RouterOptionsComponent,
    RouterServiceOptionsComponent,
    ModalAddPluginComponent,
    AddRouteServiceComponent,
    EditConsumerComponent,
    AddGroupComponent,
    CredentialsComponent,
    AccesibleRoutesComponent,
    CredentialMenuComponent,
    BasicCredentialComponent,
    KeyCredentialComponent,
    HmacCredentialComponent,
    OauthCredentialComponent,
    JwtCredentialComponent,
    AddBasicComponent,
    AddHmacComponent,
    AddJwtComponent,
    AddKeyComponent,
    AddOauthComponent,
    RouterDetailsComponent,
    EditApiComponent,
    UpstreamDetailComponent,
    UpstreamMenuComponent,
    UpstreamTargetComponent,
    AddUpstreamTargetComponent,
    AddDatadogPluginComponent,
  ],
  imports: [
    CommonModule,
    GatewayRoutingModule,
    ComponentsModule,
    MaterialModule,
    SharedModule,
    CoreModule,
    TranslateModule,
    PipesModule,
    MatStepperModule,
    MatExpansionModule,
    MatProgressBarModule,
    FlexLayoutModule,
    MatRippleModule,
    MonacoEditorModule.forRoot(),
  ],
  entryComponents: [
    ModalApiComponent,
    ModalCertificatesComponent,
    ModalCaCertificatesComponent,
    ModalServiceComponent,
    ModalUpstreamsComponent,
    ModalInfoComponent,
    ModalRouterComponent,
    ModalOptionsComponent,
    PluginDetailsComponent,
    ModalOptionsComponent,
    RouterServiceOptionsComponent,
    ModalAddPluginComponent,
  ],
})
export class GatewayModule {}
