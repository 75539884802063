<label>{{ name }}</label>
<mat-form-field appearance="outline" class="w-100" [formGroup]="form">
  <mat-chip-list #chipDestinations>
    <mat-chip
      *ngFor="let tag of stack"
      [selectable]="true"
      [removable]="true"
      (removed)="removeTag(tag)"
    >
      {{ tag }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      matInput
      [formControl]="multipleCtrl"
      [matAutocomplete]="auto"
      [formControlName]="inputName"
      [matChipInputFor]="chipDestinations"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="matChipInputAddOnBlur"
      (matChipInputTokenEnd)="addTag($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
