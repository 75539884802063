<app-header-pages title="{{'routers.detail.title' | translate:{id: router.id} }}" subTitle="{{'routers.detail.subTitle' | translate }}" class="margin-top0"></app-header-pages>
<div class="margin-top0">
    <app-card [hidHeader]="true">
        <form [formGroup]="formRouter" (submit)="submit()">
            <div class="row">
                <div class="col-12">
                    <label>{{ 'routers.modal.name' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="name"/>
                        <mat-hint>{{ 'routers.hint.name' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'name')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.tags' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipListTags aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of tags" [selectable]="true" [removable]="true" (removed)="removeTag(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.tags' | translate }}"
                                    formControlName="tags"
                                    [matChipInputFor]="chipListTags"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTag($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.tags' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'tags')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.hosts' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipListHosts aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of tagsHosts" [selectable]="true" [removable]="true" (removed)="removeTagHosts(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.hosts' | translate }}"
                                    formControlName="hosts"
                                    [matChipInputFor]="chipListHosts"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTagHosts($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.host' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'hosts')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.paths' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipListPaths aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of tagsPaths" [selectable]="true" [removable]="true" (removed)="removeTagPaths(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.paths' | translate }}"
                                    formControlName="paths"
                                    [matChipInputFor]="chipListPaths"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTagPaths($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.paths' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'paths')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.headers' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="headers"/>
                        <mat-hint>{{ 'routers.hint.headers' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'headers')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.pathHandling' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="pathHandling"/>
                        <mat-hint>{{ 'routers.hint.pathHandling' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'pathHandling')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.httpRedirectStatus' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="redirectStatusCode"/>
                        <mat-hint>{{ 'routers.hint.redirectStatusCode' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'redirectStatusCode')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.regexPriority' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="regexPriority"/>
                        <mat-hint>{{ 'routers.hint.regexPriority' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'regexPriority')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.methods' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipMethods aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of methods" [selectable]="true" [removable]="true" (removed)="removeTagMethod(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.methods' | translate }}"
                                    formControlName="methods"
                                    [matChipInputFor]="chipMethods"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTagMethod($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.methods' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'methods')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 mb-3">
                    <mat-slide-toggle formControlName="stripPath" class="mb-1">{{ 'routers.modal.stripPath' | translate }}</mat-slide-toggle>
                    <br/>
                    <span>{{ 'routers.hint.stripPath' | translate }}</span>
                </div>
                <div class="col-12 mb-3">
                    <mat-slide-toggle formControlName="preserveHost" class="mb-1">{{ 'routers.modal.preserveHost' | translate }}</mat-slide-toggle>
                    <br/>
                    <span>{{ 'routers.hint.preserveHost' | translate }}</span>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.protocols' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipProtocols aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of protocols" [selectable]="true" [removable]="true" (removed)="removeTagProtocols(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.protocols' | translate }}"
                                    formControlName="protocols"
                                    [matChipInputFor]="chipProtocols"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTagProtocols($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.protocols' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'protocols')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.snis' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipSnis aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of snis" [selectable]="true" [removable]="true" (removed)="removeSnis(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.snis' | translate }}"
                                    formControlName="snis"
                                    [matChipInputFor]="chipSnis"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addSnis($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.snis' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'snis')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.sources' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipSources aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of sources" [selectable]="true" [removable]="true" (removed)="removeSources(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.sources' | translate }}"
                                    formControlName="sources"
                                    [matChipInputFor]="chipSources"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addSources($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.sources' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'sources')">{{ error.message  | translate }}</span>
                        </mat-error>
                        <mat-error *ngIf="formRouter.get('sources').hasError('sources')">
                            <span>{{ formRouter.get('sources').getError('sources') }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.destinations' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipDestinations aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of destinations" [selectable]="true" [removable]="true" (removed)="removeDestinatios(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.destinations' | translate }}"
                                    formControlName="destinations"
                                    [matChipInputFor]="chipDestinations"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addDestination($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.destinations' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'destinations')">{{ error.message  | translate }}</span>
                        </mat-error>
                        <mat-error *ngIf="formRouter.get('destinations').hasError('destinations')">
                            <span>{{ formRouter.get('destinations').getError('destinations') }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="mt-2">
                <button class="btn btn-action" [disabled]="!formRouter.valid">{{ 'routers.buttons.save' | translate }}</button>
            </div>
        </form>
    </app-card>
</div>
