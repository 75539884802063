import {Injectable} from '@angular/core';
import {Login} from 'src/app/core/model/login.model';
import {HttpClient, HttpParams} from '@angular/common/http';

import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {endpoint} from '../../core/constants/services.constants';
import {LocalStorageService} from 'ngx-webstorage';
import {LocalstorageService} from '../localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userToken: string;
  private URL_BASE: string = environment.url_services;
  private ENDPOINT_AUTHENTICATE = `${this.URL_BASE}${endpoint.ENDPOINT_AUTH}`;
  private ENDPOINT_REGISTER = `${this.URL_BASE}${endpoint.ENDPOINT_REGISTER}`;
  private ENDPOINT_ACCOUNT = `${this.URL_BASE}${endpoint.ENDPOINT_ACCOUNT}`;
  private ENDPOINT_CHANGE_PASSWORD = `${this.URL_BASE}${endpoint.ENDPOINT_CHANGE_PASSWORD}`;
  private ENDPOINT_RESET_INIT = `${this.URL_BASE}${endpoint.ENDPOINT_RESET_INIT}`;
  private ENDPOINT_RESET_FINISH = `${this.URL_BASE}${endpoint.ENDPOINT_RESET_FINISH}`;
  private ENDPOINT_ACTIVATE = `${this.URL_BASE}${endpoint.ENDPOINT_ACTIVATE}`;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalstorageService,
    private router: Router) {
    this.leerToken();
  }

  // login(credentials: Login): Observable<void> {
  //   return this.http
  //     .post<JwtToken>(SERVER_API_URL + 'api/authenticate', credentials)
  //     .pipe(map(response => this.authenticateSuccess(response, credentials.rememberMe)));
  // }

  login(login: Login): Observable<any> {
    const authData = {
      ...login
    };

    return this.http.post(`${this.ENDPOINT_AUTHENTICATE}`, authData).pipe(
      map((resp: any) => {
        this.guardarToken(resp.id_token);
        return resp;
      })
    );
  }

  logout(): void {
    this.localStorageService.removeItem('token');
    this.localStorageService.removeItem('menu');
    this.localStorageService.removeItem('user');
    this.localStorageService.removeItem('userPermission');
    this.localStorageService.removeItem('portal');
    this.localStorageService.removeItem('environment');
    this.localStorageService.clear();
    this.router.navigateByUrl('/account/login');
  }

  getUser(): Observable<any> {
    return this.http.get(this.ENDPOINT_ACCOUNT);
  }

  private guardarToken(idToken: string): void {
    this.userToken = idToken;
    this.localStorageService.setItem('token', idToken);

    const hoy = new Date();
    hoy.setSeconds(3600);

    this.localStorageService.setItem('expira', hoy.getTime().toString());
  }

  leerToken(): void {
    if (this.localStorageService.getItem('token')) {
      this.userToken = this.localStorageService.getItem('token');
    } else {
      this.userToken = '';
    }
  }

  estaAutenticado(): boolean {
    if (this.userToken.length < 2) {
      return false;
    }
    return this.userToken.length > 2;
  }

  save(account: any): any {
    return this.http.post(this.ENDPOINT_REGISTER, account);
  }

  // {currentPassword: "admin", newPassword: "adminadmin"}
  changePassword(account: any): any {
    return this.http.post(this.ENDPOINT_CHANGE_PASSWORD, account);
  }

  resetpasword(mail: string): any {
    return this.http.post(this.ENDPOINT_RESET_INIT, mail);
  }

  finishResetPass(key: string, newPassword: string): any {
    return this.http.post(this.ENDPOINT_RESET_FINISH, {key, newPassword});
  }

  ActivateAcount(key: string): any {
    return this.http.get(this.ENDPOINT_ACTIVATE, {params: new HttpParams().set('key', key)});
  }

}
