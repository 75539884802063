<app-header-pages
  title="{{ 'apis.title' | translate }}"
  subTitle="{{ 'apis.subTitle' | translate }}"
></app-header-pages>

<ng-container *ngIf="success === true">
  <div class="jumbotron text-justify w-75 mx-auto">
    <p class="lead text-secondary">Link generated successfully!</p>
    <pre
      class="containerDeclarative"
    ><span><code>{{ result }}</code></span></pre>
    <button type="button" class="btn btn-action" (click)="handleGoBack()">
      Salir
    </button>
  </div>
</ng-container>
<ng-container *ngIf="success === false">
  <mat-vertical-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <ng-template matStepLabel>Use OpenApi Spec</ng-template>
      <app-select-spec
        [refreshSpecs]="refreshSpecs"
        [form]="firstFormGroup"
        inputName="specName"
        [handleFolder]="handleFolder.bind(this)"
      ></app-select-spec>
      <form [formGroup]="firstFormGroup">
        <input type="hidden" formControlName="specName" />
      </form>
      <app-stepper-button
        [stepperBack]="false"
        [upload]="true"
        [spinner]="spinner"
        [condition]="stepNext.one"
        [uploadCallback]="createSpecFromFile.bind(this)"
      ></app-stepper-button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Proxy Details</ng-template>
      <div class="row">
        <div class="col">
          <label>Name</label>
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              type="text"
              [value]="this.spec.title"
              disabled="true"
            />
            <mat-hint
              >Alphanumeric characters, dash(-) or underscore (_)</mat-hint
            >
          </mat-form-field>
          <label>Description</label>
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              type="text"
              [value]="this.spec.description"
              disabled="true"
            />
          </mat-form-field>
          <label>Targets (Existing API)</label>
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              type="text"
              [value]="this.spec.servers"
              disabled="true"
            />
            <mat-hint
              >The URL the backend service that this proxy invokes</mat-hint
            >
          </mat-form-field>
        </div>
        <div class="col">
          <ng-container *ngIf="this.spec.errors.length > 0">
            <h5 class="font-weight-bold">Warnings</h5>
            <hr />
            <ng-container *ngFor="let error of this.spec.errors">
              <div class="alert alert-warning" role="alert">
                {{ error }}
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <app-stepper-button
        [spinner]="spinner"
        [condition]="stepNext.two"
      ></app-stepper-button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>OpenApi Operations</ng-template>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">No.</th>
              <th scope="col">Path</th>
              <th scope="col">Verb</th>
              <th scope="col">Operation</th>
              <th scope="col">Summary</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let element of dataSource">
              <tr>
                <th>{{ element.position + 1 }}</th>
                <td>{{ element.path }}</td>
                <td>
                  <span
                    class="badge"
                    [ngClass]="['badge-' + colors[element.verb]]"
                  >
                    {{ element.verb }}</span
                  >
                </td>
                <td>{{ element.operation }}</td>
                <td>{{ element.summary }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <app-stepper-button
        [spinner]="spinner"
        [condition]="stepNext.two"
        [callback]="getDeclarativeConfig.bind(this)"
      ></app-stepper-button>
    </mat-step>
    <mat-step [stepControl]="declarativeFormGroup">
      <ng-template matStepLabel>Declarative Config</ng-template>
      <div class="row mt-3">
        <ng-container *ngIf="!declarativeError">
          <div class="col">
            <pre
              class="containerDeclarative"
            ><span><code>{{declarativeConfig}}</code></span></pre>
          </div>
          <div class="col"></div>
        </ng-container>
        <div class="col" *ngIf="declarativeError">
          <div class="alert alert-danger" role="alert">
            {{ declarativeConfig }}
          </div>
        </div>
      </div>
      <app-stepper-button
        [spinner]="spinner"
        [condition]="stepNext.three"
      ></app-stepper-button>
      <form [formGroup]="declarativeFormGroup">
        <input type="hidden" formControlName="declarative" />
      </form>
    </mat-step>
    <mat-step [stepControl]="declarativeFormGroup">
      <ng-template matStepLabel>Policies</ng-template>
      <div class="row mt-3">
        <div class="col border" style="height: 300px">
          <ngx-monaco-editor
            style="height: 100%"
            [options]="editorOptions"
            [(ngModel)]="code"
          ></ngx-monaco-editor>
        </div>
        <div class="col"></div>
      </div>
      <app-stepper-button
        [spinner]="spinner"
        condition="true"
      ></app-stepper-button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Publish Api</ng-template>
      Please select the environment: <br />
      <div class="form-check form-check-inline mt-2 mb-2">
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          [(ngModel)]="environment"
          (ngModelChange)="handleEnvironment()"
        >
          <mat-radio-button
            class="form-check-input mr-5"
            *ngFor="let env of environments"
            [value]="env"
          >
            {{ env }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <br />
      <div class="alert alert-danger" role="alert" *ngIf="publishError">
        {{ publishError }}
      </div>
      <app-stepper-button
        text="Publish Api"
        [spinner]="spinner"
        [condition]="stepNext.six"
        [callback]="publishApi.bind(this)"
      ></app-stepper-button>
    </mat-step>
  </mat-vertical-stepper>
</ng-container>
