import { Injectable } from '@angular/core';
import { LocalstorageService } from 'src/app/services/localstorage.service';

export interface INavigationItem {
    id: string;
    title: string;
    type: 'item' | 'collapse' | 'group';
    translate?: string;
    icon?: string;
    custom?: boolean;
    hidden?: boolean;
    url?: string;
    classes?: string;
    exactMatch?: boolean;
    external?: boolean;
    target?: boolean;
    breadcrumbs?: boolean;
    function?: any;
    badge?: {
        title?: string;
        type?: string;
    };
    children?: Navigation[];
    authorities?: string[];
    permissions?: string[];
    isGlobalPermission?: boolean;
}

export interface Navigation extends INavigationItem {
    children?: INavigationItem[];
}

export interface Navigation extends INavigationItem {
    children?: INavigationItem[];
}

@Injectable()
export class NavigationItem {

    constructor(
        private localstorageService: LocalstorageService
    ) {
    }

    public get(): INavigationItem[] {
        return this.localstorageService.getItemObject<INavigationItem[]>('menu');
    }
}
