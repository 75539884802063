import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {endpoint} from '../../core/constants/services.constants';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Target, Upstreams} from '../../core/model/upstreams.model';

@Injectable({
    providedIn: 'root'
})
export class UpstreamService {
    private URL_BASE: string = environment.url_services;
    private ENDPOINT_UPSTREAM = `${this.URL_BASE}${endpoint.ENDPOINT_UPSTREAM}`;
    private ENDPOINT_KONG_UPSTREAM = `${this.URL_BASE}${endpoint.KONG_UPSTREAM}`;

    constructor(private http: HttpClient) {
    }

    getUpstreams(): Observable<any> {
        return this.http.get(this.ENDPOINT_KONG_UPSTREAM).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    getUpstream(id: string): Observable<HttpResponse<Upstreams>> {
        return this.http.get<Upstreams>(`${this.ENDPOINT_KONG_UPSTREAM}/${id}`, {observe: 'response'});
    }

    getTargets(id: string): Observable<any> {
        return this.http.get(`${this.ENDPOINT_KONG_UPSTREAM}/${id}/targets`).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    createTarget(upstreamId: string, target: Target): Observable<any> {
        return this.http.post(`${this.ENDPOINT_KONG_UPSTREAM}/${upstreamId}/targets`, target);
    }

    deleteTarget(id: string, hostPort: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_UPSTREAM}/${id}/targets/${hostPort}`);
    }

    createUpstream(body: any): Observable<any> {
        return this.http.post(this.ENDPOINT_KONG_UPSTREAM, body);
    }

    updateUpstream(body: any): Observable<any> {
        return this.http.put(`${this.ENDPOINT_KONG_UPSTREAM}/${body.id}`, body);
    }

    deleteUpstream(id: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_UPSTREAM}/${id}`);
    }
}
