import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AjaxTableComponent } from './ajax-table/ajax-table.component';
import { HeaderPagesComponent } from './header-pages/header-pages.component';
import { FilterTableComponent } from './filter-table/filter-table.component';
import { TagsComponent } from './tags/tags.component';
import { MethodComponent } from './method/method.component';
import { LoadingComponent } from './loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CategoriesComponent } from './categories/categories.component';
import { DndDirective } from './dnd-directive/dnd.directive';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { SelectComponent } from './inputs/select/select.component';
import { CombinedComponent } from './inputs/combined/combined.component';
import { MultipleComponent } from './inputs/multiple/multiple.component';
import { SelectSpecComponent } from './select-spec/select-spec.component';
import { StepperButtonComponent } from './stepper/stepper-button/stepper-button.component';
import { MaterialModule } from '../material.module';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';

const components = [
  DynamicTableComponent,
  AjaxTableComponent,
  HeaderPagesComponent,
  FilterTableComponent,
  TagsComponent,
  MethodComponent,
  LoadingComponent,
  CategoriesComponent,
  DndDirective,
  CatalogsComponent,
  SelectComponent,
  CombinedComponent,
  MultipleComponent,
  SelectSpecComponent,
  StepperButtonComponent,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule,
    MatProgressSpinnerModule,
  ],
})
export class ComponentsModule {}
