<div class="container">
    <div class="row">
        <h4 class="mb-3">{{ 'userManagement.header.title.user' | translate }}</h4>
        <div class="col-md-6 pl-0">
            <div class="user__container">
                <h5 class="mb-0">{{ 'userManagement.detalle.login' | translate }}</h5>
                <p class="mb-2">{{ dataModal?.login }}</p>
            </div>
            <div class="user__container">
                <h5 class="mb-0">{{ 'userManagement.detalle.firstName' | translate }}</h5>
                <p class="mb-2">{{ dataModal?.firstName }}</p>
            </div>
            <div class="user__container">
                <h5 class="mb-0">{{ 'userManagement.detalle.email' | translate }}</h5>
                <p class="mb-2">{{ dataModal?.email }}</p>
            </div>
            <div class="user__container">
                <h5 class="mb-0">{{ 'userManagement.detalle.profiles' | translate }}</h5>
                <p class="mb-2">{{ dataModal?.authorities }}</p>
            </div>
        </div>
        <div class="col-md-4 offset-md-1">
            <div class="user__container">
                <h5 class="mb-0">{{ 'userManagement.detalle.state' | translate }}</h5>
                <p class="mb-2">{{ dataModal?.activated ? ('userManagement.detalle.enabled' | translate) : ('userManagement.detalle.disabled' | translate) }}</p>
            </div>
            <div class="user__container">
                <h5 class="mb-0">{{ 'userManagement.detalle.lastName' | translate }}</h5>
                <p class="mb-2">{{ dataModal?.lastName }}</p>
            </div>
            <div class="user__container">
                <h5 class="mb-0">{{ 'userManagement.detalle.langKey' | translate }} </h5>
                <p class="mb-2">{{ dataModal?.langKey == 'es' ? ('userManagement.detalle.es' | translate) : ('userManagement.detalle.en' | translate)}}</p>
            </div>
        </div>
        <div class="col-12 pl-0 text-right">
            <hr>
            <button class="btn btn-outline-primary" type="button" mat-dialog-close>{{ 'userManagement.detalle.back' | translate }}</button>
        </div>
    </div>
</div>

<!-- <div class="row">
    <div class="col-12">
        <div class="tab-content" id="myTabContent">

            <div class="merge-card">

                <app-card [hidHeader]="true" cardClass="mb-0" blockClass="d-flex align-items-center justify-content-between">
                    <h5 class="mb-0">{{ 'userManagement.header.title.user' | translate }}</h5>
                </app-card>

                <app-card [hidHeader]="true" blockClass="border-top pro-det-edit collapse show" *ngIf="!editProfile">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">ID</label>
                        <div class="col-sm-9">
                            {{ user?.id }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'userManagement.detalle.login' | translate }}</label>
                        <div class="col-sm-9">
                            {{ user?.login }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'userManagement.detalle.firstName' | translate }}</label>
                        <div class="col-sm-9">
                            {{ user?.firstName }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'userManagement.detalle.lastName' | translate }}</label>
                        <div class="col-sm-9">
                            {{ user?.lastName }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'userManagement.detalle.email' | translate }}</label>
                        <div class="col-sm-9">
                            {{ user?.email }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">Idioma</label>
                        <div class="col-sm-9">
                            {{ user?.langKey == 'es' ? ('userManagement.detalle.es' | translate) : ('userManagement.detalle.en' | translate)}}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">Estado</label>
                        <div class="col-sm-9">
                            {{ user?.activated ? ('userManagement.detalle.enabled' | translate) : ('userManagement.detalle.disabled' | translate) }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">{{ 'userManagement.detalle.profiles' | translate }}</label>
                        <div class="col-sm-9">
                            <h5><span class="badge m-r-5 badge-primary" *ngFor="let item of user?.authorities">{{ item }}</span></h5>
                        </div>
                    </div>
                    <div class="offset-sm-3 col-sm-6">
                        <button class="btn btn-info btn-block" (click)="onBack()">{{ 'userManagement.detalle.back' | translate }}</button>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">Fecha de Nacimiento</label>
                        <div class="col-sm-9">
                            16-12-1994
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">Location</label>
                        <div class="col-sm-9">
                            <p class="mb-0 text-muted">4289 Calvin Street</p>
                            <p class="mb-0 text-muted">Baltimore, near MD Tower Maryland,</p>
                            <p class="mb-0 text-muted">Maryland (21201)</p>
                        </div>
                    </div>
                </app-card>
                <app-card [hidHeader]="true" blockClass="border-top pro-det-edit collapse show" *ngIf="editProfile">
                    <form [formGroup]="formDatosPersonales" (ngSubmit)="onSubmit()">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">ID</label>
                            <div class="col-sm-9">
                                {{ user?.id }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Usuario del sistema</label>
                            <div class="col-sm-9">
                                <input formControlName="user" type="text" class="form-control" placeholder="Login">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Nombres</label>
                            <div class="col-sm-9">
                                <input formControlName="nombre" type="text" class="form-control" placeholder="Nombres">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Apellidos</label>
                            <div class="col-sm-9">
                                <input formControlName="apellido" type="text" class="form-control" placeholder="Apellidos">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Email</label>
                            <div class="col-sm-9">
                                <input formControlName="mail" type="mail" class="form-control" placeholder="example@mail.com">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Idioma</label>
                            <div class="col-sm-9">
                                <select class="form-control" formControlName="idioma">
                                        <option value="es">Español</option>
                                        <option value="en">Ingles</option>
                                    </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label font-weight-bolder">Estado</div>
                            <div class="col-sm-9">
                                <div class="switch switch-primary d-inline m-r-10">
                                    <input type="checkbox" formControlName="estado" id="switch-p-1" checked>
                                    <label for="switch-p-1" class="cr"></label>
                                </div>
                                <label>{{ user?.activated ? 'Activo' : 'Inactivo' }}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Perfiles</label>
                            <div class="col-sm-9">
                                <ng-select [ngClass]="'ng-select'" [options]="simpleOption" [multiple]="true" formControlName="perfilesUser">
                                </ng-select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Imagen url</label>
                            <div class="col-sm-9">
                                <input formControlName="imageUrl" type="text" class="form-control" placeholder="imagen url">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="offset-sm-3 col-sm-6">
                                <button class="btn btn-success btn-block" type="submit" [disabled]="!formDatosPersonales.valid">{{ 'userManagement.detalle.guardar' | translate }}</button>
                            </div>
                        </div>
                    </form>
                </app-card>
            </div>
        </div>
    </div>
</div>


 -->
