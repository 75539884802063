import { Component, Input, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-combined-component',
  templateUrl: './combined.component.html',
})
export class CombinedComponent implements OnInit {
  @Input() name: string;
  @Input() hint: string;
  @Input() inputName: string;
  @Input() form;
  @Input() messageError;
  @Input() schema: Record<string, unknown>;

  private field = {
    key: '',
    value: '',
  };

  public newFields: { key: string; value: string | string[] }[] = [
    {
      key: '',
      value: '',
    },
  ];

  public show = false;
  public keys: string[] = [];
  public inputs = {};
  public types = {
    string: 'text',
    integer: 'number',
  };

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() {}

  ngOnInit(): void {
    const formValues = this.form.value;
    const values = formValues[this.inputName];
    const keys = Object.keys(values);
    if (keys.length > 0) {
      this.newFields = [];
      keys.map((key) => {
        this.newFields.push({
          key: key,
          value: values[key],
        });
      });
    }
  }

  isValid(type: string, name: string): boolean {
    if (this.form.get(name)) {
      return this.form.get(name).hasError(type);
    }
  }

  handlerFocus(): void {
    this.show = true;
  }

  addRow(e): void {
    e.preventDefault();
    e.stopPropagation();
    this.newFields.push(this.field);
  }

  removeRow(e, index): void {
    e.preventDefault();
    e.stopPropagation();
    this.newFields.splice(index, 1);
  }

  addItem(e, key, index): void {
    this.newFields[index][key] = e.target.value;
    this.setData(index);
  }

  addTag(event, index): void {
    const value = event.value.trim();
    const input = event.input;
    const key = 'value';
    if (!value.length) {
      return;
    }
    let values = this.newFields[index][key];
    if (!Array.isArray(values)) {
      values = [];
    }
    if (input) {
      (values as string[]).push(value);
      this.newFields[index][key] = values;
      this.setData(index);
      input.value = '';
    }
  }

  removeTag(tag: string, index: number): void {
    const key = 'value';
    const stack = this.newFields[index][key] as string[];
    const i = stack.indexOf(tag);
    if (i >= 0) {
      stack.splice(i, 1);
      this.setData(index);
    }
  }

  setData(index: number): void {
    const data = {};
    this.newFields.map((field) => {
      data[field.key] = field.value;
    });
    this.form.patchValue({
      [this.inputName]: data,
    });
  }
}
