import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuditComponent} from './audit/audit.component';
import {ConnectionsComponent} from './connections/connections.component';
import {LogsComponent} from './logs/logs.component';

const routes: Routes = [
    {path: 'audit', component: AuditComponent},
    {path: 'connections', component: ConnectionsComponent},
    {path: 'logs', component: LogsComponent},
    {path: 'users', loadChildren: () => import('src/app/pages/management/users/users.module').then(module => module.UsersModule)}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ManagementRoutingModule {
}
