import {Component, Input, OnInit} from '@angular/core';
import {ConsumerModel, ConsumerOauthCredential} from '../../../../../core/model/consumer.model';
import {ColumnSetup} from '../../../../../components/dynamic-table/dynamic-table.component';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {AddOauthComponent} from './add-oauth/add-oauth.component';
import {ToastrService} from 'ngx-toastr';
import {SweetAlertComponent} from '../../../../../components/sweet-alert/sweet-alert.component';
import {ConsumerService} from '../../../../../services/gateway/consumer.service';
import {TranslateService} from '@ngx-translate/core';

export interface RowItem {
    id: string;
    name: string;
    client_secret: string;
    client_id: string;
    redirect_uris: string;
    tags: string;
    created_at: string;
}

@Component({
    selector: 'app-oauth-credential',
    templateUrl: './oauth-credential.component.html',
    styleUrls: ['./oauth-credential.component.scss'],
})
export class OauthCredentialComponent implements OnInit {
    @Input() oauthCredentials: ConsumerOauthCredential[] = [];
    @Input() consumer: ConsumerModel;
    public spinner = false;
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'name', title: 'consumers.oauth.table.username', cell: (row: RowItem) => `${row.name}`},
        {columnDef: 'client_id', title: 'consumers.oauth.table.clientId', cell: (row: RowItem) => `${row.client_id}`},
        {columnDef: 'redirect_uris', title: 'consumers.oauth.table.redirectUrls', cell: (row: RowItem) => `${row.redirect_uris}`},
        {
            columnDef: 'createdDate',
            title: 'consumers.table.createdDate',
            cell: (row: RowItem) => `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`
        },
        {columnDef: 'actions', title: '', custom: true},
    ];

    constructor(private dateFormat: DatePipe,
                public dialog: MatDialog,
                private toastr: ToastrService,
                private sweet: SweetAlertComponent,
                private translate: TranslateService,
                private consumerService: ConsumerService) {
    }


    ngOnInit(): void {
    }

    getOauthCredential(): void {
        this.consumerService.getOAuth2Credential(this.consumer.id).subscribe(res => {
            this.spinner = false;
            this.oauthCredentials = res;
        });
    }

    deleteOauth(id): void {
        this.translate.get('consumers.message').subscribe(translate => {
            this.sweet.confirmBox({
                title: translate.confirm.title,
                text: translate.confirm.text,
                confirmButtonText: translate.confirm.button,
                showCancelButton: true,
                alertAction: (result) => {
                    if (result.isConfirmed) {
                        this.spinner = true;
                        this.consumerService.deleteOauthCredential(id, this.consumer.id).subscribe(() => {
                                this.toastr.success(translate.oauth.deleted);
                                this.getOauthCredential();
                            },
                            (err) => {
                                console.log(err);
                                this.sweet.error({
                                    title: translate.error
                                });
                            }
                        );
                    }
                }
            });
        });
    }

    addOauth(): void {
        const dialogRef = this.dialog.open(AddOauthComponent, {
            height: 'auto',
            width: '750px',
            disableClose: false,
            data: this.consumer,
        });
        dialogRef.afterClosed().subscribe((oauth) => {
            if (oauth) {
                this.getOauthCredential();
            }
        });
    }
}
