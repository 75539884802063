import {AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {MatColumnDef, MatTable, MatTableDataSource} from '@angular/material/table';

export interface ColumnSetup {
    columnDef: string;
    title?: string;
    cell?: CallableCell;
    custom?: boolean;
}

export type CallableCell = (row: any) => string;

@Component({
    selector: 'app-ajax-table',
    templateUrl: './ajax-table.component.html',
    styleUrls: ['./ajax-table.component.scss']
})
export class AjaxTableComponent<T> implements OnInit, OnChanges, AfterContentInit {

    @Input() spinner = false;
    @Input() columnsSetup: ColumnSetup[] = []; // Información de las columnas de la tabla (nombre, titulo y tipo)
    @Input() data: T[] = [];
    @Input() pageSize = 10;
    @Input() itemSize = 0;
    @Input() showPage = true;
    @Output() OnPage = new EventEmitter<PageEvent>();

    dataSource: MatTableDataSource<T> = new MatTableDataSource<T>(this.data);  // Datos de la tabla

    @ViewChild(MatTable, {static: true}) table: MatTable<T>;                     // Referencia a la tabla

    @ContentChildren(MatColumnDef, {descendants: true}) columnDefs: QueryList<MatColumnDef>;        // Nuevas columnas desde el ng-content

    constructor() {
    }

    // Todas las colunas menos las custom
    get columns(): ColumnSetup[] {
        return this.columnsSetup.filter(column => column.custom !== true);
    }

    // Todas las colunas en un Array de string
    get displayedColumns(): string[] {
        return this.columnsSetup.map(column => column.columnDef);
    }

    // Todas las columnas custom
    get displayedColumnsCustom(): string[] {
        return this.columnsSetup.filter(column => column.custom).map(def => def.columnDef);
    }

    // Se refresca los datos de la tabla
    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource = new MatTableDataSource<T>(this.data);
    }

    // Se agregan las columnas custom a la tabla
    ngAfterContentInit(): void {
        if (this.displayedColumnsCustom.length !== 0) {
            this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
        }
    }

    ngOnInit(): void {
        // Se inicia la table con los datos actuales
        this.dataSource = new MatTableDataSource<T>(this.data);
    }

    changePage(event: PageEvent): void {
        this.OnPage.emit(event);
    }

}
