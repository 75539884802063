<app-header-pages
  title="{{ 'certificates.title' | translate }}"
  subTitle="{{ 'certificates.subTitle' | translate }}"
></app-header-pages>

<div class="d-flex justify-content-between">
  <button class="btn btn-action" (click)="createCertificate()">
    <mat-icon>add</mat-icon>
    {{ "certificates.table.new" | translate }}
  </button>
</div>

<app-dynamic-table
  [data]="dataSource"
  [columnsSetup]="columnsSetup"
  [spinner]="spinner"
  pageSize="10"
>
  <ng-container matColumnDef="tags">
    <mat-header-cell *matHeaderCellDef scope="col">{{
      "certificates.table.tags" | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <app-tags [tags]="row.tags"></app-tags>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="snis">
    <mat-header-cell *matHeaderCellDef scope="col">{{
      "certificates.table.snis" | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <app-tags [tags]="row.snis"></app-tags>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="delete">
    <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button class="btn btn-delete" (click)="editCertificate(row)">
        <mat-icon class="edit">edit</mat-icon>
        {{ "certificates.table.edit" | translate }}
      </button>
      <button class="btn btn-delete" (click)="deleteConnection(row.id)">
        <mat-icon class="delete">delete</mat-icon>
        {{ "certificates.table.delete" | translate }}
      </button>
    </mat-cell>
  </ng-container>
</app-dynamic-table>
