import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FieldEmitted, UploadResponse} from '../../core/model/service.model';
import {SERVER_API_URL} from '../../core/constants/app.constants';

@Injectable({providedIn: 'root'})
export class FileService {
    public fileEmitter: EventEmitter<FieldEmitted> = new EventEmitter<FieldEmitted>();
    public responseEmitterConfig: EventEmitter<UploadResponse> = new EventEmitter<UploadResponse>();
    public responseEmitterSociedad: EventEmitter<UploadResponse> = new EventEmitter<UploadResponse>();

    constructor(private http: HttpClient) {
    }

    uploadSingleFile(file: File, rutaSubida: string): Observable<HttpEvent<{}>> {
        const url = SERVER_API_URL + rutaSubida;
        const formData: FormData = new FormData();
        formData.append('openapi', file);
        return this.http.post<any>(url, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    fetchDeclarative(id: string): Observable<string> {
        return this.http.get(`${SERVER_API_URL}/api/apis/${id}/declarative`, {observe: 'body', responseType: 'text'});
    }

    emitFile(fieldEmitted: FieldEmitted): void {
        this.fileEmitter.emit(fieldEmitted);
    }

    emitResponseBodyConfig(body: any): void {
        const response = body as UploadResponse;
        this.responseEmitterConfig.emit(response);
    }

    emitResponseBodySociedad(body: any): void {
        const response = body as UploadResponse;
        this.responseEmitterSociedad.emit(response);
    }
}
