import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {IAlbum, IEvent, Lightbox, LIGHTBOX_EVENT, LightboxConfig, LightboxEvent} from 'ngx-lightbox';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    @Input() albums: Array<IAlbum>;
    private subscription: Subscription;

    constructor(
        private lightbox: Lightbox,
        private lightboxEvent: LightboxEvent,
        private lighboxConfig: LightboxConfig,
        private sanitizer: DomSanitizer) {
        this.albums = this.albums ? this.albums : [];
        lighboxConfig.fadeDuration = 1;
    }

    ngOnInit(): void {
    }

    public getSantizeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }


    open(index: number): void {
        this.subscription = this.lightboxEvent.lightboxEvent$.subscribe((event: IEvent) => this._onReceivedEvent(event));
        this.lightbox.open(this.albums, index, {wrapAround: true, showImageNumberLabel: true});
    }

    private _onReceivedEvent(event: IEvent): void {
        if (event.id === LIGHTBOX_EVENT.CLOSE) {
            this.subscription.unsubscribe();
        }
    }

}
