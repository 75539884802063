import { Component, Input, OnInit } from '@angular/core';
import { INavigationItem } from '../../navigation';
import { NextConfig } from '../../../../app-config';
import { Location } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { OPEN_API } from '../../../../../../core/constants/icons.constants';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent implements OnInit {
  @Input() item: INavigationItem;
  public nextConfig: any;
  public themeLayout: string;

  constructor(
    private location: Location,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    this.nextConfig = NextConfig.config;
    this.themeLayout = this.nextConfig.layout;

    iconRegistry.addSvgIconLiteral(
      'open-api',
      sanitizer.bypassSecurityTrustHtml(OPEN_API)
    );
  }

  ngOnInit(): void {}

  closeOtherMenu(event): void {
    if (this.nextConfig.layout === 'vertical') {
      const ele = event.target;
      if (ele !== null && ele !== undefined) {
        const parent = ele.parentElement;
        const upParent = parent.parentElement.parentElement;
        const lastParent = upParent.parentElement;
        const sections = document.querySelectorAll('.pcoded-hasmenu');

        for (let i = 0; i < sections.length; i++) {
          sections[i].classList.remove('active');
          sections[i].classList.remove('pcoded-trigger');
        }

        if (parent.classList.contains('pcoded-hasmenu')) {
          parent.classList.add('pcoded-trigger');
          parent.classList.add('active');
        } else if (upParent.classList.contains('pcoded-hasmenu')) {
          upParent.classList.add('pcoded-trigger');
          upParent.classList.add('active');
        } else if (lastParent.classList.contains('pcoded-hasmenu')) {
          lastParent.classList.add('pcoded-trigger');
          lastParent.classList.add('active');
        }
      }
      if (
        document
          .querySelector('app-navigation.pcoded-navbar')
          .classList.contains('mob-open')
      ) {
        document
          .querySelector('app-navigation.pcoded-navbar')
          .classList.remove('mob-open');
      }
    } else {
      setTimeout(() => {
        const sections = document.querySelectorAll('.pcoded-hasmenu');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < sections.length; i++) {
          sections[i].classList.remove('active');
          sections[i].classList.remove('pcoded-trigger');
        }

        let currentUrl = this.location.path();
        // tslint:disable-next-line: no-string-literal
        if (this.location['_baseHref']) {
          // tslint:disable-next-line: no-string-literal
          currentUrl = this.location['_baseHref'] + this.location.path();
        }
        const link = 'a.nav-link[ href="' + currentUrl + '" ]';
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
          const parent = ele.parentElement;
          const upParent = parent.parentElement.parentElement;
          const lastParent = upParent.parentElement;
          if (parent.classList.contains('pcoded-hasmenu')) {
            parent.classList.add('active');
          } else if (upParent.classList.contains('pcoded-hasmenu')) {
            upParent.classList.add('active');
          } else if (lastParent.classList.contains('pcoded-hasmenu')) {
            lastParent.classList.add('active');
          }
        }
      }, 500);
    }
  }
}
