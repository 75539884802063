import {Component, Input, OnInit} from '@angular/core';
import {Upstreams} from '../../../../core/model/upstreams.model';

@Component({
    selector: 'app-upstream-menu',
    templateUrl: './upstream-menu.component.html',
    styleUrls: ['./upstream-menu.component.scss']
})
export class UpstreamMenuComponent implements OnInit {
    @Input() upstream: Upstreams;

    constructor() {
    }

    ngOnInit(): void {
    }

}
