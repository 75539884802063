import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalstorageService {
    public objectEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    clear(): void {
        localStorage.clear();
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    setItem<T>(key: string, data: T): boolean {
        try {
            if (typeof data === 'string') {
                localStorage.setItem(key, data);
                this.objectEmitter.emit(data);
            } else if (typeof data === 'number' || typeof data === 'boolean') {
                localStorage.setItem(key, data.toString());
                this.objectEmitter.emit(data);
            } else if (typeof data === 'object') {
                const jsonData = JSON.stringify(data);
                localStorage.setItem(key, jsonData);
                this.objectEmitter.emit(jsonData);
            } else {
                localStorage.setItem(key, data.toString());
                this.objectEmitter.emit(data);
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    getItem(key: string): string {
        try {
            return localStorage.getItem(key);
        } catch (e) {
            return null;
        }
    }

    getItemObject<T>(key: string): T {
        try {
            return JSON.parse(localStorage.getItem(key)) as T;
        } catch (e) {
            return null;
        }
    }

}
