<div class="container">
    <h1 mat-dialog-title>{{title | translate }}</h1>
    <hr>
    <div mat-dialog-content>
        <form [formGroup]="formUpstream" (ngSubmit)="submit()" #f="ngForm">
            <div class="row">
                <div class="col-12">
                    <label>{{ 'upstreams.modal.name' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="name"/>
                        <mat-hint>{{ 'upstreams.hint.name' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'name')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.modal.tags' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipList aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of tags" [selectable]="true" [removable]="true" (removed)="removeTag(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'upstreams.modal.tags' | translate }}"
                                    formControlName="tags"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTag($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'upstreams.hint.tags' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'tags')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.modal.hashOn' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="hashOn" [matAutocomplete]="autoHashOn"/>
                        <mat-autocomplete #autoHashOn="matAutocomplete">
                            <mat-option *ngFor="let hashOn of hashOns" [value]="hashOn">
                                {{hashOn}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-hint>{{ 'upstreams.hint.hashOn' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'hashOn')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.modal.algorithm' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="algorithm" [matAutocomplete]="autoAlgoritm"/>
                        <mat-hint>{{ 'upstreams.hint.algorithm' | translate }}</mat-hint>
                        <mat-autocomplete #autoAlgoritm="matAutocomplete">
                            <mat-option *ngFor="let algoritm of algorithms" [value]="algoritm">
                                {{algoritm}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'algorithm')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.modal.hostHeader' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="hostHeader"/>
                        <mat-hint>{{ 'upstreams.hint.hostHeader' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'hostHeader')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.modal.hashOnHeader' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="hashOnHeader"/>
                        <mat-hint>{{ 'upstreams.hint.hashOnHeader' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'hashOnHeader')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.modal.hashFallback' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="hashFallback"/>
                        <mat-hint>{{ 'upstreams.hint.hashFallback' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'hashFallback')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.modal.hashFallbackHeader' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="hashFallbackHeader"/>
                        <mat-hint>{{ 'upstreams.hint.hashFallbackHeader' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'hashFallbackHeader')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.modal.hashOnCookie' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="hashOnCookie"/>
                        <mat-hint>{{ 'upstreams.hint.hashOnCookie' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'hashOnCookie')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.modal.hashOnCookiePath' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="hashOnCookiePath"/>
                        <mat-hint>{{ 'upstreams.hint.hashOnCookiePath' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'hashOnCookiePath')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'upstreams.modal.slots' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="slots"/>
                        <mat-hint>{{ 'upstreams.hint.slots' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'slots')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" style="margin-bottom: 10px;padding-left: 0;padding-right: 0" [formGroup]="formUpstream.get('healthChecks')?.get('active')">
                    <mat-accordion class="example-headers-align">
                        <mat-expansion-panel [expanded]="false">
                            <mat-expansion-panel-header style="background-color: #eaeaea;">
                                <mat-panel-title>
                                    Active health checks
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="col-12">
                                <mat-slide-toggle formControlName="httpsVerifyCertificate">{{ 'upstreams.modal.healthChecks.active.httpsVerifyCertificate' | translate }}</mat-slide-toggle>
                            </div>
                            <div class="col-12">
                                <label>{{ 'upstreams.modal.healthChecks.active.httpPath' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="httpPath"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.httpPath' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <label>{{ 'upstreams.modal.healthChecks.active.timeout' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="timeout"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.timeout' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <label>{{ 'upstreams.modal.healthChecks.active.httpsSni' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="httpsSni"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.httpsSni' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <label>{{ 'upstreams.modal.healthChecks.active.concurrency' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="concurrency"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.concurrency' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <label>{{ 'upstreams.modal.healthChecks.active.type' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="type"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.type' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('active').get('unhealthy')">
                                <label>{{ 'upstreams.modal.healthChecks.active.unhealthy.httpStatuses' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="httpStatuses"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.unhealthy.httpStatuses' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('active').get('unhealthy')">
                                <label>{{ 'upstreams.modal.healthChecks.active.unhealthy.tcpFailures' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="tcpFailures"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.unhealthy.tcpFailures' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('active').get('unhealthy')">
                                <label>{{ 'upstreams.modal.healthChecks.active.unhealthy.timeouts' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="timeouts"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.unhealthy.timeouts' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('active').get('unhealthy')">
                                <label>{{ 'upstreams.modal.healthChecks.active.unhealthy.httpFailures' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="httpFailures"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.unhealthy.httpFailures' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('active').get('unhealthy')">
                                <label>{{ 'upstreams.modal.healthChecks.active.unhealthy.interval' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="interval"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.unhealthy.interval' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('active').get('healthy')">
                                <label>{{ 'upstreams.modal.healthChecks.active.healthy.httpStatuses' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="httpStatuses"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.healthy.httpStatuses' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('active').get('healthy')">
                                <label>{{ 'upstreams.modal.healthChecks.active.healthy.interval' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="interval"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.healthy.interval' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('active').get('healthy')">
                                <label>{{ 'upstreams.modal.healthChecks.active.healthy.successes' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="successes"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.active.healthy.successes' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="col-12" style="margin-bottom: 20px;padding-left: 0;padding-right: 0" [formGroup]="formUpstream.get('healthChecks')?.get('passive')">
                    <mat-accordion class="example-headers-align">
                        <mat-expansion-panel [expanded]="false">
                            <mat-expansion-panel-header style="background-color: #eaeaea;">
                                <mat-panel-title>
                                    Passive health checks
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="col-12">
                                <label>{{ 'upstreams.modal.healthChecks.passive.type' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="type"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.passive.type' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('passive').get('unhealthy')">
                                <label>{{ 'upstreams.modal.healthChecks.passive.unhealthy.httpFailures' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="httpFailures"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.passive.unhealthy.tcpFailures' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('passive').get('unhealthy')">
                                <label>{{ 'upstreams.modal.healthChecks.passive.unhealthy.tcpFailures' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="tcpFailures"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.passive.unhealthy.tcpFailures' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('passive').get('unhealthy')">
                                <label>{{ 'upstreams.modal.healthChecks.passive.unhealthy.timeouts' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="timeouts"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.passive.unhealthy.timeouts' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('passive').get('unhealthy')">
                                <label>{{ 'upstreams.modal.healthChecks.passive.unhealthy.httpStatuses' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="httpStatuses"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.passive.unhealthy.httpStatuses' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('passive').get('healthy')">
                                <label>{{ 'upstreams.modal.healthChecks.passive.healthy.successes' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="successes"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.passive.healthy.successes' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12" [formGroup]="formUpstream.get('healthChecks')?.get('passive').get('healthy')">
                                <label>{{ 'upstreams.modal.healthChecks.passive.healthy.httpStatuses' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="httpStatuses"/>
                                    <mat-hint>{{ 'upstreams.hint.healthChecks.passive.healthy.httpStatuses' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions align="end" class="mt-2 pl-3">
        <button class="btn btn-close" [mat-dialog-close]="undefined">{{ buttonCancel | translate }} </button>
        <button class="btn btn-action" (click)="f.ngSubmit.emit()">{{ modalOption == 'create' ? (buttonCreate | translate) : (buttonEdit | translate) }}</button>
    </div>
</div>
