export const OPEN_API = `
<svg viewBox="-40 0 150 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g 
     transform="
                translate(-5 5)
                scale(0.3 0.3)">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M258 309 c-10 -5 -18 -20 -18 -32 0 -29 -69 -97 -98 -97 -12 0 -26
    -10 -33 -21 -9 -18 -8 -25 8 -41 24 -24 51 -15 59 21 9 36 82 111 102 104 14
    -6 42 20 42 38 0 10 -29 39 -38 39 -4 0 -15 -5 -24 -11z"/>
    <path d="M73 268 c-28 -14 -29 -20 -2 -44 22 -19 22 -19 10 6 -12 24 -11 24
    11 -2 33 -39 48 -36 48 12 0 35 -3 40 -22 39 -13 0 -33 -5 -45 -11z"/>
    <path d="M150 240 c0 -22 3 -40 8 -40 4 0 20 14 35 29 28 29 28 30 9 40 -39
    21 -52 14 -52 -29z"/>
    <path d="M22 213 c-23 -62 -23 -61 18 -65 31 -3 41 1 47 16 7 14 2 25 -20 47
    -16 16 -30 29 -32 29 -1 0 -7 -12 -13 -27z"/>
    <path d="M227 192 c-15 -15 -27 -31 -27 -35 0 -4 18 -7 40 -7 32 0 40 4 40 18
    0 10 -6 26 -13 36 -11 16 -14 15 -40 -12z"/>
    <path d="M195 119 c-5 -16 0 -29 20 -48 l26 -26 20 24 c10 13 19 34 19 47 0
    21 -5 24 -39 24 -32 0 -41 -4 -46 -21z"/>
    <path d="M8 105 c2 -14 6 -25 8 -25 6 0 74 42 74 46 0 2 -20 4 -44 4 -41 0
    -43 -1 -38 -25z"/>
    <path d="M52 88 c-24 -15 -30 -24 -23 -31 8 -8 20 -2 43 21 37 36 29 40 -20
    10z"/>
    <path d="M77 65 c-16 -14 -26 -29 -23 -34 10 -16 91 -31 122 -22 31 9 33 19 8
    56 -20 31 -61 21 -79 -20 -9 -21 -14 -24 -14 -12 -1 10 4 27 9 38 14 24 11 24
    -23 -6z"/>
    </g>
  </svg>`;
