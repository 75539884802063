<label>{{ name }}</label>
<div class="row">
  <ng-container *ngFor="let row of newFields; let i = index">
    <div class="col-3">
      <mat-form-field appearance="outline" style="max-width: 100%; margin: 0px">
        <input
          matInput
          type="text"
          (change)="addItem($event, 'key', i)"
          [value]="row.key"
        />
      </mat-form-field>
    </div>
    <div class="col-8">
      <mat-form-field
        appearance="outline"
        class="w-100"
        *ngIf="schema.values && schema.values.type === 'array'"
      >
        <mat-chip-list #tags>
          <mat-chip
            *ngFor="let tag of newFields[i].value"
            [selectable]="true"
            [removable]="true"
            (removed)="removeTag(tag, i)"
          >
            {{ tag }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            matInput
            [matChipInputFor]="tags"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addTag($event, i)"
          />
        </mat-chip-list>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="!schema.values">
        <input matInput type="text" (change)="addItem($event, 'value', i)" />
      </mat-form-field>
    </div>
    <div class="col-1" *ngIf="i === 0">
      <button mat-mini-fab color="primary" (click)="addRow($event)">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="col-1" *ngIf="i >= 1">
      <button mat-mini-fab color="warn" (click)="removeRow($event, i)">
        <mat-icon>remove</mat-icon>
      </button>
    </div>
  </ng-container>
</div>
