import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../../../../app/services/gateway/api.service';
import { LocalstorageService } from '../../../../../app/services/localstorage.service';
import { User } from '../../../../../app/core/model/login.model';
import { SweetAlertComponent } from '../../../../../app/components/sweet-alert/sweet-alert.component';
import { ModalSpecComponent } from '../../../../../app/pages/home/spec/modal-spec/modal-spec.component';
import { BehaviorSubject, Subject, Subscriber } from 'rxjs';

export interface ApiOperations {
  position?: number;
  path: string;
  verb: string;
  operation: string;
  summary: string;
}

export interface Spec {
  title: string;
  description: string;
  servers: string;
  errors: [];
  openAPIOperations: [ApiOperations];
}

@Component({
  selector: 'create-api',
  templateUrl: './create-api.component.html',
  styleUrls: ['./create-api.component.scss'],
})
export class CreateApiComponent implements OnInit, AfterViewInit {
  public environments: string[] = [];
  public environment: string = '';
  public parentId: string;
  public success = false;
  public result;
  public isLinear = true;
  public spinner = false;
  public specName: string;
  public publishError: string;
  public editorOptions = { language: 'yaml' };
  public code: string = '';
  public dataSource: ApiOperations[] = [];
  public selection = new SelectionModel<ApiOperations>(true, []);
  public declarativeConfig: string;
  public declarativeError: boolean;
  public refreshSpecs = new Subject<string>();
  public colors = {
    GET: 'primary',
    POST: 'success',
    PUT: 'warning',
    DELETE: 'danger',
    PATCH: 'info',
    OPTIONS: 'secondary',
  };
  public spec: Spec = {
    title: '',
    description: '',
    servers: '',
    errors: [],
    openAPIOperations: [
      {
        path: '',
        verb: '',
        operation: '',
        summary: '',
      },
    ],
  };
  public stepNext = {
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
  };
  public firstFormGroup: FormGroup = new FormGroup({
    specName: new FormControl('', Validators.required),
  });
  public declarativeFormGroup: FormGroup = new FormGroup({
    declarative: new FormControl('', Validators.required),
  });

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private localStorageService: LocalstorageService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private sweet: SweetAlertComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.firstFormGroup.get('specName').valueChanges.subscribe((x) => {
      this.publishError = null;
      this.spinner = true;
      this.declarativeError = false;
      this.apiService.getApiSpec(x).subscribe(
        (spec) => {
          this.specName = x;
          this.spec = spec as Spec;
          this.stepNext.one = true;
          this.spinner = false;
          const rows = [];
          if (this.spec.openAPIOperations.length > 1) {
            this.stepNext.two = true;
            this.spec.openAPIOperations.map((row, position) => {
              rows.push({
                ...row,
                position,
              });
            });
            this.dataSource = rows;
          }
        },
        (err) => {
          this.spinner = false;
          this.toastr.error('error');
        }
      );
    });
  }

  ngAfterViewInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const name = params.get('name') || '';
      if (name) {
        this.firstFormGroup.setValue({
          specName: name,
        });
        this.stepper.next();
      }
    });
  }

  ngOnInit(): void {
    this.success = false;
    const user = this.localStorageService.getItemObject<User>('user');
    this.environments.push(
      ...user.environmentPermissions.map((value) => value.environment)
    );
  }

  getDeclarativeConfig() {
    this.spinner = true;
    this.publishError = null;
    this.apiService.getDeclarative(this.specName).subscribe(
      (response) => {
        this.spinner = false;
        this.stepNext.three = true;
        this.declarativeConfig = response;
        this.declarativeFormGroup.setValue({
          declarative: response,
        });
      },
      (err) => {
        this.declarativeConfig = err.error;
        this.declarativeError = true;
        this.spinner = false;
        this.declarativeFormGroup.setValue({
          declarative: null,
        });
        this.toastr.error('error');
      }
    );
  }

  createSpecFromFile(): void {
    const dialogRef = this.dialog.open(ModalSpecComponent, {
      height: 'auto',
      width: '750px',
      disableClose: false,
    });
    dialogRef.componentInstance.parentId = this.parentId;
    dialogRef.componentInstance.title = 'spec.modal.titles.file';
    dialogRef.componentInstance.modalOption = 'create-spec-file';
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshSpecs.next(this.parentId);
        if (result.response?.body?.name) {
          this.firstFormGroup.setValue({
            specName: result.response?.body?.name,
          });
        }
      }
    });
  }

  handleEnvironment() {
    this.stepNext.six = true;
  }

  handleGoBack() {
    this.router.navigateByUrl('/admin/gateway/apis');
  }

  handleFolder(id) {
    this.parentId = id;
  }
  publishApi() {
    this.publishError = null;
    this.translate.get('apis.message').subscribe((translate) => {
      this.sweet.confirmBox({
        title: translate.confirmCreation.title,
        text: translate.confirmCreation.text,
        confirmButtonText: translate.confirmCreation.button,
        showCancelButton: true,
        alertAction: (result) => {
          if (result.isConfirmed) {
            this.spinner = true;
            this.apiService
              .publishNewApi(this.environment, this.code, this.specName)
              .subscribe(
                (res) => {
                  this.spinner = false;
                  this.success = true;
                  this.result = res;
                },
                (err) => {
                  this.toastr.error('Something went wrong');
                  this.publishError = err.message;
                  this.spinner = false;
                }
              );
          }
        },
      });
    });
  }
}
