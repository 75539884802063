<app-header-pages title="{{'services.title' | translate }}" subTitle="{{'services.subTitle' | translate }}"></app-header-pages>


<div class="d-flex justify-content-between">
    <button class="btn btn-action" (click)="addService()">
        <mat-icon>add</mat-icon>
        {{ 'services.table.new' | translate }}
    </button>
</div>

<mat-tab-group animationDuration="0ms" (selectedTabChange)="selectionChange($event)" [selectedIndex]="indexTab">
    <mat-tab label="{{'services.table.tabTitle'|translate}}">
        <app-dynamic-table [data]="dataSource" [columnsSetup]="columnsSetup" [spinner]="spinner" pageSize="10">
            <ng-container matColumnDef="view">
                <mat-header-cell *matHeaderCellDef scope="col" class="show-props"></mat-header-cell>
                <mat-cell *matCellDef="let row;" class="show-props">
                    <button class="btn btn-icon" (click)="showOptions(row)">
                        <mat-icon>visibility</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tags">
                <mat-header-cell *matHeaderCellDef scope="col">{{ 'services.table.tags' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let row;">
                    <app-tags [tags]="row.tags"></app-tags>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef scope="col">{{ 'services.table.name' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let row;">
                    <a [routerLink]="['/admin/gateway/services', row.id]">{{ row.name }} </a>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="delete">
                <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
                <mat-cell *matCellDef="let row;">
                    <button class="btn btn-delete" (click)="editService(row)">
                        <mat-icon class="edit">edit</mat-icon>
                        {{ 'services.table.edit' | translate }}</button>
                    <button class="btn btn-delete" (click)="deleteService(row.id)">
                        <mat-icon class="delete">delete</mat-icon>
                        {{ 'services.table.delete' | translate }}</button>
                </mat-cell>
            </ng-container>
        </app-dynamic-table>
    </mat-tab>
    <mat-tab *ngFor="let tab of tabs; let index = index" backgroundColor="primary">
        <ng-template backgroundColor="primary" mat-tab-label>
            <button (click)="removeTab(index)" class="close-button" mat-icon-button matRipple type="button">
                <mat-icon style="font-size: 25px;position: relative;top: 10px;">edit</mat-icon>
            </button>
            {{tab}}
            <button (click)="removeTab(index)" class="close-button" mat-icon-button matRipple type="button">
                <mat-icon style="font-size: 25px;position: relative;top: 10px;">close</mat-icon>
            </button>
        </ng-template>
        <app-modal-service></app-modal-service>
    </mat-tab>
</mat-tab-group>
