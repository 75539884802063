<app-card [hidHeader]="true" headerClass="full-width">
    <div class="mb-2">
        <div class="row">
            <div class="col-3">
                <app-credential-menu (selectCredentialType)="selectCredential($event)"></app-credential-menu>
            </div>
            <div class="col-9">
                <app-basic-credential [basicCredentials]="basicCredentials" [consumer]="consumer" *ngIf="selectedMenu==0"></app-basic-credential>
                <app-key-credential [keyCredentials]="keyCredentials" [consumer]="consumer" *ngIf="selectedMenu==1"></app-key-credential>
                <app-hmac-credential [hmacCredentials]="hmacCredentials" [consumer]="consumer" *ngIf="selectedMenu==2"></app-hmac-credential>
                <app-oauth-credential [oauthCredentials]="oauthCredentials" [consumer]="consumer" *ngIf="selectedMenu==3"></app-oauth-credential>
                <app-jwt-credential [jwtCredentials]="jwtCredentials" [consumer]="consumer" *ngIf="selectedMenu==4"></app-jwt-credential>
            </div>
        </div>
    </div>
</app-card>
