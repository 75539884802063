import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConsumerModel} from '../../../../../../core/model/consumer.model';
import {MessageError} from '../../../../../../core/interfaces/message-error.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ConsumerService} from '../../../../../../services/gateway/consumer.service';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-add-oauth',
    templateUrl: './add-oauth.component.html',
    styleUrls: ['./add-oauth.component.scss']
})
export class AddOauthComponent implements OnInit {
    formOauth: FormGroup;
    consumer: ConsumerModel;
    urls: string[] = [];
    MESSAGE_REQUIRED = 'consumers.message.required';
    protocolos = ['http', 'https'];
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    constructor(public dialog: MatDialogRef<AddOauthComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ConsumerModel,
                private translate: TranslateService,
                private toastr: ToastrService,
                private consumerService: ConsumerService) {
        this.consumer = data;
        this.formOauth = this.buildForm();
    }

    ngOnInit(): void {
    }

    submit(): void {
        if (this.formOauth.invalid) {
            this.translate.get('consumers.message').subscribe(translate => {
                this.toastr.error(translate.invalidForm);
                return;
            });
        }
        const formValues = this.formOauth.value;
        console.log(formValues);
        const requestServer = {
            name: formValues.name,
            client_id: this.transformValue(formValues.clientId),
            client_secret: this.transformValue(formValues.clientSecret),
            redirect_uris: this.urls,
        };
        this.translate.get('consumers.message').subscribe(translate => {
            this.consumerService.createOauthCredential(requestServer, this.consumer.id).subscribe((res) => {
                this.toastr.success(translate.oauth.created);
                this.dialog.close(res);
            }, (err) => {
                this.toastr.error(translate.error);
                console.error(err);
            });
        });
    }

    private transformValue(value: string): string | undefined {
        if (value.length === 0) {
            return undefined;
        }
        return value;
    }

    isValid(type: string, name: string): boolean {
        return this.formOauth.get(name).hasError(type);
    }

    buildForm(): FormGroup {
        return new FormGroup({
            name: new FormControl('', [Validators.required]),
            clientId: new FormControl('', []),
            clientSecret: new FormControl('', []),
            redirectUris: new FormControl(null, []),
        });
    }

    addTag(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.urls.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTag(tag: string): void {
        const index = this.urls.indexOf(tag);
        if (index >= 0) {
            this.urls.splice(index, 1);
        }
    }
}
