import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { CheckUrlService } from 'src/app/services/apis/check-url.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MessageError } from 'src/app/core/interfaces/message-error.interface';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UpstreamService } from '../../../../services/gateway/upstream.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-upstreams',
  templateUrl: './modal-upstreams.component.html',
  styleUrls: ['./modal-upstreams.component.scss'],
})
export class ModalUpstreamsComponent implements OnInit, AfterViewInit {
  public tabIndex = 0;
  public formUpstream: FormGroup;
  public keys = [];
  public schema: Record<string, unknown> = {};
  public names: Record<string, { name: string; hint: string }> = {};
  public values: Record<string, string[]> = {};
  public title = '';
  public selectedTab = 0;
  public buttonCreate = '';
  public buttonEdit = '';
  public buttonCancel = '';
  public modalOption = 'create';
  public MESSAGE_REQUIRED = 'upstreams.message.required';
  public tags: string[] = [];
  public algorithms: string[] = [
    'round-robin',
    'consistent-hashing',
    'least-connections',
  ];
  public hashOns: string[] = ['none', 'consumer', 'ip', 'header', 'cookie'];
  public messageError: MessageError = {
    required: [{ type: 'required', message: this.MESSAGE_REQUIRED }],
  };
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild(MatTabGroup) tabGroup;

  constructor(
    private translate: TranslateService,
    private upstreamService: UpstreamService,
    private checkUrl: CheckUrlService,
    private toastr: ToastrService,
    public dialog: MatDialogRef<ModalUpstreamsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.formUpstream = this.buildForm();
    this.setValidatorsForm('');
    if (this.data !== undefined) {
      this.updateForm(this.data);
    }
    this.processSchema();
  }

  private processSchema(): void {
    const schema = this.data.schema;
    const form = {
      id: new FormControl(null, []),
    };
    if (!schema) {
      return;
    }
    schema.map((field) => {
      const keys = Object.keys(field);
      const key = keys[0];
      const options = [];
      let defaultValue = null;
      if (!field[key].auto) {
        this.keys.push(key);
        this.schema[key] = field[key];
        this.names[key] = {
          name: `upstreams.modal.${key}`,
          hint: `upstreams.hint.${key}`,
        };
        if (field[key].required) {
          options.push(Validators.required);
        }
        if (field[key].default || field[key].default === 0) {
          this.values[key] = [];
          if (Array.isArray(field[key].default)) {
            this.values[key] = field[key].default;
          } else {
            this.values[key] = field[key].default;
          }
          defaultValue = field[key].default;
        }
        if (field[key].type === 'boolean' && !field[key].default) {
          defaultValue = false;
        }
        if (field[key].type === 'boolean' && !field[key].default) {
          defaultValue = false;
        }
        if (
          (field[key].type === 'set' || field[key].type === 'array') &&
          !field[key].default
        ) {
          defaultValue = [];
        }
        form[key] = new FormControl(defaultValue, options);
      }
    });
    // this.formCertificate = this.buildForm(form);
  }

  setValidatorsForm(type: string): void {
    this.cleanValidators();
  }

  cleanValidators(): void {
    const keys = Object.keys(this.formUpstream.value);
    keys.forEach((key) => {
      this.formUpstream.get(key).clearValidators();
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabIndex = tabChangeEvent.index;
    const tabChange = tabChangeEvent.tab.ariaLabel;
    this.setValidatorsForm(tabChange);
    this.formUpstream.patchValue({
      authType: tabChangeEvent.tab.ariaLabel,
    });
  }

  isValid(type: string, name: string): boolean {
    return this.formUpstream.get(name).hasError(type);
  }

  buildForm(): FormGroup {
    return new FormGroup({
      id: new FormControl(null, []),
      name: new FormControl('', [Validators.required]),
      tags: new FormControl(null, []),
      algorithm: new FormControl(null, []),
      hostHeader: new FormControl(null, []),
      hashOn: new FormControl(null, []),
      hashOnHeader: new FormControl(null, []),
      hashFallback: new FormControl(null, []),
      hashFallbackHeader: new FormControl(null, []),
      hashOnCookie: new FormControl(null, []),
      hashOnCookiePath: new FormControl(null, []),
      slots: new FormControl(null, []),
      healthChecks: new FormGroup({
        active: new FormGroup({
          httpsVerifyCertificate: new FormControl(false, []),
          httpPath: new FormControl(null, []),
          timeout: new FormControl(null, []),
          httpsSni: new FormControl(null, []),
          concurrency: new FormControl(null, []),
          type: new FormControl(null, []),
          unhealthy: new FormGroup({
            httpStatuses: new FormControl(null, []),
            tcpFailures: new FormControl(null, []),
            timeouts: new FormControl(null, []),
            httpFailures: new FormControl(null, []),
            interval: new FormControl(null, []),
          }),
          healthy: new FormGroup({
            httpStatuses: new FormControl(null, []),
            interval: new FormControl(null, []),
            successes: new FormControl(null, []),
          }),
        }),
        passive: new FormGroup({
          type: new FormControl(null, []),
          unhealthy: new FormGroup({
            tcpFailures: new FormControl(null, []),
            timeouts: new FormControl(null, []),
            httpFailures: new FormControl(null, []),
            interval: new FormControl(null, []),
            httpStatuses: new FormControl(null, []),
          }),
          healthy: new FormGroup({
            httpStatuses: new FormControl(null, []),
            successes: new FormControl(null, []),
          }),
        }),
        threshold: new FormControl(null, []),
      }),
    });
  }

  submit(): void {
    if (this.formUpstream.invalid) {
      this.translate.get('connections.message').subscribe((translate) => {
        this.toastr.error(translate.invalidForm);
        return;
      });
    }
    const formValues = this.formUpstream.value;
    if (this.modalOption === 'create') {
      const requestServer = {
        name: formValues.name,
        tags: this.tags,
        algorithm: formValues.algorithm,
        hash_on: formValues.hashOn,
        hash_fallback: formValues.hashFallback,
        hash_on_header: formValues.hashOnHeader,
        hash_fallback_header: formValues.hashFallbackHeader,
        hash_on_cookie: formValues.hashOnCookie,
        hash_on_cookie_path: formValues.hashOnCookiePath,
        slots: formValues.slots,
        https_verify_certificate: formValues.keyName,
        host_header: formValues.hostHeader,
        healthchecks: {
          active: {
            https_verify_certificate:
              formValues.healthchecks.active.httpsVerifyCertificate,
            http_path: formValues.healthchecks.active.httpPath,
            timeout: formValues.healthchecks.active.timeout,
            https_sni: formValues.healthchecks.active.httpsSni,
            concurrency: formValues.healthchecks.active.concurrency,
            type: formValues.healthchecks.active.type,
            unhealthy: {
              http_statuses:
                formValues.healthchecks.active.unhealthy.httpStatuses,
              tcp_failures:
                formValues.healthchecks.active.unhealthy.tcpFailures,
              timeouts: formValues.healthchecks.active.unhealthy.timeouts,
              http_failures:
                formValues.healthchecks.active.unhealthy.httpFailures,
              interval: formValues.healthchecks.active.unhealthy.interval,
            },
            healthy: {
              http_statuses: [200, 302],
              interval: formValues.healthchecks.active.healthy.interval,
              successes: formValues.healthchecks.active.healthy.successes,
            },
          },
          passive: {
            type: formValues.healthchecks.passive.type,
            unhealthy: {
              http_failures:
                formValues.healthchecks.passive.unhealthy.httpFailures,
              http_statuses: [429, 500, 503],
              tcp_failures:
                formValues.healthchecks.passive.unhealthy.tcpFailures,
              timeouts: formValues.healthchecks.passive.unhealthy.timeouts,
            },
            healthy: {
              successes: formValues.healthchecks.passive.healthy.successes,
              http_statuses: [
                200, 201, 202, 203, 204, 205, 206, 207, 208, 226, 300, 301, 302,
                303, 304, 305, 306, 307, 308,
              ],
            },
          },
          threshold: formValues.healthchecks.threshold,
        },
      };

      this.translate.get('upstreams.message').subscribe((translate) => {
        this.upstreamService.createUpstream(requestServer).subscribe(
          () => {
            this.toastr.success(translate.created);
            this.dialog.close();
          },
          (err) => {
            this.toastr.error(translate.error);
            console.error(err);
          }
        );
      });
    } else {
      const requestServer = {
        id: formValues.id,
        name: formValues.name,
        tags: this.tags,
        algorithm: formValues.algorithm,
        hash_on: formValues.hashOn,
        hash_fallback: formValues.hashFallback,
        hash_on_header: formValues.hashOnHeader,
        hash_fallback_header: formValues.hashFallbackHeader,
        hash_on_cookie: formValues.hashOnCookie,
        hash_on_cookie_path: formValues.hashOnCookiePath,
        slots: formValues.slots,
        https_verify_certificate: formValues.keyName,
        host_header: formValues.hostHeader,
      };
      this.translate.get('upstreams.message').subscribe((translate) => {
        this.upstreamService.updateUpstream(requestServer).subscribe(
          () => {
            this.toastr.success(translate.updated);
            this.dialog.close();
          },
          (err) => {
            this.toastr.error(translate.error);
            console.error(err);
          }
        );
      });
    }
  }

  updateForm(row: any): void {
    this.formUpstream.patchValue({
      id: row.id,
      name: row.name,
      tags: this.tags,
      algorithm: row.algorithm,
      hashOn: row.hash_on,
      hostHeader: row.host_header,
      hashOnHeader: row.hash_on_header,
      hashFallback: row.hash_fallback,
      hashFallbackHeader: row.hash_fallback_header,
      hashOnCookie: row.hash_on_cookie,
      hashOnCookiePath: row.hash_on_cookie_path,
      slots: row.slots,
    });
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
}
