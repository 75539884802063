import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {endpoint} from '../../core/constants/services.constants';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConsumerBasicCredential, ConsumerGroup, ConsumerHmacCredential, ConsumerJwtCredential, ConsumerKeyCredential, ConsumerModel, ConsumerOauthCredential} from '../../core/model/consumer.model';

@Injectable({
    providedIn: 'root'
})
export class ConsumerService {

    private URL_BASE: string = environment.url_services;
    private ENDPOINT_CONSUMER = `${this.URL_BASE}${endpoint.ENDPOINT_CONSUMER}`;
    private ENDPOINT_KONG_CONSUMER = `${this.URL_BASE}${endpoint.KONG_CONSUMER}`;

    constructor(private http: HttpClient) {
    }

    getConsumers(): Observable<any> {
        return this.http.get(`${this.ENDPOINT_KONG_CONSUMER}`).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    getConsumer(id: string): Observable<HttpResponse<ConsumerModel>> {
        return this.http.get<ConsumerModel>(`${this.ENDPOINT_KONG_CONSUMER}/${id}`, {observe: 'response'});
    }

    createConsumer(body: any): Observable<any> {
        return this.http.post(this.ENDPOINT_KONG_CONSUMER, body);
    }

    updateConsumer(body: any): Observable<any> {
        return this.http.put(`${this.ENDPOINT_KONG_CONSUMER}/${body.username}`, body);
    }

    deleteConsumer(id: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_CONSUMER}/${id}`);
    }

    getAcls(id: string): Observable<ConsumerGroup[]> {
        return this.http.get(`${this.ENDPOINT_KONG_CONSUMER}/${id}/acls`).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    getBasicAuthCredential(id: string): Observable<ConsumerBasicCredential[]> {
        return this.http.get(`${this.ENDPOINT_KONG_CONSUMER}/${id}/basic-auth`).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    getKeyAuthCredential(id: string): Observable<ConsumerKeyCredential[]> {
        return this.http.get(`${this.ENDPOINT_KONG_CONSUMER}/${id}/key-auth`).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    getHmacAuthCredential(id: string): Observable<ConsumerHmacCredential[]> {
        return this.http.get(`${this.ENDPOINT_KONG_CONSUMER}/${id}/hmac-auth`).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    getOAuth2Credential(id: string): Observable<ConsumerOauthCredential[]> {
        return this.http.get(`${this.ENDPOINT_KONG_CONSUMER}/${id}/oauth2`).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    getJwtAuthCredential(id: string): Observable<ConsumerJwtCredential[]> {
        return this.http.get(`${this.ENDPOINT_KONG_CONSUMER}/${id}/jwt`).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    createBasicCredential(body: any, consumerId: string): Observable<any> {
        return this.http.post(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/basic-auth`, body);
    }

    deleteBasicCredential(id: string, consumerId: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/basic-auth/${id}`);
    }

    createHmacCredential(body: any, consumerId: string): Observable<any> {
        return this.http.post(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/hmac-auth`, body);
    }

    deleteHmacCredential(id: string, consumerId: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/hmac-auth/${id}`);
    }

    createKeyCredential(body: any, consumerId: string): Observable<any> {
        return this.http.post(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/key-auth`, body);
    }

    deleteKeyCredential(id: string, consumerId: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/key-auth/${id}`);
    }

    createOauthCredential(body: any, consumerId: string): Observable<any> {
        return this.http.post(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/oauth2`, body);
    }

    deleteOauthCredential(id: string, consumerId: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/oauth2/${id}`);
    }

    createJwtCredential(body: any, consumerId: string): Observable<any> {
        return this.http.post(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/jwt`, body);
    }

    deleteJwtCredential(id: string, consumerId: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/jwt/${id}`);
    }

    deleteGroup(id: string, consumerId: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/acls/${id}`);
    }

    createGroup(body: any, consumerId: string): Observable<any> {
        return this.http.post(`${this.ENDPOINT_KONG_CONSUMER}/${consumerId}/acls`, body);
    }
}
