import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from '../../services/apis-old/auth.service';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService,
        private router: Router,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap({
                error: (err: HttpErrorResponse) => {
                    if (err.status === 401 && !err.url.includes('api/account')) {
                        this.auth.logout();
                        this.router.navigate(['/account/login']);
                    }
                },
            })
        );
    }
}
