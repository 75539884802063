import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {LightboxConfig} from 'ngx-lightbox';
import {Subscription} from 'rxjs';
import {User} from 'src/app/core/model/login.model';

import {LocalstorageService} from 'src/app/services/localstorage.service';
import {UsersService} from '../../../../services/apis-old/users.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IOption} from 'ng-select';
import {SelectOptionService} from 'src/app/theme/shared/components/select/select-option.service';
import 'rxjs/add/operator/map';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {ToastService} from 'src/app/theme/shared/components/toast/toast.service';
import {AuthService} from 'src/app/services/apis-old/auth.service';
import {ServicesService} from 'src/app/services/apis-old/services.service';
import {ToastrService} from 'ngx-toastr';
import {IConnection} from '../../connections/connection.model';

@Component({
  selector: 'app-cambioclave',
  templateUrl: './cambioclave.component.html',
  styleUrls: ['./cambioclave.component.scss']
})
export class CambioclaveComponent implements OnInit {
  private subscription: Subscription;
  public user: User;

  public formDatosPersonales: FormGroup;
  public formDatosSistema: FormGroup;

  simpleOption: Array<IOption>;

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;

  constructor(
    private lighboxConfig: LightboxConfig,
    private sanitizer: DomSanitizer,
    private servicesServices: ServicesService,
    private usersService: UsersService,
    private localstorageService: LocalstorageService,
    private fb: FormBuilder,
    public selectOptionService: SelectOptionService,
    public toastEvent: ToastService,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    lighboxConfig.fadeDuration = 1;
  }

  ngOnInit(): void {
    this.getAuthorities();
    this.getUser();
    this.user = this.localstorageService.getItemObject<User>('user');
    this.loadForm();
  }

  private loadForm(): void {
    this.formDatosPersonales = this.fb.group({
      nombre: [''],
      apellido: [''],
      mail: [''],
      user: [''],
      perfilesUser: [''],
      estado: [true],
      idioma: ['es'],
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
    });
  }

  changePassword(): void {
    /*this.error = false;
    this.success = false;
    this.doNotMatch = false;*/
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Procesando...'
    });
    Swal.showLoading();

    const newPassword = this.formDatosPersonales.value?.newPassword;
    if (newPassword !== this.formDatosPersonales.value?.confirmPassword) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Error al modificar la password',
        text: 'Las contraseñas no coinciden'
      });
    } else {
      this.authService.changePassword(
        {currentPassword: this.formDatosPersonales.value?.currentPassword, newPassword}
      ).subscribe(resp => {
        Swal.close();
        this.toastr.success('Clave cambiando con éxito');
      }, (err) => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Error al modificar la password',
          text: 'err'
        });
      });
    }
  }

  public getSantizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getAuthorities(): void {
    this.servicesServices.getUsersAuthorities()
      .subscribe(resp => {
        this.simpleOption = resp.map((role) => ({value: role, label: role}));
      }, (err) => {
        console.log(err);
      });
  }

  getUser(): void {
    this.usersService.getUsersSystem('system')
      .subscribe(resp => {
      }, (err) => {
        console.log(err);
      });
  }

  crearNuevoUsuario(): void {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Creando Usuario'
    });
    Swal.showLoading();
    // TODO: Use EventEmitter with form value
    const requestUser: User = {
      login: this.formDatosPersonales.controls.user.value,
      firstName: this.formDatosPersonales.controls.nombre.value,
      lastName: this.formDatosPersonales.controls.apellido.value,
      email: this.formDatosPersonales.controls.mail.value,
      authorities: this.formDatosPersonales.controls.perfilesUser.value,
      langKey: this.formDatosPersonales.controls.idioma.value,


      id: null,
      lastModifiedBy: '',
      lastModifiedDate: null,
      createdBy: this.user.login,
      createdDate: null,
      activated: true,
      imageUrl: null
    };

    const environment = this.localstorageService.getItem('environment');
    this.usersService.crearUsuario(requestUser, environment)
      .subscribe(resp => {
        Swal.close();
        this.user = resp;
        this.toastr.success('Usuario creado con éxito');
      }, (err) => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Error al crear usuario',
          text: 'err'
        });
      });

  }

}
