import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KeyRequestComponent} from './key-request/key-request.component';
import {DeveloperPortalRoutingModule} from './developer-portal-routing.module';
import {CssComponent} from './css/css.component';


@NgModule({
    declarations: [KeyRequestComponent, CssComponent],
    imports: [
        CommonModule,
        DeveloperPortalRoutingModule
    ]
})
export class DeveloperPortalModule {
}
