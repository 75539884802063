import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ColumnSetup } from '../../../components/dynamic-table/dynamic-table.component';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { UploadResponse } from '../../../core/model/service.model';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { Connection } from '../../../core/model/connection.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageError } from '../../../core/interfaces/message-error.interface';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../services/gateway/api.service';
import { ConnectionsService } from '../../../services/apis/connections.service';

export interface RowItem {
  id: string;
  title: string;
  apiIdentity: string;
  description: string;
  environments: string[];
  lastModifiedDate: string;
  version: string;
  urlServers: string[];
  revision: number;
}

@Component({
  selector: 'app-apis',
  templateUrl: './apis.component.html',
  styleUrls: ['./apis.component.scss'],
})
export class ApisComponent implements OnInit, AfterViewInit {
  public spinner = false;
  public dataSource: RowItem[] = [];
  public columnsSetup: ColumnSetup[] = [
    {
      columnDef: 'title',
      title: 'apis.table.title',
      cell: (row: RowItem) => `${row.title}`,
    },
    {
      columnDef: 'apiIdentity',
      title: 'apis.table.apiIdentity',
      cell: (row: RowItem) => `${row.apiIdentity}`,
    },
    {
      columnDef: 'environment',
      title: 'apis.table.environments',
      cell: (row: RowItem) => `${row.environments}`,
    },
    {
      columnDef: 'version',
      title: 'apis.table.version',
      cell: (row: RowItem) => `${row.version}`,
    },
    {
      columnDef: 'revision',
      title: 'apis.table.revision',
      cell: (row: RowItem) => `${row.revision}`,
    },
    {
      columnDef: 'createdDate',
      title: 'apis.table.createdDate',
      cell: (row: RowItem) =>
        `${this.dateFormat.transform(
          row.lastModifiedDate,
          'dd/MM/yyyy HH:mm'
        )}`,
    },
    { columnDef: 'delete', title: '', custom: true },
  ];
  public indexTab = 0;
  public tabs: string[] = [];
  public loaded = 0;
  public selectedFiles!: FileList;
  public archivos: File[] = [];
  public currentFileUpload!: File | null;
  public filePath = '';
  public tiposDocumentos = '.yml,.yaml';
  public lastId: string;
  public ymlResponse: UploadResponse;
  public isInitialUpload = false;
  public firstCompleted = false;
  public secondCompleted = false;
  public thirdCompleted = false;
  public routesModel = '';
  public apiMessage = '';
  public formServer: FormGroup;
  public MESSAGE_REQUIRED = 'apis.message.required';
  public connections: Connection[] = [];
  public messageError: MessageError = {
    required: [{ type: 'required', message: this.MESSAGE_REQUIRED }],
  };

  @ViewChild('file')
  public file: ElementRef;
  @ViewChild(MatHorizontalStepper)
  public wizard: MatHorizontalStepper;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private apiService: ApiService,
    private dateFormat: DatePipe,
    private connectionService: ConnectionsService
  ) {
    this.formServer = new FormGroup({
      server: new FormControl('', [Validators.required]),
    });
  }

  ngAfterViewInit(): void {
    this.getApis();
    this.loadConnections();
    this.connectionService.getConnectionEmitter().subscribe((value) => {
      if (value) {
        this.getApis();
      }
    });
  }

  ngOnInit(): void {}

  getApis(): void {
    this.spinner = true;
    this.apiService.getApis().subscribe(
      (elementos) => {
        this.spinner = false;
        this.dataSource = elementos;
      },
      () => {
        this.spinner = false;
      }
    );
  }

  private loadConnections(): void {
    this.connectionService.getServers().subscribe(
      (response: Connection[]) => {
        this.connections = response.map((server) => ({ ...server }));
        this.connections.find((value) => value.activo);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
