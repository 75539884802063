import {Component, OnInit} from '@angular/core';
import {LogsService} from '../../../services/apis-old/logs.service';
import {ColumnSetup} from 'src/app/components/dynamic-table/dynamic-table.component';


export interface RowItem {
    key: string;
    value: string;
}

@Component({
    selector: 'app-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

    public levels: string[];

    public spinner = false;
    public dataSource: RowItem[] = [];
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'name', title: 'logs.table.name', cell: (row: RowItem) => `${row.key}`},
        {columnDef: 'level', custom: true}
    ];

    constructor(
        private logService: LogsService,
    ) {
    }

    ngOnInit(): void {
        this.findAllLogs();
    }

    findAllLogs(): void {
        this.dataSource = [];
        this.spinner = true;
        this.logService.findAllLog()
            .subscribe((resp: any) => {

                this.levels = resp.levels;

                const loggers = resp.loggers;
                const data = Object.entries(loggers).map((m: any) => ({
                    key: m[0],
                    value: m[1].effectiveLevel
                }));
                this.spinner = false;
                this.dataSource = data;
            }, (err) => {
                this.spinner = false;
            });
    }

    setNotifi(log, level): void {
        this.logService.SetLoggers(log, {configuredLevel: level})
            .subscribe(resp => {
                this.findAllLogs();
            }, (err) => {
                console.log(err);
            });
    }

    filterFunction(row: RowItem, searchValue: string): boolean {
        const valueFilter = searchValue.trim().toLowerCase();
        const validate = row.key.toLowerCase().includes(valueFilter) || (valueFilter && valueFilter.length === 0);
        return validate;
    }

}
