import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from './shared/shared.module';

import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { NavigationComponent } from './layout/admin/navigation/navigation.component';
import { NavContentComponent } from './layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './shared/full-screen/toggle-full-screen';
import {
  NgbButtonsModule,
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '../material.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
  ],
  exports: [
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    SharedModule,
  ],
  imports: [
    HttpClientModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    MaterialModule,
    CommonModule,
    SharedModule,
    RouterModule,
  ],
})
export class ThemeModule {}
