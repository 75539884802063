<div class="row container align-items-center">
  <div class="col-12 col-md-6 text-center">
    <a mat-button class="box" [routerLink]="['/admin/home/specs']">
      <mat-icon
        svgIcon="open-api"
        style="width: 110px; height: 110px"
      ></mat-icon>
      <div>Specs</div>
      <div style="font-size: 14px; font-weight: 400">
        {{ "home.specs" | translate }}
      </div>
    </a>
  </div>
  <div class="col-12 col-md-6 text-center">
    <a mat-button class="box" [routerLink]="['/admin/gateway/apis']">
      <mat-icon style="width: 100px; height: 100px; font-size: 100px"
        >cloud</mat-icon
      >
      <div>Apis</div>
      <div style="font-size: 14px; font-weight: 400">
        {{ "home.apis" | translate }}
      </div>
    </a>
  </div>
  <div class="col-12 col-md-6 text-center">
    <a mat-button class="box" [routerLink]="['/admin/develop-portal/portal']">
      <mat-icon style="width: 100px; height: 100px; font-size: 100px"
        >dashboard</mat-icon
      >
      <div>Portal</div>
      <div style="font-size: 14px; font-weight: 400">
        {{ "home.portal" | translate }}
      </div>
    </a>
  </div>
  <div class="col-12 col-md-6 text-center">
    <a mat-button class="box" [routerLink]="['/admin/management/connections']">
      <mat-icon style="width: 100px; height: 100px; font-size: 100px"
        >cast</mat-icon
      >
      <div>Connections</div>
      <div style="font-size: 14px; font-weight: 400">
        {{ "home.connections" | translate }}
      </div>
    </a>
  </div>
</div>
