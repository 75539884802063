import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class ApexChartService {
    @Output() changeTimeRange: EventEmitter<boolean> = new EventEmitter();
    @Output() changeSeriesData: EventEmitter<boolean> = new EventEmitter();

    constructor() {
    }

    eventChangeTimeRange(): void {
        this.changeTimeRange.emit();
    }

    eventChangeSeriesData(): void {
        this.changeSeriesData.emit();
    }
}
