<app-header-pages title="{{'logs.title' | translate }}" subTitle="Debo cambiar el mensaje"></app-header-pages>

<app-filter-table #filter></app-filter-table>

<app-dynamic-table [data]="dataSource" [columnsSetup]="columnsSetup" [filterText]="filter.value" [filterFn]="filterFunction" [spinner]="spinner">
    <ng-container matColumnDef="level">
        <mat-header-cell *matHeaderCellDef scope="col">{{ 'logs.table.level' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <ng-container *ngFor="let level of levels">
                <label class="badge badge-pill pointer-log" (click)="setNotifi(row.key, level)" [ngClass]="{'badge-success': row.value == 'DEBUG' && level == 'DEBUG',
                            'badge-info': row.value == 'INFO' && level == 'INFO',
                            'badge-primary': row.value== 'TRACE' && level == 'TRACE',
                            'badge-warning': row.value == 'WARN' && level == 'WARN',
                            'badge-danger': row.value == 'ERROR' && level == 'ERROR',
                            'badge-secondary': row.value == 'OFF' && level == 'OFF'}">{{ level }}</label>
            </ng-container>
        </mat-cell>
    </ng-container>
</app-dynamic-table>