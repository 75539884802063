import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { CheckUrlService } from 'src/app/services/apis/check-url.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MessageError } from 'src/app/core/interfaces/message-error.interface';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ServicesService } from '../../../../services/gateway/services.service';
import { SchemasService } from '../../../../services/gateway/schemas.service';
import { Service } from '../../../../core/model/service.model';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionsService } from 'src/app/services/apis/connections.service';

@Component({
  selector: 'app-modal-service',
  templateUrl: './modal-service.component.html',
  styleUrls: ['./modal-service.component.scss'],
})
export class ModalServiceComponent implements OnInit, AfterViewInit {
  public tabIndex = 0;
  public formService: FormGroup;
  public inputs;
  public keys = [];
  public schema: Record<string, unknown> = {};
  public names: Record<string, { name: string; hint: string }> = {};
  title = '';
  selectedTab = 0;
  buttonCreate = '';
  buttonEdit = '';
  buttonCancel = '';
  modalOption = 'create';
  tags: string[] = [];
  MESSAGE_REQUIRED = 'services.message.required';
  protocolos = ['grpc', 'grpcs', 'http', 'https', 'tcp', 'tls', 'udp'];
  public messageError: MessageError = {
    required: [{ type: 'required', message: this.MESSAGE_REQUIRED }],
  };
  @ViewChild(MatTabGroup) tabGroup;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private servicesService: ServicesService,
    private checkUrl: CheckUrlService,
    private toastr: ToastrService,
    private connectionService: ConnectionsService,
    private schemasService: SchemasService,
    public dialog: MatDialogRef<ModalServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.connectionService.getConnectionEmitter().subscribe((value) => {
      if (value) {
        this.getSchema();
      }
    });
    this.getSchema();
  }

  private getSchema(): void {
    this.schemasService.getServices().subscribe(
      (response) => {
        this.inputs = response.fields;
        const form = {
          id: new FormControl(null, []),
        };
        const values = {};
        if (this.inputs.length > 0) {
          this.inputs.map((item) => {
            const keys = Object.keys(item);
            const key = keys[0];
            const options = [];
            if (!item[key].auto) {
              this.keys.push(key);
              this.schema[key] = item[key];
              this.names[key] = {
                name: `services.modal.${key}`,
                hint: `services.modal.${key}`,
              };
              if (item[key].required) {
                options.push(Validators.required);
              }
              if (item[key].default) {
                values[key] = item[key].default;
              }
              form[key] = new FormControl(null, options);
            }
          });
          this.formService = new FormGroup(form);
          if (this.data) {
            this.setValidatorsForm('');
            this.tags = this.data.tags || [];
            this.updateForm(this.data);
          } else {
            this.formService.patchValue(values);
          }
        }
      },
      (err) => {}
    );
  }

  setValidatorsForm(type: string): void {
    this.cleanValidators();
  }

  cleanValidators(): void {
    const keys = Object.keys(this.formService.value);
    keys.forEach((key) => {
      this.formService.get(key).clearValidators();
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabIndex = tabChangeEvent.index;
    const tabChange = tabChangeEvent.tab.ariaLabel;
    this.setValidatorsForm(tabChange);
    this.formService.patchValue({
      authType: tabChangeEvent.tab.ariaLabel,
    });
  }

  isValid(type: string, name: string): boolean {
    return this.formService.get(name).hasError(type);
  }

  updateForm(row: any): void {
    console.log('Se ejecuto esto al inicio');
    this.formService.patchValue({
      id: row.id,
      name: row.name,
      url: row.url,
      protocol: row.protocol,
      host: row.host,
      port: row.port,
      path: row.path,
      retries: row.retries,
      connect_timeout: row.connect_timeout,
      write_timeout: row.write_timeout,
      read_timeout: row.read_timeout,
      client_certificate: row.client_certificate,
      tls_verify: row.tls_verify,
      tls_verify_depth: row.tls_verify_depth,
    });
  }

  submit(): void {
    if (this.formService.invalid) {
      this.translate.get('connections.message').subscribe((translate) => {
        this.toastr.error(translate.invalidForm);
        return;
      });
    }
    const formValues = this.formService.value;
    const client_certificate = formValues.client_certificate
      ? { id: formValues.client_certificate }
      : null;
    if (this.modalOption === 'create') {
      if (this.formService.valid) {
        const requestServer = {
          name: formValues.name,
          retries: formValues.retries,
          protocol: formValues.protocol,
          url: formValues.url,
          host: formValues.host,
          port: formValues.port as number,
          path: formValues.path,
          connect_timeout: formValues.connect_timeout || 6000,
          write_timeout: formValues.write_timeout || 6000,
          read_timeout: formValues.read_timeout || 6000,
          tags: this.tags,
          client_certificate: client_certificate,
          tls_verify: formValues.tls_verify,
          tls_verify_depth: formValues.tls_verify_depth,
        } as Service;
        this.translate.get('services.message').subscribe((translate) => {
          this.servicesService.createService(requestServer).subscribe(
            () => {
              this.toastr.success(translate.created);
              this.dialog.close(true);
            },
            (err) => {
              this.toastr.error(translate.error);
            }
          );
        });
      }
    } else {
      if (this.formService.valid) {
        const requestServer = {
          id: formValues.id,
          name: formValues.name,
          retries: formValues.retries as number,
          protocol: formValues.protocol,
          url: formValues.url,
          host: formValues.host,
          port: formValues.port as number,
          path: formValues.path,
          connect_timeout: formValues.connect_timeout as number,
          write_timeout: formValues.write_timeout as number,
          read_timeout: formValues.read_timeout as number,
          tags: this.tags,
          client_certificate: client_certificate,
          tls_verify: formValues.tls_verify,
          tls_verify_depth: formValues.tls_verify_depth,
        } as Service;
        this.translate.get('services.message').subscribe((translate) => {
          this.servicesService.updateService(requestServer).subscribe(
            () => {
              this.toastr.success(translate.created);
              this.dialog.close();
            },
            (err) => {
              this.toastr.error(translate.error);
            }
          );
        });
      }
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
}
