<form [formGroup]="formService" (ngSubmit)="submit()">
  <div class="container">
    <h1 mat-dialog-title>{{ title | translate }}</h1>
    <hr />
    <div mat-dialog-content>
      <div class="row">
        <ng-container *ngFor="let key of keys">
          <div class="col-12" *ngIf="key === 'tags'">
            <label>{{ names[key].name | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip
                  *ngFor="let tag of tags"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="removeTag(tag)"
                >
                  {{ tag }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  matInput
                  placeholder="{{ 'services.modal.tags' | translate }}"
                  formControlName="tags"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addTag($event)"
                />
              </mat-chip-list>
              <mat-hint>{{ names[key].hint | translate }}</mat-hint>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, key)">{{
                  error.message | translate
                }}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12" *ngIf="schema[key].type === 'boolean'">
            <mat-slide-toggle
              *ngIf="schema[key].type === 'boolean'"
              [formControlName]="key"
              >{{ names[key].name | translate }}</mat-slide-toggle
            >
            <br />
            <mat-hint>{{ names[key].hint | translate }}</mat-hint>
          </div>
          <div class="col-12" *ngIf="schema[key].type === 'foreign'">
            <label>{{ names[key].name | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <input
                matInput
                type="text"
                [value]="schema[key].default || ''"
                [formControlName]="key"
              />
              <mat-hint>{{ names[key].hint | translate }}</mat-hint>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, key)">{{
                  error.message | translate
                }}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12" *ngIf="schema[key].type === 'integer'">
            <label>{{ names[key].name | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <input
                matInput
                [value]="schema[key].default || ''"
                [min]="schema[key].between[0] || 0"
                [max]="schema[key].between[1] || 999999999"
                [formControlName]="key"
                type="number"
              />
              <mat-hint>{{ names[key].hint | translate }}</mat-hint>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, key)">{{
                  error.message | translate
                }}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12" *ngIf="schema[key].type === 'string'">
            <label>{{ names[key].name | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <input
                *ngIf="!schema[key].one_of"
                matInput
                type="text"
                [value]="schema[key].default || ''"
                [formControlName]="key"
              />
              <mat-select
                *ngIf="schema[key].one_of"
                [value]="schema[key].default"
                [formControlName]="key"
              >
                <mat-option
                  *ngFor="let item of schema[key].one_of"
                  [value]="item"
                >
                  {{ item }}
                </mat-option>
              </mat-select>
              <mat-hint>{{ names[key].hint | translate }}</mat-hint>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, key)">{{
                  error.message | translate
                }}</span>
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>
    <div mat-dialog-actions align="end" class="mt-2 pl-3">
      <button class="btn btn-close" [mat-dialog-close]="undefined">
        {{ buttonCancel | translate }}
      </button>
      <button class="btn btn-action">
        {{
          modalOption == "create"
            ? (buttonCreate | translate)
            : (buttonEdit | translate)
        }}
      </button>
    </div>
  </div>
</form>
