<mat-card class="table-responsive">
    <mat-table [dataSource]="dataSource" matSort [class]="classes + ' w-full'">
        <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of columns">
            <mat-header-cell *matHeaderCellDef mat-sort-header scope="col" [class]="column.classes"> {{ column.title | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" [innerHTML]="column.cell(row)" [class]="column.classes"></mat-cell>
        </ng-container>
        <ng-content></ng-content>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div class="loader" *ngIf="spinner"></div>
    <h4 *ngIf="data.length === 0 && !spinner" class="msj-table">{{noData}}</h4>
    <mat-paginator *ngIf="showPage" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20, 30]" showFirstLastButtons></mat-paginator>
</mat-card>
