import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageError} from '../../../../core/interfaces/message-error.interface';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {TranslateService} from '@ngx-translate/core';
import {CheckUrlService} from '../../../../services/apis/check-url.service';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatChipInputEvent} from '@angular/material/chips';
import {Target, Upstreams} from '../../../../core/model/upstreams.model';
import {UpstreamService} from '../../../../services/gateway/upstream.service';

@Component({
    selector: 'app-add-upstream-target',
    templateUrl: './add-upstream-target.component.html',
    styleUrls: ['./add-upstream-target.component.scss']
})
export class AddUpstreamTargetComponent implements OnInit {
    upstream: Upstreams;
    public formTarget: FormGroup;
    title = '';
    selectedTab = 0;
    buttonCreate = '';
    buttonEdit = '';
    buttonCancel = '';
    modalOption = 'create';
    tags: string[] = [];
    MESSAGE_REQUIRED = 'services.message.required';
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    constructor(
        private translate: TranslateService,
        private upstreamService: UpstreamService,
        private checkUrl: CheckUrlService,
        private toastr: ToastrService,
        public dialog: MatDialogRef<AddUpstreamTargetComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Upstreams
    ) {
        this.upstream = data;
    }

    ngOnInit(): void {
        this.formTarget = this.buildForm();
    }

    setValidatorsForm(type: string): void {
        this.cleanValidators();
    }

    cleanValidators(): void {
        const keys = Object.keys(this.formTarget.value);
        keys.forEach(key => {
            this.formTarget.get(key).clearValidators();
        });
    }

    isValid(type: string, name: string): boolean {
        return this.formTarget.get(name).hasError(type);
    }

    buildForm(): FormGroup {
        return new FormGroup({
            target: new FormControl(null, [Validators.required]),
            weight: new FormControl(100, []),
            tags: new FormControl(null, []),
        });
    }

    submit(): void {
        if (this.formTarget.invalid) {
            this.translate.get('upstreams.message').subscribe(translate => {
                this.toastr.error(translate.invalidForm);
                return;
            });
        }
        const formValues = this.formTarget.value;
        if (this.formTarget.valid) {
            const requestServer = {
                target: formValues.target,
                weight: formValues.weight,
                tags: this.tags,
            } as Target;
            this.translate.get('services.message').subscribe(translate => {
                this.upstreamService.createTarget(this.upstream.id, requestServer)
                    .subscribe(() => {
                        this.toastr.success(translate.created);
                        this.dialog.close(true);
                    }, (err) => {
                        this.toastr.error(translate.error);
                        console.error(err);
                    });
            });
        }
    }

    addTag(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTag(tag: string): void {
        const index = this.tags.indexOf(tag);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }
}
