<app-header-pages title="{{'connections.title' | translate }}" subTitle="{{'connections.subTitle' | translate }}"></app-header-pages>

<div class="d-flex justify-content-between">
    <button class="btn btn-action" (click)="openConnection()">
        <mat-icon>add</mat-icon>
        Nueva conexión
    </button>
    <app-filter-table #filter></app-filter-table>
</div>

<app-dynamic-table [data]="dataSource" [columnsSetup]="columnsSetup" [spinner]="spinner" pageSize="10">
    <ng-container matColumnDef="testUrl">
        <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <button class="btn" (click)="getStatusUrl(row)">
                <mat-icon>power</mat-icon>
            </button>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef scope="col">{{ 'connections.table.name' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <a (click)="editConnection(row)">{{ row.nombre }} </a>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="activated">
        <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <mat-slide-toggle (click)="statusConnection(row.id)" [(ngModel)]="row.activo">{{ row.activo ? ('connections.table.statusEnabled' | translate) : ('connections.table.statusDisabled' | translate) }} </mat-slide-toggle>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <button class="btn btn-delete" (click)="editConnection(row)">
                <mat-icon class="edit">edit</mat-icon>
                {{ 'connections.table.edit' | translate }}</button>
            <button class="btn btn-delete" (click)="deleteConnection(row.id)">
                <mat-icon class="delete">delete</mat-icon>
                {{ 'connections.table.delete' | translate }}</button>
            <button class="btn btn-delete" (click)="backupConnection(row.id)">
                <mat-icon class="edit">save</mat-icon>
                {{ 'connections.table.dump' | translate }}</button>
        </mat-cell>
    </ng-container>
</app-dynamic-table>
