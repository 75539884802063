import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'src/app/core/constants/app.constants';

@Injectable({
    providedIn: 'root'
})
export class LogsService {

    constructor(private http: HttpClient) {
    }

    findAllLog() {
        return this.http.get(
            `${SERVER_API_URL}management/loggers`
        );
    }

    //configuredLevel: "WARN"
    //          https://kong-tools-back-hl4ygwo2nq-uc.a.run.app/management/loggers/
    SetLoggers(log: string, body: any): any {
        return this.http.post(
            `${SERVER_API_URL}management/loggers/${log}`,
            body
        );
    }
}
