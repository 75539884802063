<form [formGroup]="formPlugin" (ngSubmit)="submit()">
    <div class="container">
        <h1 mat-dialog-title>{{ title }}</h1>
        <hr>
        <div mat-dialog-content>
            <ng-container *ngFor="let element of schema.fields">
                <div class="form-group" ng-class="{'has-error' : errors[key]}" *ngFor="let item of element | keyvalue">
                    <label class="col-sm-4 control-label">
                        <strong class="text-capitalize">{{humanizeLabel(item.key)}}</strong>
                    </label>
                    <div class="col-12">
                        <div [ngSwitch]="item.value.type">
                            <!-- TYPE: RECORD -->
                            <div *ngSwitchCase="'record'">
                                <div class="plugin-field-table">
                                    <div *ngFor="let field of item.value.fields">
                                        <!--<pre>{{field | json}}</pre>-->
                                        <label><i class="mdi mdi-chevron-right"></i>{{getFieldProp(field)}}</label>
                                        <div [ngSwitch]="field[getFieldProp(field)].type">
                                            <div *ngSwitchCase="'array'">
                                                <chips ng-model="field[getFieldProp(field)].value">
                                                    <chip-tmpl>
                                                        <div class="default-chip">
                                                            <i class="mdi mdi-close" remove-chip></i>
                                                        </div>
                                                    </chip-tmpl>
                                                    <input chip-control/>
                                                </chips>
                                            </div>
                                            <div *ngSwitchCase="'record'">
                                                <div class="plugin-field-table">
                                                    <div *ngFor="let _field of field[getFieldProp(field)].fields" class="well" style="background-color: #f2f2f2;border: 1px solid #eaeaea">
                                                        <label>{{getFieldProp(_field)}}</label>
                                                        <div>
                                                            <div [ngSwitch]="_field[getFieldProp(_field)].type">
                                                                <!-- TYPE: STRING -->
                                                                <div *ngSwitchCase="'string'">
                                                                    <!--                                                                <textarea ng-model="field[getFieldProp(field)].value[getFieldProp(_field)]" class="form-control"></textarea>-->
                                                                </div>

                                                                <!-- TYPE: INTEGER -->
                                                                <div *ngSwitchCase="'integer'">
                                                                    <!--                                                                <input type="number" ng-model="field[getFieldProp(field)].value[getFieldProp(_field)]" class="form-control"></input>-->
                                                                </div>

                                                                <!-- TYPE: NUMBER -->
                                                                <div *ngSwitchCase="'number'">
                                                                    <!--                                                                <input type="number" ng-model="field[getFieldProp(field)].value[getFieldProp(_field)]" class="form-control"></input>-->
                                                                </div>

                                                                <!-- TYPE: BOOLEAN -->
                                                                <div *ngSwitchCase="'boolean'">
                                                                    <!--                                                                <input-->
                                                                    <!--                                                                        bs-switch-->
                                                                    <!--                                                                        ng-model="field[getFieldProp(field)].value[getFieldProp(_field)]"-->
                                                                    <!--                                                                        switch-size="small"-->
                                                                    <!--                                                                        type="checkbox"-->
                                                                    <!--                                                                        switch-on-text="YES"-->
                                                                    <!--                                                                        switch-off-text="NO"-->
                                                                    <!--                                                                >-->

                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngSwitchDefault>
                                                <!--                                            <input type="{{field[getFieldProp(field)].type}}" ng-model="field[getFieldProp(field)].value" class="form-control">-->
                                            </div>
                                            <div class="text-danger" ng-if="errors[key]" data-ng-bind="errors[key]"></div>
                                            <!--                                        <p class="help-block">{{data.help}}</p>-->
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <!-- TYPE: TABLE -->
                            <!--                        <div *ngSwitchCase="'table'">-->

                            <!--                            <div *ngIf="item.value.schema.flexible">-->
                            <!--                                <div *ngFor="let keyItem of item.value.custom_fields | keyvalue" class="plugin-field-table margin-bottom">-->
                            <!--                                    <p class="no-margin">-->
                            <!--                                        <strong class="pull-left">{{keyItem.key}}</strong>-->
                            <!--                                        <button data-ng-click="removeCustomField(value,cf_key)" class="btn btn-sm btn-flat btn-danger pull-right" style="margin-top: -15px">-->
                            <!--                                            <i class="mdi mdi-close"></i>-->
                            <!--                                        </button>-->
                            <!--                                    </p>-->
                            <!--                                    <div class="clearfix"></div>-->
                            <!--                                    <div class="row">-->
                            <!--                                        <div *ngFor="let titleItem of keyItem.value | keyvalue" class="col-md-4">-->
                            <!--                                            <input type="{{titleItem.value.type}}" class="form-control" data-ng-model="content.value"/>-->
                            <!--                                            <div class="help-block">{{title}}</div>-->
                            <!--                                        </div>-->
                            <!--                                    </div>-->

                            <!--                                </div>-->
                            <!--                                <div class="input-group">-->
                            <!--                                    <input type="text"-->
                            <!--                                           (keypress)="addCustomField(item.value)"-->
                            <!--                                           data-ng-model="value.custom_field"-->
                            <!--                                           class="form-control"-->
                            <!--                                           my-enter="doSomething()"-->
                            <!--                                           placeholder="add an object to limit...">-->
                            <!--                                    <span class="input-group-btn">-->
                            <!--                                    <button data-ng-click="addCustomField(value)" class="btn btn-primary" type="button">-->
                            <!--                                        <i class="mdi mdi-plus"></i>-->
                            <!--                                    </button>-->
                            <!--                                </span>-->
                            <!--                                </div>-->
                            <!--                                &lt;!&ndash;<pre>{{value.custom_fields | json}}</pre>&ndash;&gt;-->
                            <!--                            </div>-->
                            <!--                            <div *ngIf="!item.value.schema.flexible" class="plugin-field-table">-->
                            <!--                                <div data-ng-repeat="(key,data) in value.schema.fields">-->
                            <!--                                    <label><i class="mdi mdi-chevron-right"></i>{{humanizeLabel(item.key)}}</label>-->
                            <!--                                    <div ng-switch on="data.type">-->
                            <!--                                        <div ng-switch-when="array">-->
                            <!--                                            <chips ng-model="data.value">-->
                            <!--                                                <chip-tmpl>-->
                            <!--                                                    <div class="default-chip">-->
                            <!--&lt;!&ndash;                                                        {{chip}}&ndash;&gt;-->
                            <!--                                                        <i class="mdi mdi-close" remove-chip></i>-->
                            <!--                                                    </div>-->
                            <!--                                                </chip-tmpl>-->
                            <!--                                                <input chip-control/>-->
                            <!--                                            </chips>-->
                            <!--                                        </div>-->
                            <!--                                        <div ng-switch-default>-->
                            <!--&lt;!&ndash;                                            <input type="{{data.type}}" ng-model="data.value" class="form-control">&ndash;&gt;-->
                            <!--                                        </div>-->
                            <!--                                        <div class="text-danger" ng-if="errors[key]" data-ng-bind="errors[key]"></div>-->
                            <!--&lt;!&ndash;                                        <p class="help-block">{{data.help}}</p>&ndash;&gt;-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                            </div>-->


                            <!--                    </div>-->

                            <!-- TYPE: STRING -->
                            <div *ngSwitchCase="'string'">
                                <div *ngIf="item.value.one_of">
                                    <mat-form-field>
                                        <mat-label class="text-capitalize">{{item.key}}</mat-label>
                                        <mat-select [formControl]="getControl(item)">
                                            <mat-option *ngFor="let food of item.value.one_of" [value]="food">
                                                {{food}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="!item.value.one_of">
                                    <textarea [formControl]="getControl(item)" class="form-control">{{item.value?.default}}</textarea>
                                </div>
                            </div>

                            <!--                            TYPE: INTEGER-->
                            <div *ngSwitchCase="'integer'">
                                <input type="number" class="form-control" [formControl]="getControl(item)"/>
                            </div>

                            <!--                            TYPE: NUMBER-->
                            <div *ngSwitchCase="'number'">
                                <input type="number" class="form-control" [formControl]="getControl(item)"/>
                            </div>

                            <!-- TYPE: ARRAY -->
                            <div *ngSwitchCase="'array'">
                                <mat-form-field appearance="outline" class="w-100" *ngIf="isSimpleArray(item.value)">
                                    <mat-chip-list #chipList aria-label="Fruit selection">
                                        <mat-chip *ngFor="let tag of getDefaultElements(item.value.default,item)" [selectable]="true" [removable]="true"
                                                (removed)="removeTag(tag, item.value.default,getControl(item), item)">
                                            {{tag}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                        <input matInput placeholder="{{ 'plugins.modal.tags' | translate }}"
                                                [formControl]="getControl(item)"
                                                [matChipInputFor]="chipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                [matChipInputAddOnBlur]="true"
                                                (matChipInputTokenEnd)="addTag($event,item.value.default,getControl(item),item)">
                                    </mat-chip-list>
                                    <!--                                    <mat-hint>{{ 'services.hint.tags' | translate }}</mat-hint>-->
                                    <mat-hint>Tip: Press <code>Enter</code> to accept a value.</mat-hint>
                                    <!--                                    <mat-error *ngFor="let error of messageError.required">-->
                                    <!--                                        <span *ngIf="isValid(error.type, 'tags')">{{ error.message  | translate }}</span>-->
                                    <!--                                    </mat-error>-->
                                </mat-form-field>
                                <div [ngSwitch]="item.value.elements.type" [formArrayName]="item.key">
                                    <div *ngSwitchCase="'record'">
                                        <div class="plugin-field-table">
                                            <div *ngFor="let field of item.value.elements.fields">
                                                <!--<pre>{{field | json}}</pre>-->
                                                <label><i class="mdi mdi-chevron-right"></i>{{getFieldArrayProp(field)}}</label>
                                                <div [ngSwitch]="field[getFieldArrayProp(field)].type">
                                                    <div *ngSwitchCase="'array'">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-chip-list #chipList aria-label="Fruit selection">
                                                                <mat-chip *ngFor="let tag of getDefaultElements(item.value.default,item)" [selectable]="true" [removable]="true"
                                                                        (removed)="removeTag(tag, item.value.default,getControl(item), item)">
                                                                    {{tag}}
                                                                    <mat-icon matChipRemove>cancel</mat-icon>
                                                                </mat-chip>
                                                                <input matInput placeholder="{{ 'plugins.modal.tags' | translate }}"
                                                                        [formControl]="getControl(item)"
                                                                        [matChipInputFor]="chipList"
                                                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                        [matChipInputAddOnBlur]="true"
                                                                        (matChipInputTokenEnd)="addTag($event,item.value.default,getControl(item),item)">
                                                            </mat-chip-list>
                                                            <!--                                    <mat-hint>{{ 'services.hint.tags' | translate }}</mat-hint>-->
                                                            <mat-hint>Tip: Press <code>Enter</code> to accept a value.</mat-hint>
                                                            <!--                                    <mat-error *ngFor="let error of messageError.required">-->
                                                            <!--                                        <span *ngIf="isValid(error.type, 'tags')">{{ error.message  | translate }}</span>-->
                                                            <!--                                    </mat-error>-->
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngSwitchCase="'string'">
                                                        <div *ngIf="field[getFieldArrayProp(field)].one_of">
                                                            <mat-form-field>
                                                                <mat-label class="text-capitalize">{{getFieldArrayProp(field)}}</mat-label>
                                                                <mat-select [formControl]="getControl(item)">
                                                                    <mat-option *ngFor="let element of field[getFieldArrayProp(field)].one_of" [value]="element">
                                                                        {{element}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>

                                                    <!-- TYPE: INTEGER -->
                                                    <div *ngSwitchCase="'integer'">
                                                        <input type="number" class="form-control" [formControl]="getControl(item)"/>
                                                    </div>

                                                    <!-- TYPE: NUMBER -->
                                                    <div *ngSwitchCase="'number'">
                                                        <input type="number" class="form-control" [formControl]="getControl(item)"/>
                                                    </div>

                                                    <!-- TYPE: BOOLEAN -->
                                                    <div *ngSwitchCase="'boolean'">
                                                        <!--                                                                <input-->
                                                        <!--                                                                        bs-switch-->
                                                        <!--                                                                        ng-model="field[getFieldProp(field)].value[getFieldProp(_field)]"-->
                                                        <!--                                                                        switch-size="small"-->
                                                        <!--                                                                        type="checkbox"-->
                                                        <!--                                                                        switch-on-text="YES"-->
                                                        <!--                                                                        switch-off-text="NO"-->
                                                        <!--                                                                >-->

                                                    </div>
                                                    <div *ngSwitchDefault>
                                                        <!--                                            <input type="{{field[getFieldProp(field)].type}}" ng-model="field[getFieldProp(field)].value" class="form-control">-->
                                                    </div>
                                                    <div class="text-danger" ng-if="errors[key]" data-ng-bind="errors[key]"></div>
                                                    <!--                                        <p class="help-block">{{data.help}}</p>-->
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <!-- TYPE: SET -->
                            <div *ngSwitchCase="'set'">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-chip-list #chipList aria-label="Fruit selection">
                                        <mat-chip *ngFor="let tag of item.value.default" [selectable]="true" [removable]="true"
                                                (removed)="removeTag(tag, item.value.default,getControl(item), item)">
                                            {{tag}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                        <input matInput placeholder="{{ 'plugins.modal.tags' | translate }}"
                                                [formControl]="getControl(item)"
                                                [matChipInputFor]="chipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                [matChipInputAddOnBlur]="true"
                                                (matChipInputTokenEnd)="addTag($event,item.value.default,getControl(item), item)">
                                    </mat-chip-list>
                                    <!--                                    <mat-hint>{{ 'services.hint.tags' | translate }}</mat-hint>-->
                                    <mat-hint>Tip: Press <code>Enter</code> to accept a value.</mat-hint>
                                    <!--                                    <mat-error *ngFor="let error of messageError.required">-->
                                    <!--                                        <span *ngIf="isValid(error.type, 'tags')">{{ error.message  | translate }}</span>-->
                                    <!--                                    </mat-error>-->
                                </mat-form-field>
                            </div>

                            <!-- TYPE: SELECT -->
                            <!--                    <div ng-switch-when="select">-->
                            <!--                        <select class="form-control" ng-model="value.value">-->
                            <!--                            <option ng-repeat="item in units" ng-value="item">{{item}}</option>-->
                            <!--                        </select>-->
                            <!--                    </div>-->

                            <!-- TYPE: BOOLEAN -->
                            <div *ngSwitchCase="'boolean'">
                                <mat-slide-toggle [checked]="item.value?.default" [formControl]="getControl(item)">
                                    <span>{{getCheckedValue(getControl(item))}}</span>
                                </mat-slide-toggle>
                            </div>

                            <!-- TYPE: FILE -->
                            <!--                    <div ng-switch-when="file">-->
                            <!--                        <input type="file"-->
                            <!--                               ngf-select ng-model="data.value" name="data.value"-->
                            <!--                        />-->
                            <!--                    </div>-->

                            <!-- DEFAULT -->
                            <!--                            <div *ngSwitchDefault>-->
                            <!--                                <mat-form-field appearance="outline" class="w-100">-->
                            <!--                                    <input type="text" [formControl]="getControl(item)" class="form-control">-->
                            <!--                                </mat-form-field>-->
                            <!--                            </div>-->

                            <!--                    <div class="text-danger" ng-if="errors[key]" data-ng-bind="errors[key]"></div>-->
                            <!--                    <p class="help-block">{{value.help}}</p>-->
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div mat-dialog-actions align="end" class="mt-2 pl-3">
        <!--        <button class="btn btn-close" [mat-dialog-close]="undefined">{{ buttonCancel | translate }} </button>-->
        <button class="btn btn-close" [mat-dialog-close]="undefined">Cancelar</button>
        <!--        <button class="btn btn-action">{{ modalOption == 'create' ? (buttonCreate | translate) : (buttonEdit | translate) }}</button>-->
        <button class="btn btn-action">Guardar Datos</button>
    </div>
</form>
