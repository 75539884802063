import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SpecContentComponent} from './spec/spec-content/spec-content.component';
import {SpecComponent} from './spec/spec.component';
import {HomeComponent} from './home.component';
import {PermissionGuard} from '../../core/guards/permission.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [PermissionGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [PermissionGuard],
    data: {
      authorization: {
        permissions: ['PERM_READ_DASH_STATUS_KONG'],
        isGlobalPermission: false,
      }
    }
  },
  {
    path: 'specs',
    component: SpecComponent,
    canActivate: [PermissionGuard],
    data: {
      authorization: {
        permissions: ['PERM_READ_DASH_STATUS_KONG'],
        isGlobalPermission: true,
      }
    }
  },
  {
    path: 'specs/folder/:id',
    component: SpecComponent,
    canActivate: [PermissionGuard],
    data: {
      authorization: {
        permissions: ['PERM_READ_DASH_STATUS_KONG'],
        isGlobalPermission: true,
      }
    }
  },
  {
    path: 'specs/content',
    component: SpecContentComponent,
    canActivate: [PermissionGuard],
    data: {
      authorization: {
        permissions: ['PERM_READ_DASH_STATUS_KONG'],
        isGlobalPermission: true,
      }
    }
  },
  {
    path: 'specs/content/:id',
    component: SpecContentComponent,
    canActivate: [PermissionGuard],
    data: {
      authorization: {
        permissions: ['PERM_READ_DASH_STATUS_KONG'],
        isGlobalPermission: true,
      }
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {
}
