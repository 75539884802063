import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Dashboard, defaultDashboardValues, defaultNodeInformationValues, NodeInformation} from '../../../core/model/dashboard.model';
import {LocalstorageService} from '../../../services/localstorage.service';
import {Connection} from '../../../core/model/connection.model';
import {ConnectionsService} from '../../../services/apis/connections.service';
import {HttpHeaders, HttpResponse} from '@angular/common/http';
import {CheckUrlService} from '../../../services/apis/check-url.service';
import {ToastrService} from 'ngx-toastr';
import {ApexChartComponent} from '../../../theme/shared/components/chart/apex-chart/apex-chart.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {

    dashboard: Dashboard;
    nodeInformation: NodeInformation;
    intervalNumber: any;
    isLoading = false;
    pending = 0;
    running = 0;
    reload = false;
    @ViewChild('chartComponent')
    apexChart: ApexChartComponent;

    public data: any;

    constructor(
        private connectionService: ConnectionsService,
        private checkUrlService: CheckUrlService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private localStorageService: LocalstorageService
    ) {
        this.dashboard = defaultDashboardValues;
        this.nodeInformation = defaultNodeInformationValues;
        this.getChartData();
    }

    ngOnInit(): void {
        this.localStorageService.removeItem('dashboardStatus');
        this.isLoading = true;
        this.loadConnection();
        this.connectionService.getConnectionEmitter().subscribe(value => {
            if (value) {
                if (this.intervalNumber) {
                    setTimeout(() => clearInterval(this.intervalNumber));
                }
                this.dashboard = defaultDashboardValues;
                this.nodeInformation = defaultNodeInformationValues;
                this.loadConnection();
            }
        });

    }

    private loadConnection(): void {
        const env = this.localStorageService.getItem('environment');
        const token = localStorage.getItem('token');
        if (env) {
            const headers = new HttpHeaders()
                .append('Environment', env)
                .append('Authorization', 'Bearer ' + token);
            this.checkUrlService.getDashboardInformation(headers)
                .subscribe((res: HttpResponse<Dashboard>) => {
                    this.dashboard = res.body || defaultDashboardValues;
                    this.isLoading = false;
                    const isFirst = this.localStorageService.getItemObject<boolean>('dashboardStatus');
                    if (!isFirst) {
                        this.intervalNumber = setInterval(() => this.loadConnection(), 30000);
                        this.localStorageService.setItem<boolean>('dashboardStatus', true);
                    }
                }, () => {
                    this.isLoading = false;
                    this.dashboard = defaultDashboardValues;
                    this.translate.get('error.kong').subscribe(value => {
                        this.toastr.error(value);
                    });
                });
            this.checkUrlService.getDashboardNodeInfo(headers)
                .subscribe((res: HttpResponse<NodeInformation>) => {
                    this.nodeInformation = res.body || defaultNodeInformationValues;
                    this.isLoading = false;
                    this.pending = this.nodeInformation.timers.pending;
                    this.running = this.nodeInformation.timers.running;
                    this.getChartData();
                    setTimeout(() => this.reload = true);
                }, () => {
                    this.isLoading = false;
                    this.nodeInformation = defaultNodeInformationValues;
                    setTimeout(() => this.reload = true);
                    this.translate.get('error.status').subscribe(value => {
                        this.toastr.error(value);
                    });
                });
        }
    }

    ngOnDestroy(): void {
        if (this.intervalNumber) {
            setTimeout(() => clearInterval(this.intervalNumber));
        }
    }

    isEnableOnServer(available: string, enabledInCluster: string[] | undefined): boolean {
        if (enabledInCluster) {
            return enabledInCluster.some(value => value === available);
        }
        return false;
    }

    private getChartData(): void {
        this.data = {
            chart: {
                height: 200,
                type: 'bar',
                toolbar: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            legend: {
                show: false,
            },
            colors: ['#4680ff', '#0e9e4a'],
            dataLabels: {
                enabled: false,
                offsetX: 0,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                }
            },
            stroke: {
                show: false,
                width: 1,
                colors: ['#fff']
            },
            series: [{
                data: [this.pending]
            }, {
                data: [this.running]
            }],
            xaxis: {
                categories: ['Pending', 'Running'],
            }
        };
    }

}

