<app-header-pages title="{{'userManagement.header.title' | translate }}" subTitle="Manage Konga users and user roles"></app-header-pages>

<div class="d-flex justify-content-end pl-0 mb-4">
    <a [routerLink]="['create']">
        <button class="btn btn-action btn-block"><em class="feather icon-user-plus"></em>
            {{ 'userManagement.header.createLabel' | translate }}</button>
    </a>
</div>

<div class="row">
    <div class="col-sm-12">
        <app-dynamic-table [data]="dataSource" [columnsSetup]="columnsSetup" [spinner]="spinner" pageSize="10">
            <ng-container matColumnDef="activated">
                <mat-header-cell *matHeaderCellDef scope="col">Estado</mat-header-cell>
                <mat-cell *matCellDef="let row;">
                    <mat-slide-toggle (click)="activarbloquear(row.login)" [(ngModel)]="row.activated">{{ row?.activated ? ('userManagement.detalle.enabled' | translate) : ('userManagement.detalle.disabled' | translate) }}</mat-slide-toggle>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="options">
                <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
                <mat-cell *matCellDef="let row;">
                    <button class="btn btn-delete" [routerLink]="['edit']" (click)="editUser(row)">
                        <mat-icon class="edit">create</mat-icon>
                        Editar</button>
                    <button class="btn btn-delete" (click)="eliminarUsuario(row.login)">
                        <mat-icon class="delete">delete</mat-icon>
                        Eliminar
                    </button>
                    <button class="btn btn-delete" (click)="openModalUser(row)">
                        <mat-icon class="edit">visibility</mat-icon>
                        Ver
                    </button>
                </mat-cell>
            </ng-container>
        </app-dynamic-table>
    </div>
</div>
