import {Component, Input, OnInit} from '@angular/core';
import {ConsumerHmacCredential, ConsumerModel} from '../../../../../core/model/consumer.model';
import {ColumnSetup} from '../../../../../components/dynamic-table/dynamic-table.component';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {AddHmacComponent} from './add-hmac/add-hmac.component';
import {ToastrService} from 'ngx-toastr';
import {SweetAlertComponent} from '../../../../../components/sweet-alert/sweet-alert.component';
import {ConsumerService} from '../../../../../services/gateway/consumer.service';
import {TranslateService} from '@ngx-translate/core';

export interface RowItem {
    id: string;
    username: string;
    secret: string;
    tags: string;
    created_at: string;
}

@Component({
    selector: 'app-hmac-credential',
    templateUrl: './hmac-credential.component.html',
    styleUrls: ['./hmac-credential.component.scss'],
})
export class HmacCredentialComponent implements OnInit {
    @Input() hmacCredentials: ConsumerHmacCredential[] = [];
    @Input() consumer: ConsumerModel;
    public spinner = false;
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'username', title: 'consumers.hmac.table.username', cell: (row: RowItem) => `${row.username}`},
        {columnDef: 'secret', title: 'consumers.hmac.table.secret', cell: (row: RowItem) => `${row.secret}`},
        {
            columnDef: 'createdDate',
            title: 'consumers.table.createdDate',
            cell: (row: RowItem) => `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`
        },
        {columnDef: 'actions', title: '', custom: true},
    ];

    constructor(private dateFormat: DatePipe,
                public dialog: MatDialog,
                private toastr: ToastrService,
                private sweet: SweetAlertComponent,
                private translate: TranslateService,
                private consumerService: ConsumerService) {
    }

    ngOnInit(): void {
    }

    deleteHmac(id): void {
        this.translate.get('consumers.message').subscribe(translate => {
            this.sweet.confirmBox({
                title: translate.confirm.title,
                text: translate.confirm.text,
                confirmButtonText: translate.confirm.button,
                showCancelButton: true,
                alertAction: (result) => {
                    if (result.isConfirmed) {
                        this.spinner = true;
                        this.consumerService.deleteHmacCredential(id, this.consumer.id).subscribe(() => {
                                this.toastr.success(translate.hmac.deleted);
                                this.getHmacCredential();
                            },
                            (err) => {
                                console.log(err);
                                this.sweet.error({
                                    title: translate.error
                                });
                            }
                        );
                    }
                }
            });
        });
    }

    getHmacCredential(): void {
        this.consumerService.getHmacAuthCredential(this.consumer.id).subscribe(res => {
            this.spinner = false;
            this.hmacCredentials = res;
        });
    }

    addHmac(): void {
        const dialogRef = this.dialog.open(AddHmacComponent, {
            height: 'auto',
            width: '570px',
            disableClose: false,
            data: this.consumer
        });
        dialogRef.afterClosed().subscribe((hmac) => {
            if (hmac) {
                this.getHmacCredential();
            }
        });
    }
}
