import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {AuthGuard} from 'src/app/core/guards/auth.guard';
import {PermissionGuard} from './core/guards/permission.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'admin/home/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'admin',
        canActivateChild: [PermissionGuard],
        loadChildren: () => import('src/app/pages/pages.module').then(module => module.PagesModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'account',
        loadChildren: () => import('src/app/auth/auth.module').then(module => module.AuthModule),
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
