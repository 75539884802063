import {AfterViewInit, Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {INavigationItem, NavigationItem} from '../navigation';
import {NextConfig} from '../../../app-config';
import {Location} from '@angular/common';
import {LocalstorageService} from 'src/app/services/localstorage.service';
import {User} from 'src/app/core/model/login.model';
import {AuthService} from 'src/app/services/apis-old/auth.service';
import {UsersService} from '../../../../../services/apis-old/users.service';
import {ROLE_ADMIN} from '../../../../../core/constants/app.constants';

@Component({
    selector: 'app-nav-content',
    templateUrl: './nav-content.component.html',
    styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements OnInit, AfterViewInit {
    public nextConfig: any;
    public navigation: INavigationItem[];
    public prevDisabled: string;
    public nextDisabled: string;
    public contentWidth: number;
    public wrapperWidth: any;
    public scrollWidth: any;
    public windowWidth: number;
    public isNavProfile: boolean;
    public user: User;
    public currentEnvironment: string;

    @Output() OnNavMobCollapse = new EventEmitter();

    @ViewChild('navbarContent', {static: false}) navbarContent: ElementRef;
    @ViewChild('navbarWrapper', {static: false}) navbarWrapper: ElementRef;
    imageProfile = '';
    childrenPermissions: Set<string> = new Set<string>();

    constructor(
        public nav: NavigationItem,
        private zone: NgZone,
        private usersService: UsersService,
        private location: Location,
        private localstorageService: LocalstorageService,
        private auth: AuthService,
    ) {
        this.nextConfig = NextConfig.config;
        this.windowWidth = window.innerWidth;

        this.navigation = this.nav.get();
        this.prevDisabled = 'disabled';
        this.nextDisabled = '';
        this.scrollWidth = 0;
        this.contentWidth = 0;

        this.isNavProfile = false;
    }

    ngOnInit(): void {
        if (this.windowWidth < 992) {
            this.nextConfig.layout = 'vertical';
            setTimeout(() => {
                document.querySelector('.pcoded-navbar').classList.add('menupos-static');
                (document.querySelector('#nav-ps-next') as HTMLElement).style.maxHeight = '100%';
            }, 500);
        }

        this.user = this.localstorageService.getItemObject<User>('user');
        this.imageProfile = this.user.imageUrl || 'assets/images/auth/kong-ico.png';
        this.usersService.userEmitter.subscribe(usuario => {
            if (this.user.id === usuario.id) {
                this.imageProfile = usuario.imageUrl || 'assets/images/auth/kong-ico.png';
                this.localstorageService.setItem('user', usuario);
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.nextConfig.layout === 'horizontal') {
            this.contentWidth = this.navbarContent.nativeElement.clientWidth;
            this.wrapperWidth = this.navbarWrapper.nativeElement.clientWidth;
        }
    }

    scrollPlus(): void {
        this.scrollWidth = this.scrollWidth + (this.wrapperWidth - 80);
        if (this.scrollWidth > (this.contentWidth - this.wrapperWidth)) {
            this.scrollWidth = this.contentWidth - this.wrapperWidth + 80;
            this.nextDisabled = 'disabled';
        }
        this.prevDisabled = '';
        if (this.nextConfig.rtlLayout) {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginRight = '-' + this.scrollWidth + 'px';
        } else {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
        }
    }

    scrollMinus(): void {
        this.scrollWidth = this.scrollWidth - this.wrapperWidth;
        if (this.scrollWidth < 0) {
            this.scrollWidth = 0;
            this.prevDisabled = 'disabled';
        }
        this.nextDisabled = '';
        if (this.nextConfig.rtlLayout) {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginRight = '-' + this.scrollWidth + 'px';
        } else {
            (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
        }

    }

    fireLeave(): void {
        const sections = document.querySelectorAll('.pcoded-hasmenu');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < sections.length; i++) {
            sections[i].classList.remove('active');
            sections[i].classList.remove('pcoded-trigger');
        }

        let currentUrl = this.location.path();
        // tslint:disable-next-line: no-string-literal
        if (this.location['_baseHref']) {
            // tslint:disable-next-line: no-string-literal
            currentUrl = this.location['_baseHref'] + this.location.path();
        }
        const link = 'a.nav-link[href="' + currentUrl + '"]';
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
            const parent = ele.parentElement;
            const upParent = parent.parentElement.parentElement;
            const lastParent = upParent.parentElement;
            if (parent.classList.contains('pcoded-hasmenu')) {
                parent.classList.add('active');
            } else if (upParent.classList.contains('pcoded-hasmenu')) {
                upParent.classList.add('active');
            } else if (lastParent.classList.contains('pcoded-hasmenu')) {
                lastParent.classList.add('active');
            }
        }
    }

    navMob(): void {
        if (this.windowWidth < 992 && document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')) {
            this.OnNavMobCollapse.emit();
        }
    }

    fireOutClick(): void {
        let currentUrl = this.location.path();
        // tslint:disable-next-line: no-string-literal
        if (this.location['_baseHref']) {
            // tslint:disable-next-line: no-string-literal
            currentUrl = this.location['_baseHref'] + this.location.path();
        }
        const link = 'a.nav-link[ href="' + currentUrl + '"]';
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
            const parent = ele.parentElement;
            const upParent = parent.parentElement.parentElement;
            const lastParent = upParent.parentElement;
            if (parent.classList.contains('pcoded-hasmenu')) {
                // tslint:disable-next-line: no-string-literal
                if (this.nextConfig['layout'] === 'vertical') {
                    parent.classList.add('pcoded-trigger');
                }
                parent.classList.add('active');
            } else if (upParent.classList.contains('pcoded-hasmenu')) {
                // tslint:disable-next-line: no-string-literal
                if (this.nextConfig['layout'] === 'vertical') {
                    upParent.classList.add('pcoded-trigger');
                }
                upParent.classList.add('active');
            } else if (lastParent.classList.contains('pcoded-hasmenu')) {
                // tslint:disable-next-line: no-string-literal
                if (this.nextConfig['layout'] === 'vertical') {
                    lastParent.classList.add('pcoded-trigger');
                }
                lastParent.classList.add('active');
            }
        }
    }

    userHasPermissionToItem(item: INavigationItem): boolean {
        let authorities = this.user.authorities;
        if (!this.user) {
            return false;
        }
        if (!Array.isArray(authorities)) {
            authorities = [authorities];
        }
        if (authorities.includes(ROLE_ADMIN)) {
            return true;
        }
        return this.userHasPermissionToMenu(item);
    }

    private userHasPermissionToMenu(item: INavigationItem): boolean {
        const hasGlobalPermission = this.user.globalPermissions.some(value => value.menu === item.id);
        if (hasGlobalPermission) {
            return true;
        }
        const userEnvPermission = this.user.environmentPermissions.find(value => value.environment === this.currentEnvironment);
        if (!userEnvPermission) {
            return false;
        }
        const itemPermissions = item.permissions;
        const permissionsEnv = userEnvPermission.permissions;
        return itemPermissions.some(permission => permissionsEnv.some(value => value.name === permission));
    }

    userHasAuthorityToGroup(item: INavigationItem): boolean {
        const authorities = item.authorities;
        const userAuthorities = this.user.authorities;
        return authorities.some(authority => userAuthorities.some(value => value === authority));
    }
}
