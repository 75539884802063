import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-nav-left',
    templateUrl: './nav-left.component.html',
    styleUrls: ['./nav-left.component.scss']
})
export class NavLeftComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
