import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: 'develop-portal',
        loadChildren: () => import('src/app/pages/developer-portal/developer-portal.module').then(module => module.DeveloperPortalModule)
    },
    {
        path: 'gateway',
        loadChildren: () => import('src/app/pages/gateway/gateway.module').then(module => module.GatewayModule)
    },
    {
        path: 'home',
        loadChildren: () => import('src/app/pages/home/home.module').then(module => module.HomeModule)
    },
    {
        path: 'management',
        loadChildren: () => import('src/app/pages/management/management.module').then(module => module.ManagementModule)
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule {
}
