import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {IAlbum} from 'ngx-lightbox';
import {User} from '../../../../core/model/login.model';
import {UsersService} from '../../../../services/apis-old/users.service';
import {IOption} from 'ng-select';
import 'rxjs/add/operator/map';
import 'sweetalert2/src/sweetalert2.scss';
import {ActivatedRoute, Router} from '@angular/router';
import {RowItem} from '../list-users/list-users.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ServicesService} from 'src/app/services/apis-old/services.service';


@Component({
    selector: 'app-view-user',
    templateUrl: './view-user.component.html',
    styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {

    data: any;
    usuario: any;
    public user: User;

    simpleOption: Array<IOption>;

    public albums: Array<IAlbum>;

    public accion: string;

    constructor(
        private sanitizer: DomSanitizer,
        private servicesServices: ServicesService,
        private usersService: UsersService,
        private route: ActivatedRoute,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public dataModal: RowItem
    ) {
    }

    ngOnInit(): void {
        this.getAuthorities();
    }

    getSantizeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    getAuthorities(): void {
        this.servicesServices.getUsersAuthorities()
            .subscribe(resp => {
                this.simpleOption = resp.map((role) => ({value: role, label: role}));
            }, (err) => {
                console.log(err);
            });
    }

    onBack(): void {
        this.router.navigateByUrl('/users/admin');
    }

}
