import { Component, OnInit } from '@angular/core';
import { INavigationItem } from '../../../theme/layout/admin/navigation/navigation';
import { LocalstorageService } from '../../../services/localstorage.service';

const NavigationItems: INavigationItem[] = [
  {
    id: 'home',
    title: 'navigation.home.title',
    type: 'group',
    children: [
      {
        id: 'dashboad',
        title: 'Dashboard',
        type: 'item',
        icon: 'feather icon-pie-chart',
        url: '/admin/home/dashboard',
      },
      {
        id: 'specs',
        title: 'Specs',
        type: 'item',
        icon: 'feather icon-file',
        url: '/admin/home/specs',
      },
    ],
  },
  {
    id: 'gateway',
    title: 'navigation.gateway.title',
    type: 'group',
    children: [
      {
        id: 'info',
        title: 'Info',
        type: 'item',
        icon: 'feather icon-info',
        url: '/admin/gateway/info',
      },
      {
        id: 'api',
        title: 'APIs',
        type: 'item',
        icon: 'feather icon-cloud',
        url: '/admin/gateway/apis',
      },
      {
        id: 'services',
        title: 'Services',
        type: 'item',
        icon: 'feather icon-settings',
        url: '/admin/gateway/services',
      },
      {
        id: 'routers',
        title: 'Routers',
        type: 'item',
        icon: 'feather icon-link',
        url: '/admin/gateway/routers',
      },
      {
        id: 'consumers',
        title: 'Consumers',
        type: 'item',
        icon: 'feather icon-activity',
        url: '/admin/gateway/consumers',
      },
      {
        id: 'plugins',
        title: 'Plugins',
        type: 'item',
        icon: 'feather icon-box',
        url: '/admin/gateway/plugins',
      },
      {
        id: 'upstreams',
        title: 'Upstreams',
        type: 'item',
        icon: 'feather icon-droplet',
        url: '/admin/gateway/upstreams',
      },
      {
        id: 'certificates',
        title: 'Certificates',
        type: 'item',
        icon: 'feather icon-award',
        url: '/admin/gateway/certificates',
      },
      {
        id: 'ca-certificates',
        title: 'Authority certificates',
        type: 'item',
        icon: 'feather icon-award',
        url: '/admin/gateway/ca-certificates',
      },
    ],
  },
  {
    id: 'management',
    title: 'navigation.management.title',
    type: 'group',
    children: [
      {
        id: 'users',
        title: 'Users',
        type: 'item',
        url: '/admin/management/users',
        icon: 'feather icon-users',
      },
      {
        id: 'connections',
        title: 'Connections',
        type: 'item',
        url: '/admin/management/connections',
        icon: 'feather icon-cast',
      },
      {
        id: 'logs',
        title: 'Logs',
        type: 'item',
        url: '/admin/management/logs',
        icon: 'feather icon-file-text',
      },
      {
        id: 'audits',
        title: 'Audits',
        type: 'item',
        url: '/admin/management/audit',
        icon: 'feather icon-loader',
      },
    ],
  },
  {
    id: 'portal',
    title: 'navigation.portal.title',
    type: 'group',
    children: [
      {
        id: 'portal-menu',
        title: 'Portal',
        type: 'item',
        icon: 'feather icon-settings',
        url: '/admin/develop-portal/portal',
      },
      {
        id: 'settings',
        title: 'Settings',
        type: 'item',
        icon: 'feather icon-settings',
        url: '/admin/develop-portal/settings',
      },
      {
        id: 'catalogue',
        title: 'Catalogue',
        type: 'item',
        icon: 'feather icon-bookmark',
        url: '/admin/develop-portal/catalogue',
      },
      {
        id: 'keys',
        title: 'Key Requests',
        type: 'item',
        icon: 'feather icon-lock',
        url: '/admin/develop-portal/key-requests',
      },
      {
        id: 'menus',
        title: 'Menus',
        type: 'item',
        icon: 'feather icon-lock',
        url: '/admin/develop-portal/menus',
      },
      {
        id: 'pages',
        title: 'Pages',
        type: 'item',
        icon: 'feather icon-lock',
        url: '/admin/develop-portal/pages',
      },
      {
        id: 'css',
        title: 'CSS',
        type: 'item',
        icon: 'feather icon-lock',
        url: '/admin/develop-portal/css',
      },
    ],
  },
];

@Component({
  selector: 'app-key-request',
  templateUrl: './key-request.component.html',
  styleUrls: ['./key-request.component.scss'],
})
export class KeyRequestComponent implements OnInit {
  constructor(private localstorageService: LocalstorageService) {}

  ngOnInit(): void {
    this.localstorageService.setItem<INavigationItem[]>(
      'menu',
      NavigationItems
    );
  }
}
