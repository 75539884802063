import {Component, Input, NgZone, OnInit} from '@angular/core';
import {INavigationItem} from '../../navigation';
import {Location} from '@angular/common';
import {NextConfig} from '../../../../app-config';
import {ROLE_ADMIN} from '../../../../../../core/constants/app.constants';
import {User} from '../../../../../../core/model/login.model';
import {LocalstorageService} from '../../../../../../services/localstorage.service';
import {IConnection} from '../../../../../../pages/management/connections/connection.model';

@Component({
    selector: 'app-nav-group',
    templateUrl: './nav-group.component.html',
    styleUrls: ['./nav-group.component.scss']
})
export class NavGroupComponent implements OnInit {
    @Input() item: INavigationItem;
    @Input() layout1 = false;
    @Input() activeId: any;
    public nextConfig: any;
    public user: User;
    public currentEnvironment: string;

    constructor(private zone: NgZone,
                private location: Location,
                private localstorageService: LocalstorageService) {
        this.nextConfig = NextConfig.config;
    }

    ngOnInit(): void {
        // at reload time active and trigger link
        let currentUrl = this.location.path();
        // tslint:disable-next-line: no-string-literal
        if (this.location['_baseHref']) {
            // tslint:disable-next-line: no-string-literal
            currentUrl = this.location['_baseHref'] + this.location.path();
        }
        this.user = this.localstorageService.getItemObject<User>('user');
        const activeConnection = this.localstorageService.getItemObject<IConnection>('activeConnection');
        if (activeConnection) {
            this.currentEnvironment = activeConnection.enviroment;
        }
        const link = 'a.nav-link[ href="' + currentUrl + '" ]';
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
            const parent = ele.parentElement;
            const upParent = parent.parentElement.parentElement;
            const lastParent = upParent.parentElement;
            if (parent.classList.contains('pcoded-hasmenu')) {
                if (this.nextConfig.layout === 'vertical') {
                    parent.classList.add('pcoded-trigger');
                }
                parent.classList.add('active');
            } else if (upParent.classList.contains('pcoded-hasmenu')) {
                if (this.nextConfig.layout === 'vertical') {
                    upParent.classList.add('pcoded-trigger');
                }
                upParent.classList.add('active');
            } else if (lastParent.classList.contains('pcoded-hasmenu')) {
                if (this.nextConfig.layout === 'vertical') {
                    lastParent.classList.add('pcoded-trigger');
                }
                lastParent.classList.add('active');
            }
        }
    }

    userHasPermissionToItem(item: INavigationItem): boolean {
        let authorities = this.user.authorities;
        if (!this.user) {
            return false;
        }
        if (!Array.isArray(authorities)) {
            authorities = [authorities];
        }
        if (authorities.includes(ROLE_ADMIN)) {
            return true;
        }
        return this.userHasPermissionToMenu(item);
    }

    private userHasPermissionToMenu(item: INavigationItem): boolean {
        const hasGlobalPermission = this.user.globalPermissions.some(value => value.menu === item.id);
        if (hasGlobalPermission) {
            return true;
        }
        const userEnvPermission = this.user.environmentPermissions.find(value => value.environment === this.currentEnvironment);
        if (!userEnvPermission) {
            return false;
        }
        const itemPermissions = item.permissions;
        const permissionsEnv = userEnvPermission.permissions;
        return itemPermissions.some(permission => permissionsEnv.some(value => value.name === permission));
    }

}
