<div class="container">
    <h1 mat-dialog-title>{{title | translate }}</h1>
    <hr>
    <div mat-dialog-content>
            <pre>
                {{service | json}}
            </pre>
    </div>
    <div mat-dialog-actions align="end" class="mt-2 pl-3">
        <button class="btn btn-close" [mat-dialog-close]="undefined">{{ buttonCancel | translate }} </button>
    </div>
</div>
