import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApisComponent } from './apis/apis.component';
import { ServicesComponent } from './services/services.component';
import { RoutersComponent } from './routers/routers.component';
import { ConsumersComponent } from './consumers/consumers.component';
import { PluginsComponent } from './plugins/plugins.component';
import { UpstreamsComponent } from './upstreams/upstreams.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { CaCertificatesComponent } from './ca-certificates/ca-certificates.component';

import { InfoComponent } from './info/info.component';
import { ServiceDetailComponent } from './services/service-detail/service-detail.component';
import { ServiceResolve } from './services/service.resolver';
import { ServicePluginsComponent } from './services/service-plugins/service-plugins.component';
import { ServiceRoutesComponent } from './services/service-routes/service-routes.component';
import { EditConsumerComponent } from './consumers/edit-consumer/edit-consumer.component';
import { ConsumerResolve } from './consumers/consumer.resolver';
import { RouterDetailsComponent } from './routers/router-details/router-details.component';
import { RouterResolve } from './routers/router.resolver';
import { EditApiComponent } from './apis/edit-api/edit-api.component';
import { ApiResolver } from './apis/api.resolver';
import { UpstreamDetailComponent } from './upstreams/upstream-detail/upstream-detail.component';
import { UpstreamResolve } from './upstreams/upstream.resolver';
import { UpstreamTargetComponent } from './upstreams/upstream-target/upstream-target.component';
import { CreateApiComponent } from './apis/create-api/create-api.component';

const routes: Routes = [
  {
    path: 'info',
    component: InfoComponent,
    data: {
      authorization: {
        permissions: ['PERM_READ_INFO_KONG'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'apis',
    component: ApisComponent,
    data: {
      authorization: {
        permissions: ['PERM_READ_API', 'PERM_WRITE_API'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'apis/create',
    component: CreateApiComponent,
    data: {
      authorization: {
        permissions: ['PERM_READ_API', 'PERM_WRITE_API'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'apis/create/:name',
    component: CreateApiComponent,
    data: {
      authorization: {
        permissions: ['PERM_READ_API', 'PERM_WRITE_API'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'apis/:identity/:environment',
    component: EditApiComponent,
    resolve: {
      apiObject: ApiResolver,
    },
    data: {
      authorization: {
        permissions: ['PERM_READ_API', 'PERM_WRITE_API'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'certificates',
    component: CertificatesComponent,
    data: {
      authorization: {
        permissions: ['PERM_READ_CERTIFICATE', 'PERM_WRITE_CERTIFICATE'],
        isGlobalPermission: false,
      },
    },
  },
  { path: 'ca-certificates', component: CaCertificatesComponent },
  {
    path: 'consumers',
    component: ConsumersComponent,
    data: {
      authorization: {
        permissions: ['PERM_READ_CONSUMERS', 'PERM_DENY_CONSUMERS'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'plugins',
    component: PluginsComponent,
    data: {
      authorization: {
        permissions: ['PERM_READ_PLUGINS', 'PERM_WRITE_PLUGINS'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'routers',
    component: RoutersComponent,
    data: {
      authorization: {
        permissions: ['PERM_READ_ROUTES', 'PERM_WRITE_ROUTES'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'routers/:id',
    component: RouterDetailsComponent,
    resolve: {
      routerObject: RouterResolve,
    },
    data: {
      authorization: {
        permissions: ['PERM_READ_ROUTES', 'PERM_WRITE_ROUTES'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'services',
    component: ServicesComponent,
    data: {
      authorization: {
        permissions: ['PERM_READ_SERVICES', 'PERM_WRITE_SERVICES'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'services/:id',
    component: ServiceDetailComponent,
    resolve: {
      service: ServiceResolve,
    },
    data: {
      authorization: {
        permissions: ['PERM_READ_SERVICES', 'PERM_WRITE_SERVICES'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'consumers/:id',
    component: EditConsumerComponent,
    resolve: {
      consumer: ConsumerResolve,
    },
    data: {
      authorization: {
        permissions: ['PERM_READ_CONSUMERS', 'PERM_DENY_CONSUMERS'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'services/:id/plugins',
    component: ServicePluginsComponent,
    resolve: {
      service: ServiceResolve,
    },
    data: {
      authorization: {
        permissions: ['PERM_READ_SERVICES', 'PERM_WRITE_SERVICES'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'services/:id/routes',
    component: ServiceRoutesComponent,
    resolve: {
      service: ServiceResolve,
    },
    data: {
      authorization: {
        permissions: ['PERM_READ_SERVICES', 'PERM_WRITE_SERVICES'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'upstreams',
    component: UpstreamsComponent,
    data: {
      authorization: {
        permissions: ['PERM_READ_UPSTREAMS', 'PERM_WRITE_UPSTREAMS'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'upstreams/:id/detail',
    component: UpstreamDetailComponent,
    resolve: {
      upstream: UpstreamResolve,
    },
    data: {
      authorization: {
        permissions: ['PERM_READ_UPSTREAMS', 'PERM_WRITE_UPSTREAMS'],
        isGlobalPermission: false,
      },
    },
  },
  {
    path: 'upstreams/:id/target',
    component: UpstreamTargetComponent,
    resolve: {
      upstream: UpstreamResolve,
    },
    data: {
      authorization: {
        permissions: ['PERM_READ_UPSTREAMS', 'PERM_WRITE_UPSTREAMS'],
        isGlobalPermission: false,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GatewayRoutingModule {}
