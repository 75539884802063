export interface IConnection {
  id: string;
  nombre: string;
  enviroment: string;
  url: string;
  authType: string;
  algoritmo: string;
  activo: boolean;
}

export interface Permission {
  id: string;
  name: string;
  menu: string;
  label: string;
  permission: string;
  scope: string;
}


export interface IEnvironmentPermission {
  environment: string;
  organization: string;
  permissions: Permission[];
}

export class EnvironmentPermission implements IEnvironmentPermission {
  permissions: Permission[] = [];

  constructor(public environment: string,
              public organization: string) {
  }
}

export interface ISelectedPermission {
  environment: string;
  permission: Permission;
}
