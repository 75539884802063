import {Injectable, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterModule, Routes} from '@angular/router';
import {CambioclaveComponent} from './cambioclave/cambioclave.component';
import {ProfileComponent} from './profile/profile.component';
import {EditUserComponent} from './edit-user/edit-user.component';
import {ListUsersComponent} from './list-users/list-users.component';
import {ViewUserComponent} from './view-user/view-user.component';
import {CreateUserComponent} from './create-user/create-user.component';
import {Portal} from '../../developer-portal/portal/portal.model';
import {EMPTY, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {User} from '../../../core/model/login.model';
import {UsersService} from '../../../services/apis-old/users.service';


@Injectable({providedIn: 'root'})
export class AdminUserResolve implements Resolve<User> {
    constructor(protected service: UsersService, protected router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<User> | Observable<never> {
        const login = route.params.login;
        if (login) {
            return this.service.getUser(login).pipe(
                mergeMap((user: User) => {
                    if (user) {
                        return of(user);
                    } else {
                        this.router.navigate(['404']);
                        return EMPTY;
                    }
                })
            );
        }
        return of(new User());
    }
}

const routes: Routes = [
    {
        path: '',
        component: ListUsersComponent
    },
    {
        path: 'profile',
        component: ProfileComponent
    },
    {
        path: 'user/:accion/:user',
        component: ViewUserComponent
    },
    {
        path: ':login/edit',
        component: EditUserComponent,
        resolve: {
            user: AdminUserResolve
        }
    },
    {
        path: 'create',
        component: CreateUserComponent
    },
    {
        path: 'cambioclave',
        component: CambioclaveComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UsersRoutingModule {
}
