import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-component',
  templateUrl: './select.component.html',
})
export class SelectComponent implements OnInit {
  @Input() options: string[];
  @Input() name: string;
  @Input() inputName: string;
  @Input() default: string;
  @Input() hint: string;
  @Input() form;
  @Input() messageError;

  constructor() {}

  ngOnInit(): void {}
}
