import {HttpBackend, HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import sing from 'jwt-encode';
import * as moment from 'moment';
import {Dashboard, NodeInformation} from '../../core/model/dashboard.model';
import {environment} from '../../../environments/environment';
import {endpoint} from '../../core/constants/services.constants';

@Injectable({
    providedIn: 'root'
})
export class CheckUrlService {
    private http: HttpClient;
    private URL_BASE: string = environment.url_services;
    private ENDPOINT_KONG_STATUS = `${this.URL_BASE}${endpoint.KONG_STATUS}`;
    private ENDPOINT_KONG_NODE = `${this.URL_BASE}${endpoint.KONG_NODE}`;

    constructor(
        private handler: HttpBackend
    ) {
        this.http = new HttpClient(handler);
    }

    public getResponse(url: string, headers: HttpHeaders): Observable<HttpResponse<any>> {
        return this.http.get(url, {headers, observe: 'response'});
    }

    public getResponseDefault(url: string): Observable<HttpResponse<any>> {
        return this.http.get(url, {observe: 'response'});
    }

    getDashboardInformation(headers: HttpHeaders): Observable<HttpResponse<Dashboard>> {
        return this.http.get<Dashboard>(this.ENDPOINT_KONG_STATUS, {observe: 'response', headers});
    }

    getDashboardNodeInfo(headers: HttpHeaders): Observable<HttpResponse<NodeInformation>> {
        return this.http.get<NodeInformation>(`${this.ENDPOINT_KONG_NODE}`, {observe: 'response', headers});
    }

    generateToken(key: string, secret: string): string {
        const data = {
            iat: moment().unix(),
            exp: moment().add(30, 'seconds').unix(),
            iss: key
        };
        return sing(data, secret);
    }
}
