import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {RowItem} from '../../management/connections/connections.component';
import JsonHuman from '@marianoguerra/json-human';
import {CheckUrlService} from '../../../services/apis/check-url.service';
import {HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {NodeInformation} from '../../../core/model/dashboard.model';
import {ToastrService} from 'ngx-toastr';
import {LocalstorageService} from '../../../services/localstorage.service';
import {Connection} from '../../../core/model/connection.model';
import {ConnectionsService} from '../../../services/apis/connections.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit, AfterViewInit {

    public spinner = false;
    public dataSource: RowItem[] = [];
    jsonResponse: any;

    constructor(
        private translate: TranslateService,
        private checkUrlService: CheckUrlService,
        private toastr: ToastrService,
        private connectionService: ConnectionsService,
        private localStorageService: LocalstorageService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.loadInformation();
    }

    ngAfterViewInit(): void {
        this.connectionService.getConnectionEmitter().subscribe(value => {
            if (value) {
                const output = document.getElementById('output');
                output.innerHTML = '';
                this.loadInformation();
            }
        });
    }

    private loadInformation(): void {
        this.spinner = true;
        const env = this.localStorageService.getItem('environment');
        if (env) {
            const token = localStorage.getItem('token');
            const headers = new HttpHeaders()
                .append('Environment', env)
                .append('Authorization', 'Bearer ' + token);
            this.checkUrlService.getDashboardNodeInfo(headers)
                .subscribe((res: HttpResponse<NodeInformation>) => {
                    this.spinner = false;
                    this.jsonResponse = JsonHuman.format(res.body);
                    const output = document.getElementById('output');
                    output.appendChild(this.jsonResponse);
                }, (error: HttpErrorResponse) => {
                    this.spinner = false;
                    const output = document.getElementById('output');
                    const elemento = document.createElement('div');
                    output.appendChild(elemento);
                    if (error.status === 400) {
                        this.translate.get('info.error.noEnvironment').subscribe(translation => this.toastr.error(translation));
                    }
                });
        }
    }
}
