import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GatewayModule} from './gateway/gateway.module';
import {DeveloperPortalModule} from './developer-portal/developer-portal.module';
import {ManagementModule} from './management/management.module';
import {HomeModule} from './home/home.module';

import {PagesRoutingModule} from './pages-rounting.module';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        GatewayModule,
        DeveloperPortalModule,
        ManagementModule,
        HomeModule,
        PagesRoutingModule,
        TranslateModule,
    ],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {displayDefaultIndicatorType: false}
        }
    ]
})
export class PagesModule {
}
