import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {endpoint} from 'src/app/core/constants/services.constants';
import {LocalstorageService} from '../localstorage.service';
import {IUser, Login, User} from 'src/app/core/model/login.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PasswordModel} from '../../auth/change-password/password.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private URL_BASE: string = environment.url_services;

  private ENDPOINT_AUTH = `${this.URL_BASE}${endpoint.ENDPOINT_AUTH}`;
  private ENDPOINT_ACCOUNT = `${this.URL_BASE}${endpoint.ENDPOINT_ACCOUNT}`;
  private ENDPOINT_REGISTER = `${this.URL_BASE}${endpoint.ENDPOINT_REGISTER}`;
  private ENDPOINT_CHANGE_PASSWORD = `${this.URL_BASE}${endpoint.ENDPOINT_CHANGE_PASSWORD}`;
  private ENDPOINT_RESET_INIT = `${this.URL_BASE}${endpoint.ENDPOINT_RESET_INIT}`;
  private ENDPOINT_RESET_FINISH = `${this.URL_BASE}${endpoint.ENDPOINT_RESET_FINISH}`;
  private ENDPOINT_ACTIVATE = `${this.URL_BASE}${endpoint.ENDPOINT_ACTIVATE}`;

  private token: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorage: LocalstorageService
  ) {
    this.verifyToken();
  }

  private verifyToken(): void {
    const value = this.localStorage.getItem('token');
    if (value) {
      this.token = value;
    } else {
      this.token = '';
    }
  }

  setToken(token: string): void {
    const hoy = new Date();
    hoy.setSeconds(3600);
    this.token = token;
    this.localStorage.setItem<string>('token', token);
    this.localStorage.setItem<number>('expira', hoy.getTime());
  }

  login(login: Login): Observable<any> {
    const authData = {
      ...login
    };
    return this.http.post(this.ENDPOINT_AUTH, authData);
  }

  getUser(): Observable<IUser> {
    return this.http.get<IUser>(this.ENDPOINT_ACCOUNT, {observe: 'response'})
      .pipe(map((res) => res.body));
  }

  resetpasword(mail: string): Observable<any> {
    return this.http.post(this.ENDPOINT_RESET_INIT, mail);
  }

  updateUserLang(body: User): Observable<any> {
    return this.http.post(this.ENDPOINT_ACCOUNT, body);
  }

  changePassword(passwordModel: PasswordModel): Observable<any> {
    return this.http.post(this.ENDPOINT_CHANGE_PASSWORD, passwordModel);
  }
}
