import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionsService } from '../../../services/apis/connections.service';
import { ColumnSetup } from '../../../../app/components/dynamic-table/dynamic-table.component';
import { SpecService } from '../../../services/gateway/spec.service';
import { Spec } from '../../../core/model/spec.model';
import { SweetAlertComponent } from '../../../components/sweet-alert/sweet-alert.component';
import { ModalSpecComponent } from './modal-spec/modal-spec.component';
import { OPEN_API } from '../../../../app/core/constants/icons.constants';
import { KIND_FOLDER } from '../../../../app/core/constants/app.constants';

@Component({
  selector: 'app-spec',
  templateUrl: './spec.component.html',
  styleUrls: ['./spec.component.scss'],
})
export class SpecComponent implements OnInit, OnDestroy {
  private parentId: string;
  public spinner = false;
  public dataSource: Spec[] = [];
  public selectedPath: Spec[] = [];
  public columnsSetup: ColumnSetup[] = [
    { columnDef: 'name', title: '', custom: true },
    { columnDef: 'delete', title: '', custom: true },
  ];

  constructor(
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public iconRegistry: MatIconRegistry,
    private connectionService: ConnectionsService,
    private translate: TranslateService,
    private specService: SpecService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private sweet: SweetAlertComponent
  ) {
    iconRegistry.addSvgIconLiteral(
      'open-api',
      sanitizer.bypassSecurityTrustHtml(OPEN_API)
    );
  }

  ngOnInit(): void {
    this.connectionService.getConnectionEmitter().subscribe((value) => {
      if (value) {
        this.getServices();
      }
    });
    this.getServices();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  private getServices(change = true): void {
    this.dataSource = [];
    this.spinner = true;
    this.activatedRoute.paramMap.subscribe((params) => {
      if (change) {
        this.parentId = params.get('id') || '';
      }
      if (!this.parentId) {
        this.resetPaths();
      }
      this.specService.getSpecs(this.parentId).subscribe(
        (response: any) => {
          this.spinner = false;
          this.dataSource = response.body;
          if (this.selectedPath.length === 0) {
            this.selectedPath = JSON.parse(localStorage.getItem('spec-path'));
          }
        },
        (err) => {
          this.spinner = false;
        }
      );
    });
  }

  private deleteData(row: Spec): void {
    this.spinner = true;
    this.specService.deleteSpecs(row.id).subscribe(
      (response: Spec[]) => {
        this.spinner = false;
        this.dataSource = this.dataSource.filter((item) => item.id != row.id);
      },
      (err) => {
        this.spinner = false;
      }
    );
  }

  private setLocalPaths(): void {
    localStorage.setItem('spec-path', JSON.stringify(this.selectedPath));
  }

  createFolder(): void {
    const dialogRef = this.dialog.open(ModalSpecComponent, {
      height: 'auto',
      width: '750px',
      disableClose: false,
    });
    dialogRef.componentInstance.parentId = this.parentId;
    dialogRef.componentInstance.title = 'spec.modal.titles.folder';
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getServices();
      }
    });
  }

  editFolder(row: Spec): void {
    const dialogRef = this.dialog.open(ModalSpecComponent, {
      height: 'auto',
      width: '750px',
      disableClose: false,
      data: {
        id: row.id,
        name: row.name,
      },
    });
    dialogRef.componentInstance.modalOption = 'edit-folder';
    dialogRef.componentInstance.parentId = this.parentId;
    dialogRef.componentInstance.title = 'spec.modal.titles.update';
    dialogRef.componentInstance.buttonAction = 'spec.modal.buttons.update';
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getServices();
      }
    });
  }

  createSpecFromFile(): void {
    const dialogRef = this.dialog.open(ModalSpecComponent, {
      height: 'auto',
      width: '750px',
      disableClose: false,
    });
    dialogRef.componentInstance.parentId = this.parentId;
    dialogRef.componentInstance.title = 'spec.modal.titles.file';
    dialogRef.componentInstance.modalOption = 'create-spec-file';
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getServices();
      }
    });
  }

  delete(row: Spec) {
    this.translate.get('spec.message').subscribe((translate) => {
      this.sweet.confirmBox({
        title: translate.confirm.title,
        text: translate.confirm.text,
        confirmButtonText: translate.confirm.button,
        showCancelButton: true,
        alertAction: (result) => {
          if (result.isConfirmed) {
            this.deleteData(row);
          }
        },
      });
    });
  }

  getUrl(row: Spec): string {
    return row.kind === KIND_FOLDER ? `folder/${row.id}` : row.content;
  }

  selectIcon(kind: string): string {
    return kind === KIND_FOLDER ? 'folder' : 'note';
  }

  addNewPath(row: Spec): void {
    if (!this.selectedPath.find((a) => a.id == row.id)) {
      this.selectedPath.push(row);
      this.setLocalPaths();
    }
  }

  resetPaths(): void {
    this.parentId = '';
    this.selectedPath = [];
    this.setLocalPaths();
  }

  removePath(path: Spec): void {
    let index = 0;
    this.selectedPath.find((e, i) => {
      index = i;
      if (e.id === path.id) {
        return;
      }
    });
    if (index === 0) {
      this.location.go('/admin/home/specs');
      this.resetPaths();
    } else {
      this.selectedPath = this.selectedPath.slice(
        index - 1,
        this.selectedPath.length - 1
      );
      this.parentId = this.selectedPath[this.selectedPath.length - 1].id;
      this.setLocalPaths();
    }
    this.getServices(false);
  }
}
