<app-dynamic-table [data]="dataSource" [columnsSetup]="columnsSetup" [showPage]="false">
    <ng-container matColumnDef="deny">
        <mat-header-cell *matHeaderCellDef scope="col"> {{'userManagement.permission.deny' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <mat-radio-group [name]="row.name" *ngIf="row.permissions.deny" aria-label="Select an option">
                <mat-radio-button [name]="row.menu" value="DENY" [checked]="checkIfChecked(row, 'DENY')"
                        (change)="changeSelect(row.menu, 'DENY')"></mat-radio-button>
            </mat-radio-group>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="read">
        <mat-header-cell *matHeaderCellDef scope="col"> {{'userManagement.permission.read' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <mat-radio-group [name]="row.name" *ngIf="row.permissions.read" aria-label="Select an option">
                <mat-radio-button [name]="row.menu" value="READ" [checked]="checkIfChecked(row, 'READ')"
                        (change)="changeSelect(row.menu, 'READ')"></mat-radio-button>
            </mat-radio-group>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="write">
        <mat-header-cell *matHeaderCellDef scope="col"> {{'userManagement.permission.write' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <mat-radio-group [name]="row.name" *ngIf="row.permissions.write" aria-label="Select an option">
                <mat-radio-button [name]="row.menu" value="WRITE" [checked]="checkIfChecked(row, 'WRITE')"
                        (change)="changeSelect(row.menu, 'WRITE')"></mat-radio-button>
            </mat-radio-group>
        </mat-cell>
    </ng-container>

</app-dynamic-table>
