import {Injectable} from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';

interface AlertConfig {
    title?: string;
    text?: string;
    icon?: string;
    html?: string;
    showCancelButton?: boolean;
    showConfirmButton?: boolean;
    confirmButtonText?: string;
    cancelButtonText?: string;
    confirmButtonColor?: string;
    cancelButtonColor?: string;
    allowOutsideClick?: boolean;
    alertAction?: AlertCallback;
}

interface AlertResult {
    dismiss?: string;
    isDismissed?: boolean;
    isConfirmed?: boolean;
    value?: any;
}

type AlertCallback = (result: AlertResult) => void;

@Injectable({
    providedIn: 'root'
})
export class SweetAlertComponent {

    constructor() {
    }

    confirmBox(config: AlertConfig): void {
        Swal.fire({
            title: config.title,
            text: config.text,
            confirmButtonText: config.confirmButtonText,
            cancelButtonText: config.cancelButtonText || 'Cancelar',
            icon: config.icon || 'warning',
            showConfirmButton: config.showConfirmButton || true,
            confirmButtonColor: config.confirmButtonColor || '#4680ff',
            showCancelButton: config.showCancelButton,
            cancelButtonColor: config.cancelButtonColor || '#373a3c'
        }).then((result) => {
            config.alertAction(result);
        });
    }

    confirmBoxBasic(config: AlertConfig): void {
        Swal.fire({
            title: config.title,
            text: config.text,
            confirmButtonText: config.confirmButtonText,
            icon: config.icon || 'warning'
        });
    }

    basic(config: AlertConfig): void {
        Swal.fire({
            title: config.title,
            text: config.text,
            icon: config.icon || 'success'
        });
    }

    error(config: AlertConfig = {title: 'Error!'}): void {
        Swal.fire({
            icon: 'error',
            title: config.title,
        });
    }

    html(config: AlertConfig = {title: '', icon: 'error', html: '', confirmButtonText: 'OK'}): void {
        Swal.fire({
            icon: config.icon,
            title: config.title,
            html: config.html,
            confirmButtonText: config.confirmButtonText
        });
    }

    loading(config: AlertConfig = {allowOutsideClick: false, icon: 'info', text: 'Espere por favor...'}): void {
        Swal.fire({
            allowOutsideClick: config.allowOutsideClick,
            icon: config.icon,
            text: config.text
        });
        Swal.showLoading();
    }

    close(): void {
        Swal.close();
    }
}
