<div class="container">
    <h1 mat-dialog-title>{{title | translate }}</h1>
    <hr>
    <div mat-dialog-content>
        <span>Choose a connection type</span>
        <mat-tab-group #tabs animationDuration="0ms" [selectedIndex]="selectedTab" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Default" aria-label="NONE">
                <div class="label-connection">
                    <p> Konga will connect directly to Kong's admin API. This method is mainly suitable for demo scenarios or internal access (ex. localhost). Kong's admin API should not be publicly exposed.</p>
                </div>
            </mat-tab>
            <mat-tab label="Key Auth" aria-label="KEY_AUTH">
                <div class="label-connection">
                    <p>Konga will connect to Kong's admin via an exposed "loop-back" API using key authentication.</p>
                    <a href="https://docs.konghq.com/2.2.x/secure-admin-api/#kong-api-loopback">Check out how to setup an API key based "loop-back" API.</a>
                </div>
            </mat-tab>
            <mat-tab label="JWT Auth" aria-label="JWT_AUTH">
                <div class="label-connection">
                    <p>Konga will connect to Kong's admin via an exposed "loop-back" API using JWT authentication.</p>
                    <a href="https://docs.konghq.com/2.2.x/secure-admin-api/#kong-api-loopback">Check out how to setup an API key based "loop-back" API.</a>
                </div>
            </mat-tab>
            <mat-tab label="Basic Auth" aria-label="BASIC_AUTH">
                <div class="label-connection">
                    <p>Konga will connect to Kong's admin using Basic authentication. It is strongly recommended to use SSL/TLS encryption on Kong.</p>
                </div>
            </mat-tab>
        </mat-tab-group>
        <form [formGroup]="formConnection" (ngSubmit)="submit(f)" #f="ngForm">
            <div class="row">
                <div class="col-12">
                    <label for="nameConnection">{{ 'connections.modal.nameConnection' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="nameConnection"/>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'nameConnection')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label *ngIf="tabIndex === 0 || tabIndex === 3" for="url">{{ 'connections.modal.urlKong' | translate }}</label>
                    <label *ngIf="tabIndex === 1 || tabIndex === 2" for="url">{{ 'connections.modal.urlLoopback' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="url"/>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'url'),">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" *ngIf="tabIndex === 1">
                    <label>{{ 'connections.modal.apiKey' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="password" formControlName="apiKey"/>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'apiKey')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" *ngIf="tabIndex === 2">
                    <label>{{ 'connections.modal.algorithm' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="algorithm">
                            <mat-option value="HS256">HS256</mat-option>
                        </mat-select>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'algorithm')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" *ngIf="tabIndex === 2">
                    <label>{{ 'connections.modal.keyJWT' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="password" formControlName="keyJwt" [placeholder]="isEdit?'':'*******************************'"/>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'keyJwt')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" *ngIf="tabIndex === 2">
                    <label>{{ 'connections.modal.secretJWT' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="password" formControlName="secret" [placeholder]="isEdit?'':'*******************************'"/>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'secret')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" *ngIf="tabIndex === 3">
                    <label>{{ 'connections.modal.userBasic' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="usernameBasic"/>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'usernameBasic')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" *ngIf="tabIndex === 3">
                    <label>{{ 'connections.modal.pass' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="password" formControlName="passwordBasic" [disabled]="isEdit" placeholder="*******************************"/>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'passwordBasic')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" *ngIf="tabIndex === 1">
                    <label>{{ 'connections.modal.keyName' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="keyName" placeholder="API_Key"/>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'keyName')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'connections.modal.environment' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput formControlName="enviroment"/>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'enviroment')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions align="end" class="mt-2 pl-3">
        <button class="btn btn-close" [mat-dialog-close]="undefined">{{ buttonCancel | translate }} </button>
        <button class="btn btn-action" (click)="f.ngSubmit.emit()">{{ modalOption == 'create' ? (buttonCreate | translate) : (buttonEdit | translate) }}</button>
    </div>
</div>
