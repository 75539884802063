import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {IOption} from 'ng-select';
import {MessageError} from 'src/app/core/interfaces/message-error.interface';
import {ServicesService} from 'src/app/services/apis-old/services.service';
import {UsersService} from 'src/app/services/apis-old/users.service';
import {ColumnSetup} from '../../../../components/dynamic-table/dynamic-table.component';
import {SweetAlertComponent} from '../../../../components/sweet-alert/sweet-alert.component';
import {IUser, User} from '../../../../core/model/login.model';
import {EnvironmentPermission, IEnvironmentPermission, ISelectedPermission, Permission} from '../../connections/connection.model';
import {MatSelectChange} from '@angular/material/select';
import {ENVIRONMENT_SCOPE, GLOBAL_SCOPE, ROLE_AUDIT} from '../../../../core/constants/app.constants';
import {LocalstorageService} from '../../../../services/localstorage.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

export interface RowItem {
  id: string;
  name: string;
  menu: string;
  label: string;
  permission: string;
  scope: string;
}

export const valuesAuth = {
  admin: 'ROLE_ADMIN',
  user: 'ROLE_USER'
};

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  public user: User;
  public formDatos: FormGroup;
  simpleOption: Array<IOption>;
  MESSAGE_REQUIRED = 'connections.message.required';

  public dataSource: RowItem[] = [];
  public dataSourceGlobal: RowItem[] = [];
  public columnsSetup: ColumnSetup[] = [
    {columnDef: 'permisos', title: 'userManagement.permission.title', cell: (row: RowItem) => `${row.label}`},
    {columnDef: 'deny', title: 'Deny', custom: true},
    {columnDef: 'read', title: 'Read', custom: true},
    {columnDef: 'write', title: 'Write', custom: true},
  ];

  public messageError: MessageError = {
    required: [
      {type: 'required', message: this.MESSAGE_REQUIRED}
    ]
  };
  step = 0;
  environmentsControl: FormControl;
  environments: EnvironmentPermission[] = [];
  selectedEnvironments: EnvironmentPermission[] = [];
  environmentsToSelect: EnvironmentPermission[] = [];
  environmentsToShow: Set<EnvironmentPermission> = new Set<EnvironmentPermission>();
  environmentMaps = new Map<string, IEnvironmentPermission>();
  globalSelectedPermissions: Set<Permission> = new Set<Permission>();
  globalPermissions: Permission[] = [];
  userGlobalPermissions: Permission[] = [];
  environmentPermissions: Permission[] = [];
  auditPermission: string[] = [];
  userHasAuditRole = false;

  public ambientes: FormArray;

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private servicesServices: ServicesService,
    private usersService: UsersService,
    private sweet: SweetAlertComponent,
    private localstorageService: LocalstorageService,
    private translate: TranslateService,
    private route: Router,
    protected activatedRoute: ActivatedRoute,
  ) {
    this.environmentsControl = new FormControl([]);
    this.ambientes = new FormArray([]);
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({user}) => {
      this.user = user;
      this.userHasAuditRole = this.user.authorities.includes(ROLE_AUDIT);
      if (this.userHasAuditRole) {
        this.auditPermission.push(ROLE_AUDIT);
      }
      this.loadForm();
      this.environments = this.user.environmentPermissions as EnvironmentPermission[] || [];
      this.userGlobalPermissions = this.user.globalPermissions || [];
      this.userGlobalPermissions.forEach(value => this.globalSelectedPermissions.add(value));
      this.environments.forEach(value => this.environmentsToSelect.push(value));
      this.environmentsControl.setValue(this.environments);
      this.environments.forEach(value => {
        this.selectedEnvironments.push(value);
        this.environmentsToShow.add(value);
        this.environmentMaps.set(value.environment, value);
      });
    });
    this.getPermission();
    this.getGlobalPermission();
  }

  loadForm(): void {
    const index = this.user.authorities.findIndex(value => value === ROLE_AUDIT);
    if (index !== -1) {
      this.user.authorities.splice(index, 1);
    }
    this.formDatos = this.fb.group({
      id: [this.user?.id, Validators.required],
      firstName: [this.user?.firstName, Validators.required],
      lastName: [this.user?.lastName, Validators.required],
      email: [this.user?.email, Validators.required],
      login: [this.user?.login, Validators.required],
      authorities: [this.user?.authorities[0], Validators.required],
      activated: [this.user?.activated, Validators.required],
      langKey: [this.user?.langKey],
      imageUrl: [this.user?.imageUrl],
      environmentPermissions: this.ambientes,
    });
  }

  getSantizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  isValid(type: string, name: string): any {
    return this.formDatos.get(name).hasError(type);
  }

  getAuthorities(): void {
    this.servicesServices.getUsersAuthorities()
      .subscribe(resp => {
        this.simpleOption = resp.map((role) => ({value: role, label: role}));
      }, (err) => {
        console.log(err);
      });
  }

  getPermission(): void {
    this.usersService.getPermissionByScope(ENVIRONMENT_SCOPE).subscribe((response: RowItem[]) => {
      this.environmentPermissions = response;
      const permMap = response.map(item => {
        return [item.menu, {
          // name: item.name.replace('READ', 'DENY').replace('WRITE', 'DENY'),
          name: item.name,
          menu: item.menu,
          label: item.label,
          permission: item.permission,
          scope: item.scope,
          id: item.id,
        }];
      }).sort() as any;

      const permMapArr = new Map(permMap);
      const unicos = [...permMapArr.values()] as RowItem[];
      const keys = [...permMapArr.keys()] as string[];
      this.dataSource = this.getData(keys, response, unicos) as RowItem[];
    });
  }

  getGlobalPermission(): void {
    this.usersService.getPermissionByScope(GLOBAL_SCOPE).subscribe((response: RowItem[]) => {
      this.globalPermissions = response;
      const permMap = response.map(item => {
        return [item.menu, {
          name: item.name.replace('READ', 'DENY').replace('WRITE', 'DENY'),
          menu: item.menu,
          label: item.label,
          permission: 'DENY',
          scope: item.scope,
          id: item.id,
        }];
      }).sort() as any;

      const permMapArr = new Map(permMap);
      const unicos = [...permMapArr.values()] as RowItem[];
      const keys = [...permMapArr.keys()] as string[];
      this.dataSourceGlobal = this.getData(keys, response, unicos) as RowItem[];
    });
  }

  getData(arrKeys: string[], arrOriginal: RowItem[], arrUnique: RowItem[]): RowItem[] {
    return arrKeys.reduce((acc, value) => {
      const objectValue = arrUnique.find(f => f.menu === value);
      const permissions = arrOriginal
        .filter(f => f.menu === value)
        .map(m => ({[m.permission.toLowerCase()]: m.permission}))
        .reduce((accPer, valuePer) => {
          return {...accPer, ...valuePer};
        }, {});
      return [...acc, {...objectValue, permissions}];
    }, []) as RowItem[];
  }

  onSubmit(): void {
    const translates = [
      'userManagement.detalle.dialogUpdateText',
      'userManagement.detalle.updatedText',
      'userManagement.detalle.errorUpdateText'
    ];
    this.translate.get(translates).subscribe(translation => {
      this.sweet.basic({
        allowOutsideClick: false,
        icon: 'info',
        text: translation['userManagement.detalle.dialogUpdateText'],
      });
      this.sweet.loading();
      const requestUser = this.createFromForm();

      this.usersService.actualizarUsuario(requestUser)
        .subscribe(() => {
          this.usersService.userEmitter.emit(requestUser);
          this.sweet.close();
          this.sweet.confirmBox({
            title: translation['userManagement.detalle.updatedText'],
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            alertAction: () => {
              this.route.navigateByUrl('/admin/management/users');
            }
          });
        }, () => {
          this.sweet.close();
          this.sweet.error({
            icon: 'error',
            title: translation['userManagement.detalle.errorUpdateText'],
            text: ''
          });
        });
    });
  }

  selectPermission(event: ISelectedPermission): void {
    const {name, menu, label, permission, scope} = event.permission;
    const find = this.environmentPermissions.find(value => value.permission === permission && value.menu === menu && value.name === name);
    const {id} = find;
    const perm: Permission = {name, menu, label, permission, scope, id};
    if (this.environmentMaps.has(event.environment)) {
      const environmentPerms = this.environmentMaps.get(event.environment) as EnvironmentPermission;
      if (!environmentPerms.permissions) {
        environmentPerms.permissions = [];
      }
      const index = environmentPerms.permissions.findIndex(value => value.menu === menu);
      if (index >= 0) {
        environmentPerms.permissions.splice(index, 1);
      }
      environmentPerms.permissions.push(perm);
    } else {
      const organization = this.localstorageService.getItem('organization');
      const permissionEnv = new EnvironmentPermission(event.environment, organization);
      permissionEnv.permissions.push(perm);
      this.environmentMaps.set(event.environment, permissionEnv);
    }
  }

  changeSelect(menu: string, permission: string): void {
    const globalPermission = this.globalPermissions.find(element => element.menu === menu && element.permission === permission);
    console.log(globalPermission);
    if (globalPermission && this.notHasPermission(globalPermission)) {
      this.globalSelectedPermissions.add(globalPermission);
      this.userGlobalPermissions.push(globalPermission);
      const permissionsToDelete = this.userGlobalPermissions.filter(element => element.menu === menu && element.permission !== permission);
      console.log('Permission To Delete', permissionsToDelete);
      if (permissionsToDelete) {
        permissionsToDelete.forEach(perm => {
          const index = this.userGlobalPermissions.findIndex(value => value.name === perm.name);
          this.userGlobalPermissions.splice(index, 1);
        });
      }
    }
    const elementsIndex = this.dataSourceGlobal.findIndex(element => element.menu === menu);
    const newArray = [...this.dataSourceGlobal];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      name: newArray[elementsIndex].name.replace('DENY', permission).replace('READ', permission).replace('WRITE', permission),
      permission
    };
    this.dataSourceGlobal = newArray;
  }

  checkIfChecked(row: RowItem, permission: string): boolean {
    const permissions = this.userGlobalPermissions || [];
    // console.log('userGlobals', permissions);
    const item = this.itemFinded(permissions, row.menu, permission);
    if (permission === 'DENY') {
      const itemWithRead = this.itemFinded(permissions, row.menu, 'READ');
      const itemWithWrite = this.itemFinded(permissions, row.menu, 'WRITE');
      return !itemWithRead && !itemWithWrite;
    }
    return item;
  }

  private itemFinded(permissions: Permission[], menu: string, permission: string): boolean {
    const item = permissions.find(value => value.menu === menu && value.permission === permission);
    return !!item;
  }

  selectEnvironment(event: MatSelectChange): void {
    const elements = event.value as IEnvironmentPermission[];
    if (elements.length === 0) {
      this.environmentsToShow.clear();
      this.selectedEnvironments = [];
    } else {
      elements.forEach(element => {
        const organization = this.localstorageService.getItem('organization');
        const permission = new EnvironmentPermission(element.environment, organization);
        const has = this.environmentsToShow.has(permission);
        if (!has) {
          this.environmentsToShow.add(element);
        }
        this.selectedEnvironments = [];
        this.environmentsToShow.forEach(value => this.selectedEnvironments.push(value));
      });
    }
  }

  isEmptyEnvironment(): boolean {
    return this.environmentsToShow.size === 0;
  }

  notHasPermission(global: Permission): boolean {
    const {name, menu, permission, scope, label} = global;
    const id = '';
    return !this.globalSelectedPermissions.has({name, menu, permission, scope, label, id});
  }

  removeEnvironment(env: EnvironmentPermission): void {
    const environments = this.environmentsControl.value as EnvironmentPermission[];
    this.removeFirst(environments, env);
    this.environmentsControl.setValue(environments);
    this.environmentsToShow.delete(env);
    this.selectedEnvironments = [];
    this.environmentsToShow.forEach(value => this.selectedEnvironments.push(value));
    this.environmentMaps.delete(env.environment);
  }

  removeFirst(array: EnvironmentPermission[], toRemove: EnvironmentPermission): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  compareEnvironments(inicio: EnvironmentPermission, fin: EnvironmentPermission): boolean {
    return inicio === fin && inicio.environment === fin.environment;
  }

  nextStep(): void {
    this.step++;
  }

  backStep(): void {
    this.step--;
  }

  finish(): void {
    this.step++;
  }

  selectAuditRole(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.auditPermission.push(ROLE_AUDIT);
    } else {
      this.auditPermission = [];
    }
    this.userHasAuditRole = event.checked;
  }

  protected createFromForm(): IUser {
    const environmentPermissions = [];
    this.environmentMaps.forEach(value => environmentPermissions.push(value));
    const globalPermissions = [];
    this.userGlobalPermissions.forEach(value => globalPermissions.push(value));
    return {
      ...new User(),
      id: this.formDatos.get(['id'])?.value,
      login: this.formDatos.get(['login'])?.value,
      firstName: this.formDatos.get(['firstName'])?.value,
      lastName: this.formDatos.get(['lastName'])?.value,
      email: this.formDatos.get(['email'])?.value,
      authorities: [this.formDatos.get(['authorities'])?.value, ...this.auditPermission],
      langKey: this.formDatos.get(['langKey'])?.value,
      imageUrl: this.formDatos.get(['imageUrl'])?.value,
      activated: this.formDatos.get(['activated'])?.value,
      environmentPermissions,
      globalPermissions,
    };
  }
}
