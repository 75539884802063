import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-credential-menu',
    templateUrl: './credential-menu.component.html',
    styleUrls: ['./credential-menu.component.scss']
})
export class CredentialMenuComponent implements OnInit {

    @Output() selectCredentialType: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit(): void {
    }

    selectCredential(index: number): void {
        this.selectCredentialType.emit(index);
    }
}
