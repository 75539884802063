<app-header-pages title="{{'consumers.hmac.title' | translate }}" subTitle="{{'consumers.hmac.subTitle' | translate }}"></app-header-pages>
<div>
    <button class="btn btn-action btn-add-credential" (click)="addHmac()">
        <mat-icon>add</mat-icon>
        {{'consumers.hmac.new'|translate}}
    </button>
</div>
<app-dynamic-table [data]="hmacCredentials" [columnsSetup]="columnsSetup" [spinner]="spinner" [showPage]="false">
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <button class="btn btn-delete" (click)="deleteHmac(row.id)">
                <mat-icon class="delete">delete</mat-icon>
                {{ 'consumers.table.delete' | translate }}</button>
        </mat-cell>
    </ng-container>
</app-dynamic-table>
