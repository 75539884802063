import {Component, OnInit} from '@angular/core';
import {UsersService} from '../../../../services/apis-old/users.service';
import {ColumnSetup} from 'src/app/components/dynamic-table/dynamic-table.component';
import {DatePipe} from '@angular/common';
import {SweetAlertComponent} from 'src/app/components/sweet-alert/sweet-alert.component';
import {MatDialog} from '@angular/material/dialog';
import {ViewUserComponent} from '../view-user/view-user.component';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {ROLE_AUDIT} from '../../../../core/constants/app.constants';

export interface RowItem {
    login: string;
    firstName: string;
    lastName: string;
    email: string;
    langKey: string;
    authorities: string[];
    createdDate: Date;
    lastModifiedBy: string;
    lastModifiedDate: string;
    activated: boolean;
}

@Component({
    selector: 'app-list-users',
    templateUrl: './list-users.component.html',
    styleUrls: ['./list-users.component.scss'],
})
export class ListUsersComponent implements OnInit {

    public user;
    public spinner = false;
    public dataSource: RowItem[] = [];
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'login', title: 'userManagement.detalle.login', cell: (row: RowItem) => `${row.login}`},
        {columnDef: 'email', title: 'userManagement.detalle.email', cell: (row: RowItem) => `${row.email}`},
        {columnDef: 'language', title: 'userManagement.detalle.langKey', cell: (row: RowItem) => `${row.langKey}`},
        {columnDef: 'profile', title: 'userManagement.detalle.profiles', cell: (row: RowItem) => `${row.authorities.find(value => value !== ROLE_AUDIT)}`},
        {columnDef: 'created', title: 'userManagement.detalle.createdDate', cell: (row: RowItem) => `${this.dateFormat.transform(row.createdDate, 'dd/MM/yyyy HH:mm')}`},
        {columnDef: 'modifiedBy', title: 'userManagement.detalle.lastModifiedBy', cell: (row: RowItem) => `${row.lastModifiedBy}`},
        {columnDef: 'modified', title: 'userManagement.detalle.lastModifiedDate', cell: (row: RowItem) => `${this.dateFormat.transform(row.lastModifiedDate, 'dd/MM/yyyy HH:mm')}`},
        {columnDef: 'activated', title: 'Estado', custom: true},
        {columnDef: 'options', title: '', custom: true}
    ];

    position = 'bottom-right';

    constructor(
        private userServices: UsersService,
        private sweet: SweetAlertComponent,
        private router: Router,
        private dateFormat: DatePipe,
        private toastr: ToastrService,
        public dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        this.getUsers();
    }

    getUsers(): void {
        this.spinner = true;
        this.userServices.getUsers()
            .subscribe((resp: RowItem[]) => {

                this.user = resp[0];

                setTimeout(() => {
                    this.spinner = false;
                    this.dataSource = resp;
                }, 1000);


            }, error => {
                console.error('getUsers() : %o ', error);
                this.spinner = false;
            });
    }


    eliminarUsuario(user: any): void {

        this.sweet.confirmBox({
            title: 'Confirmar Eliminacion',
            text: '¿Seguro que quiere eliminar el usuario?',
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            alertAction: (result) => {
                if (result.isConfirmed) {
                    this.sweet.loading();
                    this.userServices.deleteUsuario(user)
                        .subscribe(resp => {
                            this.sweet.basic({
                                title: 'Usuario eliminado',
                                text: ''
                            });
                            this.getUsers();
                        }, (err) => {
                            this.sweet.error({
                                title: 'Ocurrio un error'
                            });
                        });
                }
            }
        });
    }

    activarbloquear(user: any): void {
        this.sweet.loading();
        this.userServices.getUser(user)
            .subscribe(resp => {
                const requestuser = resp;
                requestuser.activated = !resp.activated;
                this.userServices.actualizarUsuario(requestuser)
                    .subscribe((res: any) => {
                        this.sweet.close();
                        this.getUsers();
                        this.toastr.success('Usuario actualizado con exito');
                    }, (err) => {
                        this.sweet.close();
                        this.sweet.error({
                            title: 'Error al actualizar usuario'
                        });
                    });
            }, (err) => {
                console.log(err);
            });
    }

    openModalUser(row: RowItem): void {
        const modal = this.dialog.open(ViewUserComponent, {
            height: 'auto',
            width: '400px',
            data: row
        });
        console.log(row);

    }

    editUser(row: RowItem): void {
        this.router.navigate(['/admin', 'management', 'users', row.login, 'edit']);
    }
}
