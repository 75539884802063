<app-header-pages title="{{'plugins.title' | translate }}" subTitle="{{'plugins.subTitle' | translate }}"></app-header-pages>


<div class="d-flex justify-content-between mb-2">
    <button class="btn btn-action" (click)="createPlugin()" [disabled]="indexTab!=0">
        <mat-icon>add</mat-icon>
        {{'plugins.table.new'|translate}}
    </button>
</div>

<mat-tab-group (selectedTabChange)="selectionChange($event)" [selectedIndex]="indexTab">
    <mat-tab>
        <ng-template backgroundColor="primary" mat-tab-label>
            <button mat-icon-button matRipple type="button">
                <i class="feather icon-box"></i>
            </button>
            {{ 'plugins.tabs.list' | translate }}
        </ng-template>
        <app-dynamic-table [data]="dataSource" [columnsSetup]="columnsSetup" [spinner]="spinner" pageSize="10">
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef scope="col" class="column-flex-7"></mat-header-cell>
                <mat-cell *matCellDef="let row;" class="column-flex-7">
                    <div class="plugin-image">
                        <img src="assets/images/kong/plugins/{{row.name}}.png"

                                onerror="this.src='assets/images/kong/plugins/kong.svg'"
                                class="img-responsive" style="margin: auto;height: 72px;">
                    </div>
                    <mat-slide-toggle [(ngModel)]="row.enabled" color="primary" (change)="changeStatus($event, row)"></mat-slide-toggle>
                    <button class="btn btn-icon" (click)="getDetailsPlugin(row)">
                        <mat-icon>remove_red_eye</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef scope="col">{{ 'plugins.table.name' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let row;">
                    <a (click)="editPlugin(row)">{{ row.name }} </a>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="scope">
                <mat-header-cell *matHeaderCellDef scope="col" class="column-flex-15">{{ 'plugins.table.scope' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let row;" class="column-flex-15">
                    {{row.route ? 'route' : row.service ? 'service' : 'global'}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="protocols">
                <mat-header-cell *matHeaderCellDef scope="col" class="column-flex-15">{{ 'plugins.table.protocols' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let row;" class="column-flex-15">
                    <app-tags [tags]="row.protocols"></app-tags>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="applyTo">
                <mat-header-cell *matHeaderCellDef scope="col">{{ 'plugins.table.applyTo' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let row;">
                    <a [routerLink]="['/admin/gateway/services', row.service.id]" *ngIf="row.service">{{ row.service.id }} </a>
                    <a [routerLink]="['/admin/gateway/routers', row.route.id]" *ngIf="row.route">{{ row.route.id }} </a>
                    <span *ngIf="!row.service&&!row.route"> Global </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="consumer">
                <mat-header-cell *matHeaderCellDef scope="col" class="column-flex-10">{{ 'plugins.table.consumer' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let row;" class="column-flex-10">
                    <span>{{row.consumer | nulleable}}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="delete">
                <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
                <mat-cell *matCellDef="let row;">
                    <button class="btn btn-delete" (click)="deletePlugin(row.id)">
                        <mat-icon class="delete">delete</mat-icon>
                        {{ 'plugins.table.delete' | translate }}</button>
                </mat-cell>
            </ng-container>
        </app-dynamic-table>
    </mat-tab>
    <mat-tab *ngFor="let tab of tabs; let index = index" backgroundColor="primary">
        <ng-template backgroundColor="primary" mat-tab-label>
            {{accion}}
            <button (click)="removeTab(index)" class="close-button" mat-icon-button matRipple type="button">
                <mat-icon style="font-size: 25px;position: relative;top: 10px;">close</mat-icon>
            </button>
        </ng-template>
        <app-select-plugin type="global" typeId="0"></app-select-plugin>
    </mat-tab>
</mat-tab-group>
