import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'route'
})
export class RoutePipe implements PipeTransform {

    transform(value: any, ...args: unknown[]): any {
        if (value.name) {
            return value.name;
        }
        return value.id;
    }

}
