<ul class="navbar-nav ml-auto">
    <li>
        <app-translation-select></app-translation-select>
    </li>
    <li>
        <div class="dropdown" ngbDropdown placement="auto">
            <a ngbDropdownToggle href="javascript:" data-toggle="dropdown">{{ selectedEnvironment }} <em class="icon feather icon-chevron-down"></em></a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
                <div class="noti-head" style="background-color: #4680ff">
                    <h6 class="d-inline-block m-b-0" style="color: #222">
                        {{ "navigation.home.menuSelection" | translate }}
                    </h6>
                </div>
                <perfect-scrollbar [style.max-height]="'300px'">
                    <ul class="noti-body">
                        <li class="notification" *ngFor="let env of environments" (click)="selectEnvironment(env)">
                            <div class="media">
                                <div class="media-body">
                                    <p>
                                        <strong>{{ env.organization.toUpperCase() }} - {{ env.environment }} </strong>
                                        <span class="n-time text-muted" [ngClass]="{green: env.environment == selectedEnvironment,red: env.environment != selectedEnvironment}"></span>
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </perfect-scrollbar>
            </div>
        </div>
    </li>
    <li>
        <div class="dropdown drp-user" ngbDropdown placement="auto">
            <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
                <mat-icon style="padding-top: 10px">person</mat-icon>
            </a>
            <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
                <div class="pro-head">
                    <img
                            [src]="imageProfile"
                            class="img-radius"
                            alt="User-Profile-Image"/>
                    <span>{{ user?.firstName }} {{ user?.lastName }}</span>
                    <a href="javascript:" class="dud-logout" title="Logout">
                        <mat-icon>logout</mat-icon>
                    </a>
                </div>
                <ul class="pro-body">
                    <li>
                        <a mat-button href="javascript:">
                            <mat-icon>person</mat-icon>
                            Profile
                        </a>
                    </li>
                    <li>
                        <a mat-button href="javascript:">
                            <mat-icon>mail</mat-icon>
                            {{ user?.email }}
                        </a>
                    </li>
                    <li>
                        <a mat-button (click)="changePassword()">
                            <mat-icon>lock</mat-icon>
                            Change Password
                        </a>
                    </li>
                    <li>
                        <a mat-button href="javascript:" (click)="salir()">
                            <mat-icon>logout</mat-icon>
                            Logout
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </li>
</ul>
