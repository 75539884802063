<form [formGroup]="formKey" (ngSubmit)="submit()">
    <div class="container">
        <h1 mat-dialog-title>{{'consumers.key.modal.title' | translate }}</h1>
        <hr>
        <div mat-dialog-content>
            <div class="row">
                <div class="col-12">
                    <label>{{ 'consumers.key.modal.key.placeholder' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="key"/>
                        <mat-hint>{{ 'consumers.key.modal.key.hint' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'key')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div mat-dialog-actions align="end" class="mt-2 pl-3">
            <button class="btn btn-close" [mat-dialog-close]="undefined">{{ 'consumers.modal.buttonCancel' | translate }} </button>
            <button class="btn btn-action" [disabled]="formKey.invalid">{{ 'consumers.key.save' | translate }}</button>
        </div>
    </div>
</form>
