import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageError} from '../../../../core/interfaces/message-error.interface';
import {ConsumerService} from '../../../../services/gateway/consumer.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConsumerModel} from '../../../../core/model/consumer.model';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-add-group',
    templateUrl: './add-group.component.html',
    styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {
    formGroup: FormGroup;
    MESSAGE_REQUIRED = 'consumers.message.required';
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    consumerId: string;

    constructor(public dialog: MatDialogRef<AddGroupComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ConsumerModel,
                private translate: TranslateService,
                private toastr: ToastrService,
                private consumerService: ConsumerService) {
        this.formGroup = this.buildForm();
        this.consumerId = data.id;
    }

    ngOnInit(): void {
    }

    isValid(type: string, name: string): boolean {
        return this.formGroup.get(name).hasError(type);
    }

    buildForm(): FormGroup {
        return new FormGroup({
            group: new FormControl('', [Validators.required]),
        });
    }

    submit(): void {
        this.consumerService.createGroup(this.formGroup.value, this.consumerId).subscribe(res => {
            this.dialog.close(res);
        });
    }
}
