<app-header-pages
  title="{{
    'consumers.detail.title' | translate: { name: consumer.username }
  }}"
  subTitle="{{ 'consumers.detail.subTitle' | translate }}"
  class="margin-top0"
></app-header-pages>

<div class="row margin-top0">
  <div class="col-12">
    <app-card [hidHeader]="true" headerClass="full-width">
      <mat-tab-group
        (selectedTabChange)="selectionChange($event)"
        [selectedIndex]="indexTab"
      >
        <mat-tab>
          <ng-template backgroundColor="primary" mat-tab-label>
            <button mat-icon-button matRipple type="button">
              <mat-icon color="primary">info</mat-icon>
            </button>
            {{ "consumers.tabs.details" | translate }}
          </ng-template>
          <form
            [formGroup]="formConsumer"
            (ngSubmit)="submit()"
            style="padding: 20px"
          >
            <div class="row">
              <div class="col-12">
                <label>{{ "consumers.modal.username" | translate }}</label>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="text" formControlName="username" />
                  <mat-hint>{{
                    "consumers.hint.username" | translate
                  }}</mat-hint>
                  <mat-error *ngFor="let error of messageError.required">
                    <span *ngIf="isValid(error.type, 'username')">{{
                      error.message | translate
                    }}</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <label>{{ "consumers.modal.custom_id" | translate }}</label>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="text" formControlName="customId" />
                  <mat-hint>{{
                    "consumers.hint.custom_id" | translate
                  }}</mat-hint>
                  <mat-error *ngFor="let error of messageError.required">
                    <span *ngIf="isValid(error.type, 'customId')">{{
                      error.message | translate
                    }}</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <label>{{ "consumers.modal.tags" | translate }}</label>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-chip-list #chipListTags aria-label="Fruit selection">
                    <mat-chip
                      *ngFor="let tag of tags"
                      [selectable]="true"
                      [removable]="true"
                      (removed)="removeTag(tag)"
                    >
                      {{ tag }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      matInput
                      placeholder="{{ 'consumers.modal.tags' | translate }}"
                      formControlName="tags"
                      [matChipInputFor]="chipListTags"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="true"
                      (matChipInputTokenEnd)="addTag($event)"
                    />
                  </mat-chip-list>
                  <mat-hint>{{ "consumers.hint.tags" | translate }}</mat-hint>
                  <mat-error *ngFor="let error of messageError.required">
                    <span *ngIf="isValid(error.type, 'tags')">{{
                      error.message | translate
                    }}</span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="mt-2">
              <button class="btn btn-action">Guardar Cambios</button>
            </div>
          </form>
        </mat-tab>
        <mat-tab>
          <ng-template backgroundColor="primary" mat-tab-label>
            <button mat-icon-button matRipple type="button">
              <mat-icon color="primary">group</mat-icon>
            </button>
            {{ "consumers.tabs.group" | translate }}
          </ng-template>
          <div style="padding: 10px">
            <button class="btn btn-action" (click)="addGroup()">
              Add Grupo
            </button>
            <mat-card class="grupo-card" *ngFor="let grupo of dataSource">
              <mat-card-header>
                <mat-card-title>
                  <label class="group-card-title">{{ grupo.group }}</label>
                </mat-card-title>
                <button
                  type="button"
                  mat-icon-button
                  class="close-button button-card-title"
                  (click)="deleteGroup(grupo)"
                >
                  <mat-icon style="font-size: 25px">close</mat-icon>
                </button>
              </mat-card-header>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template backgroundColor="primary" mat-tab-label>
            <button mat-icon-button matRipple type="button">
              <mat-icon color="primary">verified_user</mat-icon>
            </button>
            {{ "consumers.tabs.credentials" | translate }}
          </ng-template>
          <app-credentials
            [basicCredentials]="basicCredentials"
            [keyCredentials]="keyCredentials"
            [hmacCredentials]="hmacCredentials"
            [oauthCredentials]="oauthCredentials"
            [jwtCredentials]="jwtCredentials"
            [consumer]="consumer"
          ></app-credentials>
        </mat-tab>
        <mat-tab>
          <ng-template backgroundColor="primary" mat-tab-label>
            <button mat-icon-button matRipple type="button">
              <mat-icon color="primary">cloud</mat-icon>
            </button>
            {{ "consumers.tabs.accesibleRoutes" | translate }}
          </ng-template>
          <app-dynamic-table
            [data]="dataSource"
            [columnsSetup]="columnsSetup"
            [spinner]="spinner"
            [showPage]="false"
          >
            <ng-container matColumnDef="tags">
              <mat-header-cell *matHeaderCellDef scope="col">{{
                "consumers.table.tags" | translate
              }}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <app-tags [tags]="row.tags"></app-tags>
              </mat-cell>
            </ng-container>
          </app-dynamic-table>
        </mat-tab>
      </mat-tab-group>
    </app-card>
  </div>
</div>
