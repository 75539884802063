<!--    <span class="tag" *ngFor="let tag of tags"><mat-icon class="mat-icon-tag">local_offer</mat-icon>{{tag}}</span>-->
<div>
    <ng-container *ngIf="catalogs.length > 0; else noCategories">
        <ng-container *ngFor="let catalog of catalogs">
            <span>{{catalog.displayTitle}}</span>
        </ng-container>
    </ng-container>
    <ng-template #noCategories>
        <span>Sin Categorias</span>
    </ng-template>
</div>
