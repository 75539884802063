<app-header-pages
  title="{{ 'routers.title' | translate }}"
  subTitle="{{ 'routers.subTitle' | translate }}"
></app-header-pages>

<app-dynamic-table
  [data]="dataSource"
  [columnsSetup]="columnsSetup"
  [spinner]="spinner"
  pageSize="10"
>
  <ng-container matColumnDef="actions">
    <mat-header-cell
      *matHeaderCellDef
      scope="col"
      class="show-props"
    ></mat-header-cell>
    <mat-cell *matCellDef="let row" class="show-props">
      <button class="btn btn-icon" (click)="stripPath(row)">
        <mat-icon [ngStyle]="{ color: row.strip_path ? 'green' : 'gray' }">{{
          row.strip_path ? "link" : "link"
        }}</mat-icon>
      </button>
      <button class="btn btn-icon" (click)="preserveHost(row)">
        <mat-icon [ngStyle]="{ color: row.preserve_host ? 'green' : 'gray' }">{{
          row.preserve_host ? "cloud_queue" : "cloud_off"
        }}</mat-icon>
      </button>
      <button class="btn btn-icon" (click)="getRouterOptions(row)">
        <mat-icon>visibility</mat-icon>
      </button>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef scope="col">{{
      "routers.table.name" | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <a [routerLink]="['/admin/gateway/routers', row.id]"
        >{{ row | route }}
      </a>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="tags">
    <mat-header-cell *matHeaderCellDef scope="col" class="column-flex-5">{{
      "routers.table.tags" | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="column-flex-5">
      <app-tags [tags]="row.tags"></app-tags>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="service">
    <mat-header-cell *matHeaderCellDef scope="col" class="column-flex-10">{{
      "routers.table.service" | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="column-flex-10">
      {{ row.service?.id }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="hosts">
    <mat-header-cell *matHeaderCellDef scope="col" class="column-flex-6">{{
      "routers.table.hosts" | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="column-flex-6">
      {{ row.host | nulleable }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="delete">
    <mat-header-cell
      *matHeaderCellDef
      scope="col"
      class="column-flex-6"
    ></mat-header-cell>
    <mat-cell *matCellDef="let row" class="column-flex-6">
      <button class="btn btn-delete" (click)="editRouter(row)">
        <mat-icon class="edit">edit</mat-icon>
        {{ "routers.table.edit" | translate }}
      </button>
      <button class="btn btn-delete" (click)="deleteRouter(row.id)">
        <mat-icon class="delete">delete</mat-icon>
        {{ "routers.table.delete" | translate }}
      </button>
    </mat-cell>
  </ng-container>
</app-dynamic-table>
