import {Component, OnInit} from '@angular/core';
import {Service} from '../../../../core/model/service.model';
import {ActivatedRoute} from '@angular/router';
import {ServicesService} from '../../../../services/gateway/services.service';
import {ToastrService} from 'ngx-toastr';
import {PluginsModel} from '../../../../core/model/plugins.model';
import {ColumnSetup} from '../../../../components/dynamic-table/dynamic-table.component';
import {DatePipe} from '@angular/common';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {PluginsService} from '../../../../services/gateway/plugins.service';
import {SweetAlertComponent} from '../../../../components/sweet-alert/sweet-alert.component';
import {TranslateService} from '@ngx-translate/core';
import {PluginDetailsComponent} from '../../plugins/plugin-details/plugin-details.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

export interface RowItem {
    id: string;
    name: string;
    host: string;
    tags: string;
    service: string;
    created_at: string;
    consumer: string;
    config: any;
    port: number;
    protocols: string[];
    route: any;
    retries: number;
    enabled: boolean;
    updated_at: number;
}

@Component({
    selector: 'app-service-plugins',
    templateUrl: './service-plugins.component.html',
    styleUrls: ['./service-plugins.component.scss']
})
export class ServicePluginsComponent implements OnInit {
    service: Service;
    plugins: PluginsModel[] = [];
    tabs: string[] = [];
    accion = '';
    public spinner = false;
    public dataSource: RowItem[] = [];
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'actions', title: '', custom: true},
        {columnDef: 'name', title: '', custom: true},
        {columnDef: 'consumer', title: 'plugins.table.consumer', custom: true},
        {
            columnDef: 'createdDate',
            title: 'plugins.table.createdDate',
            cell: (row: RowItem) => `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`
        },
        {columnDef: 'delete', title: '', custom: true},
    ];
    indexTab = 0;

    constructor(protected activatedRoute: ActivatedRoute,
                private servicesService: ServicesService,
                private pluginsService: PluginsService,
                private toastr: ToastrService,
                private dateFormat: DatePipe,
                private sweet: SweetAlertComponent,
                public dialog: MatDialog,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(({service}) => {
            this.service = service;
            this.getPlugins(service.id);
        });
    }

    private getPlugins(id): void {
        this.dataSource = [];
        this.spinner = true;
        this.servicesService.getServicePlugins(id).subscribe((plugins: PluginsModel[]) => {
            this.plugins = plugins || [];
            this.dataSource = plugins as RowItem[];
            this.spinner = false;
        });
    }

    addPlugin(): void {
        if (this.tabs.length === 0) {
            this.accion = 'Grupo de Plugins';
            this.tabs.push('');
        }
        this.indexTab = 1;
    }

    removeTab(index: number): void {
        this.tabs.splice(index, 1);
        this.getPlugins(this.service.id);
    }

    selectionChange(event: MatTabChangeEvent): void {
        this.indexTab = event.index;
    }

    deletePlugin(id): void {
        this.translate.get('plugins.message').subscribe(translate => {
            this.sweet.confirmBox({
                title: translate.confirm.title,
                text: translate.confirm.text,
                confirmButtonText: translate.confirm.button,
                showCancelButton: true,
                alertAction: (result) => {
                    if (result.isConfirmed) {
                        this.pluginsService.deletePlugin(id)
                            .subscribe(() => {
                                    this.toastr.success(translate.deleted);
                                    this.getPlugins(this.service.id);
                                },
                                (err) => {
                                    console.log(err);
                                    this.sweet.error({
                                        title: translate.error
                                    });
                                });
                    }
                }
            });
        });
    }

    getDetailsPlugin(row): void {
        const dialogRef = this.dialog.open(PluginDetailsComponent, {
            height: 'auto',
            width: '750px',
            disableClose: true,
            data: row
        });
        dialogRef.componentInstance.title = 'plugins.modal.titleOpen';
        dialogRef.componentInstance.buttonCancel = 'plugins.modal.buttonCancel';
    }

    changeStatus(event: MatSlideToggleChange, row: any): void {
        row.enabled = event.checked;
        this.pluginsService.updatePlugin(row).subscribe(() => {
            this.getPlugins(this.service.id);
        });
    }
}
