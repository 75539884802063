import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Service} from '../../../../core/model/service.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageError} from '../../../../core/interfaces/message-error.interface';
import {MatTabGroup} from '@angular/material/tabs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {ServicesService} from '../../../../services/gateway/services.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-service-detail',
    templateUrl: './service-detail.component.html',
    styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent implements OnInit {
    service: Service;
    public formService: FormGroup;
    title = '';
    selectedTab = 0;
    buttonCreate = '';
    buttonEdit = 'services.detail.edit';
    tags: string[] = [];
    MESSAGE_REQUIRED = 'service.message.required';
    protocolos = ['http', 'https'];
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    @ViewChild(MatTabGroup) tabGroup;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    constructor(protected activatedRoute: ActivatedRoute,
                private servicesService: ServicesService,
                private toastr: ToastrService,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.formService = this.buildForm();
        this.activatedRoute.data.subscribe(({service}) => {
            this.service = service;
            this.updateForm(service);
        });
    }

    isValid(type: string, name: string): boolean {
        return this.formService.get(name).hasError(type);
    }

    submit(): void {
        if (this.formService.invalid) {
            this.translate.get('connections.message').subscribe(translate => {
                this.toastr.error(translate.invalidForm);
                return;
            });
        }
        const formValues = this.formService.value;
        const requestServer = {
            id: formValues.id,
            name: formValues.name,
            retries: formValues.retries as number,
            protocol: formValues.protocol,
            url: formValues.url,
            host: formValues.host,
            port: formValues.port as number,
            path: formValues.path,
            connect_timeout: formValues.connectTimeout as number,
            write_timeout: formValues.writeTimeout as number,
            read_timeout: formValues.readTimeout as number,
            tags: this.tags,
            client_certificate: formValues.clientCertificate,
        } as Service;
        this.translate.get('services.message').subscribe(translate => {
            this.servicesService.updateService(requestServer)
                .subscribe(() => {
                    this.toastr.success(translate.created);
                }, (err) => {
                    this.toastr.error(translate.error);
                    console.error(err);
                });
        });
    }

    addTag(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTag(tag: string): void {
        const index = this.tags.indexOf(tag);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    buildForm(): FormGroup {
        return new FormGroup({
            id: new FormControl(null, []),
            name: new FormControl('', []),
            tags: new FormControl(null, []),
            url: new FormControl(null, []),
            protocol: new FormControl(null, [Validators.required]),
            host: new FormControl(null, [Validators.required]),
            port: new FormControl(null, [Validators.required]),
            path: new FormControl(null, []),
            retries: new FormControl(null, []),
            connectTimeout: new FormControl(null, []),
            writeTimeout: new FormControl(null, []),
            readTimeout: new FormControl(null, []),
            clientCertificate: new FormControl(null, []),
        });
    }

    updateForm(row: Service): void {
        this.tags = row.tags;
        this.formService.patchValue({
            id: row.id,
            name: row.name,
            url: row.url,
            protocol: row.protocol,
            host: row.host,
            port: row.port,
            path: row.path,
            retries: row.retries,
            connectTimeout: row.connect_timeout,
            writeTimeout: row.write_timeout,
            readTimeout: row.read_timeout,
            clientCertificate: row.client_certificate,
        });
    }
}
