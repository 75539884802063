<mat-card>
    <mat-card-content>
        <div class="container">
            <div>
                <mat-tab-group #tabs animationDuration="0ms" [selectedIndex]="selectedTab" (selectedTabChange)="tabChanged($event)">
                    <ng-container *ngFor="let plugin of plugins">
                        <mat-tab aria-label="NONE" [label]="plugin.name" *ngIf="plugin.name!='Other'">
                            <div class="label-connection" style="padding: 20px 0">
                                <p> {{plugin.description}}</p>
                            </div>
                            <div class="container">
                                <div class="row">
                                    <div class="p-3 col-3" *ngFor="let item of plugin.plugins | keyvalue">
                                        <mat-card class="justify-center plugin-card mat-elevation-z4">
                                            <div>
                                                <h5 class="capitalize margin-bottom no-wrap elipsize">{{item.key.split('-').join(" ")}}</h5>
                                            </div>
                                            <div class="image-plugin">
                                                <img src="assets/images/kong/plugins/{{item.key}}.png"
                                                        onerror="this.src='assets/images/kong/plugins/kong.svg'"
                                                        class="img-responsive" style="margin: auto;height: 72px;">
                                            </div>
                                            <div>
                                                {{(item.value.isReadMore) ? item.value.description : item.value.description | slice:0:50}} <span *ngIf="!item.value.isReadMore">...</span>
                                                <a href="javascript:;" *ngIf="!item.value.isReadMore" (click)="item.value.isReadMore=true">[Read More]</a>
                                            </div>
                                            <div class="card-actions">
                                                <button class="btn btn-action btn-full-width" (click)="addPlugin(item)">
                                                    <mat-icon>add</mat-icon>
                                                    {{'plugins.modal.addPlugin'|translate}}
                                                </button>
                                            </div>
                                        </mat-card>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab aria-label="NONE" [label]="plugin.name" *ngIf="plugin.name=='Other'">
                            <div class="label-connection" style="padding: 20px 0">
                                <p> {{plugin.description}}</p>
                            </div>
                            <div class="container">
                                <div class="row">
                                    <div class="p-3 col-3" *ngFor="let otherPlugin of otherPlugins">
                                        <mat-card class="justify-center plugin-card mat-elevation-z4">
                                            <div>
                                                <h5 class="capitalize margin-bottom no-wrap elipsize">{{otherPlugin.split('-').join(" ")}}</h5>
                                            </div>
                                            <div class="image-plugin">
                                                <img src="assets/images/kong/plugins/kong.svg"
                                                        onerror="this.src='assets/images/kong/plugins/kong.svg'"
                                                        class="img-responsive" style="margin: auto;height: 72px;">
                                            </div>
                                            <div>
                                                {{otherPlugin}}
                                            </div>
                                            <div class="card-actions">
                                                <button class="btn btn-action btn-full-width" (click)="addOtherPlugin(otherPlugin)">
                                                    <mat-icon>add</mat-icon>
                                                    {{'plugins.modal.addPlugin'|translate}}
                                                </button>
                                            </div>
                                        </mat-card>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
            </div>
        </div>
    </mat-card-content>
</mat-card>
