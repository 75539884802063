import {DatePipe} from '@angular/common';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ColumnSetup} from 'src/app/components/dynamic-table/dynamic-table.component';
import {ConnectionsService} from 'src/app/services/apis/connections.service';
import {MatDialog} from '@angular/material/dialog';
import {ModalConnectionComponent} from './modal-connection/modal-connection.component';
import * as FileSaver from 'file-saver';

import {SweetAlertComponent} from 'src/app/components/sweet-alert/sweet-alert.component';
import {ToastrService} from 'ngx-toastr';
import {CheckUrlService} from 'src/app/services/apis/check-url.service';
import {HttpErrorResponse, HttpEventType, HttpHeaders} from '@angular/common/http';
import {ProgressStatus, ProgressStatusEnum} from '../../../core/model/connection.model';
import {TranslateService} from '@ngx-translate/core';

export interface RowItem {
    id: string;
    nombre: string;
    enviroment: string;
    url: string;
    authType: string;
    apiKey: string;
    apiKeyHeaderName: string;
    algoritmo: string;
    activo: boolean;
    createdDate: Date;
    userName: string;
    password: string;
    key: string;
    secret: string;
}


@Component({
    selector: 'app-connections',
    templateUrl: './connections.component.html',
    styleUrls: ['./connections.component.scss'],
})
export class ConnectionsComponent implements OnInit {
    public spinner = false;
    public dataSource: RowItem[] = [];
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'testUrl', title: '', custom: true},
        {columnDef: 'name', title: '', custom: true},
        {columnDef: 'type', title: 'connections.table.type', cell: (row: RowItem) => `${row.authType}`},
        {columnDef: 'urlAdmin', title: 'connections.table.urlAdmin', cell: (row: RowItem) => `${row.url}`},
        {columnDef: 'environment', title: 'connections.table.environment', cell: (row: RowItem) => `${row.enviroment}`},
        {
            columnDef: 'createdDate',
            title: 'connections.table.createdDate',
            cell: (row: RowItem) => `${this.dateFormat.transform(row.createdDate, 'dd/MM/yyyy HH:mm')}`
        },
        {columnDef: 'activated', title: '', custom: true},
        {columnDef: 'delete', title: '', custom: true},
    ];
    isDownloading = false;
    @Output() public downloadStatus: EventEmitter<ProgressStatus>;

    constructor(
        private translate: TranslateService,
        private dateFormat: DatePipe,
        private connectionServices: ConnectionsService,
        private toastr: ToastrService,
        private sweet: SweetAlertComponent,
        private checkUrlService: CheckUrlService,
        public dialog: MatDialog
    ) {
        this.downloadStatus = new EventEmitter<ProgressStatus>();
    }

    ngOnInit(): void {
        this.getConnections();
        this.connectionServices.getConnectionEmitter().subscribe(env => {
            if (env) {
                this.selectConnection(env);
            }
        });
    }

    getConnections(): void {
        this.dataSource = [];
        this.spinner = true;
        this.connectionServices.getServers().subscribe((response: RowItem[]) => {
                this.spinner = false;
                this.dataSource = response.map(server => ({
                    ...server,
                    createdDate: new Date()
                }));
            },
            err => {
                console.log(err);
                this.spinner = false;
            });
    }

    openConnection(): void {
        const dialogRef = this.dialog.open(ModalConnectionComponent, {
            height: 'auto',
            width: '750px',
            disableClose: true

        });

        dialogRef.componentInstance.title = 'connections.modal.titleOpen';
        dialogRef.componentInstance.buttonCancel = 'connections.modal.buttonCancel';
        dialogRef.componentInstance.buttonCreate = 'connections.modal.buttonCreate';

        dialogRef.afterClosed().subscribe(() => {
            this.getConnections();
        });
    }

    editConnection(row: RowItem): void {
        const dialogRef = this.dialog.open(ModalConnectionComponent, {
            height: 'auto',
            width: '750px',
            disableClose: true,
            data: row
        });

        dialogRef.componentInstance.title = 'connections.modal.titleEdit';
        dialogRef.componentInstance.buttonCancel = 'connections.modal.buttonCancel';
        dialogRef.componentInstance.buttonEdit = 'connections.modal.buttonEdit';
        dialogRef.componentInstance.modalOption = 'edit';

        dialogRef.afterClosed().subscribe(() => {
            this.getConnections();
        });
    }

    deleteConnection(id: string): void {
        this.translate.get('connections.message').subscribe(translate => {
            this.sweet.confirmBox({
                title: translate.confirm.title,
                text: translate.confirm.text,
                confirmButtonText: translate.confirm.button,
                showCancelButton: true,
                alertAction: (result) => {
                    if (result.isConfirmed) {
                        this.connectionServices.deleteServer(id)
                            .subscribe(() => {
                                    this.toastr.success(translate.deleted);
                                    this.getConnections();
                                },
                                (err) => {
                                    console.log(err);
                                    this.sweet.error({
                                        title: translate.error
                                    });
                                });
                    }
                }
            });
        });
    }

    getStatusUrl(row: RowItem): void {

        if (row.authType === 'NONE') {
            this.checkUrlService.getResponseDefault(row.url)
                .subscribe(() => {
                    this.toastr.success('URL OK');
                }, err => {
                    this.toastr.error('URL ERROR');
                });
        }

        if (row.authType === 'KEY_AUTH') {
            const headers = new HttpHeaders().append(row.apiKeyHeaderName, row.apiKey);
            this.checkUrlService.getResponse(row.url, headers)
                .subscribe(() => {
                    this.toastr.success('URL APIKEY OK');
                }, err => {
                    this.toastr.error('URL ERROR');
                });
        }

        if (row.authType === 'BASIC_AUTH') {
            const headers = new HttpHeaders().append('Authorization', 'Basic ' + btoa(`${row.userName}:${row.password}`));
            this.checkUrlService.getResponse(row.url, headers)
                .subscribe(() => {
                    this.toastr.success('URL BASIC OK');
                }, err => {
                    this.toastr.error('URL ERROR');
                });
        }

        if (row.authType === 'JWT_AUTH') {
            const token = this.checkUrlService.generateToken(row.key, row.secret);
            const headers = new HttpHeaders().append('Authorization', 'Bearer ' + token)
                .append('Access-Control-Allow-Origin', 'localhost');
            this.checkUrlService.getResponse(row.url, headers)
                .subscribe(() => {
                    this.toastr.success('URL JWT OK');
                }, err => {
                    this.toastr.error('URL ERROR');
                });
        }

    }

    statusConnection(id: string): void {
        this.translate.get('connections.message.statusConnection').subscribe(translate => {
            this.connectionServices.getServer(id).subscribe(response => {
                const requestServer = response;
                requestServer.activo = !response.activo;
                this.connectionServices.statusServer(requestServer.id, requestServer.activo).subscribe(status => {
                    this.connectionServices.emitConnectionChangeSelected();
                    this.getConnections();
                    if (status.activo) {
                        this.toastr.success(translate.active);
                    } else {
                        this.toastr.info(translate.deactivate);
                    }
                }, (err) => {
                    console.error(err);
                    this.toastr.error(translate.error);
                });
            }, (err) => {
                console.error(err);
                this.toastr.error(translate.error);
            });
        });
    }

    backupConnection(id): void {
        this.connectionServices.backupServer(id).subscribe((data) => {
                if (data.type === HttpEventType.DownloadProgress) {
                    this.downloadStatus.emit({
                        status: ProgressStatusEnum.IN_PROGRESS,
                        percentage: Math.round((data.loaded / data.total) * 100)
                    });
                } else if (data.type === HttpEventType.Response) {
                    FileSaver.saveAs(data.body, 'dump-server-config.yaml');
                }
            },
            (error: HttpErrorResponse) => {
                if (error.status === 404) {
                    console.log('Documento no encontrado.');
                }
                this.isDownloading = false;
            }
        );
    }

    private selectConnection(env: string): void {
        const selectedConnection = this.dataSource.find(value => value.enviroment === env);
        if (selectedConnection) {
            this.statusConnection(selectedConnection.id);
        }
    }
}
