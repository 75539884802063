import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ColumnSetup } from '../../../components/dynamic-table/dynamic-table.component';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SweetAlertComponent } from '../../../components/sweet-alert/sweet-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalConsumerComponent } from './modal-consumer/modal-consumer.component';
import { ConsumerService } from '../../../services/gateway/consumer.service';
import { ConsumerOptionsComponent } from './consumer-options/consumer-options.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SchemasService } from 'src/app/services/gateway/schemas.service';

export interface RowItem {
  id: string;
  username: string;
  custom_id: string;
  tags: string;
  url: string;
  created_at: string;
}

@Component({
  selector: 'app-consumers',
  templateUrl: './consumers.component.html',
  styleUrls: ['./consumers.component.scss'],
})
export class ConsumersComponent implements OnInit, AfterViewInit {
  public spinner = false;
  public dataSource: RowItem[] = [];
  public columnsSetup: ColumnSetup[] = [
    { columnDef: 'actions', title: '', custom: true },
    {
      columnDef: 'username',
      title: 'consumers.table.type',
      cell: (row: RowItem) => `${row.username}`,
    },
    { columnDef: 'customId', custom: true },
    { columnDef: 'tags', custom: true },
    {
      columnDef: 'createdDate',
      title: 'consumers.table.createdDate',
      cell: (row: RowItem) =>
        `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`,
    },
    { columnDef: 'delete', title: '', custom: true },
  ];

  constructor(
    private translate: TranslateService,
    private dateFormat: DatePipe,
    private consumerService: ConsumerService,
    private router: Router,
    private toastr: ToastrService,
    private sweet: SweetAlertComponent,
    private schemasService: SchemasService,
    public dialog: MatDialog
  ) {}

  ngAfterViewInit(): void {
    this.getConsumers();
  }

  ngOnInit(): void {}

  private getConsumers(): void {
    this.dataSource = [];
    this.spinner = true;
    this.consumerService.getConsumers().subscribe(
      (response: RowItem[]) => {
        this.spinner = false;
        this.dataSource = response;
      },
      (err) => {
        console.log(err);
        this.spinner = false;
      }
    );
  }

  createConsumer(): void {
    this.schemasService.getConsumers().subscribe(
      (response) => {
        const schema = response.fields;
        const dialogRef = this.dialog.open(ModalConsumerComponent, {
          height: 'auto',
          width: '750px',
          disableClose: true,
          data: { schema },
        });
        dialogRef.componentInstance.title = 'consumers.modal.titleOpen';
        dialogRef.componentInstance.buttonCancel =
          'consumers.modal.buttonCancel';
        dialogRef.componentInstance.buttonCreate =
          'consumers.modal.buttonCreate';
        dialogRef.afterClosed().subscribe(() => {
          this.getConsumers();
        });
      },
      (err) => {}
    );
  }

  getStatusUrl(row): void {}

  editConsumer(row): void {
    this.spinner = true;
    this.router.navigate(['admin', 'gateway', 'consumers', row.id]);
  }

  deleteConnection(id): void {
    this.translate.get('consumers.message').subscribe((translate) => {
      this.sweet.confirmBox({
        title: translate.confirm.title,
        text: translate.confirm.text,
        confirmButtonText: translate.confirm.button,
        showCancelButton: true,
        alertAction: (result) => {
          if (result.isConfirmed) {
            this.consumerService.deleteConsumer(id).subscribe(
              () => {
                this.toastr.success(translate.deleted);
                this.getConsumers();
              },
              (err) => {
                console.log(err);
                this.sweet.error({
                  title: translate.error,
                });
              }
            );
          }
        },
      });
    });
  }

  getCustomerOptions(row): void {
    const dialogRef = this.dialog.open(ConsumerOptionsComponent, {
      height: 'auto',
      width: '750px',
      disableClose: true,
      data: row,
    });
    dialogRef.componentInstance.title = 'consumers.modal.detailTitle';
    dialogRef.componentInstance.buttonCancel = 'consumers.modal.buttonCancel';
  }
}
