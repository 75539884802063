import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ColumnSetup} from '../../../components/dynamic-table/dynamic-table.component';
import {DatePipe} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {SweetAlertComponent} from '../../../components/sweet-alert/sweet-alert.component';
import {MatDialog} from '@angular/material/dialog';
import {PluginsService} from '../../../services/gateway/plugins.service';
import {PluginDetailsComponent} from './plugin-details/plugin-details.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {ConnectionsService} from '../../../services/apis/connections.service';
import {TranslateService} from '@ngx-translate/core';

export interface RowItem {
    id: string;
    name: string;
    host: string;
    tags: string;
    service: any;
    created_at: string;
    consumer: string;
    config: any;
    port: number;
    protocols: string[];
    route: any;
    retries: number;
    enabled: boolean;
    updated_at: number;
}

@Component({
    selector: 'app-plugins',
    templateUrl: './plugins.component.html',
    styleUrls: ['./plugins.component.scss']
})
export class PluginsComponent implements OnInit, AfterViewInit {

    tabs: string[] = [];
    indexTab = 0;
    accion = '';
    public spinner = false;
    public dataSource: RowItem[] = [];
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'actions', title: '', custom: true},
        {columnDef: 'name', title: '', custom: true},
        {columnDef: 'scope', title: 'plugins.table.scope', custom: true},
        // {columnDef: 'protocols', title: 'plugins.table.protocols', custom: true},
        {columnDef: 'applyTo', title: 'plugins.table.applyTo', custom: true},
        {columnDef: 'consumer', title: 'plugins.table.consumer', custom: true},
        {
            columnDef: 'createdDate',
            title: 'plugins.table.createdDate',
            cell: (row: RowItem) => `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`
        },
        {columnDef: 'delete', title: '', custom: true},
    ];

    constructor(
        private translate: TranslateService,
        private dateFormat: DatePipe,
        private pluginsService: PluginsService,
        private toastr: ToastrService,
        private connectionService: ConnectionsService,
        private sweet: SweetAlertComponent,
        public dialog: MatDialog
    ) {
    }

    ngAfterViewInit(): void {
        this.getPlugins();
        this.pluginsService.getEmitter().subscribe(value => {
            this.removeTab(value);
        });
        this.connectionService.getConnectionEmitter().subscribe(value => {
            if (value) {
                this.getPlugins();
            }
        });
    }

    ngOnInit(): void {
    }

    createPlugin(): void {
        if (this.tabs.length === 0) {
            this.accion = 'Grupo de Plugins';
            this.tabs.push('');
        }
        this.indexTab = 1;
    }

    getStatusUrl(row): void {

    }

    statusPlugin(id): void {

    }

    deletePlugin(id): void {
        this.translate.get('plugins.message').subscribe(translate => {
            this.sweet.confirmBox({
                title: translate.confirm.title,
                text: translate.confirm.text,
                confirmButtonText: translate.confirm.button,
                showCancelButton: true,
                alertAction: (result) => {
                    if (result.isConfirmed) {
                        this.pluginsService.deletePlugin(id)
                            .subscribe(() => {
                                    this.toastr.success(translate.deleted);
                                    this.getPlugins();
                                },
                                (err) => {
                                    console.log(err);
                                    this.sweet.error({
                                        title: translate.error
                                    });
                                });
                    }
                }
            });
        });
    }

    private getPlugins(): void {
        this.dataSource = [];
        this.spinner = true;
        this.pluginsService.getPlugins().subscribe((response: RowItem[]) => {
            this.spinner = false;
            this.dataSource = response;
        }, err => {
            console.log(err);
            this.spinner = false;
        });
    }

    getDetailsPlugin(row): void {
        const dialogRef = this.dialog.open(PluginDetailsComponent, {
            height: 'auto',
            width: '750px',
            disableClose: true,
            data: row
        });
        dialogRef.componentInstance.title = 'plugins.modal.titleOpen';
        dialogRef.componentInstance.buttonCancel = 'plugins.modal.buttonCancel';
    }

    removeTab(index: number): void {
        this.tabs.splice(index, 1);
    }

    selectionChange(event: MatTabChangeEvent): void {
        this.indexTab = event.index;
    }

    changeStatus(event: MatSlideToggleChange, row: any): void {
        row.enabled = event.checked;
        this.pluginsService.updatePlugin(row).subscribe(() => {
            this.getPlugins();
        });
    }
}
