import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {PagesModule} from './pages/pages.module';
import {PagesPortalModule} from './pages-portal/pages-portal.module';
import {MaterialModule} from './material.module';
import {ThemeModule} from './theme/theme.module';
import {ToastrModule} from 'ngx-toastr';
import {CoreModule} from './core/core.module';

import {AppComponent} from './app.component';

/* Menu Items */
import {NavigationItem} from './theme/layout/admin/navigation/navigation';
import {NgbButtonsModule, NgbDropdownModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {InterceptorService} from 'src/app/core/interceptors/interceptor.service';
import localeEs from '@angular/common/locales/es';
/* translate */
import {MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {registerLocaleData} from '@angular/common';
import {LocalstorageService} from './services/localstorage.service';
import {AuthExpiredInterceptor} from './core/interceptors/auth-expired.interceptor';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {QuillModule} from 'ngx-quill';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {missingTranslationHandler, translatePartialLoader} from './config/translation.config';
import {MatPaginatorI18nService} from './locale-paginator';

registerLocaleData(localeEs);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbButtonsModule,
        FormsModule,
        HttpClientModule,
        QuillModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translatePartialLoader,
                deps: [HttpClient],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useFactory: missingTranslationHandler,
            },
            useDefaultLang: true,
        }),
        AngularEditorModule,
        PagesModule,
        PagesPortalModule,
        MaterialModule,
        ThemeModule,
        CoreModule,
        NgxWebstorageModule.forRoot({prefix: 'kong', separator: '-', caseSensitive: true}),
        ToastrModule.forRoot({
            enableHtml: true,
            timeOut: 5000,
            progressBar: true,
            progressAnimation: 'decreasing'
        }),
    ],
    exports: [],
    providers: [NavigationItem, {
        provide: LOCALE_ID,
        useValue: 'es',
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: InterceptorService,
        multi: true
    }, {
        provide: MatPaginatorIntl,
        useClass: MatPaginatorI18nService,
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthExpiredInterceptor,
        multi: true,
    }],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor(translateService: TranslateService, localStorageService: LocalstorageService) {
        const langKey = localStorageService.getItem('langKey') || 'es';
        translateService.setDefaultLang(langKey);
        translateService.use(langKey);
    }
}
