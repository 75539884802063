<app-header-pages
  title="{{ 'consumers.title' | translate }}"
  subTitle="{{ 'consumers.subTitle' | translate }}"
></app-header-pages>

<div class="d-flex justify-content-between">
  <button class="btn btn-action" (click)="createConsumer()">
    <mat-icon>add</mat-icon>
    {{ "consumers.table.new" | translate }}
  </button>
</div>

<app-dynamic-table
  [data]="dataSource"
  [columnsSetup]="columnsSetup"
  [spinner]="spinner"
  pageSize="10"
>
  <ng-container matColumnDef="actions">
    <mat-header-cell
      *matHeaderCellDef
      scope="col"
      class="column-flex-3"
    ></mat-header-cell>
    <mat-cell *matCellDef="let row" class="column-flex-3">
      <button class="btn" (click)="getCustomerOptions(row)">
        <mat-icon>visibility</mat-icon>
      </button>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="tags">
    <mat-header-cell *matHeaderCellDef scope="col">{{
      "consumers.table.tags" | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <app-tags [tags]="row.tags"></app-tags>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="customId">
    <mat-header-cell *matHeaderCellDef scope="col" class="column-flex-10">{{
      "consumers.table.customId" | translate
    }}</mat-header-cell>
    <mat-cell *matCellDef="let row" class="column-flex-10">
      <span>{{ row.custom_id | nulleable }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="delete">
    <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button class="btn btn-delete" (click)="editConsumer(row)">
        <mat-icon class="edit">edit</mat-icon>
        {{ "consumers.table.edit" | translate }}
      </button>
      <button class="btn btn-delete" (click)="deleteConnection(row.id)">
        <mat-icon class="delete">delete</mat-icon>
        {{ "consumers.table.delete" | translate }}
      </button>
    </mat-cell>
  </ng-container>
</app-dynamic-table>
