import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalstorageService} from 'src/app/services/localstorage.service';
import {ServicesService} from 'src/app/services/apis-old/services.service';

@Component({
    selector: 'app-translation',
    templateUrl: './translation.component.html',
    styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {

    public activeLang = 'es';

    constructor(
        private localstorageService: LocalstorageService,
        public translate: TranslateService,
        private servicesService: ServicesService
    ) {
    }


    ngOnInit(): void {
        this.activeLang = this.localstorageService.getItem('langKey');
    }

    public cambiaIdioma(): void {
        this.localstorageService.setItem<string>('langKey', this.activeLang);
        this.servicesService.changeLangKeyEvent.emit(this.activeLang);
    }
}
