import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Upstreams} from '../../../../core/model/upstreams.model';
import {ColumnSetup} from '../../../../components/dynamic-table/dynamic-table.component';
import {DatePipe} from '@angular/common';
import {UpstreamService} from '../../../../services/gateway/upstream.service';
import {MatDialog} from '@angular/material/dialog';
import {AddUpstreamTargetComponent} from '../add-upstream-target/add-upstream-target.component';
import {TranslateService} from '@ngx-translate/core';
import {SweetAlertComponent} from '../../../../components/sweet-alert/sweet-alert.component';

export interface RowItem {
    id: string;
    tags: string;
    target: string;
    weight: number;
    created_at: number;
    upstream: { id: string };
}

@Component({
    selector: 'app-upstream-target',
    templateUrl: './upstream-target.component.html',
    styleUrls: ['./upstream-target.component.scss']
})
export class UpstreamTargetComponent implements OnInit {
    upstream: Upstreams;
    public spinner = false;
    public dataSource: RowItem[] = [];
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'target', title: 'upstreams.table.target', cell: (row: RowItem) => `${row.target}`},
        {columnDef: 'weight', title: 'upstreams.table.weight', cell: (row: RowItem) => `${row.weight}`},
        {columnDef: 'tags', title: 'upstreams.table.tags', custom: true},
        {
            columnDef: 'createdDate',
            title: 'upstreams.table.createdDate',
            cell: (row: RowItem) => `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`
        },
        {columnDef: 'delete', title: '', custom: true},
    ];

    constructor(protected activatedRoute: ActivatedRoute,
                private upstreamService: UpstreamService,
                public dialog: MatDialog,
                private translate: TranslateService,
                private sweet: SweetAlertComponent,
                private dateFormat: DatePipe) {
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(({upstream}) => {
            this.upstream = upstream;
            this.getTargets();
        });
    }

    private getTargets(): void {
        this.upstreamService.getTargets(this.upstream.id).subscribe(targets => {
            this.dataSource = targets;
        });
    }

    createTarget(): void {
        const dialogRef = this.dialog.open(AddUpstreamTargetComponent, {
            width: '500px',
            data: this.upstream
        });
        dialogRef.beforeClosed().subscribe(() => {
            this.getTargets();
        });
    }

    deleteTarget(hostPort: string): void {
        this.translate.get('upstreams.message').subscribe(translate => {
            this.sweet.confirmBox({
                title: translate.confirm.title,
                text: translate.confirm.text,
                confirmButtonText: translate.confirm.button,
                showCancelButton: true,
                alertAction: (result) => {
                    if (result.isConfirmed) {
                        this.upstreamService.deleteTarget(this.upstream.id, hostPort).subscribe(() => {
                            this.getTargets();
                        });
                    }
                }
            });
        });
    }
}
