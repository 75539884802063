import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalstorageService } from 'src/app/services/localstorage.service';
import { Location } from '@angular/common';
import { ModalSpecComponent } from '../modal-spec/modal-spec.component';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertComponent } from 'src/app/components/sweet-alert/sweet-alert.component';
import { TranslateService } from '@ngx-translate/core';
import { SpecService } from 'src/app/services/gateway/spec.service';
import { tap } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';

declare const SwaggerEditorBundle: any;
declare const SwaggerEditorStandalonePreset: any;

@Component({
  selector: 'app-spec-content',
  templateUrl: './spec-content.component.html',
  styleUrls: ['./spec-content.component.scss'],
})
export class SpecContentComponent implements OnInit, AfterViewInit, OnDestroy {
  baseUrl = environment.url_services;
  public id: string;
  public loaded = 0;
  private parentId: string;

  constructor(
    private location: Location,
    private localStorageService: LocalstorageService,
    private activatedRoute: ActivatedRoute,
    private sweet: SweetAlertComponent,
    private translate: TranslateService,
    private specService: SpecService,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.id = params.get('id') || '';
      localStorage.setItem('swagger-editor-content', ' ');
    });
    const path = JSON.parse(localStorage.getItem('spec-path'));
    if (path) {
      this.parentId = path.pop()['id'];
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    SwaggerEditorBundle({
      dom_id: '#swagger-editor',
      layout: 'StandaloneLayout',
      presets: [SwaggerEditorStandalonePreset],
      url: `${this.baseUrl}/api/specs/${this.id}/content`,
      requestInterceptor: (req) => {
        const token = localStorage.getItem('token');
        req.headers.Authorization = `Bearer ${token}`;
        const env = this.localStorageService.getItem('environment');
        if (env) {
          req.headers.Environment = `${env}`;
        }
        return req;
      },
    });

    if (!this.id) {
      localStorage.setItem('swagger-editor-content', ' ');
      SwaggerEditorBundle({
        dom_id: '#swagger-editor',
        layout: 'StandaloneLayout',
        presets: [SwaggerEditorStandalonePreset],
      });
    }
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    if (localStorage.getItem('swagger-editor-content').length < 2) {
      this.translate.get('spec.message').subscribe((translate) => {
        this.toastr.error(translate.missing_content);
      });
      return;
    }
    if (!this.id && localStorage.getItem('swagger-editor-content').length > 1) {
      const dialogRef = this.dialog.open(ModalSpecComponent, {
        height: 'auto',
        width: '750px',
        disableClose: false,
        data: {
          id: this.id,
          text: localStorage.getItem('swagger-editor-content'),
        },
      });
      if (this.parentId) {
        dialogRef.componentInstance.parentId = this.parentId;
      }
      dialogRef.componentInstance.title = 'spec.modal.titles.create';
      dialogRef.componentInstance.modalOption = 'create-spec-text';
    } else {
      this.translate.get('spec.message').subscribe((translate) => {
        this.sweet.confirmBox({
          title: translate.confirm_create.title,
          text: translate.confirm_create.text,
          confirmButtonText: translate.confirm_create.button,
          showCancelButton: true,
          alertAction: (result) => {
            if (result.isConfirmed) {
              this.specService
                .updateSpec(
                  this.id,
                  localStorage.getItem('swagger-editor-content')
                )
                .pipe(
                  tap((event: any) => {
                    if (event.type === HttpEventType.UploadProgress) {
                      this.loaded = Math.round(
                        (100 * event.loaded) / event.total
                      );
                    }
                  })
                )
                .subscribe(
                  (event: any) => {
                    if (event instanceof HttpResponse) {
                      this.toastr.success(translate.created);
                    }
                  },
                  (res: HttpErrorResponse) => {
                    this.toastr.error(translate.error);
                  }
                );
            }
          },
        });
      });
    }
  }
}
