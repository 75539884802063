import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {LocalstorageService} from 'src/app/services/localstorage.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {

  constructor(
    private localstorageService: LocalstorageService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.localstorageService.getItem('token');
    if (token) {
      return true;
    } else {
      this.router.navigateByUrl('/account/login');
      return false;
    }
  }

}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(
    private localstorageService: LocalstorageService,
    private router: Router
  ) {
  }

  canActivateChild(): boolean {
    const token = this.localstorageService.getItem('token');
    if (token) {
      return true;
    } else {
      this.router.navigateByUrl('/account/login');
      return false;
    }
  }

}
