import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {RoutesModel} from '../../../../core/model/routes.model';
import {RouterService} from '../../../../services/gateway/router.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageError} from '../../../../core/interfaces/message-error.interface';
import {MatTabGroup} from '@angular/material/tabs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {KongError} from '../../../../core/model/audit.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-router-details',
    templateUrl: './router-details.component.html',
    styleUrls: ['./router-details.component.scss']
})
export class RouterDetailsComponent implements OnInit {
    router: RoutesModel;
    formRouter: FormGroup;
    title = '';
    selectedTab = 0;
    buttonCreate = '';
    buttonEdit = '';
    buttonCancel = '';
    modalOption = 'create';
    MESSAGE_REQUIRED = 'routers.message.required';
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    @ViewChild(MatTabGroup) tabGroup;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    tags: string[] = [];
    destinations: string[] = [];
    tagsHosts: string[] = [];
    tagsPaths: string[] = [];
    methods: string[] = [];
    protocols: string[] = [];
    snis: string[] = [];
    sources: string[] = [];

    constructor(protected activatedRoute: ActivatedRoute,
                private routerService: RouterService,
                private toastr: ToastrService,
                private translate: TranslateService) {
        this.formRouter = this.buildForm();
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(({routerObject}) => {
            this.router = routerObject;
            this.updateForm(routerObject);
        });
    }

    buildForm(): FormGroup {
        return new FormGroup({
            name: new FormControl('', []),
            tags: new FormControl(null, []),
            hosts: new FormControl('', []),
            paths: new FormControl(null, []),
            headers: new FormControl(null, []),
            pathHandling: new FormControl(null, [Validators.required]),
            redirectStatusCode: new FormControl(null, []),
            regexPriority: new FormControl(null, []),
            methods: new FormControl(null, []),
            stripPath: new FormControl(null, []),
            preserveHost: new FormControl(null, []),
            protocols: new FormControl(null, []),
            snis: new FormControl(null, []),
            sources: new FormControl(null, []),
            destinations: new FormControl(null, []),
        });
    }

    updateForm(row: any): void {
        this.tags = row.tags || [];
        this.methods = row.methods || [];
        this.tagsPaths = row.paths || [];
        this.protocols = row.protocols || [];
        this.snis = row.snis || [];
        this.sources = row.sources || [];
        this.destinations = row.destinations || [];
        this.formRouter.patchValue({
            id: row.id,
            name: row.name,
            hosts: row.host,
            headers: row.headers,
            pathHandling: row.path_handling,
            redirectStatusCode: row.https_redirect_status_code,
            regexPriority: row.regex_priority,
            stripPath: row.strip_path,
            preserveHost: row.preserve_host,
            sources: row.sources,
            destinations: row.destinations,
        });
    }

    isValid(type: string, name: string): boolean {
        return this.formRouter.get(name).hasError(type);
    }

    addTag(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTag(tag: string): void {
        const index = this.tags.indexOf(tag);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    removeDestinatios(destination: string): void {
        const index = this.destinations.indexOf(destination);
        if (index >= 0) {
            this.destinations.splice(index, 1);
        }
    }

    addTagHosts(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tagsHosts.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTagHosts(tag: string): void {
        const index = this.tagsHosts.indexOf(tag);
        if (index >= 0) {
            this.tagsHosts.splice(index, 1);
        }
    }

    addTagPaths(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tagsPaths.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTagPaths(tag: string): void {
        const index = this.tagsPaths.indexOf(tag);
        if (index >= 0) {
            this.tagsPaths.splice(index, 1);
        }
    }

    addDestination(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.destinations.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    submit(): void {
        if (this.formRouter.valid) {
            const formValues = this.formRouter.value;
            const requestServer = {
                id: this.router.id,
                name: formValues.name,
                tags: this.tags,
                hosts: formValues.host,
                paths: this.tagsPaths,
                headers: formValues.headers,
                path_handling: formValues.pathHandling,
                https_redirect_status_code: formValues.redirectStatusCode,
                regex_priority: formValues.regexPriority,
                methods: this.methods,
                strip_path: formValues.stripPath,
                preserve_host: formValues.preserveHost,
                protocols: this.protocols,
                snis: this.snis,
                sources: this.sources.length === 0 ? null : this.sources,
                destinations: this.destinations.length === 0 ? null : this.destinations,
            };
            this.translate.get('routers.message').subscribe(translate => {
                this.routerService.updateRouter(requestServer)
                    .subscribe(() => {
                        this.toastr.success(translate.updated);
                    }, (err) => {
                        this.toastr.error(translate.error);
                        const fieldErrors = err.error as KongError;
                        const keys = Object.keys(fieldErrors.fields);
                        console.error(keys);
                        keys.forEach(key => {
                            this.formRouter.get(key).setErrors({[key]: fieldErrors.fields[key]});
                        });
                        console.log(this.formRouter.get('sources').errors);
                        console.error(err);
                    });
            });
        }
    }

    removeTagMethod(tag: string): void {
        const index = this.methods.indexOf(tag);
        if (index >= 0) {
            this.methods.splice(index, 1);
        }
    }

    addTagMethod(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.methods.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTagProtocols(tag: string): void {
        const index = this.protocols.indexOf(tag);
        if (index >= 0) {
            this.protocols.splice(index, 1);
        }
    }

    addTagProtocols(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.protocols.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeSnis(tag: string): void {
        const index = this.snis.indexOf(tag);
        if (index >= 0) {
            this.snis.splice(index, 1);
        }
    }

    addSnis(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.snis.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeSources(tag: string): void {
        const index = this.sources.indexOf(tag);
        if (index >= 0) {
            this.sources.splice(index, 1);
        }
    }

    addSources(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.sources.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }
}
