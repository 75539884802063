import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConsumerModel} from '../../../../../../core/model/consumer.model';
import {MessageError} from '../../../../../../core/interfaces/message-error.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ConsumerService} from '../../../../../../services/gateway/consumer.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-add-jwt',
    templateUrl: './add-jwt.component.html',
    styleUrls: ['./add-jwt.component.scss']
})
export class AddJwtComponent implements OnInit {
    formJwt: FormGroup;
    tags: string[] = [];
    algoritmos: string[] = ['HS256', 'HS384', 'HS512', 'RS256', 'RS384', 'RS512', 'ES256', 'ES384'];
    consumer: ConsumerModel;
    MESSAGE_REQUIRED = 'consumers.message.required';
    protocolos = ['http', 'https'];
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };

    constructor(public dialog: MatDialogRef<AddJwtComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ConsumerModel,
                private translate: TranslateService,
                private toastr: ToastrService,
                private consumerService: ConsumerService) {
        this.consumer = data;
        this.formJwt = this.buildForm();
    }

    ngOnInit(): void {
    }

    submit(): void {
        if (this.formJwt.invalid) {
            this.translate.get('consumers.message').subscribe(translate => {
                this.toastr.error(translate.invalidForm);
                return;
            });
        }
        const formValues = this.formJwt.value;
        const requestServer = {
            key: formValues.key,
            algorithm: formValues.algorithm,
            rsa_public_key: formValues.rsaPublicKey,
            secret: formValues.secret,
        };
        this.translate.get('consumers.message').subscribe(translate => {
            this.consumerService.createJwtCredential(requestServer, this.consumer.id).subscribe((res) => {
                this.toastr.success(translate.jwt.created);
                this.dialog.close(res);
            }, (err) => {
                this.toastr.error(translate.error);
                console.error(err);
            });
        });
    }

    isValid(type: string, name: string): boolean {
        return this.formJwt.get(name).hasError(type);
    }

    buildForm(): FormGroup {
        return new FormGroup({
            key: new FormControl('', [Validators.required]),
            algorithm: new FormControl('', [Validators.required]),
            rsaPublicKey: new FormControl('', [Validators.required]),
            secret: new FormControl('', [Validators.required]),
        });
    }

}
