import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse, HttpEventType, HttpResponse} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {FileService} from '../../../../services/gateway/file.service';
import {UploadResponse} from '../../../../core/model/service.model';
import {WizardComponent} from 'angular-archwizard';

@Component({
    selector: 'app-api-wizard',
    templateUrl: './api-wizard.component.html',
    styleUrls: ['./api-wizard.component.scss']
})
export class ApiWizardComponent implements OnInit {

    @ViewChild('file') file: ElementRef;
    loaded = 0;
    selectedFiles!: FileList;
    archivos: File[] = [];
    currentFileUpload!: File | null;
    filePath = '';
    tiposDocumentos = '.yml,.yaml';
    lastId: string;
    @ViewChild(WizardComponent)
    public wizard: WizardComponent;

    constructor(
        private fileService: FileService,
    ) {
    }

    ngOnInit(): void {
    }

    selectFile(files: any): void {
        this.selectedFiles = files;
        if (this.selectedFiles.length > 0) {
            this.archivos = [];
            const file = this.selectedFiles.item(0);
            this.filePath = file ? file.name : '';
            const cantidad = this.selectedFiles.length;
            for (let i = 0; i < cantidad; i++) {
                const item = this.selectedFiles.item(i);
                if (item) {
                    this.archivos.push(item);
                }
            }
        }
    }

    upload(): void {
        if (this.selectedFiles && this.selectedFiles.length > 0) {
            this.currentFileUpload = this.selectedFiles.item(0);
            if (this.currentFileUpload) {
                this.fileService
                    .uploadSingleFile(this.currentFileUpload, 'api/apis/init')
                    .pipe(
                        tap((event: any) => {
                            if (event.type === HttpEventType.UploadProgress) {
                                this.loaded = Math.round((100 * event.loaded) / event.total);
                            }
                        })
                    )
                    .subscribe(
                        (event: any) => {
                            if (event instanceof HttpResponse) {
                                const respuesta = event.body as UploadResponse;
                                this.lastId = respuesta.id;
                                this.wizard.goToNextStep();
                                this.fileService.fetchDeclarative(this.lastId).subscribe(res => {
                                    console.log(res);
                                });
                            }
                        },
                        (res: HttpErrorResponse) => {

                        }
                    );
            }
        } else {

        }
    }

    formatBytes(bytes: any, decimals?: any): string {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    fileBrowseHandler(event: any): void {
        this.selectedFiles = event.target.files;
        if (this.selectedFiles.length > 0) {
            this.archivos = [];
            const file = this.selectedFiles.item(0);
            this.filePath = file ? file.name : '';
            const cantidad = this.selectedFiles.length;
            for (let i = 0; i < cantidad; i++) {
                const item = this.selectedFiles.item(i);
                if (item) {
                    this.archivos.push(item);
                }
            }
        }
    }

    finishFunction(): void {

    }
}
