import {HttpClient, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {endpoint} from 'src/app/core/constants/services.constants';
import {environment} from 'src/environments/environment';
import {IConnection} from '../../pages/management/connections/connection.model';

@Injectable({
    providedIn: 'root'
})
export class ConnectionsService {

    private URL_BASE: string = environment.url_services;
    private ENDPOINT_CONNECTIONS = `${this.URL_BASE}${endpoint.ENDPOINT_CONNECTIONS}`;
    private connectionEmitter: EventEmitter<string> = new EventEmitter<string>();
    private connectionChangeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private http: HttpClient,
    ) {
    }

    getServers(): Observable<any> {
        return this.http.get(this.ENDPOINT_CONNECTIONS);
    }

    getServer(id: string): Observable<any> {
        return this.http.get(`${this.ENDPOINT_CONNECTIONS}/${id}`);
    }

    getEnvironments(): Observable<HttpResponse<IConnection[]>> {
        return this.http.get<IConnection[]>(this.ENDPOINT_CONNECTIONS, {observe: 'response'});
    }

    createServer(body: any): Observable<any> {
        return this.http.post(this.ENDPOINT_CONNECTIONS, body);
    }

    updateServer(body: any): Observable<any> {
        return this.http.put(this.ENDPOINT_CONNECTIONS, body);
    }

    deleteServer(id: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_CONNECTIONS}/${id}`);
    }

    statusServer(id: string, status): Observable<any> {
        const params = new HttpParams().append('status', status);
        return this.http.post(`${this.ENDPOINT_CONNECTIONS}/${id}/activate`, {}, {params});
    }

    selectConnection(id: string): Observable<any> {
        return this.http.post(`${this.ENDPOINT_CONNECTIONS}/${id}/activate`, {}, {params: {status: 'true'}});
    }

    getConnectionEmitter(): EventEmitter<string> {
        return this.connectionEmitter;
    }

    emitConnectionSelected(env: string): void {
        this.connectionEmitter.emit(env);
    }

    getConnectionChangeEmitter(): EventEmitter<boolean> {
        return this.connectionChangeEmitter;
    }

    emitConnectionChangeSelected(): void {
        this.connectionChangeEmitter.emit(true);
    }

    backupServer(id: string): Observable<any> {
        return this.http.request(
            new HttpRequest('GET', `${this.ENDPOINT_CONNECTIONS}/${id}/dump`, {
                reportProgress: true,
                responseType: 'blob'
            })
        );
    }
}
