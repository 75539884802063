import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {CheckUrlService} from 'src/app/services/apis/check-url.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {MessageError} from 'src/app/core/interfaces/message-error.interface';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {RouterService} from '../../../../services/gateway/router.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-modal-router',
    templateUrl: './modal-router.component.html',
    styleUrls: ['./modal-router.component.scss']
})
export class ModalRouterComponent implements OnInit, AfterViewInit {

    public tabIndex = 0;
    public formRouter: FormGroup;
    title = '';
    selectedTab = 0;
    buttonCreate = '';
    buttonEdit = '';
    buttonCancel = '';
    modalOption = 'create';
    MESSAGE_REQUIRED = 'routers.message.required';
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    @ViewChild(MatTabGroup) tabGroup;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    tags: string[] = [];
    destinations: string[] = [];
    tagsHosts: string[] = [];
    tagsPaths: string[] = [];
    tagsProtocols: string[] = [];
    tagsMethods: string[] = [];

    constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        private routerService: RouterService,
        private checkUrl: CheckUrlService,
        private toastr: ToastrService,
        public dialog: MatDialogRef<ModalRouterComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this.formRouter = this.buildForm();
        this.setValidatorsForm('');
        if (this.data) {
            this.updateForm(this.data);
        }
    }

    setValidatorsForm(type: string): void {
        this.cleanValidators();
    }

    cleanValidators(): void {
        const keys = Object.keys(this.formRouter.value);
        keys.forEach(key => {
            this.formRouter.get(key).clearValidators();
        });
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.tabIndex = tabChangeEvent.index;
        const tabChange = tabChangeEvent.tab.ariaLabel;
        this.setValidatorsForm(tabChange);
        this.formRouter.patchValue({
            authType: tabChangeEvent.tab.ariaLabel
        });
    }

    isValid(type: string, name: string): boolean {
        return this.formRouter.get(name).hasError(type);
    }

    buildForm(): FormGroup {
        return new FormGroup({
            name: new FormControl('', []),
            tags: new FormControl(null, []),
            hosts: new FormControl('', []),
            paths: new FormControl(null, []),
            headers: new FormControl(null, []),
            pathHandling: new FormControl(null, [Validators.required]),
            redirectStatusCode: new FormControl(null, []),
            regexPriority: new FormControl(null, []),
            methods: new FormControl(null, []),
            stripPath: new FormControl(null, []),
            preserveHost: new FormControl(null, []),
            protocols: new FormControl(null, []),
            snis: new FormControl(null, []),
            sources: new FormControl(null, []),
            destinations: new FormControl(null, []),
            requestBuffering: new FormControl(true, []),
            responseBuffering: new FormControl(true, []),
        });
    }

    submit(): void {
        if (this.formRouter.invalid) {
            this.translate.get('routers.message').subscribe(translate => {
                this.toastr.error(translate.invalidForm);
                return;
            });
        }

        const formValues = this.formRouter.value;
        if (this.modalOption === 'create') {
            const requestServer = {
                name: formValues.name,
                tags: this.tags,
                hosts: formValues.host,
                paths: formValues.paths,
                headers: formValues.headers,
                path_handling: formValues.pathHandling,
                https_redirect_status_code: formValues.redirectStatusCode,
                regex_priority: formValues.regexPriority,
                methods: this.tagsMethods,
                strip_path: formValues.stripPath,
                preserve_host: formValues.preserveHost,
                protocols: this.tagsProtocols,
                snis: formValues.snis,
                sources: formValues.sources,
                destinations: formValues.destinations,
                request_buffering: formValues.requestBuffering,
                response_buffering: formValues.responseBuffering,
            };

            this.translate.get('routers.message').subscribe(translate => {
                this.routerService.createRouter(requestServer)
                    .subscribe(() => {
                        this.toastr.success(translate.created);
                        this.dialog.close();
                    }, (err) => {
                        this.toastr.error(translate.error);
                        console.error(err);
                    });
            });
        } else {
            const requestServer = {
                id: formValues.id,
                name: formValues.name,
                tags: this.tags,
                hosts: formValues.host,
                paths: formValues.paths,
                headers: formValues.headers,
                path_handling: formValues.pathHandling,
                https_redirect_status_code: formValues.redirectStatusCode,
                regex_priority: formValues.regexPriority,
                methods: this.tagsMethods,
                strip_path: formValues.stripPath,
                preserve_host: formValues.preserveHost,
                protocols: this.tagsProtocols,
                snis: formValues.snis,
                sources: formValues.sources,
                destinations: formValues.destinations,
                request_buffering: formValues.requestBuffering,
                response_buffering: formValues.responseBuffering,
            };
            this.translate.get('routers.message').subscribe(translate => {
                this.routerService.updateRouter(requestServer)
                    .subscribe(() => {
                        this.toastr.success(translate.updated);
                        this.dialog.close();
                    }, (err) => {
                        this.toastr.error(translate.error);
                        console.error(err);
                    });
            });
        }
    }

    updateForm(row: any): void {
        this.tagsMethods = row.methods;
        this.tagsProtocols = row.protocols;
        this.formRouter.patchValue({
            id: row.id,
            name: row.name,
            tags: this.tags,
            hosts: row.host,
            paths: row.paths,
            headers: row.headers,
            pathHandling: row.path_handling,
            redirectStatusCode: row.https_redirect_status_code,
            regexPriority: row.regex_priority,
            stripPath: row.strip_path,
            preserveHost: row.preserve_host,
            snis: row.snis,
            sources: row.sources,
            destinations: row.destinations,
            requestBuffering: row.request_buffering,
            responseBuffering: row.response_buffering,
        });
    }

    addTag(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTag(tag: string): void {
        const index = this.tags.indexOf(tag);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    removeDestinatios(destination: string): void {
        const index = this.destinations.indexOf(destination);
        if (index >= 0) {
            this.destinations.splice(index, 1);
        }
    }

    addTagHosts(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tagsHosts.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTagHosts(tag: string): void {
        const index = this.tagsHosts.indexOf(tag);
        if (index >= 0) {
            this.tagsHosts.splice(index, 1);
        }
    }

    addTagPaths(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tagsPaths.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTagPaths(tag: string): void {
        const index = this.tagsPaths.indexOf(tag);
        if (index >= 0) {
            this.tagsPaths.splice(index, 1);
        }
    }

    addDestination(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.destinations.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    addTagProtocols(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tagsProtocols.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTagProtocols(tag: string): void {
        const index = this.tagsProtocols.indexOf(tag);
        if (index >= 0) {
            this.tagsProtocols.splice(index, 1);
        }
    }

    addTagMethods(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tagsMethods.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTagMethods(tag: string): void {
        const index = this.tagsMethods.indexOf(tag);
        if (index >= 0) {
            this.tagsMethods.splice(index, 1);
        }
    }
}
