<div class="row">
  <div class="offset-md-1 col-md-10 col-12">
    <div class="card">
      <form [formGroup]="formDatos" (ngSubmit)="crearNuevoUsuario()">
        <div class="row">
          <div class="col-12">
            <h3>{{ 'userManagement.header.createLabel' | translate }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="userName">{{ 'userManagement.detalle.username' | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text" formControlName="login" id="login"/>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, 'login')">{{ error.message  | translate }}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <label for="name">{{ 'userManagement.detalle.firstName' | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text" formControlName="firstName" id="firstName"/>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, 'firstName')">{{ error.message  | translate }}</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="lastName">{{ 'userManagement.detalle.lastName' | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text" formControlName="lastName" id="lastName"/>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, 'lastName')">{{ error.message  | translate }}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <label for="email">{{ 'userManagement.detalle.email' | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text" formControlName="email" id="email"/>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, 'email')">{{ error.message  | translate }}</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label>{{ 'userManagement.detalle.profiles' | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <mat-select formControlName="authorities" #auth>
                <mat-option *ngFor="let opt of optionsAuth" [value]="opt.value">{{ opt.view | translate }}</mat-option>
              </mat-select>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, 'authorities')">{{ error.message  | translate }}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <label for="imageUrl">{{ 'userManagement.detalle.imageUrl' | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text" formControlName="imageUrl" id="imageUrl"/>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, 'imageUrl')">{{ error.message  | translate }}</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="environments">{{ 'connections.modal.environment' | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <mat-select class="select-multiple" [formControl]="environmentsControl" (selectionChange)="selectEnvironment($event)" [compareWith]="compareEnvironments">
                <mat-select-trigger>
                  <mat-chip-list>
                    <mat-chip *ngFor="let env of selectedEnvironments" [removable]="true" (removed)="removeEnvironment(env)" selected>
                      {{ env.environment }}
                      <button mat-icon-button matChipRemove style="position: relative; top: -12px;">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                  </mat-chip-list>
                </mat-select-trigger>
                <mat-option *ngFor="let env of environmentsToSelect" [value]="env">{{ env.environment }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <label>{{ 'userManagement.detalle.state' | translate }}</label>
            <div class="w-100">
              <mat-slide-toggle formControlName="activated">
                {{ user?.activated ? ('userManagement.detalle.enabled' | translate) : ('userManagement.detalle.disabled' | translate) }}
              </mat-slide-toggle>
            </div>
          </div>
          <div class="col-md-3">
            <label>{{ 'userManagement.detalle.isAuditor' | translate }}</label>
            <div class="w-100">
              <mat-slide-toggle (change)="selectAuditRole($event)">
                {{ userHasAuditRole ? ('userManagement.detalle.enabled' | translate) : ('userManagement.detalle.disabled' | translate) }}
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12" *ngIf="auth.value == 'ROLE_USER'">
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{'userManagement.permission.globalScope'|translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <app-dynamic-table [data]="dataSourceGlobal" [columnsSetup]="columnsSetup" [showPage]="false">
                  <ng-container matColumnDef="deny">
                    <mat-header-cell *matHeaderCellDef scope="col"> {{'userManagement.permission.deny' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let row;">
                      <mat-radio-group [name]="row.name" *ngIf="row.permissions.deny" aria-label="Select an option">
                        <mat-radio-button [name]="row.menu" value="DENY" [checked]="row.permission == 'DENY' || false" (change)="changeSelect(row.menu, 'DENY')"></mat-radio-button>
                      </mat-radio-group>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="read">
                    <mat-header-cell *matHeaderCellDef scope="col"> {{'userManagement.permission.read' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let row;">
                      <mat-radio-group [name]="row.name" *ngIf="row.permissions.read" aria-label="Select an option">
                        <mat-radio-button [name]="row.menu" value="READ" [checked]="row.permission == 'READ' || false" (change)="changeSelect(row.menu, 'READ')"></mat-radio-button>
                      </mat-radio-group>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="write">
                    <mat-header-cell *matHeaderCellDef scope="col"> {{'userManagement.permission.write' | translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let row;">
                      <mat-radio-group [name]="row.name" *ngIf="row.permissions.write" aria-label="Select an option">
                        <mat-radio-button [name]="row.menu" value="WRITE" [checked]="row.permission == 'WRITE' || false" (change)="changeSelect(row.menu, 'WRITE')"></mat-radio-button>
                      </mat-radio-group>
                    </mat-cell>
                  </ng-container>

                </app-dynamic-table>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12" *ngIf="auth.value == 'ROLE_USER'">
            <mat-card *ngIf="!isEmptyEnvironment()">
              <mat-card-header>
                <mat-card-title>{{'userManagement.permission.environmentScope' | translate}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <ng-container formArrayName="environmentPermissions">
                  <mat-accordion class="example-headers-align">
                    <ng-container *ngFor="let env of environmentsToShow; let i = index; let last = last; let first = first;">
                      <mat-expansion-panel [expanded]="step == i" hideToggle>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            {{env.environment}}
                          </mat-panel-title>
                          <mat-panel-description>
                            Seleccione los permisos para el ambiente {{env.environment}}
                            <mat-icon>account_circle</mat-icon>
                          </mat-panel-description>
                        </mat-expansion-panel-header>

                        <app-environment-permission [dataSource]="dataSource" [selectedEnvironment]="env"
                          (onSelectPermission)="selectPermission($event)"></app-environment-permission>

                        <mat-action-row>
                          <button class="btn btn-action mr-3" type="button" (click)="backStep()" *ngIf="!first">Anterior</button>
                          <button class="btn btn-action mr-3" type="button" (click)="nextStep()" *ngIf="!last">Siguiente</button>
                          <button class="btn btn-action" type="button" style="width: 160px;" *ngIf="last" (click)="finish()">
                            {{ 'userManagement.detalle.finish' | translate }}
                          </button>
                        </mat-action-row>
                      </mat-expansion-panel>

                    </ng-container>
                  </mat-accordion>
                </ng-container>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="text-right mt-4">
          <button class="btn btn-action" type="submit" style="width: 160px;">{{ 'userManagement.detalle.guardar' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</div>
