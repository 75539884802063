import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ICategory} from '../../pages/developer-portal/catalog/catalog.model';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
    @Input() categories: ICategory[] = [];

    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
    }

    getMoreCategories(): string {
        return this.translate.instant('catalog.categories.more', {count: this.categories.length - 1});
    }
}
