<perfect-scrollbar *ngIf="this.nextConfig['layout'] === 'vertical'" (clickOutside)="navMob()" [excludeBeforeClick]="true" [exclude]="'#mobile-collapse'" id="nav-ps-next" ngClass="able-pro-scroll" [style.max-width]="'300px'" [style.max-height]="'calc(100vh - 60px)'" [usePSClass]="'next'" [disabled]="null">
    <div class="navbar-content">
        <div class="">
            <div class="main-menu-header">
                <img class="img-radius" [src]="imageProfile" alt="User-Profile-Image"/> <!-- onError="this.src='assets/images/user/img-default.jpg'"/>-->
                <div class="user-details">
                    <div id="more-details" style="text-transform: capitalize">{{ user?.firstName }} {{ user?.lastName }}</div>
                </div>
            </div>
            <!-- <div class="collapse" id="nav-user-link" *ngIf="isNavProfile" [style.display]="'block'">
              <ul class="list-unstyled">
                <hr style="margin-bottom: 0rem; margin-top: 1rem;" />
                <li class="list-group-item"><a href='/users/admin/profile'><i class="feather icon-user m-r-5"></i>View Profile</a></li>
                <li class="list-group-item"><a href='/users/admin/cambioclave'><i class="feather icon-user m-r-5"></i>Cambiar Contraseña</a></li>
                <li class="list-group-item" (click)="salir()"><a href="javascript:"><i class="feather icon-log-out m-r-5"></i>Logout</a></li>
                <hr style="margin-top: 0rem; margin-bottom: 0rem;" />
              </ul>
            </div> -->
        </div>
        <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
            <ng-container *ngFor="let item of navigation">
                <app-nav-group *ngIf="item.type=='group' && userHasAuthorityToGroup(item)" [item]="item"></app-nav-group>
                <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
                <app-nav-item *ngIf="item.type=='item' && userHasPermissionToItem(item)" [item]="item"></app-nav-item>
            </ng-container>
        </ul>
    </div>
</perfect-scrollbar>

<div *ngIf="this.nextConfig['layout'] === 'horizontal'" #navbarWrapper class="navbar-content sidenav-horizontal" id="layout-sidenav">
    <a href="javascript:" class="sidenav-horizontal-prev" [ngClass]="prevDisabled" (click)="scrollMinus()"></a>
    <div class="sidenav-horizontal-wrapper"><!-- add 14-4 viral -->
        <ul #navbarContent id="side-nav-horizontal" class="nav pcoded-inner-navbar sidenav-inner" (clickOutside)="fireLeave()" (mouseleave)="fireLeave()">
            <ng-container *ngFor="let item of navigation">
                <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
                <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
                <app-nav-item *ngIf="item.type=='item' && userHasPermissionToItem(item)" [item]="item"></app-nav-item>
            </ng-container>
        </ul>
    </div>
    <a href="javascript:" class="sidenav-horizontal-next" [ngClass]="nextDisabled" (click)="scrollPlus()"></a>
</div>
