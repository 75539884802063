import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: 'portal',
        loadChildren: () => import('../developer-portal/portal/portal.module').then(module => module.PortalModule)
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DeveloperPortalRoutingModule {
}
