<app-header-pages title="{{'plugins.services.title' | translate:{id: service.id} }}" subTitle="{{'plugins.services.subTitle' | translate }}" class="margin-top0"></app-header-pages>

<div class="row margin-top0">
    <div class="col-2">
        <app-service-menu [service]="service"></app-service-menu>
    </div>
    <div class="col-10">
        <app-card [hidHeader]="true" headerClass="full-width">
            <div class="d-flex justify-content-between mb-2">
                <button class="btn btn-action" (click)="addPlugin()" [disabled]="indexTab!=0">
                    <mat-icon>add</mat-icon>
                    {{'plugins.services.addPlugin'|translate}}
                </button>
            </div>
            <mat-tab-group (selectedTabChange)="selectionChange($event)" [selectedIndex]="indexTab">
                <mat-tab>
                    <ng-template backgroundColor="primary" mat-tab-label>
                        <button mat-icon-button matRipple type="button">
                            <i class="feather icon-box"></i>
                        </button>
                        {{ 'plugins.tabs.service' | translate }}
                    </ng-template>
                    <app-dynamic-table [data]="dataSource" [columnsSetup]="columnsSetup" [spinner]="spinner" pageSize="10">
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef scope="col" class="column-flex-7"></mat-header-cell>
                            <mat-cell *matCellDef="let row;" class="column-flex-7">
                                <mat-slide-toggle [(ngModel)]="row.enabled" color="primary" (change)="changeStatus($event, row)"></mat-slide-toggle>
                                <button class="btn btn-icon" (click)="getDetailsPlugin(row)">
                                    <mat-icon>remove_red_eye</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef scope="col">{{ 'plugins.table.name' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let row;">
                                <a>{{ row.name }} </a>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="consumer">
                            <mat-header-cell *matHeaderCellDef scope="col" class="column-flex-10">{{ 'plugins.table.consumer' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let row;" class="column-flex-10">
                                <span>{{row.consumer | nulleable}}</span>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="delete">
                            <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
                            <mat-cell *matCellDef="let row;">
                                <button class="btn btn-delete" (click)="deletePlugin(row.id)">
                                    <mat-icon class="delete">delete</mat-icon>
                                    {{ 'plugins.table.delete' | translate }}</button>
                            </mat-cell>
                        </ng-container>
                    </app-dynamic-table>
                </mat-tab>
                <mat-tab *ngFor="let tab of tabs; let index = index" backgroundColor="primary">
                    <ng-template backgroundColor="primary" mat-tab-label>
                        {{accion}}
                        <button (click)="removeTab(index)" class="close-button" mat-icon-button matRipple type="button">
                            <mat-icon style="font-size: 25px;position: relative;top: 10px;">close</mat-icon>
                        </button>
                    </ng-template>
                    <app-select-plugin type="service" [typeId]="service.id"></app-select-plugin>
                </mat-tab>
            </mat-tab-group>
        </app-card>
    </div>
</div>

