import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiModel} from '../../../core/model/api.model';
import {ApiService} from '../../../services/gateway/api.service';

@Injectable({providedIn: 'root'})
export class ApiResolver implements Resolve<ApiModel> {
    constructor(private service: ApiService, private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApiModel> {
        const environment = route.params.environment;
        const identity = route.params.identity;
        if (identity && environment) {
            return this.service.getApi(identity, environment);
        }
        return of(route.data as ApiModel);
    }
}
