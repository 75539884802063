import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-accesible-routes',
    templateUrl: './accesible-routes.component.html',
    styleUrls: ['./accesible-routes.component.scss']
})
export class AccesibleRoutesComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
