<div class="box">
  <div class="content-button">
    <button class="btn btn-flat" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button class="btn btn-action mb-3" (click)="save()">
      <mat-icon>save</mat-icon>
      {{ "spec.save" | translate }}
    </button>
  </div>
  <div id="swagger-editor"></div>
</div>
