export interface Dashboard {
    database: Database;
    server: Server;
}

export interface Database {
    reachable: boolean;
}

export interface Server {
    connections_active: number;
    connections_reading: number;
    connections_writing: number;
    connections_waiting: number;
    connections_accepted: number;
    connections_handled: number;
    total_requests: number;
}

export interface NodeInformation {
    hostname: string;
    lua_version: string;
    node_id: string;
    tagline: string;
    version: string;
    plugins: Plugins;
    configuration: Configuration;
    timers: Timers;
}

export interface Timers {
    pending: number;
    running: number;
}


export interface Plugins {
    enabled_in_cluster: string[];
    available_on_server: Record<string, boolean>;
}

export interface AvailableOnServer {
    enabled_in_cluster: string;
    lua_version: string;
    node_id: string;
    tagline: string;
    version: string;
}

export interface Configuration {
    database: string;
    pg_host: string;
    pg_database: string;
    pg_user: string;
    pg_port: number;
    admin_listen: string[];
}

export const defaultDashboardValues: Dashboard = {
    database: {reachable: false},
    server: {
        connections_accepted: 0,
        connections_active: 0,
        connections_handled: 0,
        connections_reading: 0,
        connections_waiting: 0,
        connections_writing: 0,
        total_requests: 0
    }
};

export interface NotificationMessage {
    message: string;
    success: boolean;
}

export const defaultNodeInformationValues: NodeInformation = {
    hostname: '', node_id: '', lua_version: '', tagline: '', version: '',
    configuration: {database: '', pg_database: '', admin_listen: [], pg_host: '', pg_port: 0, pg_user: ''},
    timers: {pending: 0, running: 0},
    plugins: {available_on_server: {}, enabled_in_cluster: []}
};
