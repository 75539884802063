<app-header-pages title="{{'consumers.jwt.title' | translate }}" subTitle="{{'consumers.jwt.subTitle' | translate }}"></app-header-pages>
<div>
    <button class="btn btn-action btn-add-credential" (click)="addJwt()">
        <mat-icon>add</mat-icon>
        {{'consumers.jwt.new'|translate}}
    </button>
</div>
<app-dynamic-table [data]="jwtCredentials" [columnsSetup]="columnsSetup" [spinner]="spinner" [showPage]="false">
    <ng-container matColumnDef="json">
        <mat-header-cell *matHeaderCellDef scope="col">{{'consumers.jwt.table.json'|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <div style="margin: 5px 6px;">
                <pre [innerHTML]="row | prettyprint"></pre>
            </div>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
        <mat-cell *matCellDef="let row;">
            <button class="btn btn-delete" (click)="deleteJwt(row.id)">
                <mat-icon class="delete">delete</mat-icon>
                {{ 'consumers.table.delete' | translate }}</button>
        </mat-cell>
    </ng-container>
</app-dynamic-table>
