import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UsersRoutingModule} from './users-routing.module';
import {ListUsersComponent} from './list-users/list-users.component';
import {ProfileComponent} from './profile/profile.component';
import {NgbCarouselModule, NgbDropdownModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {LightboxModule} from 'ngx-lightbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SelectModule} from 'ng-select';
import {SelectOptionService} from 'src/app/theme/shared/components/select/select-option.service';
import {CreateUserComponent} from './create-user/create-user.component';
import {ViewUserComponent} from './view-user/view-user.component';
import {CambioclaveComponent} from './cambioclave/cambioclave.component';
import {TranslateModule} from '@ngx-translate/core';
import {ThemeModule} from 'src/app/theme/theme.module';
import {MaterialModule} from '../../../material.module';
import {ComponentsModule} from '../../../components/components.module';
import {EditUserComponent} from './edit-user/edit-user.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {EnvironmentPermissionComponent} from './environment-permission/environment-permission.component';


@NgModule({
    declarations: [ListUsersComponent, ProfileComponent, CreateUserComponent, ViewUserComponent, CambioclaveComponent, EditUserComponent, EnvironmentPermissionComponent],
    imports: [
        CommonModule,
        UsersRoutingModule,
        ThemeModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbCarouselModule,
        LightboxModule,
        ReactiveFormsModule,
        SelectModule,
        FormsModule,
        MaterialModule,
        ComponentsModule,
        TranslateModule,
        MatExpansionModule,
        MatChipsModule
    ],
    providers: [SelectOptionService]
})
export class UsersModule {
}
