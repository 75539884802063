import { Component, OnInit } from '@angular/core';
import { Service } from '../../../../core/model/service.model';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from '../../../../services/gateway/services.service';
import { ToastrService } from 'ngx-toastr';
import { RoutesModel } from '../../../../core/model/routes.model';
import { ColumnSetup } from '../../../../components/dynamic-table/dynamic-table.component';
import { DatePipe } from '@angular/common';
import { SweetAlertComponent } from '../../../../components/sweet-alert/sweet-alert.component';
import { RouterService } from '../../../../services/gateway/router.service';
import { MatDialog } from '@angular/material/dialog';
import { RouterServiceOptionsComponent } from './router-options/router-service-options.component';
import { AddRouteServiceComponent } from './add-route-service/add-route-service.component';
import { TranslateService } from '@ngx-translate/core';
import { SchemasService } from 'src/app/services/gateway/schemas.service';

export interface RowItem {
  id: string;
  name: string;
  host: string;
  headers: string;
  tags: string[];
  paths: string[];
  methods: string[];
  path_handling: string;
  destinations: string;
  created_at: string;
  sources: string;
  snis: string;
  service: string;
  protocols: string[];
  route: any;
  https_redirect_status_code: number;
  regex_priority: number;
  strip_path: boolean;
  preserve_host: boolean;
  updated_at: number;
}

@Component({
  selector: 'app-service-routes',
  templateUrl: './service-routes.component.html',
  styleUrls: ['./service-routes.component.scss'],
})
export class ServiceRoutesComponent implements OnInit {
  service: Service;
  routes: RoutesModel[] = [];
  public spinner = false;
  public dataSource: RowItem[] = [];
  public columnsSetup: ColumnSetup[] = [
    { columnDef: 'actions', title: '', custom: true },
    { columnDef: 'name', title: '', custom: true },
    {
      columnDef: 'hosts',
      title: 'routers.table.hosts',
      classes: 'column-flex-6',
      custom: true,
    },
    { columnDef: 'methods', title: '', custom: true },
    {
      columnDef: 'paths',
      title: 'routers.table.paths',
      classes: 'column-flex-7',
      cell: (row: RowItem) => `${row.paths || ''}`,
    },
    {
      columnDef: 'createdDate',
      title: 'routers.table.createdDate',
      classes: 'column-flex-6',
      cell: (row: RowItem) =>
        `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`,
    },
    { columnDef: 'delete', title: '', custom: true },
  ];

  constructor(
    protected activatedRoute: ActivatedRoute,
    private servicesService: ServicesService,
    private toastr: ToastrService,
    private dateFormat: DatePipe,
    private routerService: RouterService,
    private schemasService: SchemasService,
    private sweet: SweetAlertComponent,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ service }) => {
      this.service = service;
      this.getRoutesFromService(service.id);
    });
  }

  getRoutesFromService(id): void {
    this.dataSource = [];
    this.spinner = true;
    this.servicesService
      .getServiceRoutes(id)
      .subscribe((routes: RoutesModel[]) => {
        this.spinner = false;
        this.routes = routes || [];
        this.dataSource = routes as RowItem[];
      });
  }

  showOptions(row): void {
    const dialogRef = this.dialog.open(RouterServiceOptionsComponent, {
      height: 'auto',
      width: '750px',
      disableClose: true,
      data: row,
    });
    dialogRef.componentInstance.title = 'routers.modal.title_show';
    dialogRef.componentInstance.buttonCancel = 'routers.modal.button_cancel';
  }

  deleteRouter(id: string): void {
    this.translate.get('routers.message').subscribe((translate) => {
      this.sweet.confirmBox({
        title: translate.confirm.title,
        text: translate.confirm.text,
        confirmButtonText: translate.confirm.button,
        showCancelButton: true,
        alertAction: (result) => {
          if (result.isConfirmed) {
            this.routerService.deleteRouter(id).subscribe(
              () => {
                this.toastr.success(translate.deleted);
                this.getRoutesFromService(this.service.id);
              },
              (err) => {
                console.log(err);
                this.sweet.error({
                  title: translate.error,
                });
              }
            );
          }
        },
      });
    });
  }

  addRouteToService(): void {
    // SEARCH SCHEMA BEFORE
    this.schemasService.getRouters().subscribe(
      (response) => {
        const schema = response.fields;
        const dialogRef = this.dialog.open(AddRouteServiceComponent, {
          height: 'auto',
          width: '750px',
          disableClose: true,
          data: { serviceId: this.service.id, schema },
        });
        dialogRef.componentInstance.title = 'routers.modal.title_open';
        dialogRef.componentInstance.buttonCancel =
          'routers.modal.button_cancel';
        dialogRef.componentInstance.buttonCreate =
          'routers.modal.button_create';
        dialogRef.afterClosed().subscribe(() => {
          this.getRoutesFromService(this.service.id);
        });
      },
      (err) => {}
    );
  }

  editRoute(row): void {
    this.schemasService.getRouters().subscribe(
      (response) => {
        const schema = response.fields;
        const dialogRef = this.dialog.open(AddRouteServiceComponent, {
          height: 'auto',
          width: '750px',
          disableClose: true,
          data: { serviceId: this.service.id, schema, route: row },
        });
        dialogRef.componentInstance.title = 'routers.modal.title_open';
        dialogRef.componentInstance.buttonCancel =
          'routers.modal.button_cancel';
        dialogRef.componentInstance.buttonEdit = 'routers.modal.button_edit';
        dialogRef.afterClosed().subscribe(() => {
          this.getRoutesFromService(this.service.id);
        });
      },
      (err) => {}
    );
  }
}
