import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Service} from '../../../../core/model/service.model';

@Component({
    selector: 'app-modal-options',
    templateUrl: './modal-options.component.html',
    styleUrls: ['./modal-options.component.scss']
})
export class ModalOptionsComponent implements OnInit {
    title = '';
    buttonCancel = '';
    service: Service;

    constructor(public dialog: MatDialogRef<ModalOptionsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.service = data;
    }

    ngOnInit(): void {
    }

}
