import {AfterContentInit, AfterViewInit, Component, ContentChildren, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatColumnDef, MatTable, MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

export interface ColumnSetup {
    columnDef: string;
    title?: string;
    classes?: string;
    cell?: CallableCell;
    custom?: boolean;
    sticky?: boolean;
}

export type CallableCell = (row: any) => string;

@Component({
    selector: 'app-dynamic-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: ['./dynamic-table.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTableComponent<T> implements OnInit, OnChanges, AfterContentInit, AfterViewInit {

    // Table
    @Input() columnsSetup: ColumnSetup[] = []; // Información de las columnas de la tabla (nombre, titulo y tipo)
    @Input() data: T[] = [];
    dataSource: MatTableDataSource<T> = new MatTableDataSource<T>(this.data);  // Datos de la tabla

    // Loading
    @Input() spinner = false;

    // Paginacion
    @Input() pageSize = 10;
    @Input() showPage = true;

    // Filter
    @Input() filterFn: (data: T, filter: string) => boolean;
    @Input() filterText = '';
    @Input() classes = '';
    @Input() noData = '';

    // MatTable
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatTable, {static: true}) table: MatTable<T>;                     // Referencia a la tabla
    @ViewChild(MatSort) sort: MatSort;

    @ContentChildren(MatColumnDef, {descendants: true}) columnDefs: QueryList<MatColumnDef>;        // Nuevas columnas desde el ng-content

    constructor() {
    }

    // Todas las colunas menos las custom
    get columns(): ColumnSetup[] {
        return this.columnsSetup.filter(column => column.custom !== true);
    }

    // Todas las colunas en un Array de string
    get displayedColumns(): string[] {
        return this.columnsSetup.map(column => column.columnDef);
    }

    // Todas las columnas custom
    get displayedColumnsCustom(): string[] {
        return this.columnsSetup.filter(column => column.custom).map(def => def.columnDef);
    }

    // Se refresca los datos de la tabla
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.dataSource.data = changes.data.currentValue;
            setTimeout(() => {
                if (this.showPage === true) {
                    this.dataSource.paginator = this.paginator;
                }
                this.dataSource.sort = this.sort;
            });
        }
        if (changes.filterText) {
            this.dataSource.filter = this.filterText;
        }
    }

    // Se agregan las columnas custom a la tabla
    ngAfterContentInit(): void {
        if (this.displayedColumnsCustom.length !== 0) {
            this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
        }
    }

    ngAfterViewInit(): void {
        // this.renderedData.subscribe(() => {
        //   if (this.showPage === true) {
        //     this.dataSource.paginator = this.paginator;
        //   }
        //   this.dataSource.sort = this.sort;
        // });
    }

    ngOnInit(): void {
        // Se inicia la table con los datos actuales
        // this.dataSource.filterPredicate = this.filterFn;
        this.dataSource.data = this.data;
        setTimeout(() => {
            if (this.showPage === true) {
                this.dataSource.paginator = this.paginator;
            }
            this.dataSource.sort = this.sort;
        });
    }

}
