<div class="container">
    <div class="row margin-top0">
        <div class="col-2">
            <app-upstream-menu [upstream]="upstream"></app-upstream-menu>
        </div>
        <div class="col-10">
            <app-card [hidHeader]="true" headerClass="full-width">
                <div class="d-flex justify-content-between">
                    <button class="btn btn-action" (click)="createTarget()">
                        <mat-icon>add</mat-icon>
                        {{'upstreams.table.newTarget'|translate}}
                    </button>
                    <!--    <app-filter-table #filter></app-filter-table>-->
                </div>
                <app-dynamic-table [data]="dataSource" [columnsSetup]="columnsSetup" [spinner]="spinner" pageSize="10" noData="{{'upstreams.table.noData'|translate}}" [showPage]="false">
                    <ng-container matColumnDef="tags">
                        <mat-header-cell *matHeaderCellDef scope="col">{{ 'upstreams.table.tags' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let row;">
                            <app-tags [tags]="row.tags"></app-tags>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                        <mat-header-cell *matHeaderCellDef scope="col"></mat-header-cell>
                        <mat-cell *matCellDef="let row;">
                            <button class="btn btn-delete" (click)="deleteTarget(row.id)">
                                <mat-icon class="delete">delete</mat-icon>
                                {{ 'upstreams.table.delete' | translate }}</button>
                        </mat-cell>
                    </ng-container>
                </app-dynamic-table>
            </app-card>
        </div>
    </div>
</div>
