import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {endpoint} from '../../core/constants/services.constants';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RoutesModel} from '../../core/model/routes.model';

@Injectable({
    providedIn: 'root'
})
export class RouterService {

    private URL_BASE: string = environment.url_services;
    private ENDPOINT_ROUTER = `${this.URL_BASE}${endpoint.ENDPOINT_ROUTER}`;
    private ENDPOINT_KONG_ROUTER = `${this.URL_BASE}${endpoint.KONG_ROUTER}`;

    constructor(private http: HttpClient) {
    }

    getRouters(): Observable<any> {
        return this.http.get(`${this.ENDPOINT_KONG_ROUTER}`).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    getRoutersById(id: string): Observable<HttpResponse<RoutesModel>> {
        return this.http.get<RoutesModel>(`${this.ENDPOINT_KONG_ROUTER}/${id}`, {observe: 'response'});
    }

    createRouter(body: any): Observable<any> {
        return this.http.post(this.ENDPOINT_KONG_ROUTER, body);
    }

    updateRouter(body: any): Observable<any> {
        return this.http.put(`${this.ENDPOINT_KONG_ROUTER}/${body.id}`, body);
    }

    deleteRouter(id: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_ROUTER}/${id}`);
    }

    statusRouter(id: string, status): Observable<any> {
        const params = new HttpParams().append('status', status);
        return this.http.post(`${this.ENDPOINT_ROUTER}/${id}/activate`, params);
    }

    preserveHost(body: any): Observable<any> {
        return this.http.put(this.ENDPOINT_ROUTER, body);
    }
}
