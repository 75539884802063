<form [formGroup]="formOauth" (ngSubmit)="submit()">
    <div class="container">
        <h1 mat-dialog-title>{{'consumers.oauth.modal.title' | translate }}</h1>
        <hr>
        <div mat-dialog-content>
            <div class="row">
                <div class="col-12">
                    <label>{{ 'consumers.oauth.modal.name.placeholder' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="name"/>
                        <mat-hint>{{ 'consumers.oauth.modal.name.hint' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'name')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'consumers.oauth.modal.clientId.placeholder' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="clientId"/>
                        <mat-hint>{{ 'consumers.oauth.modal.clientId.hint' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'clientId')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'consumers.oauth.modal.clientSecret.placeholder' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="clientSecret"/>
                        <mat-hint>{{ 'consumers.oauth.modal.clientSecret.hint' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'clientSecret')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'consumers.oauth.modal.redirectUris.placeholder' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipList aria-label="Fruit selection">
                            <mat-chip *ngFor="let url of urls" [selectable]="true" [removable]="true" (removed)="removeTag(url)">
                                {{url}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'consumers.oauth.modal.redirectUris.placeholder' | translate }}"
                                    formControlName="redirectUris"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTag($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'consumers.oauth.modal.redirectUris.hint' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'redirectUris')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div mat-dialog-actions align="end" class="mt-2 pl-3">
            <button class="btn btn-close" [mat-dialog-close]="undefined">{{ 'consumers.modal.buttonCancel' | translate }} </button>
            <button class="btn btn-action" [disabled]="formOauth.invalid">{{ 'consumers.oauth.save' | translate }}</button>
        </div>
    </div>
</form>
