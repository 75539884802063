export interface Connection {
    id: string;
    nombre: string;
    enviroment: string;
    url: string;
    authType: string;
    apiKey: string;
    apiKeyHeaderName: string;
    algoritmo: string;
    activo: boolean;
    createdDate: Date;
    userName: string;
    password: string;
    key: string;
    secret: string;
}

export interface ProgressStatus {
    status: ProgressStatusEnum;
    percentage?: number;
}

export interface IEnvironmentOrganization {
  environment: string;
  organization: string;
}

export enum ProgressStatusEnum {
    START,
    COMPLETE,
    IN_PROGRESS,
    ERROR
}
