<div class="btn-group mt-3" role="group" aria-label="Basic example">
  <button
    type="button"
    class="btn btn-secondary"
    *ngIf="this.upload"
    (click)="handleCallbackUpload()"
  >
    <span>Upload new spec</span>
  </button>
  <button
    matStepperPrevious
    type="button"
    class="btn btn-secondary"
    *ngIf="this.stepperBack"
  >
    Back
  </button>
  <button
    matStepperNext
    type="button"
    class="btn btn-action"
    [disabled]="!condition"
    *ngIf="this.stepperNext"
    (click)="handleCallback()"
  >
    <span
      *ngIf="this.spinner"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    <span *ngIf="!this.spinner">{{ this.text }}</span>
  </button>
</div>
