import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpecService } from '../../../../../app/services/gateway/spec.service';
import { Spec, SpecCreate } from '../../../../../app/core/model/spec.model';
import { tap } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';

@Component({
  selector: 'modal-spec-content',
  templateUrl: './modal-spec.component.html',
  styleUrls: ['./modal-spec.component.scss'],
})
export class ModalSpecComponent implements OnInit, OnDestroy {
  public formService: FormGroup;
  public parentId: string;
  public title: string;
  public modalOption = 'create-folder';
  public selectedFiles!: FileList;
  public filePath = '';
  public file: File;
  public buttonAction = 'spec.modal.buttons.create';
  public fileName = 'Select your file';
  public loaded = 0;
  public spinner = false;

  constructor(
    private location: Location,
    private translate: TranslateService,
    private toastr: ToastrService,
    private specService: SpecService,
    public dialog: MatDialogRef<ModalSpecComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.formService = this.buildForm();
    if (this.data) {
      this.formService.patchValue({
        id: this.data.id,
        name: this.data.name,
      });
    }
  }

  ngOnDestroy(): void {
    this.spinner = false;
  }

  ngAfterViewInit(): void {}

  private prepareSpec(kind: 'Folder' | 'Doc'): SpecCreate {
    const formValues = this.formService.value;
    const spec: SpecCreate = {
      kind,
      name: formValues.name,
    };
    if (this.parentId) {
      spec.parentId = this.parentId;
    }
    return spec;
  }

  handlerName() {
    const reg = /[^A-Za-z0-9-_]/g;
    const formValues = this.formService.value;
    this.formService.patchValue({
      name: formValues.name.replace(reg, ''),
    });
  }

  buildForm(): FormGroup {
    if (this.modalOption === 'create-spec-file') {
      return new FormGroup({
        name: new FormControl(null, [Validators.required]),
        file: new FormControl(null, [Validators.required]),
      });
    }
    if (
      this.modalOption === 'create-folder' ||
      this.modalOption === 'edit-folder' ||
      this.modalOption === 'create-spec-text'
    ) {
      return new FormGroup({
        name: new FormControl(null, [Validators.required]),
      });
    }
  }

  submit(): void {
    if (this.formService.invalid) {
      this.translate.get('connections.message').subscribe((translate) => {
        this.toastr.error(translate.invalidForm);
        return;
      });
    }
    this.spinner = true;
    const formValues = this.formService.value;

    if (this.modalOption === 'create-spec-text') {
      this.translate.get('spec.message').subscribe((translate) => {
        this.specService
          .createSpec({
            ...this.prepareSpec('Doc'),
            openapi: this.data.text,
          })
          .subscribe(
            (res: HttpResponse<Spec>) => {
              if (res instanceof HttpResponse) {
                this.toastr.success(translate.created);
                this.dialog.close(true);
                this.location.back();
              }
            },
            (err) => {
              this.toastr.error(translate.error);
            }
          );
      });
    }

    if (this.modalOption === 'create-spec-file' && this.file) {
      this.translate.get('spec.message').subscribe((translate) => {
        this.specService
          .createSpec({
            ...this.prepareSpec('Doc'),
            openapiFile: this.file,
          })
          .subscribe(
            (res: HttpResponse<Spec>) => {
              if (res instanceof HttpResponse) {
                this.toastr.success(translate.created);
                this.dialog.close({
                  close: true,
                  response: res,
                });
              }
            },
            (err) => {
              this.toastr.error(translate.error);
            }
          );
      });
    }

    if (this.modalOption === 'create-folder') {
      this.translate.get('spec.message').subscribe((translate) => {
        this.specService
          .createSpec({
            ...this.prepareSpec('Folder'),
          })
          .subscribe(
            (res: HttpResponse<Spec>) => {
              if (res instanceof HttpResponse) {
                this.toastr.success(translate.created);
                this.dialog.close(true);
              }
            },
            (err) => {
              this.toastr.error(translate.error);
            }
          );
      });
    }

    if (this.modalOption === 'edit-folder') {
      this.translate.get('spec.message').subscribe((translate) => {
        this.specService
          .editFolder(this.data.id, { ...this.prepareSpec('Folder') })
          .subscribe(
            () => {
              this.toastr.success(translate.created);
              this.dialog.close(true);
            },
            (err) => {
              this.toastr.error(translate.error);
            }
          );
      });
    }
  }

  fileBrowseHandler(event: any): void {
    event.preventDefault();
    this.selectedFiles = event.target.files;
    if (this.selectedFiles.length > 0) {
      this.file = this.selectedFiles.item(0);
      this.filePath = this.file ? this.file.name : '';
      this.fileName = this.file.name;
    }
  }
}
