import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PluginsModel} from '../../../../core/model/plugins.model';

@Component({
    selector: 'app-plugin-details',
    templateUrl: './plugin-details.component.html',
    styleUrls: ['./plugin-details.component.scss']
})
export class PluginDetailsComponent implements OnInit {
    plugin: PluginsModel;
    title: string;
    buttonCancel: 'plugins.modal.buttonCancel';

    constructor(public dialog: MatDialogRef<PluginDetailsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.plugin = data;
    }

    ngOnInit(): void {
    }

}
