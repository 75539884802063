import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConsumerModel} from '../../../../core/model/consumer.model';

@Component({
    selector: 'app-consumer-options',
    templateUrl: './consumer-options.component.html',
    styleUrls: ['./consumer-options.component.scss']
})
export class ConsumerOptionsComponent implements OnInit {
    consumer: ConsumerModel;
    title = '';
    buttonCancel = '';

    constructor(public dialog: MatDialogRef<ConsumerOptionsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.consumer = data;
    }

    ngOnInit(): void {
    }
}
