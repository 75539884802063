import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatTab, MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {ConnectionsService} from 'src/app/services/apis/connections.service';
import {CheckUrlService} from 'src/app/services/apis/check-url.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {MessageError} from 'src/app/core/interfaces/message-error.interface';
import {TranslateService} from '@ngx-translate/core';
import {EnvironmentPermission} from '../connection.model';
import {LocalstorageService} from '../../../../services/localstorage.service';
import {ENVIRONMENT_PERMISSION} from '../../../../core/constants/app.constants';
import {SweetAlertComponent} from '../../../../components/sweet-alert/sweet-alert.component';

@Component({
    selector: 'app-modal-connection',
    templateUrl: './modal-connection.component.html',
    styleUrls: ['./modal-connection.component.scss']
})
export class ModalConnectionComponent implements OnInit, AfterViewInit {

    public tabIndex = 0;
    public formConnection: FormGroup;
    title = '';
    selectedEnvironment = '';
    isEdit = false;
    selectedTab = 0;
    buttonCreate = '';
    buttonEdit = '';
    buttonCancel = '';
    modalOption = 'create';
    MESSAGE_REQUIRED = 'connections.message.required';
    type: string;
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    @ViewChild(MatTabGroup) tabGroup;
    environments: EnvironmentPermission[] = [];

    constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        private connectionService: ConnectionsService,
        private checkUrl: CheckUrlService,
        private toastr: ToastrService,
        private sweet: SweetAlertComponent,
        private localstorageService: LocalstorageService,
        public dialog: MatDialogRef<ModalConnectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (this.data) {
            this.isEdit = true;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const tabs: MatTab[] = this.tabGroup._tabs._results;
            this.selectedTab = tabs.findIndex((element: MatTab) => element.ariaLabel === (this.data && this.data.authType));
        });
    }

    ngOnInit(): void {
        this.selectedEnvironment = this.localstorageService.getItem('environment');
        this.formConnection = this.buildForm(this.selectedEnvironment);
        this.environments = this.localstorageService.getItemObject<EnvironmentPermission[]>(ENVIRONMENT_PERMISSION);
        this.setValidatorsForm('', this.selectedEnvironment);
        if (this.data) {
            this.updateForm(this.data);
        }
    }

    setValidatorsForm(type: string, environment: string): void {
        this.cleanValidators();
        this.formConnection.get('algorithm').setValue('NONE');
        switch (type) {
            case 'JWT_AUTH':
                this.type = 'JWT_AUTH';
                this.formConnection = new FormGroup({
                    id: new FormControl(null, []),
                    nameConnection: new FormControl(null, [Validators.required, Validators.maxLength(15)]),
                    url: new FormControl(null, [Validators.required]),
                    apiKey: new FormControl(null, []),
                    keyJwt: new FormControl(null, [Validators.required]),
                    secret: new FormControl(null, [Validators.required]),
                    algorithm: new FormControl('HS256', [Validators.required]),
                    usernameBasic: new FormControl(null, []),
                    passwordBasic: new FormControl(null, []),
                    enviroment: new FormControl({value: environment, disabled: true}, Validators.required),
                    keyName: new FormControl(null, []),
                    authType: new FormControl('JWT_AUTH', []),
                });
                break;
            case 'KEY_AUTH':
                this.type = 'KEY_AUTH';
                this.formConnection = new FormGroup({
                    id: new FormControl(null, []),
                    nameConnection: new FormControl(null, [Validators.required, Validators.maxLength(15)]),
                    url: new FormControl(null, [Validators.required]),
                    apiKey: new FormControl(null, []),
                    keyJwt: new FormControl(null, []),
                    secret: new FormControl(null, []),
                    algorithm: new FormControl(null, []),
                    usernameBasic: new FormControl(null, []),
                    passwordBasic: new FormControl(null, []),
                    enviroment: new FormControl({value: environment, disabled: true}, Validators.required),
                    keyName: new FormControl(null, [Validators.required]),
                    authType: new FormControl('KEY_AUTH', []),
                });
                break;
            case 'BASIC_AUTH':
                this.type = 'BASIC_AUTH';
                this.formConnection = new FormGroup({
                    id: new FormControl(null, []),
                    nameConnection: new FormControl(null, [Validators.required]),
                    url: new FormControl(null, [Validators.required]),
                    apiKey: new FormControl(null, []),
                    keyJwt: new FormControl(null, []),
                    secret: new FormControl(null, []),
                    algorithm: new FormControl(null, []),
                    usernameBasic: new FormControl(null, [Validators.required]),
                    passwordBasic: new FormControl(null, [Validators.required]),
                    enviroment: new FormControl({value: environment, disabled: true}, Validators.required),
                    keyName: new FormControl(null, []),
                    authType: new FormControl('BASIC_AUTH', []),
                });
                break;
            default:
                this.type = '';
                this.formConnection = new FormGroup({
                    id: new FormControl(null, []),
                    nameConnection: new FormControl(null, [Validators.required, Validators.maxLength(15)]),
                    url: new FormControl(null, [Validators.required]),
                    apiKey: new FormControl(null, []),
                    keyJwt: new FormControl(null, []),
                    secret: new FormControl(null, []),
                    algorithm: new FormControl(null, []),
                    usernameBasic: new FormControl(null, []),
                    passwordBasic: new FormControl(null, []),
                    enviroment: new FormControl({value: environment, disabled: true}, Validators.required),
                    keyName: new FormControl(null, []),
                    authType: new FormControl('NONE', []),
                });
                break;
        }
        if (this.data) {
            this.updateForm(this.data);
        }
    }

    cleanValidators(): void {
        const keys = Object.keys(this.formConnection.value);
        keys.forEach(key => {
            this.formConnection.get(key).clearValidators();
        });
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.tabIndex = tabChangeEvent.index;
        const tabChange = tabChangeEvent.tab.ariaLabel;
        this.setValidatorsForm(tabChange, this.selectedEnvironment);
        this.formConnection.patchValue({
            authType: tabChangeEvent.tab.ariaLabel
        });
    }

    isValid(type: string, name: string): boolean {
        return this.formConnection.get(name).hasError(type);
    }

    buildForm(environment: string): FormGroup {
        return this.fb.group({
            id: new FormControl(null, []),
            nameConnection: new FormControl(null, []),
            url: new FormControl(null, []),
            apiKey: new FormControl(null, []),
            keyJwt: new FormControl(null, []),
            secret: new FormControl(null, []),
            algorithm: new FormControl(null, []),
            usernameBasic: new FormControl(null, []),
            passwordBasic: new FormControl(null, []),
            enviroment: new FormControl({value: environment, disabled: true}, Validators.required),
            keyName: new FormControl(null, []),
            authType: new FormControl('NONE', []),
        });
    }

    submit(form: AbstractControl): void {
        if (!this.isvalidForm()) {
            this.translate.get('connections.message').subscribe(translate => {
                this.toastr.error(translate.invalidForm);
                return;
            });
        } else {
            const formValues = form.value;
            if (this.modalOption === 'create') {
                this.translate.get('connections.message.create')
                    .subscribe(translation => this.sweet.confirmBox({
                        title: translation.title,
                        text: `${translation.text} ${this.selectedEnvironment} ${translation.question}`,
                        confirmButtonText: translation.button,
                        showCancelButton: true,
                        alertAction: (result) => {
                            if (result.isConfirmed) {
                                const requestServer = {
                                    id: formValues.id,
                                    nombre: formValues.nameConnection,
                                    enviroment: this.selectedEnvironment.trim(),
                                    url: formValues.url,
                                    authType: formValues.authType,
                                    algoritmo: formValues.algorithm,
                                    key: formValues.keyJwt,
                                    secret: formValues.secret,
                                    userName: formValues.usernameBasic,
                                    password: formValues.passwordBasic,
                                    apiKey: formValues.apiKey,
                                    apiKeyHeaderName: formValues.keyName,
                                    activo: false
                                };
                                this.translate.get('connections.message').subscribe(translate => {
                                    this.connectionService.createServer(requestServer)
                                        .subscribe(() => {
                                            this.toastr.success(translate.created);
                                            this.dialog.close();
                                        }, (err) => {
                                            this.toastr.error(translate.error);
                                            console.error(err);
                                        });
                                });
                            }
                        }
                    }));
            } else {
                const jwtValue = formValues.keyJwt;
                const secretValue = formValues.secret;
                const requestServer = {
                    id: formValues.id,
                    nombre: formValues.nameConnection,
                    enviroment: this.isEdit ? this.data.enviroment : (formValues.enviroment ? formValues.enviroment.trim() : null),
                    url: formValues.url,
                    authType: formValues.authType,
                    algoritmo: formValues.algorithm,
                    key: jwtValue,
                    secret: secretValue,
                    userName: formValues.usernameBasic,
                    password: formValues.passwordBasic,
                    apiKey: formValues.apiKey,
                    apiKeyHeaderName: formValues.keyName
                };
                this.translate.get('connections.message').subscribe(translate => {
                    this.connectionService.updateServer(requestServer)
                        .subscribe(() => {
                            this.toastr.success(translate.updated);
                            this.dialog.close();
                        }, (err) => {
                            this.toastr.error(translate.error);
                            console.error(err);
                        });
                });
            }
        }
    }

    updateForm(row: any): void {
        this.formConnection.patchValue({
            id: row?.id,
            nameConnection: row.nombre,
            enviroment: row.enviroment,
            url: row.url,
            authType: row.authType,
            algorithm: row.algoritmo,
            keyJwt: row.key,
            secret: row.secret,
            usernameBasic: row.userName,
            passwordBasic: row.password,
            apiKey: row.apiKey,
            keyName: row.apiKeyHeaderName,
            activo: row.activo
        });
    }

    isvalidForm(): boolean {
        switch (this.type) {
            case 'JWT_AUTH':
                return this.formConnection.get('nameConnection').valid && this.formConnection.get('url').valid && this.formConnection.get('secret').valid
                    && this.formConnection.get('algorithm').valid && this.formConnection.get('keyJwt').valid;
            case 'KEY_AUTH':
                return this.formConnection.get('nameConnection').valid && this.formConnection.get('url').valid && this.formConnection.get('apiKey').valid
                    && this.formConnection.get('keyName').valid;
            case 'BASIC_AUTH':
                return this.formConnection.get('nameConnection').valid && this.formConnection.get('url').valid && this.formConnection.get('usernameBasic').valid
                    && (this.isEdit ? true : this.formConnection.get('passwordBasic').valid);
            default:
                return this.formConnection.get('nameConnection').valid && this.formConnection.get('url').valid;
        }
    }
}
