import {AfterViewInit, Component, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {CheckUrlService} from 'src/app/services/apis/check-url.service';
import {HttpHeaders, HttpResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {MessageError} from 'src/app/core/interfaces/message-error.interface';
import {IPlugin, IPluginGroup} from '../../../../core/model/plugins.model';
import {PluginsService} from '../../../../services/gateway/plugins.service';
import {MatDialog} from '@angular/material/dialog';
import {ModalAddPluginComponent} from '../modal-add-plugin/modal-add-plugin.component';
import {KeyValue} from '@angular/common';
import {NodeInformation} from '../../../../core/model/dashboard.model';
import {Connection} from '../../../../core/model/connection.model';
import {LocalstorageService} from '../../../../services/localstorage.service';
import {TranslateService} from '@ngx-translate/core';
import {AddDatadogPluginComponent} from '../add-datadog-plugin/add-datadog-plugin.component';

class EventEmitter<T> {
}

@Component({
    selector: 'app-select-plugin',
    templateUrl: './select-plugin.component.html',
    styleUrls: ['./select-plugin.component.scss']
})
export class SelectPluginComponent implements OnInit, AfterViewInit {

    public tabIndex = 0;
    public formConnection: FormGroup;
    title = '';
    selectedTab = 0;
    buttonCreate = '';
    buttonEdit = '';
    buttonCancel = '';
    modalOption = 'create';
    MESSAGE_REQUIRED = 'plugins.message.required';
    plugins: IPluginGroup[] = [];
    @Input() type: 'global' | 'service' | 'route';
    @Input() typeId: string;
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    @ViewChild(MatTabGroup) tabGroup;
    @Output() addPluginToService = new EventEmitter<string>();
    readMore = false;
    nodeInformation: NodeInformation;
    otherPlugins: string[] = [];

    constructor(
        private translate: TranslateService,
        private pluginService: PluginsService,
        private checkUrl: CheckUrlService,
        private toastr: ToastrService,
        public dialog: MatDialog,
        private localStorageService: LocalstorageService
    ) {
    }

    ngAfterViewInit(): void {
        this.pluginService.getPluginsGroup().subscribe((res: HttpResponse<IPluginGroup[]>) => {
            this.plugins = res.body || [];
            this.loadOtherPlugins();
        });
    }

    ngOnInit(): void {
        this.setValidatorsForm('');
    }

    setValidatorsForm(type: string): void {
        this.cleanValidators();
    }

    cleanValidators(): void {
        const keys = Object.keys(this.formConnection.value);
        keys.forEach(key => {
            this.formConnection.get(key).clearValidators();
        });
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.tabIndex = tabChangeEvent.index;
        const tabChange = tabChangeEvent.tab.ariaLabel;
        this.setValidatorsForm(tabChange);
        this.formConnection.patchValue({
            authType: tabChangeEvent.tab.ariaLabel
        });
    }

    isValid(type: string, name: string): boolean {
        return this.formConnection.get(name).hasError(type);
    }

    addPlugin(item: KeyValue<string, IPlugin>): void {
        if (item.key === 'datadog') {
            const dialogRef = this.dialog.open(AddDatadogPluginComponent, {
                height: 'auto',
                width: '750px',
                disableClose: true,
                data: {type: this.type, typeId: this.typeId, plugin: item}
            });
            dialogRef.componentInstance.buttonCancel = 'plugins.modal.buttonCancel';
            dialogRef.componentInstance.buttonCreate = 'plugins.modal.buttonCreate';
            dialogRef.afterClosed().subscribe(() => {
                this.pluginService.emitClose();
            });
        } else {
            const dialogRef = this.dialog.open(ModalAddPluginComponent, {
                height: 'auto',
                width: '750px',
                disableClose: true,
                data: {type: this.type, typeId: this.typeId, plugin: item}
            });
            dialogRef.componentInstance.buttonCancel = 'plugins.modal.buttonCancel';
            dialogRef.componentInstance.buttonCreate = 'plugins.modal.buttonCreate';
            dialogRef.afterClosed().subscribe(() => {
                this.pluginService.emitClose();
            });

        }
    }

    loadOtherPlugins(): void {
        const allPlugins = [];
        this.plugins.forEach(plugin => {
            const record = plugin.plugins;
            for (const k in record) {
                if (record.hasOwnProperty(k)) {
                    allPlugins.push(k);
                }
            }
        });
        const token = localStorage.getItem('token');
        const env = this.localStorageService.getItem('environment');
        const headers = new HttpHeaders()
            .append('Environment', env)
            .append('Authorization', 'Bearer ' + token);
        this.checkUrl.getDashboardNodeInfo(headers)
            .subscribe((res: HttpResponse<NodeInformation>) => {
                this.nodeInformation = res.body;
                const available = this.nodeInformation?.plugins?.available_on_server;
                if (available) {
                    for (const k in available) {
                        if (available.hasOwnProperty(k)) {
                            if (!allPlugins.some(value => value === k)) {
                                this.otherPlugins.push(k);
                            }
                        }
                    }
                }
            }, () => {
                this.toastr.error('URL ERROR');
            });
    }

    addOtherPlugin(otherPlugins: string): void {
        const item: KeyValue<string, IPlugin> = {key: otherPlugins, value: {name: otherPlugins, description: 'Sin descripcion', icon: '', hasConsumerPlugins: false, isReadMore: false}};
        this.addPlugin(item);
    }
}
