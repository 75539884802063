import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, F, M } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';

@Component({
  selector: 'app-multiple-component',
  templateUrl: './multiple.component.html',
})
export class MultipleComponent implements OnInit {
  @Input() name: string;
  @Input() inputName: string;
  @Input() hint: string;
  @Input() form;
  @Input() stack: string[] = [];
  @Input() options: string[] = [];
  @Input() change;

  matChipInputAddOnBlur = true;
  multipleCtrl = new FormControl();
  filteredOptions: Observable<string[]>;
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('multipleInput') multipleInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() {}

  ngOnInit(): void {
    const formValues = this.form.value;
    this.stack = formValues[this.inputName];
    if (Array.isArray(this.options) && this.options.length > 0) {
      this.matChipInputAddOnBlur = false;
    }
    if (Array.isArray(this.options)) {
      this.filteredOptions = this.multipleCtrl.valueChanges.pipe(
        startWith(null),
        map((option: string | null) =>
          option ? this._filter(option) : this.options.slice()
        )
      );
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.stack.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.multipleCtrl.setValue(null);
    this.form.patchValue({
      [this.inputName]: this.stack,
    });
    if (this.change) {
      this.change(this.form, this.inputName);
    }
  }

  removeTag(tag: string): void {
    const index = this.stack.indexOf(tag);
    if (index >= 0) {
      this.stack.splice(index, 1);
    }
    this.form.patchValue({
      [this.inputName]: this.stack,
    });
    if (this.change) {
      this.change(this.form, this.inputName);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log(this.stack);
    this.stack.push(event.option.viewValue);
    this.form.patchValue({
      [this.inputName]: this.stack,
    });
    if (this.change) {
      this.change(this.form, this.inputName);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(
      (fruit) => fruit.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
