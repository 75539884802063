<div class="container">
  <h1 mat-dialog-title>{{ title | translate }}</h1>
  <hr />
  <div mat-dialog-content>
    <form [formGroup]="formCertificate" (ngSubmit)="submit(f)" #f="ngForm">
      <div class="row">
        <ng-container *ngFor="let key of keys">
          <div class="col-12" *ngIf="schema[key].type === 'string'">
            <label for="cert">
              <label>{{ names[key].name | translate }}</label>
            </label>
            <mat-form-field appearance="outline" class="w-100">
              <textarea
                id="cert"
                matInput
                formControlName="cert"
                rows="6"
                placeholder="-----BEGIN CERTIFICATE-----"
              ></textarea>
              <mat-hint>{{ names[key].hint | translate }}</mat-hint>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, key)">{{
                  error.message | translate
                }}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12" *ngIf="schema[key].type === 'set'">
            <app-multiple-component
              [schema]="schema[key]"
              hint="{{ names[key].hint | translate }}"
              name="{{ names[key].name | translate }}"
              [form]="formCertificate"
              [inputName]="key"
              [stack]="values[key]"
              [options]="schema[key].elements?.one_of || null"
            ></app-multiple-component>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="mt-2 pl-3">
    <button class="btn btn-close" [mat-dialog-close]="undefined">
      {{ buttonCancel | translate }}
    </button>
    <button class="btn btn-action" (click)="f.ngSubmit.emit()">
      {{
        modalOption == "create"
          ? (buttonCreate | translate)
          : (buttonEdit | translate)
      }}
    </button>
  </div>
</div>
