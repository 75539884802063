import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { OPEN_API } from 'src/app/core/constants/icons.constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    iconRegistry.addSvgIconLiteral(
      'open-api',
      sanitizer.bypassSecurityTrustHtml(OPEN_API)
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
