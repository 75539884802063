import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColumnSetup} from '../../../../components/dynamic-table/dynamic-table.component';
import {EnvironmentPermission, ISelectedPermission, Permission} from '../../connections/connection.model';

export interface RowItem {
    id: string;
    name: string;
    menu: string;
    label: string;
    permission: string;
    scope: string;
}

@Component({
    selector: 'app-environment-permission',
    templateUrl: './environment-permission.component.html',
    styleUrls: ['./environment-permission.component.scss']
})
export class EnvironmentPermissionComponent implements OnInit {
    @Input() selectedEnvironment: EnvironmentPermission;
    @Input() dataSource: RowItem[] = [];
    @Input() selectedPermissions: EnvironmentPermission;
    @Input() isEdit = false;
    @Output() onSelectPermission = new EventEmitter<ISelectedPermission>();
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'permisos', title: 'userManagement.permission.title', cell: (row: RowItem) => `${row.label}`},
        {columnDef: 'deny', custom: true},
        {columnDef: 'read', custom: true},
        {columnDef: 'write', custom: true},
    ];

    ngOnInit(): void {
        console.log(this.dataSource);
    }

    changeSelect(menu: string, permission: string): void {
        const elementsIndex = this.dataSource.findIndex(element => element.menu === menu);
        const newArray = [...this.dataSource];
        newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            name: newArray[elementsIndex].name.replace('DENY', permission).replace('READ', permission).replace('WRITE', permission),
            permission
        };
        this.dataSource = newArray;
        const rowItem = this.dataSource.find(element => element.menu === menu);
        this.onSelectPermission.emit({environment: this.selectedEnvironment.environment, permission: rowItem});
    }

    checkIfChecked(row: RowItem, permission: string): boolean {
        if (this.isEdit) {
            const permissions = this.selectedPermissions?.permissions || [];
            const item = this.itemFinded(permissions, row.menu, permission);
            if (permission === 'DENY') {
                const itemWithRead = this.itemFinded(permissions, row.menu, 'READ');
                const itemWithWrite = this.itemFinded(permissions, row.menu, 'WRITE');
                return !itemWithRead && !itemWithWrite;
            }
            return item;
        }
        return row.permission === permission;
    }

    private itemFinded(permissions: Permission[], menu: string, permission: string): boolean {
        const item = permissions.find(value => value.menu === menu && value.permission === permission);
        return !!item;
    }
}
