import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiModel, Revision} from '../../../../core/model/api.model';
import {ApiService} from '../../../../services/gateway/api.service';
import {ColumnSetup} from '../../../../components/dynamic-table/dynamic-table.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConnectionsService} from '../../../../services/apis/connections.service';

export interface RowItem {
    id: string;
    titulo: string;
    apiIdentity: string;
    description: string;
    environment: string;
    lastModifiedDate: string;
    version: string;
    urlServers: string[];
    revision: number;
}

export interface RowRoutes {
    id: string;
    name: string;
    paths: string;
    methods: string;
    protocols: string;
}

@Component({
    selector: 'app-edit-api',
    templateUrl: './edit-api.component.html',
    styleUrls: ['./edit-api.component.scss']
})
export class EditApiComponent implements OnInit {
    apiValue: ApiModel;
    revisions: Revision[] = [];
    dataSource = [];
    formulario: FormGroup;
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'environment', title: 'apis.table.environment', cell: (row: RowItem) => `${row.environment}`},
        {columnDef: 'revision', title: 'apis.table.revision', cell: (row: RowItem) => `${row.revision}`},
        {columnDef: 'status', title: 'apis.table.environment', cell: (row: RowItem) => `${row.environment}`},
        {columnDef: 'url', title: 'apis.table.urlServers', cell: (row: RowItem) => `${row.urlServers}`},
    ];
    spinner = false;
    dataEndPoint = [];
    columnsEndpoint: ColumnSetup[] = [
        {columnDef: 'name', title: 'apis.table.name', cell: (row: RowRoutes) => `${row.name}`},
        {columnDef: 'paths', title: 'apis.table.paths', cell: (row: RowRoutes) => `${row.paths}`},
        {columnDef: 'methods', title: 'apis.table.methods', cell: (row: RowRoutes) => `${row.methods}`},
        {columnDef: 'protocols', title: 'apis.table.protocols', cell: (row: RowRoutes) => `${row.protocols}`},
    ];
    deployments: RowItem[] = [];

    constructor(protected activatedRoute: ActivatedRoute,
                private apiService: ApiService,
                private connectionService: ConnectionsService) {
        this.formulario = new FormGroup({
            server: new FormControl('', [Validators.required]),
            deployment: new FormControl('', [Validators.required]),
        });
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(({apiObject}) => {
            this.apiValue = apiObject;
            this.apiService.getRevisions(this.apiValue.apiIdentity).subscribe(res => {
                this.dataSource = res;
            });
            this.apiService.getEndpoints(this.apiValue.apiIdentity).subscribe(res => {
                this.dataEndPoint = res;
            });
            this.getRevisions(this.apiValue.apiIdentity);
            this.getConnections();
        });
    }

    private getRevisions(apiIdentity: string): void {
        this.apiService.getRevisions(apiIdentity).subscribe(value => {
            this.revisions = value;
        });
    }

    private getConnections(): void {
        this.dataSource = [];
        this.spinner = true;
        this.connectionService.getServers().subscribe((response: RowItem[]) => {
                this.spinner = false;
                this.deployments = response;
            },
            err => {
                console.log(err);
                this.spinner = false;
            });
    }

    updateRevision(): void {
        const values = this.formulario.value;
        this.apiService.publishRevision(this.apiValue.apiIdentity, values.server, values.deployment).subscribe(res => {
            console.log(res);
        });
    }
}
