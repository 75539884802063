import {Component, Inject, OnInit} from '@angular/core';
import {ConsumerModel} from '../../../../../../core/model/consumer.model';
import {MessageError} from '../../../../../../core/interfaces/message-error.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ConsumerService} from '../../../../../../services/gateway/consumer.service';
import {FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-add-key',
    templateUrl: './add-key.component.html',
    styleUrls: ['./add-key.component.scss']
})
export class AddKeyComponent implements OnInit {
    formKey: FormGroup;
    consumer: ConsumerModel;
    MESSAGE_REQUIRED = 'consumers.message.required';
    protocolos = ['http', 'https'];
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };

    constructor(public dialog: MatDialogRef<AddKeyComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ConsumerModel,
                private translate: TranslateService,
                private toastr: ToastrService,
                private consumerService: ConsumerService) {
        this.consumer = data;
        this.formKey = this.buildForm();
    }

    ngOnInit(): void {
    }

    submit(): void {
        if (this.formKey.invalid) {
            this.translate.get('consumers.message').subscribe(translate => {
                this.toastr.error(translate.invalidForm);
                return;
            });
        }
        const formValues = this.formKey.value;
        const requestServer = {
            key: this.transformValue(formValues.key),
        };
        this.translate.get('consumers.message').subscribe(translate => {
            this.consumerService.createKeyCredential(requestServer, this.consumer.id).subscribe((res) => {
                this.toastr.success(translate.key.created);
                this.dialog.close(res);
            }, (err) => {
                this.toastr.error(translate.error);
                console.error(err);
            });
        });
    }

    private transformValue(value: string): string | undefined {
        if (value.length === 0) {
            return undefined;
        }
        return value;
    }

    isValid(type: string, name: string): boolean {
        return this.formKey.get(name).hasError(type);
    }

    buildForm(): FormGroup {
        return new FormGroup({
            key: new FormControl('', []),
        });
    }
}
