import {Component, Input, OnInit} from '@angular/core';
import {ConsumerBasicCredential, ConsumerHmacCredential, ConsumerJwtCredential, ConsumerKeyCredential, ConsumerModel, ConsumerOauthCredential} from '../../../../core/model/consumer.model';

@Component({
    selector: 'app-credentials',
    templateUrl: './credentials.component.html',
    styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {
    @Input() basicCredentials: ConsumerBasicCredential[] = [];
    @Input() keyCredentials: ConsumerKeyCredential[] = [];
    @Input() hmacCredentials: ConsumerHmacCredential[] = [];
    @Input() oauthCredentials: ConsumerOauthCredential[] = [];
    @Input() jwtCredentials: ConsumerJwtCredential[] = [];
    @Input() consumer: ConsumerModel;
    selectedMenu = 0;

    constructor() {
    }

    ngOnInit(): void {
    }

    selectCredential(event: number): void {
        this.selectedMenu = event;
    }
}
