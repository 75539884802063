import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AlertModule, BreadcrumbModule, CardModule, ModalModule} from './components';
import {DataFilterPipe} from './components/data-table/data-filter.pipe';
import {TodoListRemoveDirective} from './components/todo/todo-list-remove.directive';
import {TodoCardCompleteDirective} from './components/todo/todo-card-complete.directive';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClickOutsideModule} from 'ng-click-outside';

import {SpinnerComponent} from './components/spinner/spinner.component';
import {ApexChartComponent} from './components/chart/apex-chart/apex-chart.component';
import {ApexChartService} from './components/chart/apex-chart/apex-chart.service';
import {ToastComponent} from './components/toast/toast.component';
import {ToastService} from './components/toast/toast.service';
import {GalleryComponent} from './components/gallery/gallery.component';
import {LightboxModule} from 'ngx-lightbox';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationComponent} from './components/translation/translation.component';
import {TranslationSelectComponent} from './components/translation-select/translation-select.component';
import {MaterialModule} from 'src/app/material.module';
import {ArchwizardModule} from 'angular-archwizard';
import {TranslateDirective} from './language/translate.directive';
/*import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';*/

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        CardModule,
        BreadcrumbModule,
        ModalModule,
        ClickOutsideModule,
        LightboxModule,
        TranslateModule,
        MaterialModule,
        ArchwizardModule
    ],
    exports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        CardModule,
        BreadcrumbModule,
        ModalModule,
        DataFilterPipe,
        TodoListRemoveDirective,
        TodoCardCompleteDirective,
        ClickOutsideModule,
        SpinnerComponent,
        ApexChartComponent,
        GalleryComponent,
        ToastComponent,
        TranslateModule,
        TranslationComponent,
        TranslationSelectComponent,
        ArchwizardModule,
        TranslateDirective
    ],
    declarations: [
        DataFilterPipe,
        TodoListRemoveDirective,
        TodoCardCompleteDirective,
        SpinnerComponent,
        ApexChartComponent,
        ToastComponent,
        GalleryComponent,
        TranslationComponent,
        TranslationSelectComponent,
        TranslateDirective
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        ApexChartService,
        ToastService
    ]
})
export class SharedModule {
}
