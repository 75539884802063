import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { endpoint } from '../../core/constants/services.constants';
import { SERVER_API_URL } from '../../core/constants/app.constants';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private URL_BASE: string = environment.url_services;
  private ENDPOINT_API = `${this.URL_BASE}${endpoint.ENDPOINT_API}`;
  private ENDPOINT_KONG_SERVICES = `${this.URL_BASE}${endpoint.KONG_SERVICES}`;

  constructor(private http: HttpClient) {}

  getApis(): Observable<any> {
    return this.http.get(`${this.ENDPOINT_API}`);
  }

  getApi(identity: string, enviroment: string): Observable<any> {
    return this.http.get(
      `${this.ENDPOINT_API}/${enviroment}/last-revision/${identity}`
    );
  }

  getDeclarative(name: string): Observable<any> {
    return this.http.get(`${this.ENDPOINT_API}/specs/${name}/declarative`, {
      observe: 'body',
      responseType: 'text',
    });
  }

  getApiSpec(name: string): Observable<unknown> {
    return this.http.get(`${this.ENDPOINT_API}/specs/${name}`);
  }

  getRevisions(apiIdentity: string): Observable<any> {
    return this.http.get(`${this.ENDPOINT_API}/${apiIdentity}/revisions`);
  }

  getRevision(apiIdentity: string, revisionId: string): Observable<any> {
    return this.http.get(
      `${this.ENDPOINT_API}/${apiIdentity}/revisions/${revisionId}`
    );
  }

  publishNewApi(
    environemnt: string,
    policies: string,
    specName: string
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('name', specName);
    formData.append('policies', policies);
    formData.append('deployEnvironment', environemnt);
    return this.http.post(this.ENDPOINT_API, formData, {
      observe: 'body',
      responseType: 'text',
    });
  }

  publishRevision(
    apiIdentity: string,
    revisionId: string,
    enviroment: string
  ): Observable<any> {
    const params = new HttpParams()
      .append('idRevision', revisionId)
      .append('enviroment', enviroment);
    return this.http.post(
      `${this.ENDPOINT_API}/${apiIdentity}`,
      {},
      { params }
    );
  }

  getEndpoints(apiIdentity: string): Observable<any> {
    return this.http
      .get(`${this.ENDPOINT_KONG_SERVICES}/${apiIdentity}/routes`)
      .pipe(
        map((response: any) => {
          return response.data;
        })
      );
  }

  publishApi(enviroment: string, id: string): Observable<any> {
    return this.http.post(
      `${SERVER_API_URL}/api/apis/${id}/publish`,
      {},
      { params: { enviroment }, responseType: 'text' }
    );
  }

  updateApi(body: any): Observable<any> {
    return this.http.put(this.ENDPOINT_API, body);
  }

  deleteApi(id: string): Observable<any> {
    return this.http.delete(`${this.ENDPOINT_API}/${id}`);
  }

  selectEnviroment(enviroment: string, id: string): Observable<any> {
    return this.http.post(
      `${SERVER_API_URL}api/apis/${id}/publish`,
      {},
      { params: { enviroment }, responseType: 'text' }
    );
  }
}
