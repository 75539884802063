<form [formGroup]="formPlugin" (ngSubmit)="submit()">
    <div class="container">
        <h1 mat-dialog-title>{{ title }}</h1>
        <hr>
        <div mat-dialog-content>
            <div class="col-12">
                <label>{{ 'plugins.modal.datadog.host' | translate }}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="text" formControlName="host"/>
                    <mat-hint>{{ 'plugins.hint.datadog.host' | translate }}</mat-hint>
                    <mat-error *ngFor="let error of messageError.required">
                        <span *ngIf="isValid(error.type, 'host')">{{ error.message  | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <label>{{ 'plugins.modal.datadog.port' | translate }}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="text" formControlName="port"/>
                    <mat-hint>{{ 'plugins.hint.datadog.port' | translate }}</mat-hint>
                    <mat-error *ngFor="let error of messageError.required">
                        <span *ngIf="isValid(error.type, 'port')">{{ error.message  | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">
                <label>{{ 'plugins.modal.datadog.prefix' | translate }}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="text" formControlName="prefix"/>
                    <mat-hint>{{ 'plugins.hint.datadog.prefix' | translate }}</mat-hint>
                    <mat-error *ngFor="let error of messageError.required">
                        <span *ngIf="isValid(error.type, 'prefix')">{{ error.message  | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div formArrayName="metrics">
                <app-card cardTitle="{{'plugins.modal.datadog.metrics'|translate}}" [options]="false" headerClass="full-width">
                    <ng-container *ngFor="let element of names;let i = index">
                        <div [formGroup]="metricsForm.at(i)">
                            <div class="col-12">
                                <h4 class="text-capitalize">{{ element }}</h4>
                            </div>
                            <div class="col-12">
                                <label>{{ 'plugins.modal.datadog.statType' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-select formControlName="stat_type">
                                        <mat-option *ngFor="let statType of statTypes" [value]="statType">
                                            {{statType}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint>{{ 'plugins.hint.datadog.statType' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <label>{{ 'plugins.modal.datadog.sampleRate' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input matInput type="text" formControlName="sample_rate"/>
                                    <mat-hint>{{ 'plugins.hint.datadog.sampleRate' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <label>{{ 'plugins.modal.datadog.consumerIdentifier' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-select formControlName="consumer_identifier">
                                        <mat-option *ngFor="let consumer of consumerIdentifiers" [value]="consumer">
                                            {{consumer}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint>{{ 'plugins.hint.datadog.consumerIdentifier' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <label>{{ 'plugins.modal.tags' | translate }}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-chip-list #chipList aria-label="Fruit selection">
                                        <mat-chip *ngFor="let tag of tags[element]" [selectable]="true" [removable]="true" (removed)="removeTag(element, tag)">
                                            {{tag}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                        <input matInput placeholder="{{ 'plugins.modal.tags' | translate }}"
                                                formControlName="tags"
                                                [matChipInputFor]="chipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                [matChipInputAddOnBlur]="true"
                                                (matChipInputTokenEnd)="addTag($event, element)">
                                    </mat-chip-list>
                                    <mat-hint>{{ 'plugins.hint.tags' | translate }}</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </app-card>
            </div>
        </div>
    </div>
    <div mat-dialog-actions align="end" class="mt-2 pl-3">
        <button class="btn btn-close" [mat-dialog-close]="undefined">Cancelar</button>
        <button class="btn btn-action">Guardar Datos</button>
    </div>
</form>
