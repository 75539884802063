import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {endpoint} from '../../core/constants/services.constants';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IPluginGroup, PluginsModel} from '../../core/model/plugins.model';

type PluginGroupArray = HttpResponse<IPluginGroup[]>;

@Injectable({
    providedIn: 'root'
})
export class PluginsService {

    private URL_BASE: string = environment.url_services;
    private ENDPOINT_PLUGINS = `${this.URL_BASE}${endpoint.ENDPOINT_PLUGINS}`;
    private ENDPOINT_KONG_PLUGINS = `${this.URL_BASE}${endpoint.KONG_PLUGINS}`;
    private staticPlugins = 'assets/plugins/listPlugins.json';
    private staticPluginsOptions = 'assets/plugins/pluginsOptions.json';
    private closeEmitter: EventEmitter<number> = new EventEmitter<number>();

    constructor(private http: HttpClient) {
    }

    getPlugins(): Observable<any> {
        return this.http.get(this.ENDPOINT_KONG_PLUGINS).pipe(
            map((response: any) => {
                return response.data;
            })
        );
    }

    getPlugin(id: string): Observable<HttpResponse<PluginsModel>> {
        return this.http.get<PluginsModel>(`${this.ENDPOINT_KONG_PLUGINS}/${id}`, {observe: 'response'});
    }

    getPluginsGroup(): Observable<PluginGroupArray> {
        return this.http.get<IPluginGroup[]>(this.staticPlugins, {observe: 'response'});
    }

    getPluginsOptions(clave: string): Observable<any> {
        return this.http.get<Record<string, any>>(this.staticPluginsOptions, {observe: 'response'})
            .pipe(map(res => {
                const cuerpo = res.body;
                return this.getMapKeyValue(cuerpo, clave);
            }));
    }

    getMapKeyValue(obj: Record<string, any>, key: string): any {
        console.log(key);
        console.log(obj.hasOwnProperty(key));
        if (obj.hasOwnProperty(key)) {
            return {clave: key, value: obj[key]};
        }
        return {};
    }

    schema(name: string): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${this.ENDPOINT_KONG_PLUGINS}/schema/${name}`, {observe: 'response'});
    }

    createGlobalPlugin(body: any): Observable<any> {
        return this.http.post(this.ENDPOINT_KONG_PLUGINS, body);
    }

    createServicePlugin(body: any, serviceId: string): Observable<any> {
        return this.http.post(`${this.URL_BASE}${endpoint.KONG_SERVICES}/${serviceId}/plugins`, body);
    }

    createRoutePlugin(body: any, routeId: string): Observable<any> {
        return this.http.post(`${this.URL_BASE}${endpoint.KONG_ROUTER}/${routeId}/plugins`, body);
    }

    updatePlugin(body: any): Observable<any> {
        return this.http.put(`${this.ENDPOINT_KONG_PLUGINS}/${body.id}`, body);
    }

    deletePlugin(id: string): Observable<any> {
        return this.http.delete(`${this.ENDPOINT_KONG_PLUGINS}/${id}`);
    }

    statusPlugin(id: string, status): Observable<any> {
        const params = new HttpParams().append('status', status);
        return this.http.post(`${this.ENDPOINT_PLUGINS}/${id}/activate`, params);
    }

    getEmitter(): EventEmitter<number> {
        return this.closeEmitter;
    }

    emitClose(): void {
        this.closeEmitter.emit(1);
    }
}
