import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    lang: string[] = [];

    constructor(
        private router: Router,
        private translate: TranslateService
    ) {
        this.translate.addLangs(['en', 'es']);
        this.lang = this.translate.getLangs();
    }

    changeLang(lang: string): void {
        this.translate.use(lang);
    }

    ngOnInit(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
