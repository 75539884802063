<form [formGroup]="formService" (ngSubmit)="submit()">
  <div class="container">
    <h1 mat-dialog-title>{{ title | translate }}</h1>
    <hr />
    <div mat-dialog-content>
      <div class="row">
        <div class="col-12">
          <mat-hint>{{ "spec.modal.inputs.name" | translate }}</mat-hint>
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              type="text"
              formControlName="name"
              (keyup)="handlerName()"
            />
          </mat-form-field>
        </div>
        <div class="col-12" *ngIf="modalOption == 'create-spec-file'">
          <mat-hint>{{ "spec.modal.inputs.file" | translate }}</mat-hint> <br />
          <input
            #fileDropRef
            type="file"
            formControlName="file"
            class="hidden"
            accept=".yaml,.json"
            (change)="fileBrowseHandler($event)"
          />
          <div class="row">
            <div class="col hint">
              <mat-form-field appearance="outline" class="w-100">
                <input
                  matInput
                  type="text"
                  disabled="true"
                  placeholder="{{ fileName }}"
                />
              </mat-form-field>
            </div>
            <div class="col">
              <div
                class="mat-mini-fab btn-primary pt-2"
                (click)="fileDropRef.click()"
              >
                <mat-icon matSuffix>attach_file</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions align="end" class="mt-2 pl-3">
      <button class="btn btn-close" [mat-dialog-close]="undefined">
        {{ "spec.modal.buttons.cancel" | translate }}
      </button>
      <button class="btn btn-action" [disabled]="spinner">
        {{ buttonAction | translate }}
      </button>
    </div>
  </div>
</form>
