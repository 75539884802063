import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ColumnSetup} from '../../../components/dynamic-table/dynamic-table.component';
import {DatePipe} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {SweetAlertComponent} from '../../../components/sweet-alert/sweet-alert.component';
import {MatDialog} from '@angular/material/dialog';
import {ModalRouterComponent} from './modal-router/modal-router.component';
import {RouterService} from '../../../services/gateway/router.service';
import {RouterOptionsComponent} from './router-options/router-options.component';
import {ConnectionsService} from '../../../services/apis/connections.service';
import {TranslateService} from '@ngx-translate/core';

export interface RowItem {
    id: string;
    name: string;
    host: string;
    headers: string;
    tags: string[];
    paths: string[];
    methods: string[];
    path_handling: string;
    destinations: string;
    created_at: string;
    sources: string;
    snis: string;
    service: string;
    protocols: string[];
    route: any;
    https_redirect_status_code: number;
    regex_priority: number;
    strip_path: boolean;
    preserve_host: boolean;
    updated_at: number;
}

@Component({
    selector: 'app-routers',
    templateUrl: './routers.component.html',
    styleUrls: ['./routers.component.scss']
})
export class RoutersComponent implements OnInit, AfterViewInit {

    public spinner = false;
    public dataSource: RowItem[] = [];
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'actions', title: '', custom: true},
        {columnDef: 'name', title: '', custom: true},
        {columnDef: 'tags', title: '', classes: 'column-flex-3', custom: true},
        {columnDef: 'hosts', title: 'routers.table.hosts', classes: 'column-flex-6', custom: true},
        {columnDef: 'service', title: '', custom: true},
        {columnDef: 'paths', title: 'routers.table.paths', classes: 'column-flex-7', cell: (row: RowItem) => `${row.paths}`},
        {
            columnDef: 'createdDate',
            title: 'routers.table.createdDate',
            classes: 'column-flex-6',
            cell: (row: RowItem) => `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`
        },
        {columnDef: 'delete', title: '', custom: true},
    ];

    constructor(
        private translate: TranslateService,
        private dateFormat: DatePipe,
        private routerService: RouterService,
        private toastr: ToastrService,
        private connectionService: ConnectionsService,
        private sweet: SweetAlertComponent,
        public dialog: MatDialog
    ) {
    }

    ngAfterViewInit(): void {
        this.getRouters();
        this.connectionService.getConnectionEmitter().subscribe(value => {
            if (value) {
                this.getRouters();
            }
        });
    }

    ngOnInit(): void {
    }

    createRouter(): void {
        const dialogRef = this.dialog.open(ModalRouterComponent, {
            height: 'auto',
            width: '750px',
            disableClose: true

        });
        dialogRef.componentInstance.title = 'routers.modal.titleOpen';
        dialogRef.componentInstance.buttonCancel = 'routers.modal.buttonCancel';
        dialogRef.componentInstance.buttonCreate = 'routers.modal.buttonCreate';
        dialogRef.afterClosed().subscribe(() => {
            this.getRouters();
        });
    }

    getStatusUrl(row): void {

    }

    editRouter(row): void {
        const dialogRef = this.dialog.open(ModalRouterComponent, {
            height: 'auto',
            width: '750px',
            disableClose: true,
            data: row
        });
        dialogRef.componentInstance.title = 'routers.modal.titleEdit';
        dialogRef.componentInstance.buttonCancel = 'routers.modal.buttonCancel';
        dialogRef.componentInstance.buttonCreate = 'routers.modal.buttonEdit';
        dialogRef.afterClosed().subscribe(() => {
            this.getRouters();
        });
    }

    statusRouter(id): void {

    }

    deleteRouter(id): void {
        this.translate.get('routers.message').subscribe(translate => {
            this.sweet.confirmBox({
                title: translate.confirm.title,
                text: translate.confirm.text,
                confirmButtonText: translate.confirm.button,
                showCancelButton: true,
                alertAction: (result) => {
                    if (result.isConfirmed) {
                        this.routerService.deleteRouter(id)
                            .subscribe(() => {
                                    this.toastr.success(translate.deleted);
                                    this.getRouters();
                                },
                                (err) => {
                                    console.log(err);
                                    this.sweet.error({
                                        title: translate.error
                                    });
                                });
                    }
                }
            });
        });
    }

    private getRouters(): void {
        this.dataSource = [];
        this.spinner = true;
        this.routerService.getRouters().subscribe((response: RowItem[]) => {
            this.spinner = false;
            this.dataSource = response.map(route => ({...route, createdDate: new Date()}));
        }, err => {
            console.log(err);
            this.spinner = false;
        });
    }

    getRouterOptions(row): void {
        const dialogRef = this.dialog.open(RouterOptionsComponent, {
            height: 'auto',
            width: '750px',
            disableClose: true,
            data: row
        });
        dialogRef.componentInstance.title = 'routers.modal.detailTitle';
        dialogRef.componentInstance.buttonCancel = 'routers.modal.buttonCancel';
    }

    preserveHost(row: any): void {
        row.preserve_host = !row.preserve_host;
        row.createdDate = null;
        this.routerService.updateRouter(row).subscribe(() => {
            this.getRouters();
        });
    }

    stripPath(row: any): void {
        row.strip_path = !row.strip_path;
        row.createdDate = null;
        this.routerService.updateRouter(row).subscribe(() => {
            this.getRouters();
        });
    }
}
