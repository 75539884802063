import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MessageError } from 'src/app/core/interfaces/message-error.interface';
import { KongError } from '../../../../core/model/audit.model';
import { TranslateService } from '@ngx-translate/core';
import { CaCertificateService } from 'src/app/services/gateway/ca-certificate.service';

@Component({
  selector: 'app-modal-ca-certificates',
  templateUrl: './modal-ca-certificates.component.html',
  styleUrls: ['./modal-ca-certificates.component.scss'],
})
export class ModalCaCertificatesComponent implements OnInit, AfterViewInit {
  public tabIndex = 0;
  public formCertificate: FormGroup;
  public keys = [];
  public schema: Record<string, unknown> = {};
  public names: Record<string, { name: string; hint: string }> = {};
  public values: Record<string, string[]> = {};
  public title = '';
  public selectedTab = 0;
  public buttonCreate = '';
  public buttonEdit = '';
  public buttonCancel = '';
  public modalOption = 'create';
  public MESSAGE_REQUIRED = 'caCertificates.message.required';
  public messageError: MessageError = {
    required: [{ type: 'required', message: this.MESSAGE_REQUIRED }],
  };
  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private caCertificatesService: CaCertificateService,
    private toastr: ToastrService,
    public dialog: MatDialogRef<ModalCaCertificatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.processSchema();
  }

  private processSchema(): void {
    const schema = this.data.schema;
    const form = {
      id: new FormControl(null, []),
    };
    if (!schema) {
      return;
    }
    schema.map((field) => {
      const keys = Object.keys(field);
      const key = keys[0];
      const options = [];
      let defaultValue = null;
      if (!field[key].auto) {
        this.keys.push(key);
        this.schema[key] = field[key];
        this.names[key] = {
          name: `caCertificates.modal.${key}`,
          hint: `caCertificates.hint.${key}`,
        };
        if (field[key].required) {
          options.push(Validators.required);
        }
        if (field[key].default || field[key].default === 0) {
          this.values[key] = [];
          if (Array.isArray(field[key].default)) {
            this.values[key] = field[key].default;
          } else {
            this.values[key] = field[key].default;
          }
          defaultValue = field[key].default;
        }
        if (field[key].type === 'boolean' && !field[key].default) {
          defaultValue = false;
        }
        if (field[key].type === 'boolean' && !field[key].default) {
          defaultValue = false;
        }
        if (
          (field[key].type === 'set' || field[key].type === 'array') &&
          !field[key].default
        ) {
          defaultValue = [];
        }
        form[key] = new FormControl(defaultValue, options);
      }
    });
    this.formCertificate = this.buildForm(form);
  }

  buildForm(form): FormGroup {
    return new FormGroup(form);
  }

  cleanValidators(): void {
    // const keys = Object.keys(this.formCertificate.value);
    // keys.forEach((key) => {
    //   this.formCertificate.get(key).clearValidators();
    // });
  }

  isValid(type: string, name: string): boolean {
    return this.formCertificate.get(name).hasError(type);
  }

  submit(form: AbstractControl): void {
    const formValues = this.formCertificate.value;
    const fields = {};

    this.keys.map((key) => {
      fields[key] = formValues[key] || null;
      if (Array.isArray(formValues[key]) && formValues[key].length === 0) {
        fields[key] = null;
      }
    });
    if (form.invalid) {
      this.translate.get('caCertificates.message').subscribe((translate) => {
        this.toastr.error(translate.invalidForm);
        return;
      });
    }

    if (this.modalOption === 'create') {
      this.translate.get('caCertificates.message').subscribe((translate) => {
        this.caCertificatesService.createCertificate(fields).subscribe(
          () => {
            this.toastr.success(translate.created);
            this.dialog.close();
          },
          (err) => {
            this.toastr.error(translate.error);
            const fieldErrors = err.error as KongError;
            const keys = Object.keys(fieldErrors.fields);
            keys.forEach((key) => {
              this.formCertificate
                .get(key)
                .setErrors({ [key]: fieldErrors.fields[key] });
            });
          }
        );
      });
    } else {
      this.translate.get('caCertificates.message').subscribe((translate) => {
        this.caCertificatesService
          .updateCertificate({ ...fields, id: this.data.id })
          .subscribe(
            () => {
              this.toastr.success(translate.updated);
              this.dialog.close();
            },
            (err) => {
              this.toastr.error(translate.error);
              console.error(err);
            }
          );
      });
    }
  }

  updateForm(row: any): void {
    this.formCertificate.patchValue({
      cert: row.cert,
      key: row.key,
      tags: row.tags,
      snis: row.snis,
    });
  }
}
