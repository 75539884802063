<div class="row">
    <div class="col-12">
        <div class="p-3 d-inline-flex justify-content-between w-100 bg-light align-items-center">
            <div class="d-flex ml-3">
                <i class="fas fa-network-wired"></i>
                <h5 class="pl-3 mb-0 text-uppercase">{{'dashboard.title'|translate}}</h5>
            </div>
            <div class="box-request">
                <p class="mb-0 text-uppercase">{{'dashboard.totalRequest'|translate}}:
                    <span class="number-request">701K+</span>
                </p>
            </div>
        </div>
        <app-card [hidHeader]="true" cardClass="rounded-0">
            <div class="row justify-content-around align-items-center" *ngIf="isLoading==false">
                <div class="col-auto">
                    <h6 class="text-muted m-b-10 text-uppercase">{{'dashboard.active'|translate}}</h6>
                    <h2 class="m-b-0">{{dashboard.server?.connections_active}}</h2>
                </div>
                <div class="col-auto">
                    <h6 class="text-muted m-b-10 text-uppercase">{{'dashboard.reading'|translate}}</h6>
                    <h2 class="m-b-0">{{dashboard.server?.connections_reading}}</h2>
                </div>
                <div class="col-auto">
                    <h6 class="text-muted m-b-10 text-uppercase">{{'dashboard.written'|translate}}</h6>
                    <h2 class="m-b-0">{{dashboard.server?.connections_writing}}</h2>
                </div>
                <div class="col-auto">
                    <h6 class="text-muted m-b-10 text-uppercase">{{'dashboard.waiting'|translate}}</h6>
                    <h2 class="m-b-0">{{dashboard.server?.connections_waiting}}</h2>
                </div>
                <div class="col-auto">
                    <h6 class="text-muted m-b-10 text-uppercase">{{'dashboard.accepted'|translate}}</h6>
                    <h2 class="m-b-0">{{dashboard.server?.connections_accepted}}</h2>
                </div>
                <div class="col-auto">
                    <h6 class="text-muted m-b-10 text-uppercase">{{'dashboard.handled'|translate}}</h6>
                    <h2 class="m-b-0">{{dashboard.server?.connections_handled}}</h2>
                </div>
            </div>
            <app-loading *ngIf="isLoading"></app-loading>
        </app-card>
    </div>
</div>

<!-- Tablas -->

<div class="row">
    <!-- Card Node Info -->
    <div class="col-12 col-lg-4">
        <div class="card">
            <div class="card-header bg-light">
                <i class="fas fa-info-circle"></i>
                <h5 class="pl-3 text-uppercase">{{'dashboard.nodeInfo'|translate}}</h5>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <td><strong>{{'dashboard.hostname'|translate}}</strong></td>
                            <td>{{nodeInformation?.hostname}}</td>
                        </tr>
                        <tr>
                            <td><strong>{{'dashboard.tagLine'|translate}}</strong></td>
                            <td>{{nodeInformation?.tagline}}</td>
                        </tr>
                        <tr>
                            <td><strong>{{'dashboard.version'|translate}}</strong></td>
                            <td>{{nodeInformation?.version}}</td>
                        </tr>
                        <tr>
                            <td><strong>{{'dashboard.luaVersion'|translate}}</strong></td>
                            <td>{{nodeInformation?.lua_version}}</td>
                        </tr>
                        <tr>
                            <td><strong>Admin Listen</strong></td>
                            <td class="pre-wrap">[{{nodeInformation?.configuration?.admin_listen.join(', ')}}]</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- Card Timers -->
    <div class="col-12 col-lg-4">
        <div class="card min-height-50">
            <div class="card-header bg-light">
                <i class="fas fa-clock"></i>
                <h5 class="pl-3">TIMERS</h5>
            </div>
            <div class="p-3">
                <ng-container *ngIf="reload">
                    <app-apex-chart #chartComponent chartID="bar-chart-3-cac" [chartConfig]="this.data"></app-apex-chart>
                </ng-container>
            </div>
        </div>
    </div>


    <!-- Card Datastore Info -->
    <div class="col-12 col-lg-4">
        <div class="card">
            <div class="card-header bg-light">
                <i class="fas fa-database"></i>
                <h5 class="pl-3">DATASTORE INFO</h5>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <td><strong>DBMS</strong></td>
                            <td>{{nodeInformation?.configuration?.database}}</td>
                        </tr>
                        <tr>
                            <td><strong>Host</strong></td>
                            <td class="pre-wrap">{{nodeInformation?.configuration?.pg_host}}</td>
                        </tr>
                        <tr>
                            <td><strong>Database</strong></td>
                            <td>{{nodeInformation?.configuration?.pg_database}}</td>
                        </tr>
                        <tr>
                            <td><strong>User</strong></td>
                            <td>{{nodeInformation?.configuration?.pg_user}}</td>
                        </tr>
                        <tr>
                            <td><strong>Admin Listen Port</strong></td>
                            <td>{{nodeInformation?.configuration?.pg_port}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<!-- Plugins -->

<div class="row">
    <div class="col-12">
        <div class="p-3 w-100 bg-light">
            <div class="d-flex ml-3">
                <i class="fas fa-plug"></i>
                <h5 class="pl-3 mb-0">PLUGINS</h5>
            </div>
        </div>
        <app-card [hidHeader]="true" [options]="false" cardClass="rounded-0">
            <span class="badge m-r-5 text-uppercase" *ngFor="let available of nodeInformation?.plugins?.available_on_server | keyvalue"
                    [ngClass]="isEnableOnServer(available.key, nodeInformation?.plugins?.enabled_in_cluster) ? 'badge-primary' : 'badge-light-secondary'">
                {{available.key}}
            </span>
        </app-card>
    </div>
</div>
