import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IPlugin, IPluginData} from '../../../../core/model/plugins.model';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {PluginsService} from '../../../../services/gateway/plugins.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-modal-add-plugin',
    templateUrl: './modal-add-plugin.component.html',
    styleUrls: ['./modal-add-plugin.component.scss']
})
export class ModalAddPluginComponent implements OnInit {
    name: string;
    plugin: IPlugin;
    formPlugin: FormGroup;
    controls: Record<string, FormControl> = {};
    defaultsValues: Record<string, Array<string>> = {};
    title = '';
    modalOption = 'create';
    buttonCancel = '';
    buttonCreate = '';
    buttonEdit = '';
    schema: any;
    type: 'global' | 'service' | 'route';
    typeId: string;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    constructor(
        private translate: TranslateService,
        private pluginsService: PluginsService,
        public dialog: MatDialogRef<ModalAddPluginComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IPluginData) {
        this.type = data.type;
        this.typeId = data.typeId;
        const item = data.plugin;
        this.name = item.key;
        this.plugin = item.value;
        this.formPlugin = new FormGroup({});
        this.schema = {};
        this.title = this.plugin.description;
    }

    ngOnInit(): void {
        this.pluginsService.schema(this.name).subscribe(response => {
            const group = {};
            this.schema = response.body;
            const fields = this.schema.fields as Array<any>;
            console.log(fields);
            fields.forEach(value => {
                const keys = Object.keys(value);
                if (value[keys[0]] && value[keys[0]].type === 'array') {
                    const clave = keys[0];
                    const control = new FormArray([]);
                    const elementos = value[keys[0]].elements;
                    const elementFields = elementos.fields as Array<any>;
                    elementFields.forEach(elemento => {
                        const keysElement = Object.keys(elemento);
                        const claveElemento = keysElement[0];
                        const validators = [];
                        if (elemento[claveElemento].required) {
                            validators.push(Validators.required);
                        }
                        control.controls[claveElemento] = new FormControl('', validators);
                    });
                    group[clave] = control;
                } else {
                    const clave = keys[0];
                    const control = new FormControl();
                    this.controls[clave] = control;
                    group[clave] = control;
                }
            });
            this.formPlugin = new FormGroup(group);
        });
    }

    submit(): void {
        if (this.formPlugin.valid) {
            let newPlugin = {enabled: true, name: this.name};
            newPlugin = Object.assign(newPlugin, {config: this.formPlugin.value});
            switch (this.type) {
                case 'global':
                    this.pluginsService.createGlobalPlugin(newPlugin).subscribe(() => {
                        this.dialog.close(true);
                    });
                    break;
                case 'service':
                    this.pluginsService.createServicePlugin(newPlugin, this.typeId).subscribe(() => {
                        this.dialog.close(true);
                    });
                    break;
                case 'route':
                    this.pluginsService.createRoutePlugin(newPlugin, this.typeId).subscribe(() => {
                        this.dialog.close(true);
                    });
                    break;
                default:
                    this.pluginsService.createGlobalPlugin(newPlugin).subscribe(() => {
                        this.dialog.close(true);
                    });
                    break;
            }
        }
    }

    humanizeLabel(key: string): string {
        return key.split('_').join(' ');
    }

    getFieldProp(field: any): any {
        return Object.keys(field)[0];
    }

    getFieldArrayProp(field: any): any {
        console.log(field[Object.keys(field)[0]]);
        return Object.keys(field)[0];
    }

    getControl(item: any): FormControl {
        const control = this.controls[item.key];
        if (control) {
            return control;
        }
        return new FormControl();
    }

    getCheckedValue(control: FormControl): string {
        if (!control.value) {
            return 'No';
        }
        return control.value ? 'Si' : 'No';
    }

    removeTag(tag: string, elements: Array<string>, control: FormControl, item: any): void {
        if (elements) {
            const index = elements.indexOf(tag);
            if (index >= 0) {
                elements.splice(index, 1);
                control.setValue(elements);
            }
        } else {
            if (this.defaultsValues[item.key]) {
                const index = this.defaultsValues[item.key].indexOf(tag);
                if (index >= 0) {
                    this.defaultsValues[item.key].splice(index, 1);
                    control.setValue(this.defaultsValues[item.key]);
                }
                console.log(this.defaultsValues[item.key]);
            }
        }
    }

    addTag(event: MatChipInputEvent, elements: Array<string>, control: FormControl, item: any): void {
        if (elements) {
            const input = event.input;
            const value = event.value;
            if ((value || '').trim()) {
                elements.push(value.trim());
                control.setValue(elements);
            }
            if (input) {
                input.value = '';
            }
        } else {
            const input = event.input;
            const value = event.value;
            const arreglo = this.defaultsValues[item.key];
            if ((value || '').trim()) {
                arreglo.push(value.trim());
                control.setValue(arreglo);
            }
            if (input) {
                input.value = '';
            }
        }
    }

    getDefaultElements(defaultValue: Array<any>, item: any): Array<string> {
        if (defaultValue) {
            let isString = false;
            defaultValue.forEach(value => {
                if (typeof item === 'string') {
                    isString = true;
                    return;
                }
            });
            if (isString) {
                return defaultValue;
            }
            return defaultValue.map(value => value.name);
        }
        if (this.defaultsValues[item.key]) {
            return this.defaultsValues[item.key];
        } else {
            this.defaultsValues[item.key] = [];
            return this.defaultsValues[item.key] || [];
        }
    }

    isSimpleArray(value): boolean {
        const elementos = value.elements;
        if (elementos) {
            return elementos.type !== 'record';
        }
        return true;
    }
}
