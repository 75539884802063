import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-filter-table',
    templateUrl: './filter-table.component.html',
    styleUrls: ['./filter-table.component.scss']
})
export class FilterTableComponent<T> implements OnInit {

    public value = '';

    constructor() {
    }

    ngOnInit(): void {
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.value = filterValue;
    }

}
