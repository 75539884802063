import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ToastrService } from 'ngx-toastr';
import { MessageError } from '../../../app/core/interfaces/message-error.interface';
import { ISpec } from '../../../app/pages/developer-portal/catalog/catalog.model';
import { CatalogService } from '../../../app/pages/developer-portal/catalog/service/catalog.service';
import { MatSelectionListChange } from '@angular/material/list';
import { SpecService } from '../../../app/services/gateway/spec.service';
import { ApiService } from '../../../app/services/gateway/api.service';
import { KIND_FOLDER } from '../../../app/core/constants/app.constants';

@Component({
  selector: 'app-select-spec',
  templateUrl: './select-spec.component.html',
  styleUrls: ['./select-spec.component.scss'],
})
export class SelectSpecComponent implements OnInit {
  @Input() inputName: string;
  @Input() form;
  @Input() refreshSpecs;
  @Input() handleFolder;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public upload = true;
  public MESSAGE_REQUIRED = 'catalog.message.required';
  public isSaving = false;
  public messageError: MessageError = {
    required: [{ type: 'required', message: this.MESSAGE_REQUIRED }],
  };
  public environments: string[] = [];
  public action = 'catalog.form.buttonCreate';
  public portalTitle = 'catalog.header.editLabel';
  public categories: string[] = [];
  public specs: ISpec[] = [];
  public selectedSpec: ISpec;
  public title = 'catalog.header.title';
  public specVisited: string[] = [];

  @ViewChild('file') file: ElementRef;
  filePath = '';

  constructor(
    protected catalogService: CatalogService,
    protected activatedRoute: ActivatedRoute,
    protected spectService: SpecService,
    protected apiService: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.listSpecByParent('');
    this.refreshSpecs.subscribe((res) => {
      this.listSpecByParent(res || '');
    });
  }

  goBackToSpec(): void {
    const length = this.specVisited.length;
    this.specVisited.splice(length - 1, 1);
    if (this.specVisited.length > 0) {
      const visitedElement = this.specVisited[length - 2];
      if (this.handleFolder) {
        this.handleFolder(visitedElement);
      }
      this.listSpecByParent(visitedElement);
    } else {
      if (this.handleFolder) {
        this.handleFolder('');
      }
      this.listSpecByParent('');
    }
  }

  selectItem(event: MatSelectionListChange): void {
    const items = event.source._value;
    if (items && items.length > 0) {
      const item = items[0] as ISpec;
      if (item.kind === KIND_FOLDER) {
        this.specVisited.push(item.id);
        this.listSpecByParent(item.id);
        if (this.handleFolder) {
          this.handleFolder(item.id);
        }
      } else {
        if (this.form) {
          this.form.setValue({
            [this.inputName]: item.name,
          });
        }
      }
    }
  }

  private listSpecByParent(parentId: string): void {
    this.catalogService.querySpecs({ parentId }).subscribe({
      next: (res: HttpResponse<ISpec[]>) => {
        this.specs = res.body ?? [];
      },
    });
  }
}
