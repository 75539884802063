<app-header-pages
  title="{{ 'audits.title' | translate }}"
  subTitle="{{ 'audits.subTitle' | translate }}"
></app-header-pages>

<mat-form-field appearance="fill">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input matStartDate formControlName="start" placeholder="Start date" />
    <input matEndDate formControlName="end" placeholder="End date" />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker (closed)="closeDate()"></mat-date-range-picker>

  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
    >Invalid start date</mat-error
  >
  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
    >Invalid end date</mat-error
  >
</mat-form-field>

<div class="box">
  <table
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="mat-elevation-z8 w-100"
  >
    <ng-container
      matColumnDef="{{ column }}"
      *ngFor="let column of columnsToDisplay"
    >
      <th mat-header-cell *matHeaderCellDef>
        {{ "audits.table.header." + column | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="column === 'data'">
          <mat-icon *ngIf="element == expandedElement" style="cursor: pointer">
            keyboard_arrow_up
          </mat-icon>
          <mat-icon *ngIf="element != expandedElement" style="cursor: pointer">
            keyboard_arrow_down
          </mat-icon>
        </ng-container>
        <ng-container *ngIf="column !== 'data'">
          {{ element[column] }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="columnsToDisplay.length"
      >
        <div
          class="example-element-detail"
          [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <div class="example-element-diagram">
            <div *ngFor="let item of element['data'] | keyvalue">
              <b>{{ item.key }}</b
              >: {{ item.value }}
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplay"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
  </table>
</div>

<mat-paginator
  [length]="pages"
  [pageSize]="itemsPerPage"
  [pageSizeOptions]="[5, 10, 25, 100]"
  (page)="changePages($event)"
  class="w-100"
></mat-paginator>
