import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {getMenuIdentifier, ICatalog, ICategory, IContent, ISpec} from '../catalog.model';
import {createRequestOption} from '../../../../core/request/request-util';
import {isPresent} from '../../../../core/util/operators';
import moment from 'moment';
import {environment} from '../../../../../environments/environment';
import {endpoint} from '../../../../core/constants/services.constants';

export type EntityResponseType = HttpResponse<ICatalog>;
export type EntityArrayResponseType = HttpResponse<ICatalog[]>;
export type SpecArrayResponseType = HttpResponse<ISpec[]>;
export type ContentEntityType = HttpResponse<IContent>;

@Injectable({providedIn: 'root'})
export class CatalogService {
    private URL_BASE: string = environment.url_services;
    protected resourceUrl = `${this.URL_BASE}${endpoint.ENDPOINT_CATALOG}`;
    protected specResourceUrl = `${this.URL_BASE}${endpoint.ENDPOINT_SPECS}`;
    protected contentResourceUrl = `${this.URL_BASE}${endpoint.ENDPOINT_CONTENT}`;
    protected contentUploadUrl = `${this.URL_BASE}${endpoint.ENDPOINT_SPEC_UPLOAD}`;

    constructor(protected http: HttpClient) {
    }

    create(catalog: ICatalog): Observable<EntityResponseType> {
        const copy = this.convertDateFromClient(catalog);
        return this.http
            .post<ICatalog>(this.resourceUrl, copy, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
    }

    update(catalog: ICatalog): Observable<EntityResponseType> {
        const copy = this.convertDateFromClient(catalog);
        return this.http
            .put<ICatalog>(`${this.resourceUrl}/${getMenuIdentifier(catalog) as string}`, copy, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
    }

    partialUpdate(catalog: ICatalog): Observable<EntityResponseType> {
        const copy = this.convertDateFromClient(catalog);
        return this.http
            .patch<ICatalog>(`${this.resourceUrl}/${getMenuIdentifier(catalog) as string}`, copy, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
    }

    find(id: string): Observable<EntityResponseType> {
        return this.http
            .get<ICatalog>(`${this.resourceUrl}/${id}`, {observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http
            .get<ICatalog[]>(this.resourceUrl, {params: options, observe: 'response'})
            .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
    }

    querySpecs(req?: any): Observable<SpecArrayResponseType> {
        const options = createRequestOption(req);
        return this.http
            .get<ISpec[]>(this.specResourceUrl, {params: options, observe: 'response'});
    }

    findContent(id: string): Observable<ContentEntityType> {
        return this.http.get<IContent>(`${this.contentResourceUrl}/${id}`, {observe: 'response'});
    }

    uploadSpec(name: string, file: File): Observable<HttpEvent<{}>> {
        const formData: FormData = new FormData();
        formData.append('name', name);
        formData.append('openapi', file);
        return this.http.post<any>(this.contentUploadUrl, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    delete(id: string): Observable<HttpResponse<{}>> {
        return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    addMenuToCollectionIfMissing(catalogCollection: ICatalog[], ...menusToCheck: (ICatalog | null | undefined)[]): ICatalog[] {
        const menus: ICatalog[] = menusToCheck.filter(isPresent);
        if (menus.length > 0) {
            const menuCollectionIdentifiers = catalogCollection.map(menuItem => getMenuIdentifier(menuItem));
            const menusToAdd = menus.filter(menuItem => {
                const menuIdentifier = getMenuIdentifier(menuItem);
                if (menuIdentifier == null || menuCollectionIdentifiers.includes(menuIdentifier)) {
                    return false;
                }
                menuCollectionIdentifiers.push(menuIdentifier);
                return true;
            });
            return [...menusToAdd, ...catalogCollection];
        }
        return catalogCollection;
    }

    protected convertDateFromClient(menu: ICatalog): ICatalog {
        return Object.assign({}, menu, {
            createDate: menu.createDate?.isValid() ? menu.createDate.toJSON() : undefined,
            lastModifiedDate: menu.lastModifiedDate?.isValid() ? menu.lastModifiedDate.toJSON() : undefined,
        });
    }

    protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
        if (res.body) {
            res.body.createDate = res.body.createDate ? moment(res.body.createDate) : undefined;
            res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
        }
        return res;
    }

    protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
        if (res.body) {
            res.body.forEach((catalog: ICatalog) => {
                catalog.createDate = catalog.createDate ? moment(catalog.createDate) : undefined;
                catalog.lastModifiedDate = catalog.lastModifiedDate ? moment(catalog.lastModifiedDate) : undefined;
            });
        }
        return res;
    }
}
