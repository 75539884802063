// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

// export const VERSION = process.env.VERSION;
// export const DEBUG_INFO_ENABLED = Boolean(process.env.DEBUG_INFO_ENABLED);
import { environment } from '../../../environments/environment';

export const SERVER_API_URL = environment.url_services; // process.env.SERVER_API_URL;
export const ENVIRONMENT_PERMISSION = 'userPermission';
export const GLOBAL_SCOPE = 'GLOBAL';
export const ENVIRONMENT_SCOPE = 'ENVIRONMENT';
export const ROLE_AUDIT = 'ROLE_AUDIT';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const KIND_FOLDER = 'Folder';
// export const SERVER_API_URL = 'https://kong-tools-back-hl4ygwo2nq-uc.a.run.app/'
// export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;
