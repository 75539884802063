import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoutePipe} from './route.pipe';
import {NulleablePipe} from './nulleable.pipe';
import {PrettyPrintPipe} from './pretty-print.pipe';


@NgModule({
    declarations: [RoutePipe, NulleablePipe, PrettyPrintPipe],
    exports: [RoutePipe, NulleablePipe, PrettyPrintPipe],
    imports: [
        CommonModule
    ]
})
export class PipesModule {
}
