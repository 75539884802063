import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {SERVER_API_URL} from 'src/app/core/constants/app.constants';

@Injectable({
    providedIn: 'root'
})
export class ServicesService {

    changeLangKeyEvent = new EventEmitter<string>();

    constructor(private http: HttpClient) {
    }

    public resourceUrl = SERVER_API_URL + 'api/users';

    getManagementAudits(): void {

    }

    getUsersAuthorities(): any {
        return this.http.get(
            `${SERVER_API_URL}api/users/authorities`
        );
    }


    // query(req?: Pagination): Observable<HttpResponse<IUser[]>> {
    //   const options = createRequestOption(req);
    //   return this.http.get<IUser[]>(this.resourceUrl, { params: options, observe: 'response' });
    // }

    // getActivate(key: string): Observable<{}> {
    //   return this.http.get(SERVER_API_URL + 'api/activate', {
    //     params: new HttpParams().set('key', key),
    //   });
    // }

    // changepassword(newPassword: string, currentPassword: string): Observable<{}> {
    //   return this.http.post(SERVER_API_URL + 'api/account/change-password', { currentPassword, newPassword });
    // }

    // resetpassword(key: string, newPassword: string): Observable<{}> {
    //   return this.http.post(SERVER_API_URL + 'api/account/reset-password/finish', { key, newPassword });
    // }

    // resetPasswordInit(mail: string): Observable<{}> {
    //   return this.http.post(SERVER_API_URL + 'api/account/reset-password/init', mail);
    // }

    // save(account: IUser): Observable<{}> {
    //   return this.http.post(SERVER_API_URL + 'api/register', account);
    // }


    // getBeans(): Observable<Bean[]> {
    //   return this.http.get<ConfigProps>(SERVER_API_URL + 'management/configprops').pipe(
    //     map(configProps =>
    //       Object.values(
    //         Object.values(configProps.contexts)
    //           .map(context => context.beans)
    //           .reduce((allBeans: Beans, contextBeans: Beans) => ({ ...allBeans, ...contextBeans }))
    //       )
    //     )
    //   );
    // }

    // getPropertySources(): Observable<PropertySource[]> {
    //   return this.http.get<Env>(SERVER_API_URL + 'management/env').pipe(map(env => env.propertySources));
    // }

    // checkHealth(): Observable<Health> {
    //   return this.http.get<Health>(SERVER_API_URL + 'management/health');
    // }

    // changeLevel(name: string, configuredLevel: Level): Observable<{}> {
    //   return this.http.post(SERVER_API_URL + 'management/loggers/' + name, { configuredLevel });
    // }

    // findAll(): Observable<LoggersResponse> {
    //   return this.http.get<LoggersResponse>(SERVER_API_URL + 'management/loggers');
    // }

    //   getMetrics(): Observable<Metrics> {
    //     return this.http.get<Metrics>(SERVER_API_URL + 'management/jhimetrics');
    //   }

    //   threadDump(): Observable<ThreadDump> {
    //     return this.http.get<ThreadDump>(SERVER_API_URL + 'management/threaddump');
    //   }


    // save(account: Account): Observable<{}> {
    //   return this.http.post(SERVER_API_URL + 'api/account', account);
    // }

    // private fetch(): Observable<Account> {
    //   return this.http.get<Account>(SERVER_API_URL + 'api/account');
    // }

    // login(credentials: Login): Observable<void> {
    //   return this.http
    //     .post<JwtToken>(SERVER_API_URL + 'api/authenticate', credentials)
    //     .pipe(map(response => this.authenticateSuccess(response, credentials.rememberMe)));
    // }

    // login(credentials: Login): Observable<void> {
    //   return this.http
    //     .post<JwtToken>(SERVER_API_URL + 'api/authenticate', credentials)
    //     .pipe(map(response => this.authenticateSuccess(response, credentials.rememberMe)));
    // }


    ///////////////////7
    // public resourceUrl = SERVER_API_URL + 'api/users';

    // constructor(private http: HttpClient) {}

    // create(user: IUser): Observable<IUser> {
    //   return this.http.post<IUser>(this.resourceUrl, user);
    // }

    // update(user: IUser): Observable<IUser> {
    //   return this.http.put<IUser>(this.resourceUrl, user);
    // }

    // find(login: string): Observable<IUser> {
    //   return this.http.get<IUser>(`${this.resourceUrl}/${login}`);
    // }


    // delete(login: string): Observable<{}> {
    //   return this.http.delete(`${this.resourceUrl}/${login}`);
    // }

    // authorities(): Observable<string[]> {
    //   return of([Authority.ADMIN, Authority.USER]);
    // }

    //////////////////////////

    //####################

    // private infoUrl = SERVER_API_URL + 'management/info';
    // private profileInfo$!: Observable<ProfileInfo>;

    // constructor(private http: HttpClient) {}

    // getProfileInfo(): Observable<ProfileInfo> {
    //   if (this.profileInfo$) {
    //     return this.profileInfo$;
    //   }

    //   this.profileInfo$ = this.http.get<InfoResponse>(this.infoUrl).pipe(
    //     map((response: InfoResponse) => {
    //       const profileInfo: ProfileInfo = {
    //         activeProfiles: response.activeProfiles,
    //         inProduction: response.activeProfiles && response.activeProfiles.includes('prod'),
    //         swaggerEnabled: response.activeProfiles && response.activeProfiles.includes('swagger'),
    //       };
    //       if (response.activeProfiles && response['display-ribbon-on-profiles']) {
    //         const displayRibbonOnProfiles = response['display-ribbon-on-profiles'].split(',');
    //         const ribbonProfiles = displayRibbonOnProfiles.filter(
    //           profile => response.activeProfiles && response.activeProfiles.includes(profile)
    //         );
    //         if (ribbonProfiles.length > 0) {
    //           profileInfo.ribbonEnv = ribbonProfiles[0];
    //         }
    //       }
    //       return profileInfo;
    //     }),
    //     shareReplay()
    //   );
    //   return this.profileInfo$;
    // }

    //##################################################

    // describe('Service methods', () => {
    //   it('should call correct URL', () => {
    //     service.query(fakeRequest).subscribe();

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     const resourceUrl = SERVER_API_URL + 'management/audits';
    //     expect(req.request.url).toEqual(resourceUrl);
    //   });

    //   it('should return Audits', () => {
    //     let expectedResult: HttpResponse<Audit[]> = new HttpResponse({ body: [] });
    //     const audit = new Audit({ remoteAddress: '127.0.0.1', sessionId: '123' }, 'user', '20140101', 'AUTHENTICATION_SUCCESS');

    //     service.query(fakeRequest).subscribe(received => {
    //       expectedResult = received;
    //     });

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     req.flush([audit]);
    //     let audits: Audit[] = [];
    //     if (expectedResult.body !== null) {
    //       audits = expectedResult.body;
    //     }
    //     expect(audits.length).toBe(1);
    //     expect(audits[0]).toEqual(audit);
    //   });

    //   it('should propagate not found response', () => {
    //     let expectedResult = 0;
    //     service.query(fakeRequest).subscribe(null, (error: HttpErrorResponse) => {
    //       expectedResult = error.status;
    //     });

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     req.flush('Invalid request parameters', {
    //       status: 404,
    //       statusText: 'Bad Request',
    //     });
    //     expect(expectedResult).toEqual(404);
    //   });
    // });

    // describe('Service methods', () => {
    //   it('should call correct URL', () => {
    //     service.getBeans().subscribe();

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     const resourceUrl = SERVER_API_URL + 'management/configprops';
    //     expect(req.request.url).toEqual(resourceUrl);
    //   });

    //   it('should get the config', () => {
    //     const bean: Bean = {
    //       prefix: 'jhipster',
    //       properties: {
    //         clientApp: {
    //           name: 'jhipsterApp',
    //         },
    //       },
    //     };
    //     const configProps: ConfigProps = {
    //       contexts: {
    //         jhipster: {
    //           beans: {
    //             'io.github.jhipster.config.JHipsterProperties': bean,
    //           },
    //         },
    //       },
    //     };
    //     service.getBeans().subscribe(received => (expectedResult = received));

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     req.flush(configProps);
    //     expect(expectedResult).toEqual([bean]);
    //   });

    //   it('should get the env', () => {
    //     const propertySources: PropertySource[] = [
    //       {
    //         name: 'server.ports',
    //         properties: {
    //           'local.server.port': {
    //             value: '8080',
    //           },
    //         },
    //       },
    //     ];
    //     const env: Env = { propertySources };
    //     service.getPropertySources().subscribe(received => (expectedResult = received));

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     req.flush(env);
    //     expect(expectedResult).toEqual(propertySources);
    //   });
    // });

    // describe('Service methods', () => {
    //   it('should call correct URL', () => {
    //     service.findAll().subscribe();

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     const resourceUrl = SERVER_API_URL + 'management/loggers';
    //     expect(req.request.url).toEqual(resourceUrl);
    //   });

    //   it('should change log level', () => {
    //     service.changeLevel('main', 'ERROR').subscribe();

    //     const req = httpMock.expectOne({ method: 'POST' });
    //     const resourceUrl = SERVER_API_URL + 'management/loggers/main';
    //     expect(req.request.url).toEqual(resourceUrl);
    //     expect(req.request.body).toEqual({ configuredLevel: 'ERROR' });
    //   });
    // });

    // describe('Service methods', () => {
    //   it('should call correct URL', () => {
    //     service.getMetrics().subscribe();

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     const resourceUrl = SERVER_API_URL + 'management/jhimetrics';
    //     expect(req.request.url).toEqual(resourceUrl);
    //   });

    //   it('should return Metrics', () => {
    //     let expectedResult: Metrics | null = null;
    //     const metrics: Metrics = {
    //       jvm: {},
    //       'http.server.requests': {},
    //       cache: {},
    //       services: {},
    //       databases: {},
    //       garbageCollector: {},
    //       processMetrics: {},
    //     };

    //     service.getMetrics().subscribe(received => {
    //       expectedResult = received;
    //     });

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     req.flush(metrics);
    //     expect(expectedResult).toEqual(metrics);
    //   });

    //   it('should return Thread Dump', () => {
    //     let expectedResult: ThreadDump | null = null;
    //     const dump: ThreadDump = { threads: [{ name: 'test1', threadState: 'RUNNABLE' }] };

    //     service.threadDump().subscribe(received => {
    //       expectedResult = received;
    //     });

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     req.flush(dump);
    //     expect(expectedResult).toEqual(dump);
    //   });
    // });

    // describe('identity', () => {
    //   it('should call /account if user is undefined', () => {
    //     service.identity().subscribe();
    //     const req = httpMock.expectOne({ method: 'GET' });
    //     const resourceUrl = SERVER_API_URL + 'api/account';

    //     expect(req.request.url).toEqual(`${resourceUrl}`);
    //   });

    //   it('should call /account only once if not logged out after first authentication and should call /account again if user has logged out', () => {
    //     // Given the user is authenticated
    //     service.identity().subscribe();
    //     httpMock.expectOne({ method: 'GET' }).flush({});

    //     // When I call
    //     service.identity().subscribe();

    //     // Then there is no second request
    //     httpMock.expectNone({ method: 'GET' });

    //     // When I log out
    //     service.authenticate(null);
    //     // and then call
    //     service.identity().subscribe();

    //     // Then there is a new request
    //     httpMock.expectOne({ method: 'GET' });
    //   });

    //   describe('navigateToStoredUrl', () => {
    //     it('should navigate to the previous stored url post successful authentication', () => {
    //       // GIVEN
    //       storageService.setResponse('admin/users?page=0');

    //       // WHEN
    //       service.identity().subscribe();
    //       httpMock.expectOne({ method: 'GET' }).flush({});

    //       // THEN
    //       expect(storageService.getUrlSpy).toHaveBeenCalledTimes(1);
    //       expect(storageService.clearUrlSpy).toHaveBeenCalledTimes(1);
    //       expect(router.navigateByUrlSpy).toHaveBeenCalledWith('admin/users?page=0');
    //     });

    //     it('should not navigate to the previous stored url when authentication fails', () => {
    //       // WHEN
    //       service.identity().subscribe();
    //       httpMock.expectOne({ method: 'GET' }).error(new ErrorEvent(''));

    //       // THEN
    //       expect(storageService.getUrlSpy).not.toHaveBeenCalled();
    //       expect(storageService.clearUrlSpy).not.toHaveBeenCalled();
    //       expect(router.navigateByUrlSpy).not.toHaveBeenCalled();
    //     });

    //     it('should not navigate to the previous stored url when no such url exists post successful authentication', () => {
    //       // GIVEN
    //       storageService.setResponse(null);

    //       // WHEN
    //       service.identity().subscribe();
    //       httpMock.expectOne({ method: 'GET' }).flush({});

    //       // THEN
    //       expect(storageService.getUrlSpy).toHaveBeenCalledTimes(1);
    //       expect(storageService.clearUrlSpy).not.toHaveBeenCalled();
    //       expect(router.navigateByUrlSpy).not.toHaveBeenCalled();
    //     });
    //   });
    // });

    // describe('Service methods', () => {
    //   it('should call correct URL', () => {
    //     service.find('user').subscribe();

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     const resourceUrl = SERVER_API_URL + 'api/users';
    //     expect(req.request.url).toEqual(`${resourceUrl}/user`);
    //   });

    //   it('should return User', () => {
    //     let expectedResult: string | undefined;

    //     service.find('user').subscribe(received => {
    //       expectedResult = received.login;
    //     });

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     req.flush(new User(1, 'user'));
    //     expect(expectedResult).toEqual('user');
    //   });

    //   it('should propagate not found response', () => {
    //     let expectedResult = 0;

    //     service.find('user').subscribe(null, (error: HttpErrorResponse) => {
    //       expectedResult = error.status;
    //     });

    //     const req = httpMock.expectOne({ method: 'GET' });
    //     req.flush('Invalid request parameters', {
    //       status: 404,
    //       statusText: 'Bad Request',
    //     });
    //     expect(expectedResult).toEqual(404);
    //   });
    // });

}
