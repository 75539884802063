<form [formGroup]="formRouter" (submit)="submit()">
  <div class="container">
    <h1 mat-dialog-title>{{ title | translate }}</h1>
    <hr />
    <div mat-dialog-content>
      <div class="row">
        <ng-container *ngFor="let key of keys">
          <div
            class="col-12"
            *ngIf="schema[key].type === 'string' && !schema[key].one_of"
          >
            <label>{{ names[key].name | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <input
                matInput
                type="text"
                [value]="schema[key].default || ''"
                [formControlName]="key"
              />
              <mat-hint>{{ names[key].hint | translate }}</mat-hint>
              <mat-error *ngFor="let error of messageError.required">
                <span *ngIf="isValid(error.type, key)">{{
                  error.message | translate
                }}</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12" *ngIf="schema[key].type === 'map'">
            <app-combined-component
              hint="{{ names[key].hint | translate }}"
              name="{{ names[key].name | translate }}"
              [form]="formRouter"
              [inputName]="key"
              [stack]="values[key]"
              [options]="schema[key].elements?.one_of || null"
              [change]="handlerChange"
              [schema]="schema[key]"
            ></app-combined-component>
          </div>
          <div
            class="col-12"
            *ngIf="schema[key].type === 'set' || schema[key].type === 'array'"
          >
            <app-multiple-component
              [schema]="schema[key]"
              hint="{{ names[key].hint | translate }}"
              name="{{ names[key].name | translate }}"
              [form]="formRouter"
              [inputName]="key"
              [stack]="values[key]"
              [options]="schema[key].elements?.one_of || null"
              [change]="handlerChange"
            ></app-multiple-component>
          </div>
          <div class="col-12" *ngIf="schema[key].one_of">
            <app-select-component
              [schema]="schema[key]"
              hint="{{ names[key].hint | translate }}"
              name="{{ names[key].name | translate }}"
              [options]="schema[key].one_of"
              [form]="formRouter"
              [inputName]="key"
            ></app-select-component>
          </div>
          <div class="col-12" *ngIf="schema[key].type === 'boolean'">
            <mat-slide-toggle
              *ngIf="schema[key].type === 'boolean'"
              [formControlName]="key"
              >{{ names[key].name | translate }}</mat-slide-toggle
            >
            <br />
            <mat-hint>{{ names[key].hint | translate }}</mat-hint>
          </div>
          <div
            class="col-12"
            *ngIf="schema[key].type === 'integer' && !schema[key].one_of"
          >
            <label>{{ names[key].name | translate }}</label>
            <mat-form-field appearance="outline" class="w-100">
              <input
                matInput
                [value]="schema[key].default || ''"
                [formControlName]="key"
                type="number"
              />
              <mat-hint>{{ names[key].hint | translate }}</mat-hint>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>
    <div mat-dialog-actions align="end" class="mt-2 pl-3">
      <button class="btn btn-close" [mat-dialog-close]="undefined">
        {{ buttonCancel | translate }}
      </button>
      <button class="btn btn-action">
        {{
          modalOption == "create"
            ? (buttonCreate | translate)
            : (buttonEdit | translate)
        }}
      </button>
    </div>
  </div>
</form>
