<form [formGroup]="formRouter" (submit)="submit()">
    <div class="container">
        <h1 mat-dialog-title>{{title | translate }}</h1>
        <hr>
        <div mat-dialog-content>
            <div class="row">
                <div class="col-12">
                    <label>{{ 'routers.modal.name' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="name"/>
                        <mat-hint>{{ 'routers.hint.name' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'name')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.tags' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipListTags aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of tags" [selectable]="true" [removable]="true" (removed)="removeTag(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.tags' | translate }}"
                                    formControlName="tags"
                                    [matChipInputFor]="chipListTags"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTag($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.tags' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'tags')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.hosts' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipListHosts aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of tagsHosts" [selectable]="true" [removable]="true" (removed)="removeTagHosts(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.hosts' | translate }}"
                                    formControlName="hosts"
                                    [matChipInputFor]="chipListHosts"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTagHosts($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.hosts' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'hosts')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.paths' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipListPaths aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of tagsPaths" [selectable]="true" [removable]="true" (removed)="removeTagPaths(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.paths' | translate }}"
                                    formControlName="paths"
                                    [matChipInputFor]="chipListPaths"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTagPaths($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.paths' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'paths')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.headers' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="headers"/>
                        <mat-hint>{{ 'routers.hint.headers' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'headers')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.pathHandling' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="pathHandling"/>
                        <mat-hint>{{ 'routers.hint.pathHandling' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'pathHandling')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.httpRedirectStatus' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="redirectStatusCode"/>
                        <mat-hint>{{ 'routers.hint.redirectStatusCode' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'redirectStatusCode')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.regexPriority' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="regexPriority"/>
                        <mat-hint>{{ 'routers.hint.regexPriority' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'regexPriority')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.methods' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipListMethods aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of tagsMethods" [selectable]="true" [removable]="true" (removed)="removeTagMethods(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.paths' | translate }}"
                                    formControlName="methods"
                                    [matChipInputFor]="chipListMethods"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTagMethods($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.methods' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'methods')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.stripPath' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="stripPath"/>
                        <mat-hint>{{ 'routers.hint.stripPath' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'stripPath')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.preserveHost' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="preserveHost"/>
                        <mat-hint>{{ 'routers.hint.preserveHost' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'preserveHost')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.protocols' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipListProtocols aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of tagsProtocols" [selectable]="true" [removable]="true" (removed)="removeTagProtocols(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.protocols' | translate }}"
                                    formControlName="protocols"
                                    [matChipInputFor]="chipListProtocols"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addTagProtocols($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.protocols' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'protocols')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.snis' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="snis"/>
                        <mat-hint>{{ 'routers.hint.snis' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'snis')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.sources' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="sources"/>
                        <mat-hint>{{ 'routers.hint.sources' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'sources')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label>{{ 'routers.modal.destinations' | translate }}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-chip-list #chipDestinations aria-label="Fruit selection">
                            <mat-chip *ngFor="let tag of destinations" [selectable]="true" [removable]="true" (removed)="removeDestinatios(tag)">
                                {{tag}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input matInput placeholder="{{ 'routers.modal.destinations' | translate }}"
                                    formControlName="destinations"
                                    [matChipInputFor]="chipDestinations"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="true"
                                    (matChipInputTokenEnd)="addDestination($event)">
                        </mat-chip-list>
                        <mat-hint>{{ 'routers.hint.destinations' | translate }}</mat-hint>
                        <mat-error *ngFor="let error of messageError.required">
                            <span *ngIf="isValid(error.type, 'destinations')">{{ error.message  | translate }}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-slide-toggle formControlName="requestBuffering">{{ 'routers.modal.requestBuffering' | translate }}</mat-slide-toggle>
                    <br/>
                    <mat-hint>{{ 'routers.hint.requestBuffering' | translate }}</mat-hint>
                </div>
                <div class="col-12" style="margin-top: 15px;">
                    <mat-slide-toggle formControlName="responseBuffering">{{ 'routers.modal.responseBuffering' | translate }}</mat-slide-toggle>
                    <br/>
                    <mat-hint>{{ 'routers.hint.responseBuffering' | translate }}</mat-hint>
                </div>
            </div>
        </div>
        <div mat-dialog-actions align="end" class="mt-2 pl-3">
            <button class="btn btn-close" [mat-dialog-close]="undefined">{{ buttonCancel | translate }} </button>
            <button class="btn btn-action">{{ modalOption == 'create' ? (buttonCreate | translate) : (buttonEdit | translate) }}</button>
        </div>
    </div>
</form>
