import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';

import {AuditComponent} from './audit/audit.component';
import {LogsComponent} from './logs/logs.component';

import {SharedModule} from '../../theme/shared/shared.module';
import {DataTablesModule} from 'angular-datatables';
import {ConnectionsComponent} from './connections/connections.component';
import {UsersModule} from './users/users.module';
import {ManagementRoutingModule} from './management-routing.module';
import {ComponentsModule} from '../../components/components.module';
import {MaterialModule} from 'src/app/material.module';
import {ModalConnectionComponent} from './connections/modal-connection/modal-connection.component';
import {CoreModule} from 'src/app/core/core.module';

@NgModule({
    declarations: [AuditComponent, LogsComponent, ConnectionsComponent, ModalConnectionComponent],
    imports: [
        CommonModule,
        SharedModule,
        DataTablesModule,
        UsersModule,
        ManagementRoutingModule,
        ComponentsModule,
        MaterialModule,
        CoreModule
    ],
    providers: [
        DatePipe
    ]
})
export class ManagementModule {
}
