<app-header-pages title="{{'services.detail.title' | translate:{id: service.id} }}" subTitle="{{'services.detail.subTitle' | translate }}" class="margin-top0"></app-header-pages>

<div class="row margin-top0">
    <div class="col-2">
        <app-service-menu [service]="service"></app-service-menu>
    </div>
    <div class="col-10">
        <app-card [hidHeader]="true" headerClass="full-width">
            <form [formGroup]="formService" (ngSubmit)="submit()">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <label>{{ 'services.modal.name' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" formControlName="name"/>
                                <mat-hint>{{ 'services.hint.name' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'name')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <label>{{ 'services.modal.tags' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-chip-list #chipList aria-label="Fruit selection">
                                    <mat-chip *ngFor="let tag of tags" [selectable]="true" [removable]="true" (removed)="removeTag(tag)">
                                        {{tag}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    <input matInput placeholder="{{ 'services.modal.tags' | translate }}"
                                            formControlName="tags"
                                            [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="true"
                                            (matChipInputTokenEnd)="addTag($event)">
                                </mat-chip-list>
                                <mat-hint>{{ 'services.hint.tags' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'tags')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <label>{{ 'services.modal.url' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" formControlName="url"/>
                                <mat-hint>{{ 'services.hint.name' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'url')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <label>{{ 'services.modal.protocol' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" formControlName="protocol" [matAutocomplete]="auto"/>
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of protocolos" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-hint>{{ 'services.hint.name' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'protocol')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <label>{{ 'services.modal.host' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" formControlName="host"/>
                                <mat-hint>{{ 'services.hint.host' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'host')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <label>{{ 'services.modal.port' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="number" formControlName="port"/>
                                <mat-hint>{{ 'services.hint.port' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'port')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <label>{{ 'services.modal.path' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" formControlName="path"/>
                                <mat-hint>{{ 'services.hint.path' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'path')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <label>{{ 'services.modal.retries' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="number" formControlName="retries"/>
                                <mat-hint>{{ 'services.hint.retries' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'retries')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <label>{{ 'services.modal.connectTimeout' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="number" formControlName="connectTimeout"/>
                                <mat-hint>{{ 'services.hint.connectTimeout' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'connectTimeout')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <label>{{ 'services.modal.writeTimeout' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="number" formControlName="writeTimeout"/>
                                <mat-hint>{{ 'services.hint.writeTimeout' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'writeTimeout')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <label>{{ 'services.modal.readTimeout' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="number" formControlName="readTimeout"/>
                                <mat-hint>{{ 'services.hint.readTimeout' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'readTimeout')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <label>{{ 'services.modal.clientCertificate' | translate }}</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" formControlName="clientCertificate"/>
                                <mat-hint>{{ 'services.hint.clientCertificate' | translate }}</mat-hint>
                                <mat-error *ngFor="let error of messageError.required">
                                    <span *ngIf="isValid(error.type, 'clientCertificate')">{{ error.message  | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="mt-2 pl-3">
                    <button class="btn btn-action">{{ buttonEdit | translate }}</button>
                </div>
            </form>
        </app-card>
    </div>
</div>

