import {Component, OnInit} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';
import {LocalstorageService} from 'src/app/services/localstorage.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../../services/apis/auth.service';
import {User} from '../../../../core/model/login.model';

@Component({
    selector: 'app-translation-select',
    templateUrl: './translation-select.component.html',
    styleUrls: ['./translation-select.component.scss']
})
export class TranslationSelectComponent implements OnInit {
    selected: string;

    constructor(
        private translate: TranslateService,
        private authService: AuthService,
        private localstorageService: LocalstorageService
    ) {
    }

    ngOnInit(): void {
        this.selected = this.localstorageService.getItem('langKey') || 'es';
        this.translate.use(this.selected);
    }

    changeTranslate(select: MatSelectChange): void {
        this.translate.use(select.value);
        this.selected = select.value;
        const user = this.localstorageService.getItemObject<User>('user');
        user.langKey = select.value;
        this.authService.updateUserLang(user).subscribe();
        this.localstorageService.setItem<string>('langKey', select.value);
    }
}
