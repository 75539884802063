import {EventEmitter, Injectable} from '@angular/core';
import {SERVER_API_URL} from 'src/app/core/constants/app.constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../../core/model/login.model';
import {IConnection} from '../../pages/management/connections/connection.model';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    public userEmitter: EventEmitter<User> = new EventEmitter<User>();

    constructor(private http: HttpClient) {
    }

    getUsers(): Observable<any> {
        return this.http.get(
            `${SERVER_API_URL}/api/admin/users`
        );
    }

    getUser(user: User): Observable<any> {
        return this.http.get(
            `${SERVER_API_URL}/api/admin/users/${user}`
        );
    }

    getUsersSystem(user: string): Observable<any> {
        return this.http.get(
            `${SERVER_API_URL}/api/admin/users/${user}`
        );
    }

    actualizarUsuario(body: User): Observable<any> {
        return this.http.put(
            `${SERVER_API_URL}/api/admin/users`,
            body
        );
    }

    deleteUsuario(user: string): Observable<any> {
        return this.http.delete(
            `${SERVER_API_URL}/api/admin/users/${user}`
        );
    }

    crearUsuario(body: User, enviroment: string): Observable<any> {
        const headers = new HttpHeaders()
            .append('environment', enviroment);
        return this.http.post(
            `${SERVER_API_URL}/api/admin/users`,
            body,
            {headers}
        );
    }

    getPermission(): Observable<any> {
        return this.http.get(
            `${SERVER_API_URL}/api/permissions`
        );
    }

    getPermissionByScope(scope: string): Observable<any> {
        return this.http.get(
            `${SERVER_API_URL}/api/permissions/${scope}`
        );
    }
}
