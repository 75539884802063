<!-- profile body start -->
<div class="row">
    <div class="offset-md-3 col-md-6 order-md-2">

        <div class="merge-card">
            <app-card [hidHeader]="true" cardClass="mb-0" blockClass="d-flex align-items-center justify-content-between">
                <h5 class="mb-0">Cambiar contraseña</h5>
            </app-card>
            <app-card [hidHeader]="true" blockClass="border-top pro-det-edit collapse show">
                <form [formGroup]="formDatosPersonales" (ngSubmit)="changePassword()">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">Current password</label>
                        <div class="col-sm-9">
                            <input formControlName="currentPassword" type="password" class="form-control" placeholder="Current password">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">New password</label>
                        <div class="col-sm-9">
                            <input formControlName="newPassword" type="password" class="form-control" placeholder="New password">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-weight-bolder">New password confirmation</label>
                        <div class="col-sm-9">
                            <input formControlName="confirmPassword" type="password" class="form-control" placeholder="New password confirmation">
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-6">
                            <button class="btn btn-success btn-block" type="submit" [disabled]="!formDatosPersonales.valid">Guardar</button>
                        </div>
                    </div>
                </form>
            </app-card>
        </div>
    </div>

</div>
