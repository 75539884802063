import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';

import {AuthService} from 'src/app/services/apis-old/auth.service';
import {LocalstorageService} from 'src/app/services/localstorage.service';
import {User} from 'src/app/core/model/login.model';
import {Connection, IEnvironmentOrganization} from '../../../../../core/model/connection.model';
import {ConnectionsService} from '../../../../../services/apis/connections.service';
import {RowItem} from '../../../../../pages/management/connections/connections.component';
import {ToastrService} from 'ngx-toastr';
import {UsersService} from '../../../../../services/apis-old/users.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {

  public user: User;
  connectionActive: string;
  connections: Connection[] = [];
  environments: IEnvironmentOrganization[] = [];
  selectedEnvironment: string;
  imageProfile = '';

  constructor(
    private auth: AuthService,
    private connectionService: ConnectionsService,
    private toastr: ToastrService,
    private usersService: UsersService,
    private router: Router,
    private localStorageService: LocalstorageService,
  ) {
  }

  ngOnInit(): void {
    this.user = this.localStorageService.getItemObject<User>('user');
    this.selectedEnvironment = this.localStorageService.getItem('environment');
    this.loadEnvironments();
    this.imageProfile = this.user.imageUrl || 'assets/images/auth/kong-ico.png';
    this.usersService.userEmitter.subscribe(usuario => {
      if (this.user.id === usuario.id) {
        this.imageProfile = usuario.imageUrl || 'assets/images/auth/kong-ico.png';
        this.localStorageService.setItem('user', usuario);
      }
    });
    this.connectionService.getConnectionChangeEmitter().subscribe((val) => {
      if (val) {
        this.connectionService.getServers().subscribe((response: RowItem[]) => {
          this.connections = response.map(server => ({...server}));
          const activeConnection = this.connections.find(value => value.activo);
          this.connectionActive = activeConnection ? activeConnection.nombre : '';
          if (activeConnection) {
            this.localStorageService.removeItem('activeConnection');
            this.localStorageService.setItem<Connection>('activeConnection', activeConnection);
          }
        }, err => {
          console.log(err);
        });
      }
    });
  }

  private loadEnvironments(): void {
    const environmentsOrganizations = this.user.environmentPermissions.map(value => {
      return {environment: value.environment, organization: value.organization};
    });
    this.environments.push(...environmentsOrganizations);
    this.connectionService.getServers().subscribe((response: RowItem[]) => {
      this.connections = response.map(server => ({...server}));
      const activeConnection = this.connections.find(value => value.activo);
      this.connectionActive = activeConnection ? activeConnection.nombre : '';
      if (activeConnection) {
        this.localStorageService.removeItem('activeConnection');
        this.localStorageService.setItem<Connection>('activeConnection', activeConnection);
        this.localStorageService.setItem('environment', activeConnection.enviroment);
        this.connectionService.getConnectionEmitter().emit(activeConnection.enviroment);
      }
    }, err => {
      console.log(err);
    });
  }

  salir(): void {
    this.auth.logout();
  }

  selectEnvironment(env: IEnvironmentOrganization): void {
    this.localStorageService.setItem('environment', env.environment);
    this.toastr.success(`Se ha cambiado el ambiente a ${env.environment}`);
    this.selectedEnvironment = env.environment;
    this.connectionService.emitConnectionSelected(env.environment);
  }

  changePassword(): void {
    this.router.navigate(['/account', 'change-password']);
  }
}
