import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ColumnSetup } from '../../../components/dynamic-table/dynamic-table.component';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SweetAlertComponent } from '../../../components/sweet-alert/sweet-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalUpstreamsComponent } from './modal-upstreams/modal-upstreams.component';
import { UpstreamService } from '../../../services/gateway/upstream.service';
import { UpstreamsOptionsComponent } from './upstreams-options/upstreams-options.component';
import { ConnectionsService } from '../../../services/apis/connections.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ServicesService } from 'src/app/services/gateway/services.service';
import { SchemasService } from 'src/app/services/gateway/schemas.service';

export interface RowItem {
  id: string;
  name: string;
  tags: string;
  host_header: string;
  algorithm: string;
  created_at: string;
  hash_fallback: string;
  client_certificate: string;
  hash_on: string;
  hash_on_cookie: string;
  hash_on_cookie_path: string;
  protocol: string;
  hash_on_header: string;
  hash_fallback_header: string;
  healthchecks: any;
  slots: number;
}

// healthchecks: {threshold: 0, active: {,…},…}

@Component({
  selector: 'app-upstreams',
  templateUrl: './upstreams.component.html',
  styleUrls: ['./upstreams.component.scss'],
})
export class UpstreamsComponent implements OnInit, AfterViewInit {
  public spinner = false;
  public dataSource: RowItem[] = [];
  public columnsSetup: ColumnSetup[] = [
    { columnDef: 'testUrl', title: '', custom: true },
    { columnDef: 'name', title: '', custom: true },
    { columnDef: 'tags', title: 'upstreams.table.tags', custom: true },
    {
      columnDef: 'algorithm',
      title: 'upstreams.table.algorithm',
      cell: (row: RowItem) => `${row.algorithm}`,
    },
    {
      columnDef: 'slots',
      title: 'upstreams.table.slots',
      cell: (row: RowItem) => `${row.slots}`,
    },
    {
      columnDef: 'createdDate',
      title: 'upstreams.table.createdDate',
      cell: (row: RowItem) =>
        `${this.dateFormat.transform(row.created_at, 'dd/MM/yyyy HH:mm')}`,
    },
    { columnDef: 'delete', title: '', custom: true },
  ];

  constructor(
    private translate: TranslateService,
    private dateFormat: DatePipe,
    private upstreamService: UpstreamService,
    private toastr: ToastrService,
    private sweet: SweetAlertComponent,
    private connectionService: ConnectionsService,
    private router: Router,
    private schemasService: SchemasService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getUpstreams();
  }

  createUpstream(): void {
    this.schemasService.getUpstreams().subscribe(
      (response) => {
        const schema = response.fields;
        const dialogRef = this.dialog.open(ModalUpstreamsComponent, {
          height: 'auto',
          width: '750px',
          disableClose: true,
          data: { schema },
        });
        dialogRef.componentInstance.title = 'upstreams.modal.titleOpen';
        dialogRef.componentInstance.buttonCancel =
          'upstreams.modal.buttonCancel';
        dialogRef.componentInstance.buttonCreate =
          'upstreams.modal.buttonCreate';
        dialogRef.afterClosed().subscribe(() => {
          this.getUpstreams();
        });
        this.connectionService.getConnectionEmitter().subscribe((value) => {
          if (value) {
            this.getUpstreams();
          }
        });
      },
      (err) => {}
    );
  }

  private getUpstreams(): void {
    this.dataSource = [];
    this.spinner = true;
    this.upstreamService.getUpstreams().subscribe(
      (response: RowItem[]) => {
        this.spinner = false;
        this.dataSource = response;
      },
      (err) => {
        console.log(err);
        this.spinner = false;
      }
    );
  }

  getStatusUrl(row): void {}

  statusUpstream(id): void {}

  editUpstream(row): void {
    const dialogRef = this.dialog.open(ModalUpstreamsComponent, {
      height: 'auto',
      width: '750px',
      disableClose: true,
      data: row,
    });
    dialogRef.componentInstance.modalOption = 'edit';
    dialogRef.componentInstance.title = 'upstreams.modal.titleEdit';
    dialogRef.componentInstance.buttonCancel = 'upstreams.modal.buttonCancel';
    dialogRef.componentInstance.buttonEdit = 'upstreams.modal.buttonEdit';
    dialogRef.afterClosed().subscribe(() => {
      this.getUpstreams();
    });
  }

  deleteUpstream(id): void {
    this.translate.get('upstreams.message').subscribe((translate) => {
      this.sweet.confirmBox({
        title: translate.confirm.title,
        text: translate.confirm.text,
        confirmButtonText: translate.confirm.button,
        showCancelButton: true,
        alertAction: (result) => {
          if (result.isConfirmed) {
            this.upstreamService.deleteUpstream(id).subscribe(
              () => {
                this.toastr.success(translate.deleted);
                this.getUpstreams();
              },
              (err) => {
                console.log(err);
                this.sweet.error({
                  title: translate.error,
                });
              }
            );
          }
        },
      });
    });
  }

  getUpstreamOptions(row): void {
    const dialogRef = this.dialog.open(UpstreamsOptionsComponent, {
      height: 'auto',
      width: '750px',
      disableClose: true,
      data: row,
    });
    dialogRef.componentInstance.title = 'upstreams.modal.detailTitle';
    dialogRef.componentInstance.buttonCancel = 'upstreams.modal.buttonCancel';
  }

  showDetails(row): void {
    this.router.navigate(['admin', 'gateway', 'upstreams', row.id, 'detail']);
  }
}
