import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {ActivatedRoute} from '@angular/router';
import {ConsumerBasicCredential, ConsumerHmacCredential, ConsumerJwtCredential, ConsumerKeyCredential, ConsumerModel, ConsumerOauthCredential} from '../../../../core/model/consumer.model';
import {FormControl, FormGroup} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MessageError} from '../../../../core/interfaces/message-error.interface';
import {ConsumerService} from '../../../../services/gateway/consumer.service';
import {ToastrService} from 'ngx-toastr';
import {MatChipInputEvent} from '@angular/material/chips';
import {ColumnSetup} from '../../../../components/dynamic-table/dynamic-table.component';
import {forkJoin} from 'rxjs';
import {SweetAlertComponent} from '../../../../components/sweet-alert/sweet-alert.component';
import {MatDialog} from '@angular/material/dialog';
import {AddGroupComponent} from '../add-group/add-group.component';
import {TranslateService} from '@ngx-translate/core';

export interface RowItem {
    id: string;
    tags: string[];
    group: string;
    consumer: ConsumerModel;
    created_at: number;
}

@Component({
    selector: 'app-edit-consumer',
    templateUrl: './edit-consumer.component.html',
    styleUrls: ['./edit-consumer.component.scss']
})
export class EditConsumerComponent implements OnInit {
    indexTab = 0;
    consumer: ConsumerModel;
    basicCredentials: ConsumerBasicCredential[] = [];
    keyCredentials: ConsumerKeyCredential[] = [];
    hmacCredentials: ConsumerHmacCredential[] = [];
    oauthCredentials: ConsumerOauthCredential[] = [];
    jwtCredentials: ConsumerJwtCredential[] = [];
    formConsumer: FormGroup;
    tags: string[] = [];
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    MESSAGE_REQUIRED = 'consumers.message.required';
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    @ViewChild(MatTabGroup) tabGroup;
    public spinner = false;
    public dataSource: RowItem[] = [];
    public columnsSetup: ColumnSetup[] = [
        {columnDef: 'group', title: 'consumers.table.group', cell: (row: RowItem) => `${row.group}`},
    ];

    constructor(private translate: TranslateService,
                private consumerService: ConsumerService,
                private toastr: ToastrService,
                public dialog: MatDialog,
                private sweet: SweetAlertComponent,
                protected activatedRoute: ActivatedRoute) {
        this.formConsumer = this.buildForm();
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(({consumer}) => {
            this.consumer = consumer;
            if (consumer) {
                this.updateData();
            }
        });
    }

    isValid(type: string, name: string): boolean {
        return this.formConsumer.get(name).hasError(type);
    }

    buildForm(): FormGroup {
        return new FormGroup({
            id: new FormControl(null, []),
            username: new FormControl('', []),
            customId: new FormControl('', []),
            tags: new FormControl(null, []),
        });
    }

    updateData(): void {
        this.tags = [...this.consumer.tags];
        this.formConsumer.patchValue({
            id: this.consumer.id,
            username: this.consumer.username,
            customId: this.consumer.custom_id,
        });
    }

    selectionChange(event: MatTabChangeEvent): void {
        this.indexTab = event.index;
        switch (this.indexTab) {
            case 1:
                this.getGroups();
                break;
            case 2:
                const allRequest = [
                    this.consumerService.getBasicAuthCredential(this.consumer.id),
                    this.consumerService.getKeyAuthCredential(this.consumer.id),
                    this.consumerService.getHmacAuthCredential(this.consumer.id),
                    this.consumerService.getOAuth2Credential(this.consumer.id),
                    this.consumerService.getJwtAuthCredential(this.consumer.id),
                ];
                forkJoin(allRequest).subscribe((responses: Array<any>) => {
                    this.basicCredentials = responses[0] as Array<ConsumerBasicCredential>;
                    this.keyCredentials = responses[1] as Array<ConsumerKeyCredential>;
                    this.hmacCredentials = responses[2] as Array<ConsumerHmacCredential>;
                    this.oauthCredentials = responses[3] as Array<ConsumerOauthCredential>;
                    this.jwtCredentials = responses[4] as Array<ConsumerJwtCredential>;
                });
                break;
            default:
                break;
        }
    }

    submit(): void {
        if (this.formConsumer.invalid) {
            this.translate.get('consumers.message').subscribe(translate => {
                this.toastr.error(translate.invalidForm);
                return;
            });
        }
        const formValues = this.formConsumer.value;
        const requestServer = {
            id: formValues.id,
            username: formValues.username,
            custom_id: formValues.customId,
            tags: this.tags,
        };
        this.translate.get('consumers.message').subscribe(translate => {
            this.consumerService.updateConsumer(requestServer)
                .subscribe(() => {
                    this.toastr.success(translate.updated);
                }, (err) => {
                    this.toastr.error(translate.error);
                });
        });
    }

    addTag(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTag(tag: string): void {
        const index = this.tags.indexOf(tag);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    deleteGroup(grupo: RowItem): void {
        this.translate.get('consumers.message').subscribe(translate => {
            this.sweet.confirmBox({
                title: translate.confirm.title,
                text: translate.confirm.text,
                confirmButtonText: translate.confirm.button,
                showCancelButton: true,
                alertAction: (result) => {
                    if (result.isConfirmed) {
                        this.spinner = true;
                        this.consumerService.deleteGroup(grupo.id, this.consumer.id).subscribe(() => {
                                this.toastr.success(translate.deleted);
                                this.getGroups();
                            },
                            (err) => {
                                console.log(err);
                                this.sweet.error({
                                    title: translate.error
                                });
                            }
                        );
                    }
                }
            });
        });
    }

    getGroups(): void {
        this.consumerService.getAcls(this.consumer.id).subscribe(grupos => {
            this.dataSource = grupos;
        });
    }

    addGroup(): void {
        const dialogRef = this.dialog.open(AddGroupComponent, {
            height: 'auto',
            width: '570px',
            disableClose: false,
            data: this.consumer
        });
        dialogRef.afterClosed().subscribe((hmac) => {
            if (hmac) {
                this.getGroups();
            }
        });
    }
}
