<mat-card class="card-table">

    <mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of columns">
            <mat-header-cell *matHeaderCellDef scope="col"> {{ column.title | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [innerHTML]="column.cell(row)"></mat-cell>
        </ng-container>
        <ng-content></ng-content>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div class="loader" *ngIf="spinner"></div>
    <h4 *ngIf="itemSize === 0 && !spinner" class="msj-table">No se encontraron datos</h4>
    <mat-paginator *ngIf="showPage" [length]="itemSize" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20, 30]" (page)="changePage($event)" showFirstLastButtons></mat-paginator>
</mat-card>