import { Component, Input, OnInit } from '@angular/core';
import { NavigationItem } from '../../../layout/admin/navigation/navigation';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() type: string;

  public navigation: any;
  breadcrumbList: Array<any> = [];
  public navigationList: Array<any> = [];

  constructor(
    private route: Router,
    private location: Location,
    public nav: NavigationItem,
    private titleService: Title
  ) {
    this.navigation = this.nav.get();
    this.type = 'theme2';
    this.setBreadcrumb();
  }

  ngOnInit(): void {
    let routerUrl: string;
    routerUrl = this.route.url;
    if (routerUrl && typeof routerUrl === 'string') {
      this.filterNavigation(routerUrl);
    }
  }

  setBreadcrumb(): void {
    let routerUrl: string;
    this.route.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        this.breadcrumbList.length = 0;
        const activeLink = router.url;
        this.filterNavigation(activeLink);
      }
    });
  }

  async filterNavigation(activeLink): Promise<void> {
    const result = [];
    const path = this.route.url.split(`/admin/`);
    const title = 'Welcome';
    if (!path[1]) {
      return;
    }
    const url = path[1].split('/');
    if (url[0] === 'home' && url[1] === 'specs') {
      const newUrl = [url[0], url[1]];
      newUrl.forEach((a, index) => {
        result.push({
          url: index > 0 ? `${this.route.url.split(a)[0]}${a}` : null,
          title: a,
          breadcrumbs: 'breadcrumbs',
          type: 'type',
        });
      });
      if (url.length > 2) {
        const paths = JSON.parse(localStorage.getItem('spec-path'));
        if (paths.length > 0) {
          paths.forEach((a, index) => {
            if (a.kind != 'Folder') {
              return;
            }
            result.push({
              url: `${this.route.url.split('specs')[0]}/specs/folder/${a.id}`,
              title: a.name,
              breadcrumbs: 'breadcrumbs',
              type: 'type',
            });
          });
        }
      }
      this.navigationList = result;
      this.titleService.setTitle(title + ' | Kong Tools Admin');
      return;
    }
    if (!this.navigation) {
      this.navigation = [];
    }
    url.forEach((a, index) => {
      result.push({
        url: index > 0 ? `${this.route.url.split(a)[0]}${a}` : null,
        title: a,
        breadcrumbs: 'breadcrumbs',
        type: 'type',
      });
    });
    this.navigationList = result;
    this.titleService.setTitle(title + ' | Kong Tools Admin');
  }
}
