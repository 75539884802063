import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { endpoint } from '../../core/constants/services.constants';
import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoutesModel } from '../../core/model/routes.model';
import { SpecCreate, SpecFolderEdit } from 'src/app/core/model/spec.model';

@Injectable({
  providedIn: 'root',
})
export class SpecService {
  private URL_BASE: string = environment.url_services;
  private CONST_PARENTS_ID = 'parentId';
  private ENDPOINT_SPECS = `${this.URL_BASE}${endpoint.ENDPOINT_SPECS}`;
  private ENDPOINT_SPECS_CONTENTS = `${this.URL_BASE}${endpoint.ENDPOINT_SPECS_CONTENTS}`;
  private ENDPOINT_SPEC_UPLOAD = `${this.URL_BASE}${endpoint.ENDPOINT_SPEC_UPLOAD}`;
  public size = 10;

  constructor(private http: HttpClient) {}

  getSpecContent(id: string): Observable<any> {
    return this.http.get(`${this.ENDPOINT_SPECS_CONTENTS}/${id}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getSpecs(parentId: string = ''): Observable<any> {
    return this.http
      .get(`${this.ENDPOINT_SPECS}?${this.CONST_PARENTS_ID}=${parentId}`, {
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteSpecs(id: string): Observable<any> {
    return this.http.delete(`${this.ENDPOINT_SPECS}/${id}`);
  }

  createSpec(body: SpecCreate): Observable<any> {
    const { name, openapi, openapiFile, parentId, kind } = body;
    const formData: FormData = new FormData();
    formData.append('kind', kind);
    formData.append('name', name);
    if (openapiFile) {
      formData.append('openapiFile', openapiFile);
    } else {
      formData.append('openapi', openapi);
    }
    if (parentId) {
      formData.append('parentId', parentId);
    }
    return this.http.post(this.ENDPOINT_SPECS, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  editFolder(id: string, body: SpecCreate): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('name', body.name);
    return this.http.put(`${this.ENDPOINT_SPECS}/${id}`, formData);
  }

  uploadSpec(name: string, value: File | string, file = true): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('name', name);
    formData.append('openapi', value);
    return this.http.post<any>(
      file ? this.ENDPOINT_SPEC_UPLOAD : this.ENDPOINT_SPECS_CONTENTS,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  updateSpec(id: string, text: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('openapi', text);
    return this.http.put(`${this.ENDPOINT_SPECS}/${id}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
