import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageError } from '../../../../../core/interfaces/message-error.interface';
import { MatTabGroup } from '@angular/material/tabs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServicesService } from '../../../../../services/gateway/services.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-route-service',
  templateUrl: './add-route-service.component.html',
  styleUrls: ['./add-route-service.component.scss'],
})
export class AddRouteServiceComponent implements OnInit, AfterViewInit {
  type: 'global' | 'service' | 'route';
  serviceId: string;
  public tabIndex = 0;
  public formRouter: FormGroup;
  public inputs;
  public keys = [];
  public schema: Record<string, unknown> = {};
  public names: Record<string, { name: string; hint: string }> = {};
  public values: Record<string, string[]> = {};
  title = '';
  selectedTab = 0;
  buttonCreate = '';
  buttonEdit = '';
  buttonCancel = '';
  modalOption = 'create';
  MESSAGE_REQUIRED = 'routers.message.required';
  route: any;
  idRoute: string;
  public messageError: MessageError = {
    required: [{ type: 'required', message: this.MESSAGE_REQUIRED }],
  };
  @ViewChild(MatTabGroup) tabGroup;
  tags: string[] = [];
  destinations: string[] = [];
  tagsHosts: string[] = [];
  tagsPaths: string[] = [];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
    private translate: TranslateService,
    private servicesService: ServicesService,
    private toastr: ToastrService,
    public dialog: MatDialogRef<AddRouteServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.serviceId = data.serviceId;
    this.route = data.route;
    if (this.route) {
      this.modalOption = 'update';
      this.idRoute = this.route.id;
    }
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.processSchema();
  }

  private processSchema(): void {
    const schema = this.data.schema;
    const form = {
      id: new FormControl(null, []),
    };
    if (!schema) {
      return;
    }
    schema.map((field) => {
      const keys = Object.keys(field);
      const key = keys[0];
      const options = [];
      let defaultValue = null;
      if (!field[key].auto) {
        this.keys.push(key);
        if (key === 'methods') {
          field[key].elements.one_of = [
            'GET',
            'POST',
            'PUT',
            'DELETE',
            'OPTIONS',
            'PATCH',
          ];
        }
        this.schema[key] = field[key];
        this.names[key] = {
          name: `routers.modal.${key}`,
          hint: `routers.hint.${key}`,
        };
        if (field[key].required || key === 'name') {
          options.push(Validators.required);
        }
        if (field[key].default || field[key].default === 0) {
          this.values[key] = [];
          if (Array.isArray(field[key].default)) {
            this.values[key] = field[key].default;
          } else {
            this.values[key] = field[key].default;
          }
          defaultValue = field[key].default;
        }
        if (field[key].type === 'boolean' && !field[key].default) {
          defaultValue = false;
        }
        if (field[key].type === 'boolean' && !field[key].default) {
          defaultValue = false;
        }
        if (
          (field[key].type === 'set' || field[key].type === 'array') &&
          !field[key].default
        ) {
          defaultValue = [];
        }
        form[key] = new FormControl(defaultValue, options);
      }
    });
    this.formRouter = this.buildForm(form);
    if (this.data.serviceId) {
      this.formRouter.patchValue({
        service: this.data.serviceId,
      });
    }
    if (this.route) {
      this.updateForm(this.route);
    }
  }

  handlerChange(form, field) {
    const formValues = form.value;
    const value = formValues[field];
    if (field === 'protocols') {
      if (value.indexOf('https') >= 0 || value.indexOf('http') >= 0) {
        form.get('methods').setValidators([Validators.required]);
        form.get('hosts').setValidators([Validators.required]);
        form.get('headers').setValidators([Validators.required]);
        form.get('paths').setValidators([Validators.required]);
        form.get('snis').setValidators([Validators.required]);
        form.patchValue({
          preserve_host: true,
        });
      }
      if (value.indexOf('grpc') >= 0 || value.indexOf('grpcs') >= 0) {
        form.get('methods').setValidators([]);
        form.get('hosts').setValidators([Validators.required]);
        form.get('headers').setValidators([Validators.required]);
        form.get('paths').setValidators([Validators.required]);
        form.get('snis').setValidators([]);
        form.patchValue({
          methods: null,
          strip_path: false,
          preserve_host: false,
        });
      }
    }
  }

  isValid(type: string, name: string): boolean {
    if (this.formRouter.get(name)) {
      return this.formRouter.get(name).hasError(type);
    }
  }

  checking(name: string) {
    return this.formRouter.get(name)?.hasError('required');
  }

  buildForm(form): FormGroup {
    return new FormGroup(form);
  }

  submit(): void {
    const formValues = this.formRouter.value;
    const fields = {};

    this.keys.map((key) => {
      fields[key] = formValues[key] || null;
      if (Array.isArray(formValues[key]) && formValues[key].length === 0) {
        fields[key] = null;
      }
    });

    if (this.formRouter.invalid) {
      this.translate.get('routers.message').subscribe((translate) => {
        this.toastr.error(translate.invalidForm);
        return;
      });
    }

    if (this.modalOption === 'create') {
      this.translate.get('routers.message').subscribe((translate) => {
        this.servicesService
          .createRouterToService(fields, this.serviceId)
          .subscribe(
            () => {
              this.toastr.success(translate.created);
              this.dialog.close();
            },
            (err) => {
              this.toastr.error(err.error?.message || err.message);
              console.error(err);
            }
          );
      });
    } else {
      this.translate.get('routers.message').subscribe((translate) => {
        this.servicesService
          .updateRouterService({ ...fields, id: this.route.id }, this.serviceId)
          .subscribe(
            () => {
              this.toastr.success(translate.updated);
              this.dialog.close();
            },
            (err) => {
              this.toastr.error(translate.error);
              console.error(err);
            }
          );
      });
    }
  }

  updateForm(data: any): void {
    const keys = Object.keys(data);
    const patch = {};
    if (keys.length > 0) {
      keys.map((key) => {
        patch[key] = data[key];
      });
    }
    this.formRouter.patchValue(patch);
  }
}
