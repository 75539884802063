<!--    <span class="tag" *ngFor="let tag of tags"><mat-icon class="mat-icon-tag">local_offer</mat-icon>{{tag}}</span>-->
<div>
    <ng-container *ngIf="categories; else noCategories">
        <ng-container *ngIf="categories.length == 0">
            <span>Sin Categorias</span>
        </ng-container>
        <ng-container *ngIf="categories.length == 1">
        <span class="" style="font-size: 13px;padding-bottom: 7px">
            {{categories[0].name}}
        </span>
        </ng-container>
        <ng-container *ngIf="categories.length > 1">
        <span class="" style="font-size: 13px;padding-bottom: 7px">
            {{categories[0].name}}, <a style="font-size: 14px;cursor: pointer" [matMenuTriggerFor]="menu">{{getMoreCategories()}}</a>
        </span>
            <mat-menu #menu="matMenu">
                <ng-container *ngFor="let category of categories; let i = index">
                    <span mat-menu-item *ngIf="i != 0">{{category.name}}</span>
                </ng-container>
            </mat-menu>
        </ng-container>
    </ng-container>
    <ng-template #noCategories>
        <span>Sin Categorias</span>
    </ng-template>
</div>
