<app-card [hidHeader]="true" headerClass="full-width">
    <div class="navbar-content">
        <div class="collapse" id="nav-user-link" [style.display]="'block'">
            <ul class="list-unstyled">
                <hr style="margin-bottom: 0rem; margin-top: 1rem;"/>
                <li class="list-group-item"><a [routerLink]="['/admin','gateway','services', service.id]">
                    <i class="feather icon-user m-r-5"></i>Detalle del Servicio</a>
                </li>
                <li class="list-group-item">
                    <a [routerLink]="['/admin','gateway','services', service.id, 'plugins']"><i class="feather icon-log-out m-r-5"></i>Plugins</a>
                </li>
                <li class="list-group-item">
                    <a [routerLink]="['/admin','gateway','services', service.id, 'routes']"><i class="feather icon-log-out m-r-5"></i>Routes</a>
                </li>
                <hr style="margin-top: 0rem; margin-bottom: 0rem;"/>
            </ul>
        </div>
    </div>
</app-card>
