import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {LocalstorageService} from '../../services/localstorage.service';
import {IUser} from '../model/login.model';
import {map} from 'rxjs/operators';
import {INavigationItem} from '../../theme/layout/admin/navigation/navigation';
import {ROLE_ADMIN} from '../constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(private localStorageService: LocalstorageService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(next);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(next);
  }

  private check(routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return of(this.localStorageService.getItemObject<IUser>('user')).pipe(
      map(user => {
        if (user) {
          const data = routeSnapshot.data;
          if (data && data.authorization) {
            const item = data.authorization as INavigationItem;
            return this.userHasPermissionToItem(item, user);
          }
          return true;
        }
        this.router.navigate(['/account', 'login']);
        return false;
      })
    );
  }

  userHasPermissionToItem(item: INavigationItem, user: IUser): boolean {
    let authorities = user.authorities;
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    if (authorities.includes(ROLE_ADMIN)) {
      return true;
    }
    return this.userHasPermissionToMenu(item, user);
  }

  private userHasPermissionToMenu(item: INavigationItem, user: IUser): boolean {
    const hasGlobalPermission = user.globalPermissions.some(value => value.menu === item.id);
    if (hasGlobalPermission) {
      return true;
    }
    const currentEnvironment = this.localStorageService.getItem('environment');
    const userEnvPermission = user.environmentPermissions.find(value => value.environment === currentEnvironment);
    if (!userEnvPermission) {
      return false;
    }
    const itemPermissions = item.permissions;
    const permissionsEnv = userEnvPermission.permissions;
    return itemPermissions.some(permission => permissionsEnv.some(value => value.name === permission));
  }
}
