import {Component, Inject, OnInit} from '@angular/core';
import {IPlugin, IPluginData} from '../../../../core/model/plugins.model';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MessageError} from '../../../../core/interfaces/message-error.interface';
import {PluginsService} from '../../../../services/gateway/plugins.service';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
    selector: 'app-add-datadog-plugin',
    templateUrl: './add-datadog-plugin.component.html',
    styleUrls: ['./add-datadog-plugin.component.scss']
})
export class AddDatadogPluginComponent implements OnInit {
    name: string;
    plugin: IPlugin;
    formPlugin: FormGroup;
    metricsForm: FormArray;
    defaultsMetrics: any[] = [];
    title = '';
    modalOption = 'create';
    buttonCancel = '';
    buttonCreate = '';
    buttonEdit = '';
    schema: any;
    type: 'global' | 'service' | 'route';
    typeId: string;
    MESSAGE_REQUIRED = 'plugins.message.required';
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    tags: Record<string, Array<string>> = {};
    names: string[] = ['request_count', 'request_size', 'response_size', 'latency', 'upstream_latency', 'kong_latency'];
    statTypes: string[] = [];
    consumerIdentifiers: string[] = [];

    constructor(private translate: TranslateService,
                private pluginsService: PluginsService,
                public dialog: MatDialogRef<AddDatadogPluginComponent>,
                @Inject(MAT_DIALOG_DATA) public data: IPluginData) {
        this.type = data.type;
        this.typeId = data.typeId;
        this.title = data.plugin.value.description;
        this.name = data.plugin.key;
        this.metricsForm = new FormArray([
            new FormGroup({
                name: new FormControl('request_count', []),
                stat_type: new FormControl('gauge', []),
                sample_rate: new FormControl(1, []),
                consumer_identifier: new FormControl('username', []),
                tags: new FormControl('', []),
            }),
            new FormGroup({
                name: new FormControl('request_size', []),
                stat_type: new FormControl('gauge', []),
                sample_rate: new FormControl(1, []),
                consumer_identifier: new FormControl('username', []),
                tags: new FormControl('', []),
            }),
            new FormGroup({
                name: new FormControl('response_size', []),
                stat_type: new FormControl('gauge', []),
                sample_rate: new FormControl(1, []),
                consumer_identifier: new FormControl('username', []),
                tags: new FormControl('', []),
            }),
            new FormGroup({
                name: new FormControl('latency', []),
                stat_type: new FormControl('gauge', []),
                sample_rate: new FormControl(1, []),
                consumer_identifier: new FormControl('username', []),
                tags: new FormControl('', []),
            }),
            new FormGroup({
                name: new FormControl('upstream_latency', []),
                stat_type: new FormControl('gauge', []),
                sample_rate: new FormControl(1, []),
                consumer_identifier: new FormControl('username', []),
                tags: new FormControl('', []),
            }),
            new FormGroup({
                name: new FormControl('kong_latency', []),
                stat_type: new FormControl('gauge', []),
                sample_rate: new FormControl(1, []),
                consumer_identifier: new FormControl('username', []),
                tags: new FormControl('', []),
            }),
        ]);
        this.formPlugin = new FormGroup({
            host: new FormControl('', [Validators.required]),
            port: new FormControl(8125, [Validators.required]),
            prefix: new FormControl('kong', [Validators.required]),
            metrics: this.metricsForm,
        });
        this.tags = {request_count: [], request_size: [], response_size: [], upstream_latency: [], kong_latency: [], latency: []};
    }

    ngOnInit(): void {
        this.pluginsService.schema(this.name).subscribe(response => {
            const metrics = response.body.fields[3]?.metrics;
            this.defaultsMetrics = metrics?.default;
            this.statTypes = metrics?.elements?.fields[1]?.stat_type?.one_of;
            this.consumerIdentifiers = metrics?.elements?.fields[4]?.consumer_identifier?.one_of;
        });
    }

    submit(): void {
        if (this.formPlugin.valid) {
            const metricsTemp = this.metricsForm.getRawValue();
            const metrics = [];
            metricsTemp.forEach(metric => {
                metric.tags = this.tags[metric.name];
                metrics.push(metric);
            });
            const config = {host: this.formPlugin.value.host, port: this.formPlugin.value.port, prefix: this.formPlugin.value.prefix, metrics};
            let newPlugin = {enabled: true, name: this.name};
            newPlugin = Object.assign(newPlugin, {config});
            switch (this.type) {
                case 'global':
                    this.pluginsService.createGlobalPlugin(newPlugin).subscribe(() => {
                        this.dialog.close(true);
                    });
                    break;
                case 'service':
                    this.pluginsService.createServicePlugin(newPlugin, this.typeId).subscribe(() => {
                        this.dialog.close(true);
                    });
                    break;
                case 'route':
                    this.pluginsService.createRoutePlugin(newPlugin, this.typeId).subscribe(() => {
                        this.dialog.close(true);
                    });
                    break;
                default:
                    this.pluginsService.createGlobalPlugin(newPlugin).subscribe(() => {
                        this.dialog.close(true);
                    });
                    break;
            }
        }
    }

    isValid(type: string, name: string): boolean {
        return this.formPlugin.get(name).hasError(type);
    }

    addTag(event: MatChipInputEvent, element: string): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tags[element].push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTag(tag: string, element: string): void {
        const index = this.tags[element].indexOf(tag);
        if (index >= 0) {
            this.tags[element].splice(index, 1);
        }
    }
}
