import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Upstreams} from '../../../../core/model/upstreams.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageError} from '../../../../core/interfaces/message-error.interface';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {TranslateService} from '@ngx-translate/core';
import {UpstreamService} from '../../../../services/gateway/upstream.service';
import {CheckUrlService} from '../../../../services/apis/check-url.service';
import {ToastrService} from 'ngx-toastr';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
    selector: 'app-upstream-detail',
    templateUrl: './upstream-detail.component.html',
    styleUrls: ['./upstream-detail.component.scss']
})
export class UpstreamDetailComponent implements OnInit {
    upstream: Upstreams;
    public tabIndex = 0;
    public formUpstream: FormGroup;
    title = '';
    selectedTab = 0;
    buttonCreate = '';
    buttonEdit = '';
    buttonCancel = '';
    modalOption = 'create';
    MESSAGE_REQUIRED = 'upstreams.message.required';
    tags: string[] = [];
    algorithms: string[] = ['round-robin', 'consistent-hashing', 'least-connections'];
    hashOns: string[] = ['none', 'consumer', 'ip', 'header', 'cookie'];
    public messageError: MessageError = {
        required: [
            {type: 'required', message: this.MESSAGE_REQUIRED}
        ]
    };
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    @ViewChild(MatTabGroup) tabGroup;

    constructor(
        private translate: TranslateService,
        private upstreamService: UpstreamService,
        private checkUrl: CheckUrlService,
        private toastr: ToastrService,
        protected activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.formUpstream = this.buildForm();
        this.activatedRoute.data.subscribe(({upstream}) => {
            this.upstream = upstream;
            this.setValidatorsForm('');
            this.updateForm();
        });
    }

    setValidatorsForm(type: string): void {
        this.cleanValidators();
    }

    cleanValidators(): void {
        const keys = Object.keys(this.formUpstream.value);
        keys.forEach(key => {
            this.formUpstream.get(key).clearValidators();
        });
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.tabIndex = tabChangeEvent.index;
        const tabChange = tabChangeEvent.tab.ariaLabel;
        this.setValidatorsForm(tabChange);
        this.formUpstream.patchValue({
            authType: tabChangeEvent.tab.ariaLabel
        });
    }

    isValid(type: string, name: string): boolean {
        return this.formUpstream.get(name).hasError(type);
    }

    buildForm(): FormGroup {
        return new FormGroup({
            id: new FormControl(null, []),
            name: new FormControl('', [Validators.required]),
            tags: new FormControl(null, []),
            algorithm: new FormControl(null, []),
            hostHeader: new FormControl(null, []),
            hashOn: new FormControl(null, []),
            hashOnHeader: new FormControl(null, []),
            hashFallback: new FormControl(null, []),
            hashFallbackHeader: new FormControl(null, []),
            hashOnCookie: new FormControl(null, []),
            hashOnCookiePath: new FormControl(null, []),
            slots: new FormControl(null, []),
            healthChecks: new FormGroup({
                active: new FormGroup({
                    httpsVerifyCertificate: new FormControl(false, []),
                    httpPath: new FormControl(null, []),
                    timeout: new FormControl(null, []),
                    httpsSni: new FormControl(null, []),
                    concurrency: new FormControl(null, []),
                    type: new FormControl(null, []),
                    unhealthy: new FormGroup({
                        httpStatuses: new FormControl(null, []),
                        tcpFailures: new FormControl(null, []),
                        timeouts: new FormControl(null, []),
                        httpFailures: new FormControl(null, []),
                        interval: new FormControl(null, []),
                    }),
                    healthy: new FormGroup({
                        httpStatuses: new FormControl(null, []),
                        interval: new FormControl(null, []),
                        successes: new FormControl(null, []),
                    })
                }),
                passive: new FormGroup({
                    type: new FormControl(null, []),
                    unhealthy: new FormGroup({
                        tcpFailures: new FormControl(null, []),
                        timeouts: new FormControl(null, []),
                        httpFailures: new FormControl(null, []),
                        interval: new FormControl(null, []),
                        httpStatuses: new FormControl(null, []),
                    }),
                    healthy: new FormGroup({
                        httpStatuses: new FormControl(null, []),
                        successes: new FormControl(null, []),
                    })
                }),
                threshold: new FormControl(null, []),
            })
        });
    }

    submit(): void {
        if (this.formUpstream.invalid) {
            this.translate.get('connections.message').subscribe(translate => {
                this.toastr.error(translate.invalidForm);
                return;
            });
        }
        const formValues = this.formUpstream.value;
        const requestServer = {
            id: this.upstream.id,
            name: formValues.name,
            tags: this.tags,
            algorithm: formValues.algorithm,
            hash_on: formValues.hashOn,
            hash_fallback: formValues.hashFallback,
            hash_on_header: formValues.hashOnHeader,
            hash_fallback_header: formValues.hashFallbackHeader,
            hash_on_cookie: formValues.hashOnCookie,
            hash_on_cookie_path: formValues.hashOnCookiePath,
            slots: formValues.slots,
            https_verify_certificate: formValues.keyName,
            host_header: formValues.hostHeader,
            healthchecks: {
                active: {
                    https_verify_certificate: formValues.healthChecks?.active?.httpsVerifyCertificate || false,
                    http_path: formValues.healthChecks.active.httpPath,
                    timeout: formValues.healthChecks.active.timeout,
                    https_sni: formValues.healthChecks.active.httpsSni,
                    concurrency: formValues.healthChecks.active.concurrency,
                    type: formValues.healthChecks.active.type,
                    unhealthy: {
                        http_statuses: formValues.healthChecks.active.unhealthy.httpStatuses,
                        tcp_failures: formValues.healthChecks.active.unhealthy.tcpFailures,
                        timeouts: formValues.healthChecks.active.unhealthy.timeouts,
                        http_failures: formValues.healthChecks.active.unhealthy.httpFailures,
                        interval: formValues.healthChecks.active.unhealthy.interval
                    },
                    healthy: {
                        http_statuses: [200, 302],
                        interval: formValues.healthChecks.active.healthy.interval,
                        successes: formValues.healthChecks.active.healthy.successes
                    }
                },
                passive: {
                    type: formValues.healthChecks.passive.type,
                    unhealthy: {
                        http_failures: formValues.healthChecks.passive.unhealthy.httpFailures,
                        http_statuses: [429, 500, 503],
                        tcp_failures: formValues.healthChecks.passive.unhealthy.tcpFailures,
                        timeouts: formValues.healthChecks.passive.unhealthy.timeouts
                    },
                    healthy: {
                        successes: formValues.healthChecks.passive.healthy.successes,
                        http_statuses: [200, 201, 202, 203, 204, 205, 206, 207, 208, 226, 300, 301, 302, 303, 304, 305, 306, 307, 308]
                    }
                },
                threshold: formValues.healthChecks.threshold
            }
        };

        this.translate.get('upstreams.message').subscribe(translate => {
            this.upstreamService.updateUpstream(requestServer)
                .subscribe(() => {
                    this.toastr.success(translate.updated);
                }, (err) => {
                    this.toastr.error(translate.error);
                    console.error(err);
                });
        });
    }

    updateForm(): void {
        const row = this.upstream;
        this.formUpstream.patchValue({
            id: row.id,
            name: row.name,
            tags: row.tags,
            algorithm: row.algorithm,
            hostHeader: row.host_header,
            hashOn: row.hash_on,
            hashOnHeader: row.hash_on_header,
            hashFallback: row.hash_fallback,
            hashFallbackHeader: row.hash_fallback_header,
            hashOnCookie: row.hash_on_cookie,
            hashOnCookiePath: row.hash_on_cookie_path,
            slots: row.slots,
            healthChecks: {
                active: {
                    httpsVerifyCertificate: row.healthchecks.active.https_verify_certificate,
                    httpPath: row.healthchecks.active.http_path,
                    timeout: row.healthchecks.active.timeout,
                    httpsSni: row.healthchecks.active.https_sni,
                    concurrency: row.healthchecks.active.concurrency,
                    type: row.healthchecks.active.type,
                    unhealthy: {
                        httpStatuses: row.healthchecks.active.unhealthy.http_statuses,
                        tcpFailures: row.healthchecks.active.unhealthy.tcp_failures,
                        timeouts: row.healthchecks.active.unhealthy.timeouts,
                        httpFailures: row.healthchecks.active.unhealthy.http_failures,
                        interval: row.healthchecks.active.unhealthy.interval,
                    },
                    healthy: {
                        httpStatuses: row.healthchecks.active.healthy.http_statuses,
                        interval: row.healthchecks.active.healthy.interval,
                        successes: row.healthchecks.active.healthy.successes,
                    }
                },
                passive: {
                    type: row.healthchecks.passive.type,
                    unhealthy: {
                        tcpFailures: row.healthchecks.passive.unhealthy.tcp_failures,
                        timeouts: row.healthchecks.passive.unhealthy.timeouts,
                        httpFailures: row.healthchecks.passive.unhealthy.http_failures,
                        interval: row.healthchecks.passive.unhealthy.interval,
                        httpStatuses: row.healthchecks.passive.unhealthy.http_statuses,
                    },
                    healthy: {
                        httpStatuses: row.healthchecks.passive.healthy.http_statuses,
                        successes: row.healthchecks.passive.healthy.successes,
                    }
                },
                threshold: row.healthchecks.threshold,
            }
        });
    }

    addTag(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.tags.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    }

    removeTag(tag: string): void {
        const index = this.tags.indexOf(tag);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }
}
